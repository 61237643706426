import React, { useState, useEffect, Fragment } from "react";
import { Layout, Grid,Button,message} from "antd";
import { CopyrightCircleOutlined } from "@ant-design/icons";
import "./FullLayout.less";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Axios from "axios";
import { url } from "../config/global";
// import handleLogout from "./Navbar";
const { Header, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;

function FullLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [hidesidebar, setSidebar] = useState(false);
  const screens = useBreakpoint();

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
    setSidebar(!hidesidebar);
  };

  useEffect(() => {
    
    if(sessionStorage.getItem("username") == 'dashboard-incoming')
    {
      setSidebar(true);
    }
    // props.screens.xs && setCollapsed(false)
    const width = window.innerWidth;
    width <= 500 && setCollapsed(false);
  }, []);

  const refresh = () => {
    const path = props.history.location.pathname;
    window.location = path;
  };
  const handleLogout = () => {
    console.log("terclick");
    let fd = new FormData();
    fd.set("id_user", sessionStorage.getItem("id_user"));
    Axios.post(`${url}/logout`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log('Logout')
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("id_user");
        sessionStorage.removeItem("parent_item");
        sessionStorage.removeItem("shift_code");
        sessionStorage.removeItem("dn_no");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
    return props.history.push("/login");
  };
  return (
    <Layout>
      {!hidesidebar && (<Sider 
        breakpoint="lg"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        className={`sidebar ${
          !screens.xs
            ? collapsed
              ? "sidebar-collapsed"
              : "sidebar-uncollapsed"
            : !collapsed
            ? "sidebar-none"
            : "sidebar-collapsed"
        }`}
        trigger={null}
        collapsed={collapsed}
      >
        <Sidebar {...props} collapsed={collapsed} />
      </Sider>)}
      
      <Layout>
        
          {sessionStorage.getItem("username") != 'dashboard-incoming' &&
          (
            <Header className="header">
              <Navbar
                {...props}
                refresh={refresh}
                collapsed={collapsed}
                screens={screens}
                onToggleCollapsed={handleCollapsed}
              />
            </Header>
          )}
          
        <Fragment>{props.children}</Fragment>
        {/* <Footer>
          <CopyrightCircleOutlined /> 2024 Sankei Dharma Indonesia {sessionStorage.getItem("username") == 'dashboard-incoming' &&
          (
            <Button type="link" onClick={() => handleLogout() }>Logout</Button>
          )}
        </Footer> */}
      </Layout>
    </Layout>
  );
}

export default FullLayout;
