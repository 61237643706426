/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Dropdown,
  Menu,
  Form,
  DatePicker,
  Select
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined, RotateRightOutlined } from "@ant-design/icons";
import send from "../../utils/send";

const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;

function PHistoryScanKanbanNG() {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [isModalVisibleChangeKanban, setIsModalVisibleChangeKanban] = useState(false);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [changeKanbanValid, setChangeKanbanValid] = useState(true);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [kanbanNumberEmpty, setKanbanNumberEmpty] = useState(false);
  const [kanbanActive, setKanbanActive] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [data, setData] = useState({
    kanban_number: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
    getData();
  }, [keyword, page, sort, date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/histori-kanban-ng`, {
      params: {
        start_date: date.start,
        end_date: date.end,
        keyword,
        columns: ["item_number", "parent_item", "dn_no", "created_at", 'id_qad', "kanban_number", 'storage'],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };


  const columns = [
    {
      title: "Parent Item",
      dataIndex: "parent_item",
      sorter: true,
    },
    {
      title: "Component Item",
      dataIndex: "id_qad",
      sorter: true,
    },
    {
      title: "Kanban Number NG",
      dataIndex: "kanban_number",
      sorter: true,
    },
    {
      title: "Kanban Number NEW",
      dataIndex: "change_kanban",
      sorter: true,
    },
    {
      title: "Kanban Type",
      dataIndex: "kanban_type",
      sorter: true,
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "model",
      sorter: true,
    },
    {
      title: "Qty Kanban",
      dataIndex: "qty_kanban",
      sorter: true,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Group",
      dataIndex: "group",
      sorter: true,
    },
    {
      title: "DN No",
      dataIndex: "dn_no",
      sorter: true,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
    },
    {
      title: "Tanggal Changed Kanban",
      dataIndex: "created_at",
      render: (tgl) => moment(tgl).format("DD MMMM YYYY"),
      sorter: true,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Kanban NG"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  {" "}
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />

                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "history-scan-produksi/change-kanban"
                    ) && (
                      <Button 
                        disabled={changeKanbanValid}
                        onClick={() => showModalChangeKanban()} 
                        type="primary"
                      >
                        <RotateRightOutlined />
                        Change Kanban
                      </Button>
                  )}
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 2000 }}
                    columns={columns}
                    dataSource={dataHistory.data}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dataHistory.total,
                      showSizeChanger: true,
                    }}
                    loading={loadingHistory}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PHistoryScanKanbanNG;
