import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Modal,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { PlusOutlined, ExportOutlined, TableOutlined } from "@ant-design/icons";
import "./SPMaterialOrder.less";
import fileDownload from "js-file-download";
import { pathnametotitle } from "../../components/function";

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function SPMaterialOrderNonPO(props) {
  const [keyword, setKeyword] = useState("");
  const [componentItem, setcomponentItem] = useState([]);
  const [keywordModal, setKeywordModal] = useState("");
  const [materials, setMaterials] = useState([]);
  const [materialPO, setMaterialPO] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statPO, setStatPO] = useState(false);
  const [collectCheckbox, setCollectCheckbox] = useState([]);
  const [sort, setSort] = useState({
    sort: "vendor_code",
    order: "ascend",
  });
  const [sortModal, setSortModal] = useState({
    sort: "vendor_code",
    order: "ascend",
  });
  const [pageModal, setPageModal] = useState(1);
  const [pageSizeModal, setPagesizeModal] = useState(10);

  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedRowKeysDataPO, setSelectedRowKeysDataPO] = useState([]);
  const [selectedRowsDataPO, setSelectedRowsDataPO] = useState([]);
  const [getSupplier, setGetSupplier] = useState([]);
  const [generate, setGenerate] = useState({
    purchase_order: "",
  });

  const [data, setData] = useState({
    dataMaterial: [],
    purchase_order: "",
    item_number: "",
    qty_ordered: "",
  });
  const [supplierName, setSupplierName] = useState([]);

  const pathname = props.location.pathname.replaceAll("/", "");

  const [error, setError] = useState(null);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleSearchModal = (value) => {
    setKeywordModal(value);
    getDataPO()
  };

  const showModal = () => {
    if (selectedRowKeysData.length == 0) {
      message.error("Anda Belum Memilih Data");
    } else {
      var selected = [];
      selectedRowsData.map((data, index) => {
        selected.push(data.component_item)
      });
      getDataPO(selected)

    }
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  useEffect(() => {
    getData(keyword, page, sort);
    // getGroupingSupplier();
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const getGroupingSupplier = (keyword_supplier) => {
    Axios.get(`${url}/material-order/get-supplier`, {
      params: {
        keyword:keyword_supplier,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setGetSupplier(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(materials)
  console.log(getSupplier)
  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/material-order/no-po`, {
      params: {
        keyword,
        columns: [
          "month",
          "years",
          "supplier_code",
          "supplier_name",
          "po_number",
          "publisher_date",
          "delivery_request_date",
          "component_item",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const dataPO = res.data.data;
        const resData = dataPO.filter(x => x.quantity_ordered != x.quantity_received);
        setMaterials({
          ...materials,
          total: res.data.total,
          type: res.data.type,
          data: resData
        });

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const getDataPO = (selected) => {
    setLoading(true);
    Axios.get(`${url}/master-material-order`, {
      params: {
        keyword:keywordModal,
        columns: [
          "purchase_order",
          "component_item",

        ],
        component_item:selected,
        perpage: pageSize,
        page,
        sort: sortModal.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setMaterialPO(res.data);
        setIsModalVisible(true);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };
  const handleTableModalChange = (pagination, filters, sorter) => {
    setPageModal(pagination.current);
    console.log(sorter.field);
    setPagesizeModal(pagination.pageSize);
    setSortModal({...sortModal,
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
      key: "purchase_order",
      sorter: true,
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    {
      title: "Item Number",
      dataIndex: "component_item",
      sorter: true,
    },
    {
      title: "Description 1",
      dataIndex: "description1",
      sorter: true,
    },
    {
      title: "Description 2",
      dataIndex: "description2",
      sorter: true,
    },
    {
      title: "Description 3",
      dataIndex: "description3",
      sorter: true,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },

    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      width: 270,
      sorter: true,
      // render: (data) => (
      //   data.detail_supp ? data.detail_supp.supplier_name : ''
      // ),
    },


    {
      title: "Quantity Ordered",
      dataIndex: "quantity_ordered",
      sorter: true,
    },
    {
      title: "Quantity Daily Order",
      dataIndex: "qty_daily_order",
      sorter: true,
    },
    {
      title: "Quantity Received",
      dataIndex: "quantity_received",
      sorter: true,
    },

    {
      title: "Std Pack",
      dataIndex: "std_pack",
      sorter: true,
    },
    {
      title: "Quantity Open",
      dataIndex: "quantity_open",
      sorter: true,
    },
  ];

  const handleSubmit = () => {
    if (selectedRowKeysDataPO.length == 0) {
      message.error("Anda Belum Memilih Data");
    } else if (selectedRowKeysDataPO > 1) {
      message.error("Hanya dapat memilih satu");
    } else {
      Axios.post(
        `${url}/material-order/no-po/merge`,
        {
          purchase_order: selectedRowsDataPO[0].purchase_order,
          children: selectedRowsData,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          props.history.push("/material-order");
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          }
          setLoading(false);
        });
    }
  };

  const handleReset = () => {
    setData({});
    setSelectedRowsData([]);
    setSelectedRowKeysData([]);
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const filterBySupplier = (value) => {
    if(value !== undefined){
      setKeyword(value);
    } else {
      setKeyword('');
      getData();

    }
  };

  return (
    <Fragment>
      <Modal
        width={1200}
        title="Data PO"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>

                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearchModal(value)}
                  />
                </Col>
                </Row>
                <Table
                  scroll={{ x: 1800 }}
                  columns={columns}
                  dataSource={materialPO.data}
                  rowKey={(data) => data.purchase_order}
                  onChange={handleTableModalChange}
                  pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: materialPO.total,
                    showSizeChanger: true,
                  }}
                  rowSelection={{
                    selectedRowKeysPO: selectedRowKeysDataPO,
                    type: "checkbox",
                    onChange: (selectedRowKeysPO, selectedRowsPO, record) => {
                      setSelectedRowsDataPO(selectedRowsPO);
                      setSelectedRowKeysDataPO(selectedRowKeysPO);

                      const purchase_order = selectedRowsPO
                        ? selectedRowsPO[0]
                        : null;

                      setGenerate({
                        ...generate,
                        purchase_order,
                      });
                    },
                    getCheckboxProps: (record) => {
                      const checkDisable = selectedRowsDataPO
                        ? selectedRowsDataPO[0]
                        : null;

                      return {
                        disabled:
                          (checkDisable &&
                            checkDisable.purchase_order !==
                              record.purchase_order) ||
                          record.status === 1,
                      };
                    },

                    columnTitle: selectedRowsDataPO.length > 0 ? "" : <></>,
                  }}
                  loading={loading}
                />
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                        className="mr-button"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Fragment>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Non-Regular Orders"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={6}>
                  <Space>
                    <Link to="/material-order">
                      <Button type="primary">
                        <TableOutlined />
                        Table PO
                      </Button>
                    </Link>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={10} className="align-right">
                  <Select
                    allowClear
                    showSearch
                    onFocus={() => getGroupingSupplier('')}
                    onSearch={(value) => getGroupingSupplier(value)}
                    onChange={(value) => filterBySupplier(value)}
                    filterOption={false}
                    style={{ width: "240px" }}
                    placeholder="Select Supplier"
                  >
                    {getSupplier && getSupplier.map((item) => {
                        return (
                          <Option value={item.supplier_code} key={item.supplier_code}>
                            {item.supplier_name}
                          </Option>
                        );
                    })}
                  </Select>
                </Col>

                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1800 }}
                    columns={columns}
                    dataSource={materials.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: materials.total,
                      showSizeChanger: true,
                    }}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        let collArr = [];
                        selectedRows.map((x) => {
                          return x.qty_daily_order == 0 || x.qty_daily_order == null ?
                            message.error(`Silahkan perbaiki atau isi terlebih dahulu Qty Daily Order, item number = ${x.component_item}`)
                            : ''
                        })

                        selectedRows.map((x) => {
                          return x.qty_daily_order == 0 || x.qty_daily_order == null ?
                            collArr.push(true)
                            : collArr.push(false)
                        })

                        setStatPO(collArr.some(x => x === true));

                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        const purchase_order = selectedRows
                          ? selectedRows[0]
                          : null;

                        setGenerate({
                          ...generate,
                          purchase_order,
                        });
                      },
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;

                        return {
                          disabled:
                            (checkDisable &&
                              checkDisable.purchase_order !==
                                record.purchase_order) ||
                            record.status === 1,
                        };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                    loading={loading}
                  />
                  <Row justify="start">
                    <Col style={{ marginTop: 20 }}>
                      <Space>
                        <Button
                          disabled={statPO}
                          type="primary"
                          onClick={showModal}
                          loading={loading}
                          className="mr-button"
                        >
                          Pilih PO
                        </Button>

                        <Button
                          onClick={handleReset}
                          type="default"
                          htmlType="button"
                          className="mr-button"
                        >
                          Reset
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPMaterialOrderNonPO;
