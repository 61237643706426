import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Modal,
  Badge
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { PlusOutlined, ExportOutlined, TableOutlined } from "@ant-design/icons";
import "./SPMaterialOrder.less";
import fileDownload from "js-file-download";
import { pathnametotitle } from "../../components/function";

const { Search } = Input;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function SPMaterialOrder(props) {
  const [keyword, setKeyword] = useState("");
  const [materials, setMaterials] = useState([]);
  const [treshold, setTreshold] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [materialID, setMaterialID] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [sort, setSort] = useState({
    sort: "vendor_code",
    order: "ascend",
  });

  const [data, setData] = useState({
    dataMaterial: [],
    purchase_order: "",
    item_number: "",
    qty_ordered: "",
  });

  const pathname = props.location.pathname.replaceAll("/", "");

  const [error, setError] = useState(null);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-material-order`, {
      params: {
        keyword,
        columns: [
          "purchase_order",
          "order_date",
          "group",
          "area",
          "site",
          "prod_line",
          "supplier_code",
          "supplier_name",
          "um",
          "due_date",
          "wo_id",
          "st",
          "type",
          "operation",
          "order_revision",
          "status",
          "presentase",
          "component_item",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setMaterials(res.data);
        setTreshold(res.data.treshold);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetQAD = () => {
    setLoading(true);
    Axios.get(`${url}/soap/material-order`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });
        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };
  const exportMaterialOrder = () => {
    Axios.get(`${url}/master-material-order/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Material Order" + ".xlsx");
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
      key: "purchase_order",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (text) => {
        return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      },
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      sorter: true,
    },
    {
      title: "Group",
      dataIndex: "group",
      sorter: true,
    },
    // {
    //   title: "Area",
    //   dataIndex: "area",
    //   sorter: true,
    // },
    {
      title: "Site",
      dataIndex: "site",
      sorter: true,
    },
    // {
    //   title: "Prod Line",
    //   dataIndex: "prod_line",
    //   sorter: true,
    // },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: true,
      // render: (_, data) => {
      //   return data.supplier && data.supplier.supplier_name;
      // },
    },
    {
      title: "UM",
      dataIndex: "um",
      sorter: true,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      sorter: true,
    },
    {
      title: "WO ID",
      dataIndex: "wo_id",
      sorter: true,
    },
    // {
    //   title: "St",
    //   dataIndex: "st",
    //   sorter: true,
    // },

    {
      title: "Type",
      dataIndex: "type",
      sorter: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      sorter: true,
    },
    {
      title: "Order Revision",
      dataIndex: "order_revision",
      sorter: true,
    },
    {
      title: "Status",
      sorter: true,
      render: (data) => {
        if (data.status >= data.complete) {
          return (
            <div>
              <Badge
                count={"COMPLETE"}
                style={{
                  backgroundColor: "#d9534f",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else if (data.status >  data.waiting) {
          return (
            <div>
              <Badge
                count={"OUTSTANDING"}
                style={{
                  backgroundColor: "#f0ad4e",
                  color: "black",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else if(data.status <=  data.waiting) {
          return (
            <div>
              <Badge
                count={"OPEN"}
                style={{
                  backgroundColor: "#5cb85c",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Presentase",
      dataIndex: "persentase",
      render: (data) => {
        return data+"%";
      },
      sorter: true,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title={pathnametotitle(pathname)}
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "get-sap/master-material-order"
                      ) && (
                        <Link>
                          <Button onClick={handleGetQAD} type="primary">
                            <PlusOutlined />
                            Sync QAD
                          </Button>
                        </Link>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "material-order/export"
                      ) && (
                        <Button onClick={exportMaterialOrder} type="primary">
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                    <Link to="/material-order/data">
                      <Button type="primary">
                        <TableOutlined />
                        Non-Regular Order
                      </Button>
                    </Link>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1800 }}
                    columns={columns}
                    dataSource={materials.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: materials.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPMaterialOrder;
