import axios from "axios";

const send = async ({ url, method, headers, data, isFormData = false }) => {
  if (method === "post") {
    return axios
      .post(url, data, {
        headers: {
          ...headers,
          "X-localization": localStorage.getItem("lang"),
        },
      })
      .then((res) => {
        return {
          success: true,
          data: res.data,
        };
      })
      .catch((err) => {
        if (err.response) {
          return {
            success: false,
            data: err.response.data,
          };
        } else {
          return {
            success: false,
            data: {
              errors: [],
              message: "Server is down",
            },
          };
        }
      });
  } else if (method === "delete") {
    return axios
      .delete(url, {
        headers: {
          ...headers,
          "X-localization": localStorage.getItem("lang"),
        },
      })
      .then((res) => {
        return {
          success: true,
          data: res.data,
        };
      })
      .catch((err) => {
        if (err.response) {
          return {
            success: false,
            data: err.response.data,
          };
        } else {
          return {
            success: false,
            data: {
              errors: [],
              message: "Server is down",
            },
          };
        }
      });
  } else {
    return axios
      .get(url, {
        params: data,
        headers: {
          ...headers,
          "X-localization": localStorage.getItem("lang"),
        },
      })
      .then((res) => {
        return {
          success: true,
          data: res.data,
        };
      })
      .catch((err) => {
        if (err.response) {
          // if (err.response.status === 401) {
          //   localStorage.removeItem("user");
          // }
          return {
            success: false,
            data: err.response.data,
          };
        } else {
          return {
            success: false,
            data: {
              errors: [],
              message: "Server is down",
            },
          };
        }
      });
  }
};

export default send;
