import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  DatePicker,
  Badge,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Search } = Input;

const { RangePicker } = DatePicker;
const { Content } = Layout;

function SPHistoryOutgoingSupplier(props) {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [page, setPage] = useState(1);

  const [historyDetails, setHistoryDetails] = useState([]);
  const [historyDetailsTotal, setHistoryDetailsTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);

  const [data, setData] = useState({
    dn_no: "",
    purchase_order: "",
    supplier_code: "",
    supplier_name: "",
    Alamat: "",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getHistoryReceivingDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  // useEffect(() => {
  //   getHistoryReceivingDetails();
  // }, []);

  useEffect(() => {
    if (selectedId) {
      getHistoryReceivingDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  // useEffect(() => {
  //   getData(keyword, page, sort);
  // }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/histori-outgoing-supplier`, {
      params: {
        keyword,

        columns: [
          "number",
          "supplier_name",
          "po_number",
          "vehicle_number",
          "dn_no",
          "purchase_order",
          "supplier_code",
          "supplier_name",
          "alamat",
          "project",
          "description",
          "part_number",
          "min_of_stock",
          "sum_of_kanban_order",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagesize(pagination.pageSize);
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPagesize(pagination.pageSize);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getHistoryReceivingDetails(data2[0]);
    }
  };

  const getHistoryReceivingDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/histori-outgoing-supplier/details/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setHistoryDetails(res.data.data);
        setHistoryDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const printPdf = (id) => {
    setLoading(true);
    Axios.get(`${url}/histori-outgoing-supplier/print/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }).then((res) => {
      setLoading(false);
      // var blob = new Blob([res.data], { type: "application/pdf" });
      // var blobURL = URL.createObjectURL(blob);
      // const newTab = window.open(blobURL);
      // if(newTab) {
      //   newTab.addEventListener('load', () => {
      //     // Set the download attribute for the custom filename
      //     newTab.document.title = 'filepdf.pdf';
      //   });
      // }

      window.open(res.data.filename);

      getData();
    }).catch((err) => {
      if (err.response) {
        Modal.error({
          title: "Failed!",
          content: err.response.data.message,
        })
        // message.error(err.response.data.message);
      }
    });
  };

  const columns = [
    // {
    //   title: "Kanban Number",
    //   dataIndex: "kanban_number",
    //   sorter: true,
    // },
    {
      title: "Nomor",
      dataIndex: "number",
      sorter: true,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "No PO",
      dataIndex: "po_number",
      sorter: true,
    },
    {
      title: "NO Kendaraan",
      dataIndex: "vehicle_number",
      sorter: true,
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      sorter: true,
    },
    {
      title: "Status Print",
      dataIndex: "status_print",
      sorter: true,
      align: "center",
      render: (status_print) => {
        if (
          status_print === undefined ||
          status_print === "" ||
          status_print === null
        ) {
          return (
            <div>
              <Badge
                count={"NOT PRINT"}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else if (status_print === "PRINT") {
          return (
            <div>
              <Badge
                count={status_print}
                style={{ backgroundColor: "green", color: "white" }}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Badge
                count={status_print}
                style={{ backgroundColor: "blue", color: "white" }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Tanggal Print",
      dataIndex: "print_date",
      sorter: true,
    },

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 75,
      render: (id, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {/* {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "history-outgoing-supplier/export"
                    ) && ( */}
                  <Button onClick={() => printPdf(id)} type="primary">
                    <ExportOutlined />
                    {data.print_date ? "Re-Print" : "Print"}
                  </Button>
                  {/* )} */}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  const getHistory = () => {
    const columns = [
      {
        title: "Kanban Number",
        dataIndex: "kanban_number",
        // sorter: true,
      },
      {
        title: "Part Name",
        dataIndex: "description_1",
        align: "left",
        key: "description_1",
      },
      {
        title: "Part Number",
        dataIndex: "description_2",
        align: "left",
        key: "description_2",
      },

      {
        title: "ID QAD",
        dataIndex: "id_qad",
        align: "left",
        key: "id_qad",
      },

      {
        title: "Storage",
        dataIndex: "storage",
        align: "left",
        key: "storage",
      },

      {
        title: "Area",
        dataIndex: "area",
        align: "left",
        key: "area",
      },
      {
        title: "Type",
        dataIndex: "model",
        align: "left",
        key: "model",
      },
      {
        title: "Jumlah",
        dataIndex: "qty_kanban",
        align: "left",
        key: "qty_kanban",
      },
      {
        title: "Unit",
        dataIndex: "unit_of_measure",
        align: "left",
        key: "unit_of_measure",
      },
      {
        title: "Keterangan",
        dataIndex: "keterangan",
        align: "left",
        key: "keterangan",
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              scroll={{ x: 1200 }}
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={historyDetails}
              size="small"
              pagination={false}
              // pagination={{
              //   pageSize: perPageDetail,
              //   current: pageDetail,
              //   total: historyDetailsTotal,
              //   showSizeChanger: true,
              //   style: {
              //     marginTop: "2rem",
              //     marginBottom: 0,
              //   },
              // }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Outgoing Supplier"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getHistory,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPHistoryOutgoingSupplier;
