/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateMasterWarehouseLocation(props) {
  const [data, setData] = useState({
    site: "",
    location: "",
    description: "",
    inv_status: "",
    model: "",
    area: "",
    storage: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("site", data.site);
    fd.set("location", data.location);
    fd.set("description", data.description);
    fd.set("printer_name", data.printer_name);
    fd.set("area", data.area);
    fd.set("storage", data.storage);
    fd.set("model", data.model);
    fd.set("inv_status", data.inv_status);

    Axios.post(`${url}/master-warehouse-location`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-warehouse-location");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      site: "",
      location: "",
      description: "",
      model: "",
      are: "",
      storage: "",
      inv_status: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Warehouse Location"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-warehouse-location">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  Create Master Warehouse Location
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Storage"
                  validateStatus={error && error.storage ? "error" : false}
                  help={error && error.storage ? error.storage[0] : false}
                >
                  <Input
                    value={data.storage}
                    onChange={(e) => handleChange("storage", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Area"
                  validateStatus={error && error.area ? "error" : false}
                  help={error && error.area ? error.area[0] : false}
                >
                  <Input
                    value={data.area}
                    onChange={(e) => handleChange("area", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Model"
                  validateStatus={error && error.model ? "error" : false}
                  help={error && error.model ? error.model[0] : false}
                >
                  <Input
                    value={data.model}
                    onChange={(e) => handleChange("model", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Printer Name"
                  validateStatus={error && error.model ? "error" : false}
                  help={error && error.model ? error.model[0] : false}
                >
                  <Input
                    placeholder="cth: Honeywell"
                    value={data.printer_name}
                    onChange={(e) => handleChange("printer_name", e.target.value)}
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateMasterWarehouseLocation;
