import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Table,
  Layout,
  message,
  Select,
  DatePicker,
  Modal,
} from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import fileDownload from "js-file-download";

const { Option } = Select;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const RReportTracebility = () => {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [childData, setChildData] = useState([]);
  const [gChildData, setGChildData] = useState([]);
  const [loadingChild, setLoadingChild] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [data, setData] = useState([]);
  const [deliveryNoteNo, setDeliveryNoteNo] = useState('');
  const [dnFilter, setDnFilter] = useState("");
  const [listScanGrup, setListScanGrup] = useState([]);
  const [loading, setLoading] = useState(1);
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [selectedScanGrup, setSelectedListScanGrup] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  // useEffect(() => {
  //   // Data dummy untuk tabel induk
  //   const parentData = [
  //     {
  //       key: "1",
  //       index: 1,
  //       delivery_note_no: "DN001",
  //       delivery_note_date: "2023-10-01",
  //       childData: [
  //         {
  //           key: "1-1",
  //           index: "1-1",
  //           parent_item: "Item A",
  //           part_name: "Part A",
  //           lot_prod: "Lot 1",
  //           kanban: {
  //             kanban_type: "Kanban WIP",
  //           },
  //         },
  //         {
  //           key: "1-2",
  //           index: "1-2",
  //           parent_item: "Item B",
  //           part_name: "Part B",
  //           lot_prod: "Lot 2",
  //           kanban: {
  //             kanban_type: "Kanban Supplier",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       key: "2",
  //       index: 2,
  //       delivery_note_no: "DN002",
  //       delivery_note_date: "2023-10-02",
  //       childData: [
  //         {
  //           key: "2-1",
  //           index: "2-1",
  //           parent_item: "Item C",
  //           part_name: "Part C",
  //           lot_prod: "Lot 3",
  //           kanban: {
  //             kanban_type: "Kanban WIP",
  //           },
  //         },
  //       ],
  //     },
  //   ];

  //   setData(parentData);
  // }, []);

  const getFilterDN = () => {
    Axios.get(`${url}/filter-tracebility?dn=${dnFilter}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setListScanGrup(res.data.data);
        getData(dnFilter);
        // setHistoryDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        // setLoadingDetail(false);
      });
    // 23/10/SDI/0011
  };

  const getData = (delivery_note_no) => {
    setLoading(true);
    Axios.get(`${url}/tracebility`, {
      params: {
        dn_no: delivery_note_no,
        columns: ["delivery_note_no"],
        perpage: pageSize,
        page,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
console.log(res.data)          
        setDeliveryNoteNo(delivery_note_no)
        console.log('DN',deliveryNoteNo)
        console.log("trace", data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "No",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + (index + 1),
    },
    {
      title: "Items",
      dataIndex: "parent_item",
      render: (text, record) => record.kanban[0].id_qad,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "part_name",
    },
    {
      title: "No Kanban",
      key: "kanban_number",
      dataIndex: "kanban_number"
    },
    {
      title: "Lot Produksi",
      key: "kanban.lot_prod",
      render: (text, record) => record.kanban[0].lot_prod,
    },
    {
      title: "Tanggal Produksi",
      key: "tanggal_produksi",
      render: (text, record) => record.tanggal_produksi,
    },
    {
      title: "Kanban Type",
      key: "kanban.kanban_type",
      render: (text, record) => record.kanban[0].kanban_type,
    },
  ];

  const ChildTable = ({ data, child }) => {
    console.log('data child',data);
    
    console.log('child',child);
    
    const [loadingChild, setLoadingChild] = useState(false);
    const [childData, setChildData] = useState([]);
    
    useEffect(() => {
      if (!child) {
        console.log('casc')
        if (data.kanban.kanban_type === "Kanban WIP" && !childData.length) {
          // Jika kanban_type adalah "Kanban WIP" dan childData masih kosong, maka buat permintaan API
          setLoadingChild(true);

          Axios.get(`${url}/wip-tracebility?barcode=${data.kanban[0].barcode}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
            .then((res) => {
              setChildData(res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                message.error(err.response.data.message);
              } else {
                message.error("Backend not yet Started");
              }
            })
            .finally(() => {
              setLoadingChild(false);
            });
        }
        if (data.kanban.kanban_type !== "Kanban WIP" && !childData.length) {
          // Jika kanban_type tidak sama dengan "Kanban WIP" dan childData masih kosong, maka buat permintaan API
          setLoadingChild(true);

          Axios.get(
            `${url}/supplier-tracebility?barcode=${data.kanban[0].barcode}`,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setChildData(res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                message.error(err.response.data.message);
              } else {
                message.error("Backend not yet Started");
              }
            })
            .finally(() => {
              setLoadingChild(false);
            });
        }
      } else if (child) {

        console.log('cecek', data)
        console.log('cececsak', childData)
        if (
          data?.kanban?.kanban_type === "Kanban WIP" || data?.details?.kanban_type === "Kanban WIP"
        ) {
          console.log('KANBAN WIP')
          // Jika kanban_type adalah "Kanban WIP" dan childData masih kosong, maka buat permintaan API
          setLoadingChild(true);

          Axios.get(
            `${url}/wip-tracebility?barcode=${data.kanban.barcode}`,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setChildData(res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                message.error(err.response.data.message);
              } else {
                message.error("Backend not yet Started");
              }
            })
            .finally(() => {
              setLoadingChild(false);
            });
        }
        console.log(data.kanban,'KNB');
        if (
          data?.kanban?.kanban_type !== "Kanban WIP" || data?.details?.kanban_type !== "Kanban WIP" 
        ) {
          // Jika kanban_type tidak sama dengan "Kanban WIP" dan childData masih kosong, maka buat permintaan API
          // setLoadingChild(true);
          console.log('KANBAN TIDAK WIP')
          Axios.get(
            `${url}/supplier-tracebility?barcode=${data.kanban.barcode}`,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setChildData(res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                message.error(err.response.data.message);
              } else {
                message.error("Backend not yet Started");
              }
            })
            .finally(() => {
              // setLoadingChild(false);
            });
        }
      }
    }, [
      data, child
    ]);

    const childColumns = [
      {
        title: "No",
        key: "index",
        render: (value, item, index) => (page - 1) * 10 + (index + 1),
      },
      
      {
        title: "Items",
        dataIndex: "items",
        render: (text, record) =>
          record?.wip_history_details?.component_item ?? record?.kanban?.id_qad,
      },
      {
        title: "Model",
        dataIndex: "model",
        render: (text, record) =>
          record?.model ?? record?.model,
      },
      {
        title: "Part name",
        dataIndex: "part_name",
        render: (text, record) =>
          record?.part_name ?? record?.kanban?.part_name,
      },
    
      {
        title: "No Kanban",
        dataIndex: "no_kanban_component",
      },
    
      {
        title: "Tanggal produksi",
        dataIndex: "printed_at",
        render: (text, record) => {
          if (record?.kanban?.supplier_name && record?.kanban?.supplier_code) {
            return "-";
          } else {
            return record?.details?.printed_at ?? record?.tgl_produksi;
          }
        },
      },
      {
        title: "Lot Produksi",
        dataIndex: "printed_at",
        render: (text, record) =>
          record?.lot_prod ?? record?.lot_prod,
      },
      {
        title: "Tanggal delivery",
        dataIndex: "tanggal_deliveri",
        key: "tanggal_deliveri",
      },
      {
        title: "Supplier code",
        dataIndex: "suplier_code",
        render: (text, record) => record?.kanban?.supplier_code,
      },
      {
        title: "Supplier name",
        dataIndex: "suplier_name",
        render: (text, record) => record?.kanban?.supplier_name,
      },
    ];
    return (
      <Table
        columns={childColumns}
        dataSource={childData}
        rowKey={(data) => data._id}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <ChildTable data={record} child={true} />
          ),
        }}
      />
    );
  };



  const handleExpand = async (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record._id]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record._id));
    }
  };

  const handleExport = (delivery_note_no) => {

    setLoadingExport(true)
    // console.log(delivery_note_no);
    Axios.get(`${url}/tracebility/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: {
        // dn_no: delivery_note_no,
        start_date: date.start,
        end_date: date.end,
      },
    
    })
    .then(res => {
      setLoadingExport(false)
      window.open(res.data.file)
    })
    .catch(err => {
      setLoadingExport(false)

      if (err.response) {
        Modal.error({
          title: "Failed!",
          content:err.response.data.message,
        });
        // message.error(err.response.data.message);
      }
    });
  };

  const onClickExport = () => {
    setModalOpen(true)

    // handleExport(dnFilter);
  };
 
  const handleOk = () => {
    setModalOpen(true)
  }
  const handleCancel = () => {
    setModalOpen(false)
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Report Tracebility"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>
      
      <Content className="content">
      <Modal title="Pilih Rentang Tanggal" visible={modalOpen} onOk={handleOk} 
      footer={[
          <Button key="back" onClick={handleCancel}>
            Batal
          </Button>,
          <Button key="submit" type="primary" loading={loadingExport} onClick={() => handleExport(dnFilter)}>
            Export
          </Button>,
          
        ]} onCancel={handleCancel}>
         <RangePicker
         disabled={loadingExport}
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
      </Modal>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col md={24}>
                  <Row gutter={20}>
                    <Col xs={4}>
                      <Input
                      
                        placeholder="Insert DN"
                        value={dnFilter}
                        onChange={(e) => setDnFilter(e.target.value)}
                        onPressEnter={(e) => getFilterDN(e.target.value)}
                      />
                    </Col>

                    <Col md={6}>
                      <Button
                        onClick={() => getFilterDN()}
                        type="primary"
                        style={{marginRight:'10px'}}
                      >
                        <SearchOutlined />
                        Filter
                      </Button>
                    <Button type="primary" onClick={onClickExport} shape="round" icon={<DownloadOutlined />} size={'Large'}>
                        Export
                    </Button>
                      
                   
                    </Col>
                    
                  </Row>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    expandable={{
                      expandedRowRender: (record) => (
                        <ChildTable data={record} child={false} />
                      ),
                      onExpand: handleExpand,
                      expandedRowKeys,
                    }}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                  {/* 
                  <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="index"
                    expandable={{
                      expandedRowRender: (record) => (
                        <ChildTable data={record.childData} />
                      ),
                      onExpand: handleExpand,
                      expandedRowKeys,
                    }}
                  /> */}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
};

export default RReportTracebility;
