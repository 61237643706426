/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Dropdown,
  Menu,
  Form,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;

function PDHistoryDNManifest() {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [dnDetails, setdnDetails] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [keywordDetails, setKeywordDetails] = useState("");
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [dnmDetailsTotal, setDNMDetailsTotal] = useState(0);
  const [data, setData] = useState({
    kanban_number: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date]);

  const scanNumberRef = useRef(null);
  console.log("dv", data);
  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);

      getDataDetailDN(data2[0]);
    }
  };

  const getDataDetailDN = (dn_id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/delivery-note-manifest-history-details`, {
      params: {
        keyword: keywordDetails,
        delivery_note_no: dn_id,
        // columns: ["_id", "quantity_kanban"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setdnDetails(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const getData = () => {
    setLoadingHistory(true);
    Axios.get(`${url}/delivery-note-manifest-history`, {
      params: {
        start_date: date.start,
        end_date: date.end,
        keyword,
        columns: [
          "delivery_note_no",
          "po_number",
          "shipment_name",
          "dn_no",
          "po_number",
          "delivery_note_date",
          "plan_receive_max_time",
          "delivery_note_cycle",
          "part_no",
          "part_name",
          "job_number",
          "lane",
          "qty_kanban",
          "order_kanban",
          "order_pcs",
          "remarks",
          "item_number",
          "status",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadingHistory(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 1
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "DN No",
      dataIndex: "delivery_note_no",
      sorter: true,
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
      sorter: true,
    },
    {
      title: "Shipped To",
      dataIndex: "shipment_name",
      sorter: true,
    },
    {
      title: "Delivery Note Date",
      dataIndex: "delivery_note_date",
      sorter: true,
    },
    {
      title: "Plan Receive Max Time",
      dataIndex: "plan_receive_max_time",
      sorter: true,
    },
    {
      title: "Delivery Note Cycle",
      dataIndex: "delivery_note_cycle",
      sorter: true,
    },
    {
      title: "Part No",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "Job Number",
      dataIndex: "job_number",
      sorter: true,
    },
    {
      title: "Lane",
      dataIndex: "lane",
      sorter: true,
    },
    {
      title: "Qty Perkanban",
      dataIndex: "qty_kanban",
      sorter: true,
    },
    {
      title: "Order (Kanban)",
      dataIndex: "order_kanban",
      sorter: true,
    },
    {
      title: "Order (Pcs)",
      dataIndex: "order_pcs",
      sorter: true,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: true,
    },
    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "Tanggal Scan",
      dataIndex: "created_at",
      render: (tgl) => moment(tgl).format("DD MMMM YYYY"),
      sorter: true,
    },

    // {
    //   title: "Statsus",
    //   dataIndex: "status",
    //   sorter: true,
    // },
  ];
  const getManifestDN = (item, index) => {
    const Columndetails = [
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        key: "status",
        render: (status) => {
          return (
            <img
              alt="icon"
              width="20"
              src={
                status === 1
                  ? require("../../assets/img/checked.png")
                  : require("../../assets/img/danger.png")
              }
            />
          );
        },
      },
      // {
      //   title: "DN No",
      //   dataIndex: "delivery_note_no",
      //   sorter: true,
      // },
      {
        title: "PO Number",
        dataIndex: "po_number",
        sorter: true,
      },
      {
        title: "Shipped To",
        dataIndex: "shipment_name",
        sorter: true,
      },
      {
        title: "Delivery Note Date",
        dataIndex: "delivery_note_date",
        sorter: true,
      },
      {
        title: "Plan Receive Max Time",
        dataIndex: "plan_receive_max_time",
        sorter: true,
      },
      {
        title: "Delivery Note Cycle",
        dataIndex: "delivery_note_cycle",
        sorter: true,
      },
      {
        title: "Part No",
        dataIndex: "part_number",
        sorter: true,
      },
      {
        title: "Part Name",
        dataIndex: "part_name",
        sorter: true,
      },
      {
        title: "Job Number",
        dataIndex: "job_number",
        sorter: true,
      },
      {
        title: "Lane",
        dataIndex: "lane",
        sorter: true,
      },
      {
        title: "Qty Perkanban",
        dataIndex: "qty_kanban",
        sorter: true,
      },
      {
        title: "Lot FG",
        dataIndex: "lod_prod",
        sorter: true,
        render: (text, record) => (
          <div>
            {record.t_fg_histories && (
              <div>
              {record.t_fg_histories.lot_prod}
          
              </div>
            )}
          </div>
        ),
      },
      {
        title: "Order (Kanban)",
        dataIndex: "order_kanban",
        sorter: true,
      },
      {
        title: "Order (Pcs)",
        dataIndex: "order_pcs",
        sorter: true,
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        sorter: true,
      },
      {
        title: "Item Number",
        dataIndex: "item_number",
        sorter: true,
      },
      {
        title: "Tanggal Scan",
        dataIndex: "created_at",
        render: (tgl) => moment(tgl).format("DD MMMM YYYY"),
        sorter: true,
      },

      // {
      //   title: "Statsus",
      //   dataIndex: "status",
      //   sorter: true,
      // },
    ];

    const mergedColumns = Columndetails.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "qty_delivery" ? "text" : "text",
          inputType:
            col.dataIndex === "tgl_pelunasan"
              ? "date"
              : col.dataIndex === "jam_pelunasan"
              ? "time"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    return (
     
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col></Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form component={false}>
              <Table
                scroll={{ x: 1500 }}
                columns={Columndetails}
                rowKey={(data) => data._id}
                loading={loadingDetail}
                // dataSource={dns.data[index].details}
                dataSource={dnDetails}
                size="small"
                pagination={{
                  pageSize: perPageDetail,
                  current: pageDetail,
                  total: dnmDetailsTotal,
                  showSizeChanger: true,
                  style: {
                    marginTop: "2rem",
                    marginBottom: 0,
                  },
                }}
              />
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(warehouseReceiving, "warehouseReceiving");
  console.log(dataHistory.data, "cek data");
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Scan DN Manifest"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  {" "}
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 2500 }}
                    columns={columns}
                    dataSource={dataHistory.data}
                    rowKey="_id"
                    onChange={handleTableChange}
                    expandable={{
                      expandedRowRender: getManifestDN,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dataHistory.total,
                      showSizeChanger: true,
                    }}
                    loading={loadingHistory}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PDHistoryDNManifest;
