/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Menu,
  Form,
  Typography,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function DetailsScanProduksi(props) {
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [pageSize, setPagesize] = useState(10);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    component_item: "",
  });

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   getDataHistory();
  // }, [keyword, page, sort]);

  useEffect(() => {
    getData();
  }, []);
  console.log(props);

  const scanNumberRef = useRef(null);

  const getData = () => {
    Axios.get(`${url}/scan-produksi`, {
      params: {
        item_number: props.match.params.id,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setData({
          ...data,
          component_item: res.data.data.component_item,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_number", data.component_item);
    fd.set("qty", data.qty);
    fd.set("unit", data.unit);

    Axios.post(`${url}/histori-scan-produksi`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/history-scan-produksi");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  // const getDataHistory = () => {
  //   setLoadingHistory(true);
  //   Axios.get(`${url}/scan-produksi`, {
  //     params: {
  //       keyword,
  //       columns: ["qty", "component_item", "created_by", "unit"],
  //     },
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => {
  //       setDataHistory(res.data.data);
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         message.error(err.response.data.message);
  //         if (err.response.status == 401) {
  //           sessionStorage.removeItem("token");
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       setLoadingHistory(false);
  //     });
  // };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan Produksi"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        {/* <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={24}>
                  <Space>
                    Item Number :
                    <Input
                      onPressEnter={(e) => getData(e, "component_item")}
                      type="primary"
                      prefix={<BarcodeOutlined />}
                      style={{ width: 1000 }}
                      onChange={(e) =>
                        handleChange("component_item", e.target.value)
                      }
                      value={data.component_item}
                      ref={scanNumberRef}
                    />
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row>
                  <Fragment>
                    <Col xs={24} md={23} lg={23}>
                      <Form.Item label="Item Number">
                        <Input
                          disabled
                          value={data.component_item}
                          onChange={(e) =>
                            handleChange("component_item", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Qty">
                        <Input
                          value={data.qty}
                          onChange={(e) => handleChange("qty", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="Unit">
                        <Input
                          value={data.unit}
                          onChange={(e) => handleChange("unit", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        // onClick={onSaveData}
                        loading={loading}
                        htmlType="submit"
                        className="mr-button"
                        // disabled={!data.data}
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default DetailsScanProduksi;
