import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Modal,
  Card,
  Breadcrumb,
  Space,
  Upload,
  List,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";

const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function CreateMasterUserInternal(props) {
  const [data, setData] = useState({
    username: "",
    password: "",
    full_name: "",
    department: "",
    phone_number: "",
    npk: "",
    email: "",
    password_confirmation: "",
    vendor_id: "",
    vendor_name: "",
    role_id: "",
    role_name: "",
    photo: null,
    vendors: [],
    roles: [],
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRemove = () => {
    setData({
      ...data,
      photo: null,
    });
  };

  const handleBeforeUpload = (files) => {
    const file = files;

    if (file) {
      const fileType = file.type;
      if (fileType === 'image/png' || fileType === 'image/jpeg') {
        // Lakukan tindakan yang sesuai dengan validasi berhasil
        setData({
          ...data,
          photo: files,
        });
      } else {
        // Tampilkan pesan kesalahan jika tipe file tidak sesuai
        message.error('Tipe file tidak valid. Harap unggah file PNG atau JPEG.');
      }
    }

    return false;
  };

  const getRole = async (keyword) => {
    await Axios.get(`${url}/role/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          roles: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  // const getStorage = async (keyword) => {
  //   await Axios.get(`${url}/master-warehouse-location/list`, {
  //     params: {
  //       keyword,
  //       columns: ["storage"]
  //     },
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => {
  //       setData({
  //         ...data,
  //         storages: res.data.data,
  //       });
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         message.error(err.response.data.message);
  //         if (err.response.status === 401) {
  //           sessionStorage.removeItem("token");
  //         }
  //       }
  //     });
  // };

  const getArea = async (keyword) => {
    await Axios.get(`${url}/master-warehouse-location/listArea`, {
      params: {
        keyword,
        columns: ["area"]
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          area: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {

    if(name === "phone_number") {
      const numericValue = value.replace(/\D/g, '');
      setData({
        ...data,
        [name]: numericValue,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const onSaveData = () => {
    const regex = /^[^\s]+$/;
    if(!regex.test(data.username))
    {
      Modal.warning({
        title: "Invalid!",
        content:"Username tidak boleh ada spasi.",
      });
      return false;
    }
    if(!regex.test(data.email))
    {
      Modal.warning({
        title: "Invalid!",
        content:"Email tidak boleh ada spasi.",
      });
      return false;
    }
    setLoading(true);

    let fd = new FormData();

    fd.set("username", data.username);
    fd.set("password", data.password);
    fd.set("full_name", data.full_name);
    fd.set("department", data.department);
    fd.set("phone_number", data.phone_number);
    fd.set("npk", data.npk);
    // fd.set("storage", data.storage_name);
    fd.set("area", data.area_name);
    fd.set("email", data.email);
    fd.set("password_confirmation", data.password_confirmation);
    fd.set("vendor_id", data.vendor_id);
    fd.set("vendor_name", data.vendor_name);
    fd.set("role_id", data.role_id);
    fd.set("role_name", data.role_name);
    fd.append("photo", data.photo);
    Axios.post(`${url}/master-user-internal`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-user-internal");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      username: "",
      password: "",
      full_name: "",
      department: "",
      phone_number: "",
      npk: "",
      email: "",
      password_confirmation: "",
      vendor_id: "",
      vendor_name: "",
      role_id: "",
      role_name: "",
      photo: null,
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master User Internal"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-user-internal">Kembali</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Tambah Master User Internal</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Username"
                        validateStatus={
                          error && error.username ? "error" : false
                        }
                        help={
                          error && error.username ? error.username[0] : false
                        }
                      >
                        <Input
                          value={data.username}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>
                      
                      <Form.Item
                        required
                        label="Email"
                        validateStatus={error && error.email ? "error" : false}
                        help={error && error.email ? error.email[0] : false}
                      >
                        <Input
                          value={data.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Nama Lengkap"
                        validateStatus={
                          error && error.full_name ? "error" : false
                        }
                        help={
                          error && error.full_name ? error.full_name[0] : false
                        }
                      >
                        <Input
                          value={data.full_name}
                          onChange={(e) =>
                            handleChange("full_name", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Departemen"
                        validateStatus={
                          error && error.department ? "error" : false
                        }
                        help={
                          error && error.department
                            ? error.department[0]
                            : false
                        }
                      >
                        <Input
                          value={data.department}
                          onChange={(e) =>
                            handleChange("department", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Nomor Telepon"
                        validateStatus={
                          error && error.phone_number ? "error" : false
                        }
                        help={
                          error && error.phone_number
                            ? error.phone_number[0]
                            : false
                        }
                      >
                        <Input
                          value={data.phone_number}
                          onChange={(e) =>
                            handleChange("phone_number", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label="Area"
                        validateStatus={error && error.area ? "error" : false}
                        help={error && error.area ? error.area[0] : false}
                      >
                        {/* <Input
                          value={data.email}
                          onChange={(e) =>
                            handleChange("storage", e.target.value)
                          }
                        /> */}
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("area", value)
                          }
                          onFocus={() => getArea("")}
                          showSearch
                          onSearch={(value) => getArea(value)}
                          filterOption={false}
                          value={{ key: data.area_id, label: data.area_name }}
                        >
                          {data.area &&
                            data.area.map((item) => {
                              return (
                                <Option value={item._id} key={item._id}>
                                  {item.area}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        // required
                        label="NPK"
                        validateStatus={error && error.npk ? "error" : false}
                        help={error && error.npk ? error.npk[0] : false}
                      >
                        <Input
                          value={data.npk}
                          onChange={(e) => handleChange("npk", e.target.value)}
                        />
                      </Form.Item>
                      
                      <Form.Item
                        required
                        label="Kata Sandi"
                        validateStatus={
                          error && error.password ? "error" : false
                        }
                        help={
                          error && error.password ? error.password[0] : false
                        }
                      >
                        <Input
                          type="password"
                          value={data.password}
                          onChange={(e) =>
                            handleChange("password", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Ketik Ulang Kata Sandi"
                        validateStatus={
                          error && error.password_confirmation ? "error" : false
                        }
                        help={
                          error && error.password_confirmation
                            ? error.password_confirmation[0]
                            : false
                        }
                      >
                        <Input
                          type="password"
                          value={data.password_confirmation}
                          onChange={(e) =>
                            handleChange(
                              "password_confirmation",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Role"
                        validateStatus={
                          error && error.role_name ? "error" : false
                        }
                        help={
                          error && error.role_name ? error.role_name[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("role", value)
                          }
                          onFocus={() => getRole("")}
                          showSearch
                          onSearch={(value) => getRole(value)}
                          filterOption={false}
                          value={{ key: data.role_id, label: data.role_name }}
                        >
                          {data.roles &&
                            data.roles.map((role) => {
                              return (
                                <Option value={role._id} key={role._id}>
                                  {role.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item name="photo" label="Foto">
                        <Fragment>
                          <Upload
                            onRemove={handleRemove}
                            beforeUpload={handleBeforeUpload}
                            fileList={[]}
                            accept=".png, .jpeg, .jpg"
                          >
                            <Button>
                              Unggah <UploadOutlined />
                            </Button>
                          </Upload>
                          {data.photo && (
                            <List>
                              <List.Item
                                actions={[
                                  <Button
                                    onClick={handleRemove}
                                    type="danger"
                                    ghost
                                  >
                                    <DeleteOutlined />
                                  </Button>,
                                ]}
                              >
                                <List.Item.Meta description={data.photo.name} />
                              </List.Item>
                            </List>
                          )}
                        </Fragment>
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Simpan
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Batal
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateMasterUserInternal;
