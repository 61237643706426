import React, { Fragment, useEffect, useState } from 'react'
import { Row, Badge, Card, Col, Breadcrumb, Button, Typography, Layout, Table, Tag, Space, message, Tooltip, Select, DatePicker, Input } from 'antd'
import { Redirect, Link } from 'react-router-dom'
import Axios from 'axios'
import { url } from '../../config/global'
import { HomeFilled, DropboxOutlined, DropboxSquareFilled, DownloadOutlined } from '@ant-design/icons'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { Chart as ChartJS, Title as ChartTitle } from "chart.js";
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FileExcelOutlined } from '@ant-design/icons';
import fileDownload from "js-file-download";

ChartJS.register(ChartTitle, ChartDataLabels);
const { Title, Text } = Typography
const { Content } = Layout
const { Meta } = Card;
const dateFormat = 'YYYY/MM/DD';
const { Search } = Input;




function DashboardKanbanPending() {
  const { RangePicker } = DatePicker;
  let styles = {
    marginLeft: '10px',
  };
  const [data, setData] = useState({
    kanbanReceiving: []

  })

  const [kanbanReceiving, setkanbanReceiving] = useState([]);
  const [dates, setDate] = useState({
    start: null,
    end: null
  });
  const [itemSelect, setItemSelect] = useState('');
  const [dataItems, setdataItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setkeyword] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [pageSize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });


  const getData = () => {
    setLoading(true)
    Axios.get(`${url}/kanban/kanban-pending`, {
      params: {
        keyword,
        columns: [
          'dn_no',
          'component_item',
          'part_number',
          'dn_no',
          'part_name',
          'supplier_code',
          'supplier_name',
          'storage',
          'area',
          'model',
          'total_qty_kanban',
          'qty_minus'
        ],
        start_date: startDate ? startDate['start'] : moment().format('YYYY-MM-DD'),
        end_date: endDate ? endDate['end'] : moment().format('YYYY-MM-DD'),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
      setLoading(false)
      setkanbanReceiving(res.data.data)

    }).catch(err => {
      setLoading(false)
      if (err.response) {
        message.error(err.response.data.message)
      }
    })
  }
  const getItem = (value) => {

    // setLoading(true)
    Axios.get(`${url}/get-items`, {
      params: {
        keyword: value,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
      // setLoading(false)
      // console.log(res.data)
      setdataItems({
        ...dataItems,
        data: res.data.data,
      })
    }).catch(err => {
      // setLoading(false)
      if (err.response) {
        message.error(err.response.data.message)
      }
    })
  }
  const handleExport = () => {

    Axios.get(`${url}/kanban/kanban-pending/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: {

        columns: [
          'component_item',
          'part_number',
          'dn_no',
          'part_name',
          'supplier',
          'supplier_name',
          'storage',
          'area',
          'model',
          'total_qty_kanban',
          'qty_minus'
        ],
        start_date: startDate['start'],
        end_date: endDate['end'],
        keyword,
      },
      responseType: "blob"
    }).then(res => {
      fileDownload(res.data, "Export kanban pending" + ".xlsx");
    }).catch(err => {

      if (err.response) {
        message.error(err.response.data.message)
      }
    })
  }



  useEffect(() => {
    getData(keyword, startDate, endDate)
    getItem()
  }, [keyword, startDate, endDate])

  if (!sessionStorage.getItem('token')) {
    return <Redirect to="/login" />
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };
  const handleRange = (value) => {
    if (value != null) {
      var start = moment(value[0]).format("YYYY-MM-DD");
      var end = moment(value[1]).format("YYYY-MM-DD");
      setstartDate({ start })
      setendDate({ end })
    } else {
      var currentDate = moment().format("YYYY-MM-DD");
      setstartDate({ start: currentDate });
      setendDate({ end: currentDate });
    }
  };
  const onSearch = (value) => {
    setkeyword(value)
    console.log("keyword", value);
  }
  //   /     


  const columns = [
    {
      title: "DN No",
      dataIndex: "dn_no",
      sorter: false,
    },
    {
      title: "Item Number",
      dataIndex: "component_item",
      sorter: false,
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      sorter: false,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: false,
    },
    {
      title: "Supplier",
      render: (supplier) => <div>{supplier.supplier == null ? "-" : supplier.supplier}</div>,
      sorter: false,
    },
    {
      title: "Supplier Name",
      render: (supplier_name) => <div>{supplier_name.supplier_name == null ? "-" : supplier_name.supplier_name}</div>,
      sorter: false,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: false,
    },
    {
      title: "Area",
      render: (area) => <div>{area.area == null ? "-" : area.area}</div>,

      sorter: false,
    },
    {
      title: "Model",
      dataIndex: "model",
      sorter: false,
    },
    {
      title: "Total Qty",
      dataIndex: "total_qty_kanban",
      sorter: false,
    },
    {
      title: "Qty Minus",
      dataIndex: "qty_minus",
      sorter: false,
    },
    {
      title: " Order Date",
      render: (data) => <div>{data.tgl_generate == null ? "-" : moment(data.tgl_generate).format("DD MMMM YYYY")}</div>,
      // render: (data) => <div>{data.histories==null?"-":moment(data.histories.tgl_generate).format("YYYY-MM-DD")}</div>,
      sorter: false,
    },
    {
      title: " Delivery Date",
      render: (data) => <div>{data.tgl_generate == null ? "-" : moment(data.tgl_deliveri).format("DD MMMM YYYY")}</div>,
      sorter: false,
    },
    {
      title: "Status Confirm",
      dataIndex: "status_confirm",
      sorter: false,
    },
    {
      title: "Alasan Pending",
      dataIndex: "keterangan",
      sorter: false,
    },

  ];


  return (
    <Fragment>
      {console.log(columns)}
      <Row>
        <Col xs={24}>
          <Card type="inner" title="KANBAN PENDING" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24} style={{ marginBottom: '10px' }}>
            <Row>

              <Col xs={24} md={24}>
                <Row>
                  <Col xs={24} md={6}>
                    <RangePicker onChange={(val) =>
                      handleRange(val)
                    } />

                  </Col>

                  {/* <Col xs={24} md={5}>
                            <Select style={{textAlign:'left',width: 250}}
                            showSearch
                            allowClear
                            
                            onChange={(value) =>  handleChange(value)}
                            placeholder="Select Item Number"
                            onSearch={(value) => getItem(value)}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                        
                            options={dataItems.data}
                        />
                            </Col> */}
                  <Col xs={24} md={6}>
                    <div style={{ marginLeft: '10px' }}>
                      <Button type="primary" onClick={handleExport} shape="round" icon={<DownloadOutlined />} size={'Large'}>
                        Export
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div style={{ textAlign: 'right' }}>
                      <Search placeholder="Search..." onSearch={(value) => onSearch(value)} style={{ width: 200 }} />
                    </div>
                  </Col>
                </Row>

              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Table
              scroll={{ x: 2000 }}
              bordered={true}
              columns={columns}
              dataSource={kanbanReceiving}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
              setLoading={loading}
              pagination={{
                pageSize: pageSize,
                total: kanbanReceiving.total,
                showSizeChanger: true,
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </Content>
    </Fragment>

  )
}

export default DashboardKanbanPending
