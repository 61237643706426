import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, ExportOutlined } from "@ant-design/icons";

const { Search } = Input;

const { RangePicker } = DatePicker;
const { Content } = Layout;

function SPSHistoryOutgoingInternal(props) {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [page, setPage] = useState(1);

  const [historyDetails, setHistoryDetails] = useState([]);
  const [historyDetailsTotal, setHistoryDetailsTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);
  const [kanbans, setKanbans] = useState([]);
  const [data, setData] = useState({
    dn_no: "",
    purchase_order: "",
    supplier_code: "",
    supplier_name: "",
    Alamat: "",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getHistoryReceivingDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    getKanban();
  }, []);

  useEffect(() => {
    if (selectedId) {
      getHistoryReceivingDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/history_receiving`, {
      params: {
        keyword,
        columns: [
          "dn_no",
          "purchase_order",
          "supplier_code",
          "supplier_name",
          "alamat",
          "project",
          "description",
          "part_number",
          "min_of_stock",
          "sum_of_kanban_order",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagesize(pagination.pageSize);
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const getKanban = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "kanban_type",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbans(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getHistoryReceivingDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/kanban/history/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["_id", "quantity_kanban"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setHistoryDetails(res.data.data);
        setHistoryDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const printPdf = (id) => {
    Axios.get(`${url}/kanban/pdf/download/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const columns = [
    {
      title: "Part Number",
      dataIndex: "part_number ",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "model",
      sorter: true,
    },
    {
      title: "Tipe",
      dataIndex: "tipe",
      sorter: true,
    },
    {
      title: "Process",
      dataIndex: "process",
      sorter: true,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Qty Kanban",
      dataIndex: "qty_kanban",
      sorter: true,
    },
    {
      title: "LOT Prod Sequence",
      dataIndex: "lot_prod_sequence",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
    },
    {
      title: "Tanggal Scan",
      dataIndex: "tanggal_scan",
      sorter: true,
    },

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 75,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "history-outgoing-internal/export"
                    ) && (
                      <Button onClick={printPdf} type="primary">
                        <ExportOutlined />
                        Print
                      </Button>
                    )}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Outgoing Internal"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPSHistoryOutgoingInternal;
