import React from 'react'
import NumberFormat from 'react-number-format'

function AntNumberFormat(props) {
    return (
        <NumberFormat displayType={props.type === 'text' ? 'text' : 'input' } prefix="IDR" value={props.value} decimalSeparator="." thousandSeparator="," className="ant-input text-right" onValueChange={(e) => props.onChange(e.floatValue)} /> 
    )
}

export default AntNumberFormat
