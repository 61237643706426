import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Upload,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./MasterWarehouseLocation.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function MasterWarehouseLocation() {
  const [keyword, setKeyword] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-warehouse-location`, {
      params: {
        keyword,
        columns: [
          "site",
          "location",
          "description",
          "storage",
          "model",
          "storage",
          "area",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setWarehouses(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/master-warehouse-location/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportWarehouse = () => {
    setLoadingExport(true);
    Axios.get(`${url}/master-warehouse-location/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Warehouse Location" + ".xlsx");
    }).finally(()=>{
      setLoadingExport(false);
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/master-warehouse-location/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Warehouse Location" + ".xlsx");
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/master-warehouse-location/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Area",
      dataIndex: "area",
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "model",
      sorter: true,
    },
    {
      title: "Printer Name",
      dataIndex: "printer_name",
      sorter: true,
    },

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "master-warehouse-location"
                    ) && (
                      <Menu.Item key="1">
                        <Link to={`/master-warehouse-location/${id}`}>
                          <EditOutlined /> Ubah
                        </Link>
                      </Menu.Item>
                    )}
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "master-warehouse-location/delete"
                    ) && (
                      <Menu.Item key="2">
                        <span onClick={() => handleDelete(id)}>
                          <DeleteOutlined />
                          Hapus
                        </span>
                      </Menu.Item>
                    )}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Import Master Warehouse Location"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Warehouse Location"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  {/* <Space> */}
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "master-warehouse-location/create"
                    ) && (
                      <Link to="/master-warehouse-location/create">
                        <Button
                          type="primary"
                          style={{ marginRight: 10, marginBottom: 10 }}
                        >
                          <PlusOutlined />
                          Tambah
                        </Button>
                      </Link>
                    )}
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "master-warehouse-location/import"
                    ) && (
                      <Button
                        type="primary"
                        style={{ marginRight: 10, marginBottom: 10 }}
                        onClick={showModal}
                      >
                        <ImportOutlined />
                        Import
                      </Button>
                    )}
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "master-warehouse-location/export"
                    ) && (
                      <Button loading={loadingExport}
                        onClick={exportWarehouse}
                        type="primary"
                        style={{ marginRight: 10, marginBottom: 10 }}
                      >
                        <ExportOutlined />
                        Export
                      </Button>
                    )}
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "master-warehouse-location/template"
                    ) && (
                      <Button
                        type="primary"
                        onClick={downloadTemplate}
                        style={{ marginRight: 10, marginBottom: 10 }}
                      >
                        <DownloadOutlined />
                        Download Template
                      </Button>
                    )}
                  {/* </Space> */}
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={warehouses.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: warehouses.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MasterWarehouseLocation;
