let url;
let socketUrl;
let base_url;
let storage_path;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  url = "https://api.ekanban.sankei-dharma.co.id/api";
  socketUrl = "https://api.ekanban.sankei-dharma.co.id/api";
  base_url = "https://api.ekanban.sankei-dharma.co.id";
  storage_path = "https://api.ekanban.sankei-dharma.co.id/storage/images/item-img/";
} else {
  url = "https://api.ekanban.sankei-dharma.co.id/api";
  socketUrl = "https://api.ekanban.sankei-dharma.co.id/api";
  base_url = "https://api.ekanban.sankei-dharma.co.id/";
  storage_path = "https://api.ekanban.sankei-dharma.co.id/storage/images/item-img/";
  // url = "https://api.ekanban.sankei-dharma.co.id/api";
}



export { url, socketUrl, base_url, storage_path };
