/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Dropdown,
  Menu,
  Form,
  DatePicker,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

function SPHistoryMatching() {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [storage, setStorage] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [data, setData] = useState({
    kanban_number: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date,storage]);

  console.log(date, "tanggal");

  const scanNumberRef = useRef(null);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/histori-matching`, {
      params: {
        start_date: date.start,
        end_date: date.end,
        keyword,
        storage,
        columns: ["component_item", "dn_no", "unit", "created_at"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWHLocation = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-warehouse-location/list`, {
      params: {
        keyword,
        columns: ["storage"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          storages: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_number", data.item_number);
    fd.set("unit", data.unit);
    fd.set("qty", data.qty);
    fd.set("tanggal_scan", data.tanggal_scan);

    Axios.post(`${url}/histori-scan-produksi/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        closeModalKanban();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeSelect = (name, value) => {
    const find = data.storages.find((x) => x._id === value.key);
    setError(null);
    setStorage(value.label)
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`storage`]: value.label,
    });

    console.log(value, name, find, "cek woy");
  };

  const showModalKanban = (id) => {
    const kanban = data.data.find((data) => data._id === id);
    setKanbanId(id);

    console.log(id);

    setData({
      ...data,
      item_number: kanban.item_number,
      unit: kanban.unit,
      qty: kanban.qty,
      tanggal_scan: kanban.tanggal_scan,
    });

    setIsModalVisibleKanban(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const closeModalKanban = () => {
    setData({
      ...data,
      item_number: "",
      qty: "",
      unit: "",
      tanggal_scan: "",
    });
    setIsModalVisibleKanban(false);
  };

  const columns = [
    {
      title: "Component Item",
      dataIndex: "component_item",
      sorter: true,
      width: 160,
    },
    {
      title: "Description 1",
      dataIndex: "description_1",
      sorter: true,
      width: 160,
    },
    {
      title: "Description 2",
      dataIndex: "description_2",
      sorter: true,
      width: 160,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
      width: 150,
    },
    
    {
      title: "Total Scanned Match ",
      dataIndex: "qty_scan_match",
      sorter: true,

      width: 150,
    },
    {
      title: "Standar Pack",
      dataIndex: "std_pack",
      sorter: true,
      width: 150,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: true,
      width: 150,
    },

    {
      title: "Status Matching",
      dataIndex: "status_matching",
      sorter: true,
      width: 160,
    },
    {
      title: "Date Matching",
      dataIndex: "date_matching",
      sorter: true,
      width: 160,
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: true,
    //   fixed: "right",
    //   width: 75,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               <Menu.Item key="1">
    //                 <Link to={`/history-scan-produksi/edit/${id}`}>
    //                   <EditOutlined /> Ubah
    //                 </Link>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(warehouseReceiving, "warehouseReceiving");

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Matching"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Modal
          title="Edit"
          style={{ top: 20 }}
          visible={isModalVisibleKanban}
          width={1000}
          footer={null}
          onCancel={closeModalKanban}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Form {...layout} name="control-hooks" labelAlign="left">
                  <Row>
                    <Fragment>
                      <Col xs={24}>
                        <Form.Item
                          validateStatus={
                            error && error.item_number ? "error" : false
                          }
                          help={
                            error && error.item_number
                              ? error.item_number[0]
                              : false
                          }
                          label="Part Number"
                        >
                          <Input
                            placeholder="Part Number"
                            value={data.item_number}
                            onChange={(e) => {
                              handleChange("item_number", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={error && error.qty ? "error" : false}
                          help={error && error.qty ? error.qty[0] : false}
                          label="QTY"
                        >
                          <Input
                            placeholder="qty"
                            value={data.qty}
                            onChange={(e) => {
                              handleChange("qty", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Form.Item
                            label="Unit"
                            validateStatus={
                              error && error.unit ? "error" : false
                            }
                            help={error && error.unit ? error.unit[0] : false}
                          >
                            <Input
                              disabled
                              value={data.unit}
                              onChange={(e) =>
                                handleChange("unit", e.target.value)
                              }
                            />
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </Fragment>
                  </Row>
                </Form>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={onSubmit}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                  }}
                >
                  Submit
                </Button>
                <Button htmlType="reset" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>

        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  {" "}
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                  <Select
                    style={{ width: 160 }}
                    allowClear="true"
                    labelInValue
                    onChange={(value) => handleChangeSelect("storages", value)}
                    onFocus={() => getWHLocation("")}
                    showSearch
                    onSearch={(value) => getWHLocation(value)}
                    filterOption={false}
                    value={{
                      key: data.storages_id,
                      label: data.storages_label,
                    }}
                  >
                    {data.storages &&
                      data.storages.map((storage) => {
                        return (
                          <Option value={storage._id} key={storage._id}>
                            {storage.storage}
                          </Option>
                        );
                      })}
                  </Select>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={dataHistory.data}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dataHistory.total,
                      showSizeChanger: true,
                    }}
                    loading={loadingHistory}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPHistoryMatching;
