import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import {
  Card,
  Layout,
  Row,
  Select,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  DatePicker,
  Alert,
  Typography,
  Modal,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./MasterWeeklyForecast.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;
const { Text } = Typography;

function MStockHK() {
  const [itemOption, setitemOption] = useState([
    { label: "TEST 1", value: "1" },
  ]);
  const [keyword, setKeyword] = useState("");
  const [yearQad, setYearQad] = useState("");
  const [yearSearch, setYearSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [dataNoKanban, setDataNoKanban] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalNoKanban, setIsModalNoKanban] = useState(false);
  const [isModalQAD, setisModalQADVisible] = useState(false);
  const [data, setData] = useState([]);
  const [file, setFile] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  const [error, setError] = useState(null);
  const yearFormat = "YYYY";
  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort, yearSearch);
  }, [keyword, page, sort, yearSearch]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);
  const handleBeforeUpload = (files) => {
    console.log(files.type)
    const isXLSX = files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isXLSX) {
      Modal.error({
        title: "Failed!",
        content: "Berkas harus berupa dokumen xlsx",
      });
    } else{
      setFile(files);

    }
  }
  const downloadTemplate = () => {
    Axios.get(`${url}/hk/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Stock HK" + ".xlsx");
    });
  };
  const handleRemoveFile = () => {
    setFile(null);
  };
  const onChangeYearQad = (value) => {
    const yr = new Date(value).getFullYear();
    setYearQad(yr);
  };
  const onChangeYearSearch = (value) => {
    const yr = new Date(value).getFullYear();
    setYearSearch(yr);
  };
  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/hk`, {
      params: {
        keyword,
        columns: ["project", "item_number",'supplier_code'],
        // year: yearSearch,
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleImport = () => {
    // console.log('AMD',file)
    let fd = new FormData();
    if(file.length == 0)
    {
      Modal.error({
        title: "Error!",
        content: "Mohon Pilih File Dahulu.",
      });
      return false;
    }
    setLoading(true);
    fd.append("file", file);
    Axios.post(`${url}/hk/import`,fd,{
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataNoKanban(res.data.kanban_not_found);
        if(res.data.kanban_not_found.length > 0)
        {
          setIsModalNoKanban(true);
        }
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });
        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleGetQADModal = () => {
    setisModalQADVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
    setisModalQADVisible(false);
    setIsModalNoKanban(false);
  };



  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };



  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/master-product-structure/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataNoKanban(res.data.kanban_not_found);
        if(res.data.kanban_not_found.length > 0)
        {
          setIsModalNoKanban(true);
        }
        
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportProductStructure = () => {
    Axios.get(`${url}/master-product-structure/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Stock Hari Kerja" + ".xlsx");
    });
  };

  const columnDataNoKanban = [
    {
      title: "Item Number",
      dataIndex: "id_qad",
      sorter: true,
    },
    {
      title: "Project / Model",
      dataIndex: "project",
      sorter: true,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    }
  ];
  const columns = [
    {
      title: "Project",
      dataIndex: "project",
      sorter: true,
    },
    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "Description 1",
      dataIndex: "description_1",
      sorter: true,
    },
    {
      title: "Description 2",
      dataIndex: "description_2",
      sorter: true,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "Std Packing",
      dataIndex: "std_packing",
      sorter: true,
    },
    {
      title: "Produksi / Del / Dey",
      dataIndex: "produksi",
      sorter: true,
    },
    {
      title: "Min Stock (HK)",
      dataIndex: "min_stock_hk",
      sorter: true,
    },
    {
      title: "Max Stock (HK)",
      dataIndex: "max_stock_hk",
      sorter: true,
    },
    {
      title: "Min Stock (Pcs)",
      dataIndex: "min_stock_pcs",
      sorter: true,
    },
    {
      title: "Max Stock (Pcs)",
      dataIndex: "max_stock_pcs",
      sorter: true,
    },
    {
      title: "Kanban Beredar",
      dataIndex: "kanban_beredar",
      sorter: true,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },

  ];



  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Import HK"
        visible={isModalQAD}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">

            <div name="file" label="File">
              <Fragment>
               <Text type="secondary">Mohon file yang diupload sesuai dengan template .</Text><br/>
              <Upload
                  onRemove={handleRemoveFile}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {file ? file.name : file}
              </Fragment>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button
                loading={loading}
                onClick={handleImport}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Import Data
              </Button>
              {/* <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button> */}
            </div>
          </div>
        </Card>
      </Modal>
      <Modal
            width={1200}
            title="Item Belum Ada Kanban"
            visible={isModalNoKanban}
            footer={null}
            onCancel={closeModal}
          > 
            <Alert message="Item Dibawah ini belum dibuatkan kanban, mohon buatkan kanban dahulu lalu upload ulang" type="warning" />
          <Table
          columns={columnDataNoKanban}
          rowKey={(data) => data.id}
          dataSource={dataNoKanban}
          // pagination={tableParams.pagination}
          loading={loading}
          // onChange={handleTableChange}
        />
        </Modal>
      <Modal
        title="Import Stock Hari Kerja"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Stock Hari Kerja"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={12}>
                  <Space>
                    <Link>
                      <Button style={{ marginRight: 10, marginBottom: 10 }} onClick={handleGetQADModal} type="primary">
                        <ImportOutlined />
                        Import Stock HK
                      </Button>
                    </Link>
                  </Space>
                  {/* <Space>
                    <Link>
                  <Button
                    type="primary"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    onClick={downloadTemplate}
                  >
                    <PlusOutlined />
                    Create Stock HK
                  </Button>
                  </Link>
                  </Space> */}
                  <Space> <Button
                    type="info"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    onClick={downloadTemplate}
                  >
                    <DownloadOutlined />
                    Download Template
                  </Button></Space>

                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Row style={{ width: "100%" }}>
                    <Col xs={24} md={12} lg={12}>

                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Search
                        placeholder="ex: CVD7800002"
                        onSearch={(value) => handleSearch(value)}
                      />
                      {/* <Button type="primary">Submit</Button> */}
                    </Col>
                  </Row>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    dataSource={products.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: products.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MStockHK;
