import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  DatePicker,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import { url } from "../config/global";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditSPKanbanGenerate(props) {
  const [data, setData] = useState({
    item_number: "",
    date_delivery: "",
    lot_prod_seq: "",
    qads: [],
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(`${url}/master-kanban-generate/show/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          item_number: res.data.data.id_qad,
          date_delivery: res.data.data.date_delivery,
          lot_prod_seq: res.data.data.lot_prod_seq,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "qads") {
      const find = data.qads.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.description_2,
        [`${name}_name`]: find.description_1,
        [`${name}_name`]: find.part_name,
        [`${name}_name`]: find.part_number,
      });
    } else if (name === "parts") {
      const find = data.parts.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`description_2`]: find.description_2,
      });

      console.log(name, value);
    } else if (name === "route") {
      const find = data.routes.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.process_name,
      });

      console.log(name, value, "ini route");
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });

      console.log(name, value);
    }
  };

  const getQAD = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          qads: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);

    console.log(data.qads);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();
    fd.set("item_number", data.item_number);
    fd.set("date_delivery", data.date_delivery);
    fd.set("lot_prod_seq", data.lot_prod_seq);

    Axios.post(
      `${url}/master-kanban-generate/update/${props.match.params.id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/kanban-generate");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      item_number: "",
      date_delivery: "",
      lot_prod_seq: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Kanban Generate"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/kanban-generate">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Kanban Generate</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="ID QAD"
                  validateStatus={error && error.item ? "error" : false}
                  help={error && error.item ? error.item[0] : false}
                >
                  <Col md={24} sm={24} xs={24}>
                    <Input
                      value={data.item_number}
                      onChange={(e) =>
                        handleChange("item_number", e.target.value)
                      }
                      disabled
                    />
                    {/* <Select
                      // disabled
                      labelInValue
                      onChange={(value) => handleChangeSelect("qads", value)}
                      onFocus={() => getQAD("", "wip")}
                      showSearch
                      onSearch={(value) => getQAD(value)}
                      filterOption={false}
                      value={{
                        key: data.qads_id,
                        label: data.qads_label,
                      }}
                    >
                      {data.qads &&
                        data.qads.map((item) => {
                          return (
                            <Option value={item._id} key={item._id}>
                              {item.item_number}
                            </Option>
                          );
                        })}
                    </Select> */}
                  </Col>
                </Form.Item>
                <Form.Item
                  required
                  label="Date Delivery"
                  validateStatus={
                    error && error.date_delivery ? "error" : false
                  }
                  help={
                    error && error.date_delivery
                      ? error.date_delivery[0]
                      : false
                  }
                >
                  <DatePicker
                    onChange={(value) => handleChange("date_delivery", value)}
                    value={data.date_delivery}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Lot Prod Sequence"
                  validateStatus={error && error.lot_prod_seq ? "error" : false}
                  help={
                    error && error.lot_prod_seq ? error.lot_prod_seq[0] : false
                  }
                >
                  <Input
                    value={data.lot_prod_seq}
                    onChange={(e) =>
                      handleChange("lot_prod_seq", e.target.value)
                    }
                    // disabled
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditSPKanbanGenerate;
