import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Col, Breadcrumb,Badge, Button, Typography, Layout, Table, Tag, Space, message, Tooltip,Select,DatePicker,Input } from 'antd'
import {Redirect, Link} from 'react-router-dom'
import Axios from 'axios'
import { url } from '../../config/global'
import { HomeFilled, DropboxOutlined, DropboxSquareFilled, DownloadOutlined } from '@ant-design/icons'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { Chart as ChartJS, Title as ChartTitle } from "chart.js";
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  ExportOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
ChartJS.register(ChartTitle,ChartDataLabels);
const { Title, Text } = Typography
const { Content } = Layout
const { Meta } = Card;
const dateFormat = 'YYYY/MM/DD';
const { Search } = Input;




function DashboardKanbanReceiving() {
    const {RangePicker} = DatePicker;
  let styles = {
    marginLeft: '10px',
  };
    const [data, setData] = useState({
     kanbanReceiving:[]
     
    })

    const [kanbanReceiving, setkanbanReceiving] = useState([]);
    const [dates, setDate] = useState({
      start:null,
      end:null
    });
    const [itemSelect, setItemSelect] = useState('');
    const [dataItems, setdataItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setkeyword] = useState('');
    const [startDate, setstartDate] = useState('');
    const [endDate, setendDate] = useState('');
    const [pageSize, setPagesize] = useState(10);
    const [page, setPage] = useState(1);
    const [buttonAccess, setButtonAccess] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [sort, setSort] = useState({
        sort: "created_at",
        order: "descend",
      });


    const getData = () => {
      setLoading(true)
      Axios.get(`${url}/kanban/kanban-receiving`, {
        params: {
          keyword,
          columns: [
            'component_item',
            'parent_item',
            'description1',
            'description2'
          ],
          start_date:startDate['start'],
          end_date:endDate['end'],
          start_date: startDate ? startDate['start'] : moment().format('MM-DD-YYYY'),
        end_date: endDate ? endDate['end'] : moment().format('MM-DD-YYYY'),
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
        setLoading(false)
        // console.log(res.data)
        setkanbanReceiving(res.data.data)
      }).catch(err => {
        setLoading(false)
        if (err.response) {
          message.error(err.response.data.message)
        }
      })
    }
    const getItem = (value) => {
      
      // setLoading(true)
      Axios.get(`${url}/get-items`, {
        params: {
          keyword:value,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
        // setLoading(false)
        // console.log(res.data)
        setdataItems({
          ...dataItems,
          data:res.data.data,
        })
      }).catch(err => {
        // setLoading(false)
        if (err.response) {
          message.error(err.response.data.message)
        }
      })
    }
    const handleChange = (value) => {
      setItemSelect({value})
      };

    useEffect(() => {
      getData(keyword,startDate,endDate)
      getItem()
    }, [keyword,startDate,endDate])
    
    if (!sessionStorage.getItem('token')) {
      return <Redirect to="/login" />
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPagesize(pagination.pageSize);
        setSort({
          sort: sorter.field,
          order: sorter.order,
        });
      };
    const handleRange = (value) => {
      if(value != null)
        {
          var start = moment(value[0]).format("MM-DD-YYYY");
          var end = moment(value[1]).format("MM-DD-YYYY");
          setstartDate({start})
          setendDate({end})
        } else{
          var currentDate = moment().format("MM-DD-YYYY");
          setstartDate({start : currentDate});
          setendDate({end : currentDate}); 
        }
      };
      const onSearch = (value) => {
        setkeyword(value)
      }
      const handleExport = () => {
     
        Axios.get(`${url}/kanban/kanban-receive/export`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          params: {
            columns: [
              'component_item',
              'parent_item',
              'description1',
              'description2'
            ],
            start_date:startDate['start'],
            end_date:endDate['end'],
            keyword,
          },
          responseType: "blob"
        }).then(res => {    
          fileDownload(res.data, "Export kanban receive" + ".xlsx");
        }).catch(err => {
        
          if (err.response) {
            message.error(err.response.data.message)
          }
        })
      }
    //   /     

  
    const columns = [
        {
          title: "Parent Item",
          dataIndex: "parent_item",
          sorter: false,
        },
        {
          title: "Component Item",
          dataIndex: "component_item",
          sorter: false,
        },
        {
          title: "Description 1",
          dataIndex: "description1",
          sorter: false,
        },
        {
          title: "Description 2",
          dataIndex: "description2",
          sorter: false,
        },
        {
          
                title: "Total Received",
                dataIndex: "total_received",
                sorter: false,
              
        },
        
          {
            title: "Standar Pack",
            dataIndex: "std_pack",
            sorter: false,
          },
          // {
          //   title: "Total Qty",
          //   dataIndex: "total_qty_kanban",
          //   sorter: false,
          // },

        {
          title: "Unit",
          dataIndex: "unit",
          sorter: false,
        },
        {
          title: "Status Receive",
          dataIndex: "status_receive",
          render: (item) => {
            return item ? (
              <CheckCircleOutlined style={{ color: "#00944b", fontSize: 30 }} />
            ) : (
              <CloseCircleOutlined style={{ color: "red", fontSize: 30 }} />
            );
          },
          sorter: false,
          align: "center",
      width: 150,
  
        },
        // {
        //   title: "Tanggal Scan",
        //   render: (tanggal_scan) => <div>{tanggal_scan.tanggal_scan==null?"-":moment(tanggal_scan.tanggal_scan).format("DD MMMM YYYY")}</div>,
        //   sorter: false,
        // },
    
      ];

      
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="KANBAN RECEIVING" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>
            <Content className="content">
            <Row>
            <Col xs={24} style={{marginBottom:'10px'}}>
                <Row>
                <Col xs={24} md={24}>
                        <Row>
                            <Col xs={24} md={6}>
                            <RangePicker onChange={(val) =>
                              handleRange(val)
                            }  />
                            
                            </Col>
                            
                            {/* <Col xs={24} md={5}>
                            <Select style={{textAlign:'left',width: 250}}
                            showSearch
                            allowClear
                            
                            onChange={(value) =>  handleChange(value)}
                            placeholder="Select Item Number"
                            onSearch={(value) => getItem(value)}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                        
                            options={dataItems.data}
                        />
                            </Col> */}
                              <Col xs={24} md={6}>
                              <div style={{marginLeft:'10px'}}>
                    <Button type="primary" onClick={handleExport} shape="round" icon={<DownloadOutlined />} size={'Large'}>
                        Export
                    </Button>
                    </div>
                    </Col>
                     <Col xs={12} md={12}>
                    <div style={{textAlign:'right'}}>
                    <Search placeholder="Search..."  onSearch={(value) => onSearch(value)} style={{ width: 200 }} />
                    </div>
                    </Col>
                        </Row>
                    
                </Col>
                </Row>
                </Col>
            <Col xs={24}>
                  <Table
                   scroll={{ x: 2000 }}
                   bordered={true}
                    columns={columns}
                    dataSource={kanbanReceiving}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    setLoading={loading}
                    pagination={{
                      pageSize: pageSize,
                      total: kanbanReceiving.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Content>
        </Fragment>

    )
}

export default DashboardKanbanReceiving
