/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
  Avatar,
  Select,
  Form,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { base_url, url, storage_path } from "../../config/global";
import moment from "moment";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./MasterKanban.less";
import fileDownload from "js-file-download";
import imgDefault from "../../assets/img/img-empty.png";

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

function MasterKanban(props) {
  const [keyword, setKeyword] = useState("");
  const [kanbans, setKanbans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [failedData, setFailedData] = useState([]);
  const [modalFailed, setFailedModal] = useState(0);
  const [data, setData] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [kanbanDetails, setKanbanDetails] = useState([]);
  const [kanbanDetailsTotal, setKanbanDetailsTotal] = useState(0);
  const [keywordDetails, setKeywordDetails] = useState("");

  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  
  const [generate, setGenerate] = useState({
    purchase_order: "",
  });

  const [printing, setPrinting] = useState(false);
  const [kanbanTypes, setKanbanTypes] = useState([]);

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getKanbanDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  const filterByType = (value) => {
    if(value !== undefined){
      setKeyword(value);
    } else {
      setKeyword('');
      getData();

    }
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    getKanbanType();
    if (selectedId) {
      getKanbanDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getKanbanType = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "kanban_type",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbanTypes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-kanban`, {
      params: {
        keyword,
        columns: [
          "kanban_type",
          "id_qad",
          "item_number",
          "description_1",
          "description_2",
          "model",
          "storage",
          "area",
          "supplier_code",
          "supplier",
          "quantity_kanban",
          "kanban_beredar",
          "part_number",
          "part_name",
          "photo",
          "file",
          "group",
          "customer_code",
          "customer",
          "tipe",
          "proses",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbans(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getKanbanDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/master-kanban/generate/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["item_code"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbanDetails(res.data.data);
        setKanbanDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
    // handleRemoveDetails();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
    setFailedModal(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleReset = () => {
    setData({
      ...data,
      qty_generate: "",
    });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/master-kanban/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteAll = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDeleteAll();
      },
    });
  };
  const [deleteAll, setDeleteAll] = useState([]);
  const confirmDeleteAll = () => {
    setLoading(true);
    Axios.post(
      `${url}/master-kanban/delete-all`,
      {
        remove: selectedRowKeysData.map((x) => {
          return {
            _id: x,
          };
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/master-kanban/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Kanban" + ".xlsx");
    });
  };

  const exportKanban = () => {
    setLoadingExport(true)
    Axios.get(`${url}/master-kanban/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Kanban" + ".xlsx");
    }).finally((res) => {
      setLoadingExport(false)
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/master-kanban/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if(res.data.type == 'warning')
        {
          message.warning(res.data.message);
          setFailedData(res.data.data)
          setFailedModal(1)
        } else{
          message.success(res.data.message);
          closeModal();
        }
        getData();
      })
      .catch((err) => {
        if (err.response) {
          if(err.response.data.type === "error"){
            message.error(err.response.data.message);  
          } else {

            const errorsKey = Object.keys(err.response.data.errors);
            const errors = err.response.data.errors;
  
            message.open({
              message: `Error!: ${err.response.data.message}`,
              description: (
                <div>
                  Error Details:
                  {errorsKey.map((error) => {
                    return <div>{errors[error][0]}</div>;
                  })}
                </div>
              ),
            });
          }

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editKanbans = (id) => {
    console.log(id);
    const edit = kanbans.data.find((data) => data._id === id);
    // setSelectedId(id);

    console.log(edit, data, "cek thr bos");

    if (edit.kanban_type === "Kanban WIP") {
      props.history.push(`/master-kanban/edit/wip/${id}`);
    } else if (edit.kanban_type === "Kanban Finish Goods") {
      props.history.push(`/master-kanban/edit/finish-goods/${id}`);
    } else {
      props.history.push(`/master-kanban/edit/supplier/${id}`);
    }
  };

  const downloadTemplateQR = (id) => {
    setPrinting(true);

    Axios.post(
      `${url}/kanban/print-qr`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .finally(() => {
        setPrinting(false);
      });
  };
  const columnsFailed = [
    {
      title: "Item Number ",
      dataIndex: "item_number",
      sorter: false,
    },
    {
      title: "Kanban Type",
      dataIndex: "kanban_type",
      sorter: false,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: false,
    },
    {
      title: "Message",
      dataIndex: "message",
      sorter: false,
    },
  ]

  const columns = [
    {
      title: "Picture",
      width: 120,
      dataIndex: "file_img",
      sorter: true,
      render: (file_img) => (
        <img src={file_img !== '-' ? `${storage_path}${file_img}` : imgDefault } width={100} height={70} alt={data.item_number} />
        // <img style={{ width: 80, height: 70 }} src={(file_img == "-" ?imgDefault:storage_path+file_img)} />
      ),
    },
    {
      title: "ID QAD",
      dataIndex: "id_qad",
      sorter: true,
    },
    {
      title: "Kanban Type",
      dataIndex: "kanban_type",
      sorter: true,
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      sorter: true,
    },
    {
      title: "Tipe",
      dataIndex: "tipe",
      sorter: true,
    },
    {
      title: "Proses",
      dataIndex: "proses",
      sorter: true,
    },
    {
      title: "Job Number",
      dataIndex: "job_number",
      sorter: true,
      render: (job_number) => (job_number === "undefined" ? "" : job_number),
    },
    {
      title: "Marking",
      dataIndex: "marking",
      sorter: true,
      render: (marking) => (marking === "null" ? "" : marking),
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Area",
      dataIndex: "area",
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "model",
      sorter: true,
    },
    {
      title: "Group",
      dataIndex: "group",
      sorter: true,
    },
    {
      title: "Standar Packing",
      dataIndex: "qty_kanban",
      sorter: true,
    },
    {
      title: "Qty Kanban Beredar",
      dataIndex: "kanban_beredar",
      sorter: true,
    },
  

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    //  disabled={kanbanDetails.length > 0}
                    onClick={() => editKanbans(id)}
                  >
                    <EditOutlined /> Edit
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Hapus
                    </span>
                  </Menu.Item>

                  {/* <Menu.Item key="3">
                    <Link onClick={() => downloadTemplateQR(id)}>
                      <PrinterOutlined /> Print
                    </Link>
                  </Menu.Item> */}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Kanban yang gagal diimport"
        visible={modalFailed}
        width={1200}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <Table
            
              columns={columnsFailed}
              dataSource={failedData}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
              loading={loading}
            />
            <div>
              <Button onClick={closeModal}>
                Tutup
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Modal
        title="Import Master Kanban"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Kanban"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={18}>
                  {/* <Space> */}
                  <Link to="/master-kanban/create">
                    <Button
                      type="primary"
                      style={{ marginRight: 10, marginBottom: 10 }}
                    >
                      <PlusOutlined />
                      Tambah
                    </Button>
                  </Link>

                  <Button
                    type="primary"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    onClick={showModal}
                  >
                    <ImportOutlined />
                    Import
                  </Button>

                  <Button loading={loadingExport}
                    onClick={exportKanban}
                    type="primary"
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <ExportOutlined />
                    Export
                  </Button>

                  <Button
                    type="primary"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    onClick={downloadTemplate}
                  >
                    <DownloadOutlined />
                    Download Template
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    onClick={handleDeleteAll}
                  >
                    <DeleteOutlined />
                    Hapus Data
                  </Button>
                  {/* </Space> */}
                </Col>

                <Col xs={24} md={24} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24} md={24} lg={24} className="align-right">
                  <Select
                    allowClear
                    onChange={(value) => filterByType(value)}
                    style={{ width: "240px" }}
                    placeholder="Select Type"
                  >
                    {kanbanTypes && kanbanTypes.map((kanban) => {
                        return (
                          <Option value={kanban.name} key={kanban._id}>
                            {kanban.name}
                          </Option>
                        );
                    })}
                  </Select>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2500 }}
                    columns={columns}
                    dataSource={kanbans.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        const purchase_order = selectedRows
                          ? selectedRows[0]
                          : null;

                        setGenerate({
                          ...generate,
                          purchase_order,
                        });
                      },
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;

                        return {
                          disabled:
                            (checkDisable &&
                              checkDisable.purchase_order !==
                                record.purchase_order) ||
                            record.status === 1,
                        };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                    pagination={{
                      pageSize: pageSize,
                      total: kanbans.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MasterKanban;
