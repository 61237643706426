import React from "react";
import { Layout } from "antd";
import "./BasicLayout.less";
const { Footer } = Layout;

export default ({ children }) => (
  <div className="content-basic">
    {children}
    <Footer className="footer">&copy; 2024 Sankei Dharma Indonesia</Footer>
  </div>
);
