/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Menu,
  Form,
  Select,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  BarcodeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;

function PScanFinishGoods() {
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    kanban_number: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  useEffect(() => {
    getDataHistory();
  }, [keyword, page, sort]);

  const scanNumberRef = useRef(null);

  const getData = () => {
    let type = "not input";
    warehouseReceiving.map((datanya) => {
      if (datanya.dn_number === data.kanban_number) {
        message.error("DN Number Already Inputed");
        setData({
          ...data,
          kanban_number: "",
        });
        type = "input";
      }
    });

    if (type === "not input") {
      Axios.get(`${url}/warehouse-outgoing/scan`, {
        params: {
          kanban_number: data.kanban_number,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          setWarehouseReceiving([...warehouseReceiving, res.data.data]);
          scanNumberRef.current.focus();
          console.log("dataa", res.data.data);
          setData([]);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status == 401) {
              sessionStorage.removeItem("token");
            }
          }
        });
    }
  };

  const onSubmit = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("qty_generate", data.qty_generate);
    fd.set("description_2", data.description_2);
    fd.set("items", data.items);

    Axios.post(`${url}/master-kanban/generate/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        closeModalKanban();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  console.log("w", warehouseReceiving);

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/warehouse-outgoing`,
      {
        details: warehouseReceiving,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getDataHistory();
        setData([]);
        setWarehouseReceiving([]);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
          setLoading(false);
        } else {
          message.error("Backend not yet Started");
        }
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const getDataHistory = () => {
    setLoadingHistory(true);
    Axios.get(`${url}/warehouse-outgoing`, {
      params: {
        keyword,
        columns: ["unit", "dn_number", "created_by", "item_code"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadingHistory(false);
      });
  };

  const showModalKanban = (id) => {
    const kanban = data.data.find((data) => data._id === id);
    setKanbanId(id);

    console.log(id);

    setData({
      ...data,
      job_number: kanban.job_number,
      item_code: kanban.item_code,
    });

    setIsModalVisibleKanban(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const closeModalKanban = () => {
    setData({
      ...data,
      select: "",
      job_number: "",
      item_code: "",
    });
    setIsModalVisibleKanban(false);
  };

  const columns = [
    {
      title: "Item Number",
      width: 200,
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "Part Name",
      width: 200,
      dataIndex: "part_name",
      sorter: true,
    },

    {
      title: "Model",
      width: 200,
      dataIndex: "model",
      sorter: true,
    },

    {
      title: "Marking",
      width: 200,
      dataIndex: "marking",
      sorter: true,
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty </div>,
      width: 200,
      align: "right",
      sorter: true,
      render: (data) => {
        return <AntNumberFormat value={data.quantity} type="text" />;
      },
    },
    {
      title: "Image",
      width: 200,
      dataIndex: "image",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 75,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="4">
                    <span onClick={() => showModalKanban(id)}>
                      <SettingOutlined style={{ marginRight: 3 }} />
                      Adjustment
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(warehouseReceiving, "warehouseReceiving");

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Scan Finish Good"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Modal
          title="Adjustment"
          style={{ top: 20 }}
          visible={isModalVisibleKanban}
          width={1000}
          footer={null}
          onCancel={closeModalKanban}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Form {...layout} name="control-hooks" labelAlign="left">
                  <Row>
                    <Fragment>
                      <Col xs={24}>
                        <Form.Item
                          validateStatus={
                            error && error.select ? "error" : false
                          }
                          help={error && error.select ? error.select[0] : false}
                          label="Select"
                          name="select"
                        >
                          <Select
                            placeholder="Item Number"
                            allowClear
                            labelInValue
                            onChange={(value) =>
                              handleChangeSelectPart("item", value)
                            }
                            onFocus={() => getPart("")}
                            showSearch
                            onSearch={(value) => getPart(value)}
                            filterOption={false}
                            value={{
                              key: data.item_id,
                              label: data.description_1,
                            }}
                          >
                            {data.items &&
                              data.items.map((item) => {
                                return (
                                  <Option value={item._id} key={item._id}>
                                    {item.description_1}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.qty_generate ? "error" : false
                          }
                          help={
                            error && error.qty_generate
                              ? error.qty_generate[0]
                              : false
                          }
                          label="Part Name"
                        >
                          <Input
                            placeholder="Part Name"
                            value={data.qty_generate}
                            onChange={(e) => {
                              handleChange("qty_generate", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.select !== currentValues.select
                          }
                        >
                          <Form.Item
                            label="Qty"
                            validateStatus={
                              error && error.description_1 ? "error" : false
                            }
                            help={
                              error && error.description_1
                                ? error.description_1[0]
                                : false
                            }
                          >
                            <Input
                              disabled
                              value={data.description_1}
                              onChange={(e) =>
                                handleChange("description_1", e.target.value)
                              }
                            />
                          </Form.Item>
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.select !== currentValues.select
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue("select") === "Item Code" ? (
                              <Form.Item
                                label="Item Code"
                                validateStatus={
                                  error && error.item_code ? "error" : false
                                }
                                help={
                                  error && error.item_code
                                    ? error.item_code[0]
                                    : false
                                }
                              >
                                <Input
                                  disabled
                                  value={data.item_code}
                                  onChange={(e) =>
                                    handleChange("item_code", e.target.value)
                                  }
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Fragment>
                  </Row>
                </Form>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={onSubmit}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                  }}
                >
                  Submit
                </Button>
                <Button htmlType="reset" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={24}>
                  <Row justify="start">
                    <Col style={{ marginTop: 20 }}>
                      <Space>
                        <Button
                          type="primary"
                          onClick={onSaveData}
                          loading={loading}
                          htmlType="submit"
                          className="mr-button"
                        >
                          Information
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={24}>
                  <Space>
                    Item Number :
                    <Input
                      onPressEnter={(e) => getData(e, "kanban_number")}
                      type="primary"
                      prefix={<BarcodeOutlined />}
                      style={{ width: 500 }}
                      onChange={(e) =>
                        handleChange("kanban_number", e.target.value)
                      }
                      value={data.kanban_number}
                      ref={scanNumberRef}
                    />
                  </Space>
                  <Row justify="start">
                    <Col style={{ marginTop: 20 }}>
                      <Space>
                        <Button
                          type="primary"
                          onClick={onSaveData}
                          loading={loading}
                          htmlType="submit"
                          className="mr-button"
                        >
                          Submit
                        </Button>

                        <Button
                          onClick={handleReset}
                          type="default"
                          htmlType="button"
                          className="mr-button"
                        >
                          Reset
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={dataHistory}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loadingHistory}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PScanFinishGoods;
