import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Select,
  TimePicker,
  InputNumber
} from "antd";
import { Link, Redirect } from "react-router-dom";
import dayjs from 'dayjs';
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditMasterGrouping(props) {
  const [data, setData] = useState({
    item_number: "",
    group: "",
    max_kanban: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(`${url}/master-grouping/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currentDate = `${year}-${month}-${day}`;
        console.log(currentDate, dayjs(currentDate + res.data.data.unloading_finished, 'HH:mm:ss'))
        setData({
          ...data,
          item_number: res.data.data.id_qad,
          item_number: res.data.data.item_number,
          qads_label: res.data.data.item_number,
          model_label: res.data.data.model_lbl,
          group: res.data.data.group,
          unloading_finished: dayjs(currentDate + res.data.data.unloading_finished, 'HH:mm:ss'),
          eta_sdi: dayjs(currentDate + res.data.data.eta_sdi, 'HH:mm:ss'),
          max_kanban: res.data.data.max_kanban,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "qads") {
      const find = data.qads.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        ["item_number"]: value.label,
        [`description_1`]: find.description_1,
        [`description_2`]: find.description_2,
        [`${name}_code`]: find.supplier_code,
        [`${name}_name`]: find.supplier_name,
        [`supplier_code`]: find.supplier_code,
        [`type`]: find.type,
        [`marking`]: find.marking,
      });
    } else if (name === "parts") {
      const find = data.parts.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`description_2`]: find.description_2,
      });
    } else if (name === "model") {
      const find = data.model.find((x) => x._id === value.key);
      console.log('TEST', value)
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        ["project"]: value.value,
      });
    } else if (name === "storages") {
      const find = data.storages.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,

        [`storage`]: value.label,
        [`area`]: find.area,
        [`model`]: find.model,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    }
  };
  const getModel = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-model/list`, {
      params: {
        keyword,
        columns: ["item_number"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          model: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };
  const getQAD = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
        columns: ["item_number"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          qads: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();
    fd.set("max_kanban", data.max_kanban);
    // fd.set("item_number", data.item_number);
    fd.set("group", data.group);
    fd.set("unloading_finished", data.unloading_finished);
    // fd.set("model", data.project);
    fd.set("eta_sdi", data.eta_sdi);

    Axios.post(`${url}/master-grouping/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-grouping");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  console.log(data)
  const handleReset = () => {
    setData({
      item_number: "",
      group: "",
      max_kanban: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Grouping "
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-grouping">Kembali</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Master Grouping</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                {/* <Form.Item
                  required
                  label="Item Number"
                  validateStatus={error && error.item_number ? "error" : false}
                  help={
                    error && error.item_number ? error.item_number[0] : false
                  }
                >
                  <Select
                    allowClear="true"
                    labelInValue
                    onChange={(value) => handleChangeSelect("qads", value)}
                    onFocus={() => getQAD("")}
                    showSearch
                    onSearch={(value) => getQAD(value)}
                    filterOption={false}
                    value={{
                      key: data.qad_id,
                      label: data.qads_label,
                    }}
                  >
                    {data.qads &&
                      data.qads.map((item) => {
                        return (
                          <Option value={item._id} key={item._id}>
                            {item.item_number}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Model"
                  validateStatus={error && error.model ? "error" : false}
                  help={
                    error && error.model ? error.model[0] : false
                  }
                >
                  <Select
                    allowClear="true"
                    labelInValue
                    onChange={(value) => handleChangeSelect("model", value)}
                    onFocus={() => getModel("")}
                    showSearch
                    onSearch={(value) => getModel(value)}
                    filterOption={false}
                    value={{
                      key: data.model_id,
                      label: data.model_label,
                    }}
                  >
                    {data.model &&
                      data.model.map((item) => {
                        return (
                          <Option value={item.model} key={item.model}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  label="Group"
                  validateStatus={error && error.group ? "error" : false}
                  help={error && error.group ? error.group[0] : false}
                >
                  <Input
                    value={data.group}
                    onChange={(e) => handleChange("group", e.target.value)}
                  />
                </Form.Item>
                <Form.Item

                  label="Max Kanban"
                  validateStatus={error && error.max_kanban ? "error" : false}
                  help={error && error.max_kanban ? error.max_kanban[0] : false}
                >
                  <Input
                    value={data.max_kanban}
                    onChange={(e) => handleChange("max_kanban", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="ETA SDI"
                  validateStatus={error && error.eta_sdi ? "error" : false}
                  help={error && error.eta_sdi ? error.eta_sdi[0] : false}
                >
                  <TimePicker
                    value={data.eta_sdi}
                    onChange={(e) => handleChange("eta_sdi", e)}
                  />
                </Form.Item>

                <Form.Item
                  required
                  label="Unloading Finished"
                  validateStatus={error && error.unloading_finished ? "error" : false}
                  help={error && error.unloading_finished ? error.unloading_finished[0] : false}
                >
                  <TimePicker
                    value={data.unloading_finished}
                    onChange={(e) => handleChange("unloading_finished", e)}
                  />
                </Form.Item>


                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditMasterGrouping;
