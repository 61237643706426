import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Form,
  DatePicker,
  Select,
  Badge
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { BarcodeOutlined,QrcodeOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function SPMatching(props) {
  const [scanWarehouse, setScanWarehouse] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [storageDisabled, setstorageDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [scanKanban, setScanKanban] = useState([]);
  const [dataTemporaryKanban, setDataTemporaryKanban] = useState([]);
  const [scanMatching, setMatching] = useState([]);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    dn_no: "",
    barcode_kanban: "",
  });

  const [isDisabled, setIsDisabled] = useState({
    dn_no: false,
    barcode_kanban: true,
    reset: true,
  });

  const dn_noRef = useRef();
  const barcode_kanbanRef = useRef();
  const storageRef = useRef();
  //tanggal Pelunasan
  const [valueDate, setValueDate] = useState(null);
  const onChangeDate = (date, dateString) => {
    setValueDate(dateString);
    // console.log(timestring);
  };
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.comopent_item === editingKey;
  const edit = (record) => {
    const { tanggal_scan } = record;
    form.setFieldsValue({
      tanggal_scan: tanggal_scan ? moment(tanggal_scan) : "",

      ...record,
    });
    setEditingKey(record.comopent_item);

    console.log(record);
  };

  // const save = async (data) => {
  //   const row = await form.validateFields();
  //   console.log("ini data", row, data);
  //   setLoading(true);
  //   const tanggal_scan = valueDate,
  //     update = scanMatching.map((x) => {
  //       if (x.component_item === data.component_item) {
  //         // scan_kanban = res.data.data;
  //         return {
  //           ...x,
  //           tanggal_scan,
  //         };
  //       } else {
  //         return {
  //           ...x,
  //         };
  //       }
  //     });

  //   setMatching(update);
  //   console.log(scanMatching);

  //   setEditingKey("");
  //   setLoading(false);
  // };

  const cancel = () => {
    setEditingKey("");
  };

  const getData = () => {
    Axios.get(`${url}/check-manifest`, {
      params: {
        dn_no: data.dn_no,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const dataScan = res.data.data;
        setMatching(res.data.data);
        setIsDisabled({
          dn_no: true,
          barcode_kanban: false,
          reset: false,
        });
        barcode_kanbanRef.current.focus();

        // getLotProduction(dataScan);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getKanban = () => {
    const findFirst = dataTemporaryKanban.find(x => x.barcode === data.barcode_kanban);
    
    setLoading(true);
    if (findFirst) {
      message.error('Kanban already scanned!')
      setData({
        ...data,
        barcode_kanban: "",
      });
      barcode_kanbanRef.current.focus();
    } else {
      Axios.get(`${url}/match-kanban`, {
        params: {
          barcode_kanban: data.barcode_kanban,
          storage: data.stg
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
       
        if (res.data.data === null) {
          message.error("Not Found");
          setData({
            ...data,
            barcode_kanban: "",
          });
          barcode_kanbanRef.current.focus();
        } else {
          const kanbanData = res.data.data;
          const compareData = scanMatching.find(
            (x) => x.unique_gr === kanbanData.unique_gr && 
            kanbanData.scan_kanban_match == 0
          );

          if (compareData) { 
            const findMatch = scanMatching.filter(
              (x) => x.unique_gr === kanbanData.unique_gr
            );
            const findMatchT = dataTemporaryKanban.filter(
              (x) => x.unique_gr === kanbanData.unique_gr
            );
            console.log(findMatchT,'mantap mantap');
            const scanned = findMatchT.reduce(
              (total, num) => total + parseInt(num.qty_kanban),
              0
              )+parseInt(kanbanData.qty_kanban)+findMatch[0].total_match;

            console.log('LOG', scanned,kanbanData.head.total_qty_kanban_for_match);
            if(scanned <= kanbanData.head.total_qty_kanban_for_match)
            {
              setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
          //   const findMatchTemp = dataTemporaryKanban.filter(
          //     (x) => x.unique_gr === kanbanData.unique_gr
          //   );
          //   // $calc = findMatch.total_match + 
          //   // if($calc){}
          //  const total = findMatchTemp.reduce(
          //     (total, num) => total + parseInt(num.qty_kanban),
          //     0
          //   );    
            console.log("AKUBUTUHPELURU")
           
            // if(scanned == kanbanData.head.total_qty_kanban_for_match){
             
                      console.log('masuk kesini')

                      const updateQtyScanMatch = scanMatching.map(x => 
                        x.unique_gr === kanbanData.unique_gr ?
                          {...x, qty_scan_kanban_match: parseInt(kanbanData.qty_kanban)}
                        : x
                      )
                      
                      setMatching(updateQtyScanMatch)
        
                      const updateMatch = scanMatching.map(x => 
                        x.unique_gr === kanbanData.unique_gr ?
                          {...x, status_match: 'Matching'}
                        : x
                      )
                      
                      console.log("TEST",findMatch[0].kanban_order, parseInt(kanbanData.qty_kanban))
                      // if(findMatch[0].kanban_order <= parseInt(kanbanData.qty_kanban)) {
                      if(scanned == kanbanData.head.total_qty_kanban_for_match) {
                        setMatching(updateMatch)
                      }
        
                    } else {
                      console.log('bukan')
                      const qtyMatch = findMatch[0].qty_scan_kanban_match;
                      const countScanMatch =  qtyMatch + parseInt(kanbanData.qty_kanban);
                      
                      const updateQtyScanMatch = scanMatching.map(x => 
                        x.unique_gr === kanbanData.unique_gr ?
                          {...x, qty_scan_kanban_match: countScanMatch}
                        : x
                      )
                      
                      setMatching(updateQtyScanMatch)
                      
                      const updateMatch = scanMatching.map(x => 
                        x.unique_gr === kanbanData.unique_gr ?
                          {...x, status_match: 'Matching'}
                        : x
                      )
                      
                      if(findMatch[0].kanban_order <= countScanMatch) {
                        setMatching(updateMatch)
                      }
                    }
              // } else {
              //   message.error("Scan sudah terpenuhi!");
              // }
             
            
          } 
          else {
            message.error("Data not match!");
          }
          
        }

        // console.log(res.data.data)
        // const kanbanData = res.data.data;
        // const compareData = scanMatching.find(
        //   (x) => x.component_item === kanbanData.id_qad && 
        //   kanbanData.scan_kanban_match == 0
        // );
        
        // const syncListGrMatch = scanMatching.find 
        // const addField = scanMatching.map(x => ({...x, status_matching: 'Matching'}))
        
        // if (compareData) {
        //   setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
        //   // setMatching(addField)
        // } else {
        //   message.error("Data not match!");
        // }
  
        setData({
          ...data,
          barcode_kanban: "",
        });
        barcode_kanbanRef.current.focus();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    }
    setLoading(false);
  };

  console.log(dataTemporaryKanban, 'temporary')
  console.log(scanMatching)

  const getWHLocation =  (keyword, type) => {
    setLoading(true);
     Axios.get(`${url}/list-gr-material/storage`, {
      params: {
        keyword:data.stg,
        columns: ["storage"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          storages: res.data.storages,
        });
        getListKanban(data.stg)
        // setLoading(false);
       
        // handleChangeSelect('storages',data.stg)
        // console.log(res.data.grouping)
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Apakah Anda yakin ingin me-refresh halaman?';
      event.returnValue = message; // Standar untuk kebanyakan browser
      return message; // Untuk Firefox
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [keyword, page, sort]);

  const handleReset = () => {
    setData([]);
    setIsDisabled({
      qty: true,
      barcode_kanban: true,
      reset: true,
    });
    setstorageDisabled(false)
    setMatching([]);
    setDataTemporaryKanban([]);
    // dn_noRef.current.focus();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeSelect = (name, value) => {
    console.log(data.stg);
    const find = data.storages.find((x) => x._id === value.key);
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`storage`]: value.label,
    });

    setTimeout(() => {
      barcode_kanbanRef.current.focus();
    }, 1000);
    getListKanban(value)
    // console.log(value, name, find, "cek woy");
  };

  const getListKanban = async (data) => {
    setLoading(true);

    await Axios.get(`${url}/get-material-with-storage`, {
      params: {
        keyword,
        storage: data,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.kanban)
        setMatching(res.data.data)
        // const getKanban = res.data.kanban.map(x => x)
        console.log(getKanban)
        setDataTemporaryKanban(res.data.kanban);
        setIsDisabled({
          dn_no: true,
          barcode_kanban: false,
        });
        // setData({
        //   ...data,
        //   storages: res.data.data,
        // });
        setstorageDisabled(true)
        barcode_kanbanRef.current.focus();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  }

  const columns = [
    {
      title: "Status",
      dataIndex: "status_match",
      sorter: true,
      width:'100px',
      render: (status_match) => {
        if(status_match === "") {
          return (
            <div>
              <Badge count={"Not Match"} style={{ backgroundColor: 'red', color:'white', fontWeight: 500 }} />
            </div>
          )
        } else {
          return (
            <div>
              <Badge count={"Matching"} style={{ backgroundColor: 'green', color:'white', fontWeight: 500 }} />
            </div>
          )
        }
      },
    },
    {
      title: "Scan Kanban",
      dataIndex: "kanban_order",
      width:'150px',
      sorter: true,
      render: (item, record) => {
        const findMatch = dataTemporaryKanban.filter(
          (x) => x.unique_gr === record.unique_gr
        );
        return `${findMatch.reduce(
          (total, num) => total + parseInt(num.qty_kanban),
          0
        )+record.total_match}/${item}`;
      },

    },
    {
      title: "DN Number",
      dataIndex: "dn_no",
      sorter: true,
    },
    {
      title: "Component Item ",
      dataIndex: "component_item",
      sorter: true,
    },
   
   
   
    {
      title: "Description 1",
      dataIndex: "description1",
      sorter: true,
    },

    {
      title: "Description 2",
      dataIndex: "description2",
      sorter: true,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Standar Pack",
      dataIndex: "std_pack",
      sorter: true,
    },
    {
      title: "Total Qty Order",
      dataIndex: "kanban_order",
      sorter: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: true,
    },

    
  ];

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    key,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          inputType === "date" ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
            >
              <DatePicker onChange={onChangeDate} />
            </Form.Item>
          ) : (
            ""
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "qty_delivery" ? "text" : "text",
        inputType:
          col.dataIndex === "tanggal_scan"
            ? "date"
            : col.dataIndex === "jam_pelunasan"
            ? "time"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  console.log(data, 'datas')
  const onSubmit = () => {
    // const findMatch = scanMatching.find(
    //   x => x.status_match === "Matching"
    // )
    const findMatch = dataTemporaryKanban.length;
    

    if (findMatch >= 1) {
      setLoading(true);
      Axios.post(
        `${url}/store-match-kanban`,
        {
          scan_dn: scanMatching,
          detail_kanban: dataTemporaryKanban,
          dn_no: data.dn_no,
          storage: data.stg,
          po_number: data.po_number,
          delivery_note_date: data.delivery_note_date,
          plan_receive_max_time: data.plan_receive_max_time,
          delivery_note_cycle: data.delivery_note_cycle,
          part_number: data.part_number,
          job_number: data.job_number,
          lane: data.lane,
          qty_kanban: data.qty_kanban,
          order_pcs: data.order_pcs,
          remarks: data.remarks,
          item_number: data.item_number,
          status: data.status,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/gr-material");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    } else {
      message.error("Silahkan scan terlebih dahulu kanban yg akan di proses.");
    }
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Matching"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical">
                <Row>
                  <Fragment>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        validateStatus={
                          error && error.storage.length > 0 ? "error" : ""
                        }
                        help={
                          error && error.storage.length > 0
                            ? error.storage[0]
                            : ""
                        }
                      >
                        <Form.Item label="Storage :">
                        <Input
                        disabled={storageDisabled}
                        prefix={<QrcodeOutlined />}
                          type="primary"
                          onChange={(e) =>
                            handleChange("stg", e.target.value)
                          }
                          value={data.stg}
                          ref={storageRef}
                          // disabled={isDisabled.barcode_kanban}
                          onPressEnter={(value) => getWHLocation()}
                        />
                      </Form.Item>
                        {/* <Select
                          allowClear="true"
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("storages", value)
                          }
                          onFocus={() => getWHLocation("")}
                          showSearch
                          onSearch={(value) => getWHLocation(value)}
                          filterOption={false}
                          value={{
                            key: data.storages_id,
                            label: data.storages_label,
                          }}
                        >
                          {data.storages &&
                            data.storages.map((storage) => {
                              return (
                                <Option value={storage.storage} key={storage._id}>
                                  {storage.storage}
                                </Option>
                              );
                            })}
                        </Select> */}

                      </Form.Item>
                      {/* {storageDisabled ? (
                        <Button
                          type="danger"
                          onClick={handleReset}
                          loading={loading}
                          className="mr-button"
                        >
                          Delete
                        </Button>
                      ) : (
                        ""
                      )} */}
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item label=" Scan Kanban :">
                        <Input
                         prefix={<QrcodeOutlined />}
                          type="primary"
                          onChange={(e) =>
                            handleChange("barcode_kanban", e.target.value)
                          }
                          value={data.barcode_kanban}
                          ref={barcode_kanbanRef}
                          disabled={isDisabled.barcode_kanban}
                          onPressEnter={(e) => getKanban(e, "barcode_kanban")}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Col xs={24}>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 2400 }}
                    columns={mergedColumns}
                    dataSource={scanMatching}
                    rowKey={(data) => data.component_item}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        // disabled={
                        //   scanMatching.find(x => x.status_match === "Matching") ? false : true
                        // }
                        disabled={
                          dataTemporaryKanban.length >= 1 ? false : true
                        }
                        // htmlType="submit"
                        className="mr-button"
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        disabled ={isDisabled.reset}
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPMatching;
