import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditPDUploadDNMaterial(props) {
    const [error, setError] = useState(null)
    const [keyword, setKeyword] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });
  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, []);


  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/delivery-note-manifest/${props.match.params.id}`, {

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
            ...data,
            remarks: res.data.data.remarks,
          });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveData = () => {
    setLoading(true);
    Axios.post(`${url}/delivery-note-manifest/${props.match.params.id}`, { 

        remarks: data.remarks,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/upload-dn-material");
    
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
    });
  };

  const handleChange = (remarks, value) => {
    setError(null);
    setData({
      ...data,
      [remarks]: value,
    });
  };

  const handleReset = () => {
    setData({
      remarks: "",
    });
  };

  return (
    <Fragment>
     <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="DN Material"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/upload-dn-material">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Remark</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Remarks"
                  validateStatus={error && error.remarks ? "error" : false}
                  help={error && error.remarks ? error.remarks[0] : false}
                >
                  <Input
                    value={data.remarks}
                    onChange={(e) => handleChange("remarks", e.target.value)}
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Update
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditPDUploadDNMaterial;
