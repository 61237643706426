import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Select,
} from "antd";
import { Redirect, Link } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;

function DetailsScanReceiving(props) {
  const [data, setData] = useState({
    dataKanban: [],
    kanban_type: "",
    item_code: "",
    item_name: "",
    quantity_kanban: "",
    qty_generate: "",
    select_id: "",
    select_label: "",
    select_name: "",
    job_number: "",
    selects: [],
  });

  const [dataDN, setDataDN] = useState({
    total: 0,
    data: [],
    datax: [],
  });

  const [keywordDetails, setKeywordDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [receivingDetails, setReceivingDetails] = useState([]);
  const [receivingTotal, setReceivingTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [rowDetails, setRowDetails] = useState([]);

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  useEffect(() => {
    getData();
  }, [sort]);

  useEffect(() => {
    if (selectedId) {
      getReceivingDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/scan-receiving-material/get-scan`, {
      params: {
        dn_no: props.match.params.id,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataDN((data) => {
          return {
            ...data,
            data: res.data.data,
            datax: res.data.data[0],
          };
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getReceivingDetails(data2[0]);
    }
  };

  const getReceivingDetails = (kanban_id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/scan-receiving-material/get-scan/details/${kanban_id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setReceivingDetails(res.data.data);
        setReceivingTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const onSaveData = async (data) => {
    console.log(dataDN.datax, "pp");
    setLoading(true);
    Axios.post(
      `${url}/scan-receiving-material/store`,
      {
        details: dataDN.datax.details,
        address_1: dataDN.datax.address_1,
        cycle_issue: dataDN.datax.cycle_issue,
        dn_no: dataDN.datax.dn_no,
        eta_sdi: dataDN.datax.eta_sdi,
        unloading_finished: dataDN.datax.unloading_finished,
        group: dataDN.datax.group,
        line: dataDN.datax.line,
        purchase_order: dataDN.datax.purchase_order,
        tgl_order: dataDN.datax.tgl_order,
        tgl_deliveri: dataDN.datax.tgl_deliveri,
        status: dataDN.datax.status,
        supplier_code: dataDN.datax.supplier_code,
        supplier_name: dataDN.datax.supplier_name,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        props.history.push("/history-receiving");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
    setLoading(false);
  };

  const columns = [
    {
      title: "DN No",
      dataIndex: "dn_no",
      sorter: true,
    },
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
      sorter: false,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: false,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: false,
    },
    {
      title: "Alamat",
      dataIndex: "address_1",
      sorter: false,
    },
    {
      title: "Tanggal Delivery",
      dataIndex: "tgl_delivery",
      sorter: false,
    },
    {
      title: "ETA SDI",
      dataIndex: "eta_sdi",
      sorter: false,
    },
    {
      title: "Unloading Finished",
      dataIndex: "unloading_finished",
      sorter: false,
    },
    {
      title: "Cycle Issue",
      dataIndex: "cycle_issue",
      sorter: false,
    },
    {
      title: "Tanggal Order",
      dataIndex: "tanggal_order",
      sorter: false,
    },
    {
      title: "Status DN",
      dataIndex: "status_dn",
      sorter: false,
    },
  ];

  const getReceiving = () => {
    const columns = [
      {
        title: "Project ",
        align: "right",
        dataIndex: "project",
        key: "project",
      },
      {
        title: "Description",
        align: "right",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Part Number",
        align: "right",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Std Pack",
        align: "right",
        dataIndex: "std_pack",
        key: "std_pack",
      },
      {
        title: "Kanban Order",
        align: "right",
        dataIndex: "kanban_order",
        key: "kanban_order",
      },
      {
        title: "Total Order",
        align: "right",
        dataIndex: "total_order",
        key: "total_order",
      },
      {
        title: "Qty Delivery",
        dataIndex: "qty_delivery",
        align: "center",
        key: "qty_delivery",
      },
      {
        title: "Total Minus",
        dataIndex: "total_minus",
        align: "center",
        key: "total_minus",
      },

      {
        title: "Tanggal Pelunasan",
        dataIndex: "tgl_pelunasan",
        align: "center",
        key: "tgl_pelunasan",
      },
      {
        title: "Jam Pelunasan",
        dataIndex: "jam_pelunasan",
        align: "center",
        key: "jam_pelunasan",
      },
      {
        title: "Keterangan",
        dataIndex: "keterangan",
        align: "center",
        key: "keterangan",
      },
      {
        title: "Status Qty Kanban",
        dataIndex: "status_qty_kanban",
        align: "center",
        key: "status_qty_kanban",
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 100,
        render: (id) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <Link to={`/scan-receiving/edit/${id}`}>
                        <EditOutlined /> Ubah
                      </Link>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={receivingDetails}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: receivingTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Details Scan Receiving"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space></Space>
                </Col>
                <Col xs={24} className="main-body">
                  <Row
                    type="flex"
                    gutter={[10, 20]}
                    justify="space-around"
                    style={{ marginBottom: 10 }}
                  >
                    <Col xs={24} md={12} lg={18}></Col>
                    <Col xs={24} md={12} lg={6} className="align-right"></Col>
                  </Row>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getReceiving,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    size="small"
                    dataSource={dataDN.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col style={{ marginTop: 20 }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={onSaveData}
                      loading={loading}
                      htmlType="submit"
                      className="mr-button"
                    >
                      Submit
                    </Button>

                    <Button
                      type="default"
                      htmlType="button"
                      className="mr-button"
                    >
                      Reset
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default DetailsScanReceiving;
