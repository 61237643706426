/* eslint-disable */
import React from "react";

function pathnametotitle(text) {
  text = text.split("-");
  text = text.map((v) => {
    return v.charAt(0).toUpperCase() + v.slice(1);
  });
  text = text.join(" ");
  return text;
}

function number_format(num) {
  num = parseFloat(num).toLocaleString("en");
  return num;
}

function checkArray(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i]._id === obj._id) {
      return true;
    }
  }

  return false;
}

export { pathnametotitle, number_format, checkArray };
