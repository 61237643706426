import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  DatePicker,
  TimePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";

import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditSPDNMaterial(props) {
  const [data, setData] = useState({
    project: "",
    description: "",
    part_number: "",
    jam_pelunasan: "",
    tanggal_pelunasan: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(
      `${url}/dn-material-histori/show-details/${props.match.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        setData({
          ...data,
          project: res.data.data.project,
          description: res.data.data.description,
          part_number: res.data.data.part_number,
          tanggal_pelunasan: res.data.data.tanggal_pelunasan,
          jam_pelunasan: res.data.data.jam_pelunasan,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();
    fd.set("project", data.project);
    fd.set("description", data.description);
    fd.set("part_number", data.part_number);
    fd.set("jam_pelunasan", data.jam_pelunasan);
    fd.set("tanggal_pelunasan", data.tanggal_pelunasan);

    Axios.post(
      `${url}/dn-material-histori/update/${props.match.params.id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/dn-material");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      tanggal_pelunasan: "",
      jam_pelunasan: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="DN Material"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/dn-material">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit DN Material</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Project"
                  validateStatus={error && error.project ? "error" : false}
                  help={error && error.project ? error.project[0] : false}
                >
                  <Input
                    value={data.project}
                    onChange={(e) => handleChange("project", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Description"
                  validateStatus={error && error.description ? "error" : false}
                  help={
                    error && error.description ? error.description[0] : false
                  }
                >
                  <Input
                    disabled
                    value={data.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Part Number"
                  validateStatus={error && error.part_number ? "error" : false}
                  help={
                    error && error.part_number ? error.part_number[0] : false
                  }
                >
                  <Input
                    disabled
                    value={data.part_number}
                    onChange={(e) =>
                      handleChange("part_number", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Tanggal Pelunasan"
                  validateStatus={
                    error && error.tanggal_pelunasan ? "error" : false
                  }
                  help={
                    error && error.tanggal_pelunasan
                      ? error.tanggal_pelunasan[0]
                      : false
                  }
                >
                  <DatePicker
                    required
                    onChange={(value) =>
                      handleChange("tanggal_pelunasan", value)
                    }
                    value={data.tanggal_pelunasan}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                <Form.Item
                  required
                  validateStatus={
                    error && error.jam_pelunasan ? "error" : false
                  }
                  help={
                    error && error.jam_pelunasan
                      ? error.jam_pelunasan[0]
                      : false
                  }
                  label="Jam Pelunasan"
                >
                  <TimePicker
                    onChange={(value) => handleChange("jam_pelunasan", value)}
                    value={data.jam_pelunasan}
                    format="HH:mm"
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditSPDNMaterial;
