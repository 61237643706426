/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { base_url, url, storage_path } from "../../config/global";
import fileDownload from "js-file-download";
import {
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  UploadOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import "./Item.less";
import imgDefault from "../../assets/img/img-empty.png";

const { Search } = Input;
const { Content } = Layout;

function Item() {
  const [loadingExport, setloadingExport] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);

  const [error, setError] = useState(null);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  //ini indeks
  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-item`, {
      params: {
        keyword,
        columns: [
          "item_number",
          "units_of_measure",
          "description_1",
          "description_2",
          "prod_line",
          "item_type",
          "status",
          "group",
          "location",
          "supplier_code",
          "supplier_name",
          "parent_item",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const syncKanban = () => {
    setLoading(true);
    Axios.get(`${url}/soap/sync-kanban`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };
  const HandlegetQAD = () => {
    setLoading(true);
    Axios.get(`${url}/soap/item`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });

        getData();
        message.info("Synchronizing to kanbans, please wait...");
        syncKanban();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });

          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const exportItem = () => {
    setloadingExport(true)
    Axios.get(`${url}/master-item/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Item" + ".xlsx");
    }).finally((res) => {
      setloadingExport(false)
    });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/master-item/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/master-item/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Item" + ".xlsx");
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/master-item/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "Unit Of Measure",
      dataIndex: "unit_of_measure",
      sorter: true,
    },
    {
      title: "Description 1",
      dataIndex: "description_1",
      sorter: true,
    },
    {
      title: "Description 2",
      dataIndex: "description_2",
      sorter: true,
    },
    {
      title: "Product Line",
      dataIndex: "prod_line",
      sorter: true,
    },
    {
      title: "Item Type",
      dataIndex: "item_type",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
    },
    {
      title: "Group",
      dataIndex: "group",
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: true,
    },
    {
      title: "Master Schedule",
      dataIndex: "master_schedule",
      sorter: true,
    },
    {
      title: "Plan Orders",
      dataIndex: "plan_orders",
      sorter: true,
    },
    {
      title: "Order Qty",
      dataIndex: "order_qty",
      sorter: true,
    },
    {
      title: "Order Periode",
      dataIndex: "order_periode",
      sorter: true,
    },
    {
      title: "Safety Stock",
      dataIndex: "safety_stock",
      sorter: true,
    },
    {
      title: "Reorder Poin",
      dataIndex: "reorder_point",
      sorter: true,
    },
    {
      title: "Buyer/Planne",
      dataIndex: "buyer_planne",
      sorter: true,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Minimum Order",
      dataIndex: "minimum_order",
      sorter: true,
    },
    {
      title: "Maximum Order",
      dataIndex: "maximum_order",
      sorter: true,
    },
    {
      title: "Order Multiple",
      dataIndex: "order_multiple",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: true,
    },
    {
      title: "Marking",
      dataIndex: "marking",
      sorter: true,
    },
    {
      title: "Job Number",
      dataIndex: "job_number",
      sorter: true,
    },
    {
      title: "Added",
      dataIndex: "added",
      sorter: true,
    },
    {
      title: "Phantom",
      dataIndex: "phantom",
      sorter: true,
    },

    {
      title: "image",
      dataIndex: "file_img",
      render: (file_img, data) => (
        <>
          {file_img !== "-" ? (
            <Space direction="vertical" space="15">
              <img
                src={
                  file_img !== "-" ? `${storage_path}${file_img}` : imgDefault
                }
                width={100}
                height={70}
                alt={data.item_number}
              />

              <Button
                size="small"
                style={{ width: "100%" }}
                onClick={() => {
                  setIsModalImageOpen(true);
                  setSelectedItem(data);
                }}
              >
                Change Image
              </Button>
            </Space>
          ) : (
            <Space direction="vertical" space="15">
              <img
                src={
                  file_img !== "-" ? `${storage_path}/${file_img}` : imgDefault
                }
                width={120}
                alt={data.item_number}
              />

              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  setIsModalImageOpen(true);
                  setSelectedItem(data);
                }}
              >
                Upload
              </Button>
            </Space>
          )}
        </>
      ),
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const props = {
    name: "file",
    showUploadList: false,
    action: `${url}/msater-item-upload/${selectedItem?._id}`,
    headers: {
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    onChange(info) {
      setUploadLoading(true);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.response) {
        setSelectedItem(info.file.response.data);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);

        setUploadLoading(false);
        getData();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);

        setUploadLoading(false);
      }
    },
  };

  return (
    <Fragment>
      <Modal
        title="Import Master Item"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Modal
        title={`Item Image : ${selectedItem ? selectedItem.item_number : ""}`}
        visible={isModalImageOpen}
        onCancel={() => setIsModalImageOpen(false)}
        footer={false}
        style={{ width: 480 }}
      >
        <Space direction="vertical" size={35}>
          {selectedItem && selectedItem.file_img !== "-" ? (
            <img
              src={`${storage_path}/${selectedItem.file_img}`}
              width={`100%`}
              alt={selectedItem.item_number}
            />
          ) : (
            <Card
              style={{ textAlign: "center", margin: "auto", width: "470px" }}
            >
              <Space direction="vertical" align="center">
                <FileImageOutlined style={{ fontSize: "4rem" }} />
                <h4>Image not Found</h4>
              </Space>
            </Card>
          )}

          <Space direction="vertical" size={10}>
            <label>Upload Image</label>
            <Upload {...props}>
              <Button icon={<UploadOutlined />} loading={uploadLoading}>
                Click to Upload
              </Button>
            </Upload>
          </Space>
        </Space>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Item"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "get-sap/item") && (
                        <Link>
                          <Button onClick={HandlegetQAD} type="primary">
                            <PlusOutlined />
                            Sync QAD
                          </Button>
                        </Link>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-item/create"
                      ) && (
                        <Link to="/master-item/create">
                          <Button type="primary">
                            <PlusOutlined />
                            Tambah
                          </Button>
                        </Link>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-item/import"
                      ) && (
                        <Button type="primary" onClick={showModal}>
                          <ImportOutlined />
                          Import
                        </Button>
                      )}

                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-item/export"
                      ) && (
                        <Button loading={loadingExport} onClick={exportItem} type="primary">
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-item/template"
                      ) && (
                        <Button type="primary" onClick={downloadTemplate}>
                          <DownloadOutlined />
                          Download Template
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search By Item Number ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 3500 }}
                    columns={columns}
                    dataSource={items.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: items.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Item;
