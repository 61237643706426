/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Dropdown,
  Menu,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined, BarcodeOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;

function SPScanReceivingMaterial() {
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [pageSize, setPagesize] = useState(10);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    kanban_number: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    getDataHistory();
  }, [keyword, page, sort]);

  const scanNumberRef = useRef(null);

  const getData = () => {
    let type = "not input";
    const dataScan = warehouseReceiving.map((datanya) => {
      if (datanya.scanned_barcode === data.kanban_number) {
        message.error("DN Number Already Inputed");
        setData({
          ...data,
          kanban_number: "",
        });
        type = "input";
      }
    });

    if (type === "not input") {
      Axios.get(`${url}/check-mysql`, {
        params: {
          kanban_number: data.kanban_number,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          scanNumberRef.current.focus();

          setWarehouseReceiving([...warehouseReceiving, ...res.data.data]);
          setData([]);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status == 401) {
              sessionStorage.removeItem("token");
            }
          }
        });
    }
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  console.log("w", warehouseReceiving);

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/warehouse-receiving-scan`,
      {
        details: warehouseReceiving,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getDataHistory();
        setData([]);
        setWarehouseReceiving([]);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const getDataHistory = () => {
    setLoadingHistory(true);
    Axios.get(`${url}/warehouse-scan`, {
      params: {
        keyword,
        columns: ["unit", "dn_number", "created_by", "item_code"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadingHistory(false);
      });
  };

  const columns = [
    {
      title: "DN NO",
      dataIndex: "dn_no ",
      sorter: true,
    },
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
      sorter: true,
    },
    {
      title: "Supplier_code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "Alamat",
      dataIndex: "alamat",
      sorter: true,
    },

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 75,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "history_receiving") && (
                      <Menu.Item key="1">
                        <Link to={`/history_receiving/${id}`}>
                          <EditOutlined /> Ubah
                        </Link>
                      </Menu.Item>
                    )}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(warehouseReceiving, "warehouseReceiving");

  const getHistory = () => {
    const columns = [
      {
        title: <div style={{ textAlign: "left" }}>Project</div>,
        align: "right",
        dataIndex: "project",
        key: "project",
      },
      {
        title: "Description",
        dataIndex: "description",
        align: "center",
        key: "description",
      },
      {
        title: "Part Number",
        dataIndex: "part_number",
        align: "center",
        key: "part_number",
      },
      {
        title: "Min of Stock",
        dataIndex: "min_of_stock",
        align: "center",
        key: "min_of_stock",
      },
      {
        title: "Sum of Kanban Order",
        dataIndex: "sum_of_kanban_order",
        align: "center",
        key: "sum_of_kanban_order",
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: true,
        fixed: "right",
        width: 100,
        render: (id) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "history_receiving"
                      ) && (
                        <Menu.Item key="1">
                          <Link to={`/history_receiving/${id}`}>
                            <EditOutlined /> Ubah
                          </Link>
                        </Menu.Item>
                      )}
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={historyDetails}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: historyDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan Receiving Material"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={24}>
                  <Space>
                    DN Material :
                    <Input
                      onPressEnter={(e) => getData(e, "kanban_number")}
                      type="primary"
                      prefix={<BarcodeOutlined />}
                      style={{ width: 1000 }}
                      onChange={(e) =>
                        handleChange("kanban_number", e.target.value)
                      }
                      value={data.kanban_number}
                      ref={scanNumberRef}
                    />
                  </Space>
                </Col>
              </Row>
              <Row justify="start">
                <Col style={{ marginTop: 20 }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={onSaveData}
                      loading={loading}
                      htmlType="submit"
                      className="mr-button"
                    >
                      Submit
                    </Button>

                    <Button
                      onClick={handleReset}
                      type="default"
                      htmlType="button"
                      className="mr-button"
                    >
                      Reset
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getHistory,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPScanReceivingMaterial;
