/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  Radio,
  Space,
  DatePicker,
  Image,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import { base_url, url, storage_path } from "../../config/global";
import moment from "moment";
import AntNumberFormat from "../../components/AntNumberFormat";
import imgDefault from "../../assets/img/img-empty.png";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateMasterKanban(props) {
  const [qads, setQads] = useState([]);
  const [selectedQads, setselectedQads] = useState([]);
  const [data, setData] = useState({
    kanban_type: "",
    job_type: "",
    model: "",
    tipe: "",
    proses: "",
    storage: "",
    qty_kanban: "",
    prod_sequence: "",
    barcode: "",
    idqad: [],
    supplier_code: "",
    area: "",
    no_kanban: "",
    date_delivery: "",
    photo: "",
    kanban_beredar: "",
    marking: "",
    customer_id: "",
    customer_name: "",
    customer: "",
    qads: [],
    storages: [],
    parts: [],
    group: [],
    groups: [],
    lines: [],
    vendors: [],
    customers: [],
  });

  useEffect(() => {
    getKanban();
  }, []);

  const [groupDisable, setGroupDisable] = useState(1);
  const [categorys, setCategorys] = useState([]);
  const [kanbans, setKanbans] = useState([]);
  const [codes, setCodes] = useState([]);
  const [packagings, setPackagings] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRemove = () => {
    setData({
      ...data,
      photo: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      photo: files,
    });

    return false;
  };

  const checkInStorage = async (group) => {
    await Axios.get(`${url}/master-kanban/check-in-storage`, {
      params: {
        id_qad: data.idqad,
        group,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          parts: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    if (name === "kanban_type") {
      setError(null);

      setData({
        ...data,
        [name]: value,
        job_type: "",
        parts: [],
        model: "",
        tipe: "",
        proses: "",
        storage: "",
        qty_kanban: "",
        prod_sequence: "",
        barcode: "",
        idqad: [],
        supplier_code: "",
        area: "",
        no_kanban: "",
        date_delivery: "",
        photo: "",
        marking: "",
        kanban_beredar: "",
        storages: [],
      });

      console.log(name, value, "kanban");
    } else {
      setError(null);
      setData({
        ...data,
        [name]: value,
      });
    }
    console.log(name, value);
  };
  const handleChangeSelect = (name, value) => {
    if (name === "qads") {
      if (value) {
        const find = qads.find((x) => x._id === value.key);
        if (data.kanban_type != "Kanban Finish Goods") {
          if (find.order_multiple === "0") {
            message.error(
              `Standar Packing tidak boleh 0, Silahkan Update Master Item ${value.label}`
            );
          }
        }

        setselectedQads({
          ...selectedQads,
          [`${name}_id`]: value.value,
          [`${name}_label`]: value.label,
          ["item_number"]: value.label,
          [`description_1`]: find.description_1,
          [`description_2`]: find.description_2,
          [`job_number`]: find.job_number,
          [`${name}_code`]: find.supplier_code,
          [`${name}_name`]: find.supplier_name,
          [`tipe`]: find.tipe,
          [`marking`]: find.marking,
          [`qty_kanban`]: find.order_multiple,
          ["file_img"]: find.file_img,
        });
      } else {
        setselectedQads({
          ...selectedQads,
          [`${name}_id`]: "",
          [`${name}_label`]: "",
          ["item_number"]: "",
          [`description_1`]: "",
          [`description_2`]: "",
          [`job_number`]: "",
          [`${name}_code`]: "",
          [`${name}_name`]: "",
          [`tipe`]: "",
          [`marking`]: "",
          [`qty_kanban`]: "",
          ["file_img"]: "",
        });
      }
    } else if (name === "parts") {
      const find = data.parts.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`description_2`]: find.description_2,
        // [`job_number`]: find.job_number,
      });
    } else if (name === "storages") {
      const find = data.storages.find((x) => x._id === value.key);
      setGroupDisable(0);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        // [`${name}`]: value.label,
        [`storage`]: value.label,
        [`area`]: find.area,
        [`model`]: find.model,
        // [`job_number`]: find.job_number,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    }
  };

  const handleChangeSelectGroup = async (name, value) => {
    setError(null);

    // validation on here
    await Axios.get(`${url}/master-kanban/check-in-group`, {
      params: {
        id_qad: data.qads_label,
        group: value.label,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
          // [`${name}_code`]: value.item_code,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }

        return false;
      });
  };

  const handleChangeSelectCustomer = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleChangeSelectVendor = (name, value) => {
    const find = data.suppliers.find((x) => x._id === value.key);
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`${name}_code`]: find.supplier_code,
      [`${name}_name`]: find.supplier_name,
    });

    console.log(value, name, find, "cek woy");
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();
    if (data.qty_kanban === "0") {
      message.error(
        `Standar Packing tidak boleh 0, Update Master Item ${selectedQads.item_number}`
      );
    }

    if (data.kanban_type === "Kanban WIP") {
      fd.set("kanban_type", data.kanban_type);
      fd.set("description_2", selectedQads.description_2);
      fd.set("description_1", selectedQads.description_1);
      fd.set("model", data.model);
      fd.set("tipe", selectedQads.tipe);
      fd.set("proses", data.proses);
      fd.set("customer_name", data.customer_name);
      fd.set("storage", data.storage);
      fd.set("qty_kanban", selectedQads.qty_kanban);
      fd.set("supplier_code", data.supplier_code);
      // fd.append("photo", data.photo);
      fd.set("file_img", selectedQads.file_img);

      if (data.group_name) {
        fd.set("group", data.group_id);
      }
      if (selectedQads.item_number) {
        fd.set("item_number", selectedQads.item_number);
      }

      // fd.set("line", data.line_name);
      fd.set("kanban_beredar", data.kanban_beredar);
    } else if (data.kanban_type === "Kanban Supplier") {
      fd.set("kanban_type", data.kanban_type);
      if (selectedQads.item_number) {
        fd.set("item_number", selectedQads.item_number);
      }
      fd.set("description_1", selectedQads.description_1);
      fd.set("description_2", selectedQads.description_2);
      fd.set("model", data.model);
      fd.set("qty_kanban", selectedQads.qty_kanban);
      // fd.set("supplier_code", data.supplier_code);
      fd.set("area", data.area);
      fd.set("storage", data.storage);
      if (data.group_name) {
        fd.set("group", data.group_id);
      }
      if (data.supplier_name) {
        fd.set("supplier_name", data.supplier_name);
      }
      if (data.supplier_code) {
        fd.set("supplier_code", data.supplier_code);
      }
      // fd.append("photo", data.photo);
      fd.set("kanban_beredar", data.kanban_beredar);
      fd.set("file_img", selectedQads.file_img);
    } else if (data.kanban_type === "Kanban Finish Goods") {
      fd.set("kanban_type", data.kanban_type);
      if (data.group_name) {
        fd.set("group", data.group_id);
      }
      if (selectedQads.item_number) {
        fd.set("item_number", selectedQads.item_number);
      }
      // if (data.customer_name) {
      //   fd.set("customer", data.customer_name);
      // }
      fd.set("description_2", selectedQads.description_2);
      fd.set("description_1", selectedQads.description_1);
      fd.set("model", data.model);
      fd.set("tipe", selectedQads.tipe);
      fd.set("marking", data.marking);
      fd.set("storage", data.storage);
      fd.set("qty_kanban", selectedQads.qty_kanban);
      fd.set("prod_sequence", data.prod_sequence);
      fd.set("barcode", data.barcode);

      fd.set("kanban_beredar", data.kanban_beredar);
      fd.set("job_number", selectedQads.job_number);
      fd.set("customer_name", data.customer_name);
      // fd.append("photo", data.photo);
      fd.set("file_img", selectedQads.file_img);

      // fd.set("customer_name", data.customer_name);
      // fd.set("customer", data.customer);
    } else {
      fd.set("kanban_type", data.kanban_type);
      fd.set("item_number", selectedQads.item_number);
      fd.set("description_1", selectedQads.description_1);
      fd.set("description_2", selectedQads.description_2);
      fd.set("model", data.model);
      fd.set("qty_kanban", selectedQads.qty_kanban);
      fd.set("supplier_code", data.supplier_code);
      fd.set("area", data.area);
      fd.set("storage", data.storage);
      fd.set("no_kanban", data.no_kanban);
      fd.set("barcode", data.barcode);
      fd.set("date_delivery", data.date_delivery);
      fd.set("prod_sequence", data.prod_sequence);
      fd.set("group", data.group_name);
      // fd.append("photo", data.photo);
      fd.set("file_img", selectedQads.file_img);
    }

    Axios.post(`${url}/master-kanban`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-kanban");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    console.log("nama", customer_name);
  };

  const handleReset = () => {
    setData({
      kanban_type: "",
      job_type: "",
      parts: [],
      model: "",
      tipe: "",
      proses: "",
      storage: "",
      qty_kanban: "",
      prod_sequence: "",
      barcode: "",
      idqad: [],
      supplier_code: "",
      area: "",
      no_kanban: "",
      date_delivery: "",
      photo: "",
      marking: "",
      kanban_beredar: "",
      group: "",
      line: "",
      groups: [],
      lines: [],
      storages: [],
    });
  };

  const getPart = async (keyword) => {
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          parts: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getQAD = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
        columns: ["item_number"],
        type: data.kanban_type,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setQads(res.data.data);
        // setData({
        //   ...data,
        //   qads: res.data.data,
        // });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getWHLocation = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-warehouse-location/list`, {
      params: {
        keyword,
        columns: ["storage"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          storages: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getCustomer = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-customer/list`, {
      params: {
        keyword,
        columns: ["customer_name"],
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getKanban = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "kanban_type",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbans(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getGroup = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-grouping/list`, {
      params: {
        keyword,
        model:data.model,
        item_number:selectedQads.item_number,
        columns: ["group"],
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          groups: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getLine = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-line/list`, {
      params: {
        keyword,
        columns: ["line"],
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          lines: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getVendor = async (keyword) => {
    await Axios.get(`${url}/master-supplier/list`, {
      params: {
        keyword,
        columns: ["supplier_code", "supplier_name"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Kanban"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-kanban">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Master Kanban</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        name="kanban_type"
                        label="Kanban Type"
                        validateStatus={
                          error && error.kanban_type ? "error" : false
                        }
                        help={
                          error && error.kanban_type
                            ? error.kanban_type[0]
                            : false
                        }
                      >
                        <Select
                          allowClear="true"
                          onChange={(value) =>
                            handleChange("kanban_type", value)
                          }
                        >
                          {kanbans &&
                            kanbans.map((kanban) => {
                              return (
                                <Option value={kanban.name} key={kanban._id}>
                                  {kanban.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item
                              required
                              label="ID QAD"
                              validateStatus={
                                error && error.item_number ? "error" : false
                              }
                              help={
                                error && error.item_number
                                  ? error.item_number[0]
                                  : false
                              }
                            >
                              <Select
                                allowClear="true"
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("qads", value)
                                }
                                onFocus={() => getQAD("")}
                                showSearch
                                onSearch={(value) => getQAD(value)}
                                filterOption={false}
                                value={{
                                  key: selectedQads.qad_id,
                                  label: selectedQads.qads_label,
                                }}
                              >
                                {qads &&
                                  qads.map((item) => {
                                    return (
                                      <Option value={item._id} key={item._id}>
                                        {item.item_number}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      {/* <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                          "Kanban Finish Goods" ? (
                            <Form.Item required label="Part Name">
                              <Select
                                allowClear="true"
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("parts", value)
                                }
                                onFocus={() => getPart("")}
                                showSearch
                                onSearch={(value) => getPart(value)}
                                filterOption={false}
                                value={{
                                  key: data.parts_id,
                                  label: data.parts_label,
                                }}
                              >
                                {data.parts &&
                                  data.parts.map((part) => {
                                    return (
                                      <Option value={part._id} key={part._id}>
                                        {part.description_1}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item> */}
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ? (
                            <Form.Item label="Part Name">
                              <Input
                                disabled
                                value={selectedQads.description_1}
                                onChange={(e) =>
                                  handleChange("description_1", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <>
                            <Form.Item label="Part Number">
                              <Input
                                disabled
                                value={selectedQads.description_2}
                                onChange={(e) =>
                                  handleChange("description_2", e.target.value)
                                }
                              />
                            </Form.Item>


                            <Form.Item label="Part Name">
                              <Input
                                disabled
                                value={selectedQads.description_1}
                                onChange={(e) =>
                                  handleChange("description_1", e.target.value)
                                }
                              />
                            </Form.Item>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                          "Kanban Finish Goods" ? (
                            <Form.Item label="Job Number">
                              <Input
                                disabled
                                value={selectedQads.job_number}
                                onChange={(e) =>
                                  handleChange("job_number", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item label="Tipe">
                              <Input
                                disabled
                                value={selectedQads.tipe}
                                onChange={(e) =>
                                  handleChange("tipe", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ? (
                            <Form.Item
                              required
                              label="Supplier"
                              validateStatus={
                                error && error.supplier_code ? "error" : false
                              }
                              help={
                                error && error.supplier_code
                                  ? error.supplier_code[0]
                                  : false
                              }
                            >
                              <Select
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectVendor("supplier", value)
                                }
                                onFocus={() => getVendor("")}
                                showSearch
                                onSearch={(value) => getVendor(value)}
                                filterOption={false}
                                value={{
                                  key: data.supplier_id,
                                  label: data.supplier_label,
                                }}
                              >
                                {data.suppliers &&
                                  data.suppliers.map((supplier) => {
                                    return (
                                      <Option
                                        value={supplier._id}
                                        key={supplier._id}
                                      >
                                        {`${supplier.supplier_code} - ${supplier.supplier_name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item
                              required
                              label="Proses"
                              validateStatus={
                                error && error.proses ? "error" : false
                              }
                              help={
                                error && error.proses ? error.proses[0] : false
                              }
                            >
                              <Input
                                value={data.proses}
                                onChange={(e) =>
                                  handleChange("proses", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item
                              required
                              label="Customer"
                              validateStatus={
                                error && error.customer_name ? "error" : false
                              }
                              help={
                                error && error.customer_name
                                  ? error.customer_name[0]
                                  : false
                              }
                            >
                              <Select
                                allowClear="true"
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectCustomer("customer", value)
                                }
                                onFocus={() => getCustomer("")}
                                showSearch
                                onSearch={(value) => getCustomer(value)}
                                filterOption={false}
                                value={{
                                  key: data.customer_id,
                                  label: data.customer_name,
                                }}
                              >
                                {data.customers &&
                                  data.customers.map((customer) => {
                                    return (
                                      <Option
                                        value={customer._id}
                                        key={customer._id}
                                      >
                                        {customer.customer_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item
                              required
                              label="Storage"
                              validateStatus={
                                error && error.storage ? "error" : false
                              }
                              help={
                                error && error.storage
                                  ? error.storage[0]
                                  : false
                              }
                            >
                              <Select
                                allowClear="true"
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("storages", value)
                                }
                                onFocus={() => getWHLocation("")}
                                showSearch
                                onSearch={(value) => getWHLocation(value)}
                                filterOption={false}
                                value={{
                                  key: data.storages_id,
                                  label: data.storages_label,
                                }}
                              >
                                {data.storages &&
                                  data.storages.map((storage) => {
                                    return (
                                      <Option
                                        value={storage._id}
                                        key={storage._id}
                                      >
                                        {storage.storage}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ? (
                            <Form.Item label="Area">
                              <Input
                                disabled
                                value={data.area}
                                onChange={(e) =>
                                  handleChange("area", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                          "Kanban Finish Goods" ? (
                            <Form.Item label="Marking">
                              <Input
                                disabled
                                value={data.marking}
                                onChange={(e) =>
                                  handleChange("marking", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ? (
                            <Form.Item label="Model">
                              <Input
                                disabled
                                value={data.model}
                                onChange={(e) =>
                                  handleChange("model", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ||
                          getFieldValue("kanban_type") === "Kanban Finish Goods"
                            ? null
                            : // <Form.Item
                              //   required
                              //   label="Job Number"
                              //   validateStatus={
                              //     error && error.line ? "error" : false
                              //   }
                              //   help={error && error.line ? error.line[0] : false}
                              // >
                              //   <Select
                              //     allowClear
                              //     labelInValue
                              //     onChange={(value) =>
                              //       handleChangeSelectGroup("line", value)
                              //     }
                              //     onFocus={() => getLine("")}
                              //     showSearch
                              //     onSearch={(value) => getLine(value)}
                              //     filterOption={false}
                              //     value={{
                              //       key: data.line_id,
                              //       label: data.line_name,
                              //     }}
                              //   >
                              //     {data.lines &&
                              //       data.lines.map((line) => {
                              //         return (
                              //           <Option value={line._id} key={line._id}>
                              //             {line.line}
                              //           </Option>
                              //         );
                              //       })}
                              //   </Select>
                              // </Form.Item>
                              null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ? (
                            <Form.Item
                              required
                              label="Group"
                              validateStatus={
                                error && error.group ? "error" : false
                              }
                              help={
                                error && error.group ? error.group[0] : false
                              }
                            >
                              <Select
                              disabled={groupDisable}
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectGroup("group", value)
                                }
                                onFocus={() => getGroup("")}
                                showSearch
                                onSearch={(value) => getGroup(value)}
                                filterOption={false}
                                value={{
                                  key: data.group_id,
                                  label: data.group_name,
                                }}
                              >
                                {data.groups &&
                                  data.groups.map((group) => {
                                    return (
                                      <Option value={group._id} key={group._id}>
                                        {group.group}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item
                              label="Qty Kanban / Standard Packing"
                              validateStatus={
                                error && error.qty_kanban ? "error" : false
                              }
                              help={
                                error && error.qty_kanban
                                  ? error.qty_kanban[0]
                                  : false
                              }
                            >
                              <Input
                                disabled
                                value={selectedQads.qty_kanban}
                                onChange={(e) =>
                                  handleChange("qty_kanban", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      {/* <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item
                              required
                              label="Kanban Beredar"
                              validateStatus={
                                error && error.kanban_beredar ? "error" : false
                              }
                              help={
                                error && error.kanban_beredar
                                  ? error.kanban_beredar[0]
                                  : false
                              }
                            >
                              <Input
                                value={data.kanban_beredar}
                                onChange={(e) =>
                                  handleChange("kanban_beredar", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ? (
                            <Form.Item
                              required
                              label="Kanban Beredar"
                              validateStatus={
                                error && error.kanban_beredar ? "error" : false
                              }
                              help={
                                error && error.kanban_beredar
                                  ? error.kanban_beredar[0]
                                  : false
                              }
                            >
                              <Input
                                value={data.kanban_beredar}
                                onChange={(e) =>
                                  handleChange("kanban_beredar", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item> */}
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") === "Kanban Supplier" ||
                          getFieldValue("kanban_type") ===
                            "Kanban Finish Goods" ||
                          getFieldValue("kanban_type") === "Kanban WIP" ? (
                            <Form.Item name="photo" label="Image">
                              <Fragment>
                                {selectedQads.file_img ? (
                                  <img
                                    style={{ border: "1px solid #BFBFBF" }}
                                    src={`${storage_path}${selectedQads.file_img}`}
                                    alt={selectedQads.item_number}
                                    width={`340`}
                                  />
                                ) : (
                                  <img
                                    style={{ border: "1px solid #BFBFBF" }}
                                    src={imgDefault}
                                    alt={selectedQads.item_number}
                                    width={`340`}
                                  />
                                )}
                                {/* <Upload
                                  onRemove={handleRemove}
                                  beforeUpload={handleBeforeUpload}
                                  fileList={[]}
                                >
                                  <Button>
                                    Upload <UploadOutlined />
                                  </Button>
                                </Upload>
                                {data.photo && (
                                  <List>
                                    <List.Item
                                      actions={[
                                        <Button
                                          onClick={handleRemove}
                                          type="danger"
                                          ghost
                                        >
                                          <DeleteOutlined />
                                        </Button>,
                                      ]}
                                    >
                                      <List.Item.Meta
                                        description={data.photo.name}
                                      />
                                    </List.Item>
                                  </List>
                                )} */}
                              </Fragment>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>{" "}
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.kanban_type !== currentValues.kanban_type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("kanban_type") ===
                          "Kanban Finish Goodss" ? (
                            <Form.Item
                              required
                              label="Marking"
                              validateStatus={
                                error && error.marking ? "error" : false
                              }
                              help={
                                error && error.marking
                                  ? error.marking[0]
                                  : false
                              }
                            >
                              <Col md={24} sm={24} xs={24}>
                                <Input
                                  value={data.marking}
                                  onChange={(e) =>
                                    handleChange("marking", e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="mr-button"
                      >
                        Submit
                      </Button>
                      <Button htmlType="reset" onClick={handleReset}>
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateMasterKanban;
