/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Dropdown,
  Menu,
  Form,
  DatePicker,
  Select,
  Radio, 
  Descriptions
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined, RotateRightOutlined } from "@ant-design/icons";
import send from "../../utils/send";

const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;

function PHistoryScanProduksi() {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [isModalVisibleChangeKanban, setIsModalVisibleChangeKanban] = useState(false);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [changeKanbanValid, setChangeKanbanValid] = useState(true);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [kanbanNumberEmpty, setKanbanNumberEmpty] = useState(false);
  const [kanbanActive, setKanbanActive] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [data, setData] = useState({
    kanban_number: "",
  });
  const [typeOptions, setTypeOptions] = useState(1);
  const [getDescriptionKanbanNG, setGetDescriptionKanbanNG] = useState([]);
  const [getDescriptionKanbanEmpty, setGetDescriptionKanbanEmpty] = useState([]);
  const [isDisabled, setIsDisabled] = useState({
    scan_ng: false,
    scan_empty: false,
  });
  const scan_barcode_ng = useRef();
  const scan_barcode_empty = useRef();

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const optionTypeScanNG = [
    { label: 'Scan Barcode', value: 1 },
    { label: 'Select Kanban', value: 2 },
  ];

  const onChangeTypeOptions = (x) => {
    console.log('radio4 checked', x.target.value);
    setTypeOptions(x.target.value);
  }

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
    getData();
  }, [keyword, page, sort, date]);

  console.log(date, "tanggal");

  const scanNumberRef = useRef(null);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/histori-scan-produksi`, {
      params: {
        start_date: date.start,
        end_date: date.end,
        keyword,
        columns: ["parent_item","lot_prod","component_item", "qty", "unit", "created_at"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_number", data.item_number);
    fd.set("unit", data.unit);
    fd.set("qty", data.qty);
    fd.set("tanggal_scan", data.tanggal_scan);

    Axios.post(`${url}/histori-scan-produksi/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        closeModalKanban();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  console.log(selectedRowsData)
  const onSubmitChangeKanban = () => {
    setLoading(true);

    Axios.post(`${url}/change-kanban-ng`, 
      {
        component_item: selectedRowsData[0].component_item,
        parent_item: selectedRowsData[0].parent_item,
        kanban_ng_id: data.kanban_ng_id,
        kanban_ng: data.kanban_ng_label,
        kanban_empty_id: data.kanban_empty_id,
        kanban_empty: data.kanban_empty_label,
        detailScanSelected: selectedRowsData,
        barcode_ng: data.barcode_ng,
        barcode_empty: data.barcode_empty,
        typeChange: typeOptions == 1 ? 'scan' : 'select'
      }, 
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }
        closeModalKanban();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModalKanban = (id) => {
    const kanban = data.data.find((data) => data._id === id);
    setKanbanId(id);

    setData({
      ...data,
      item_number: kanban.item_number,
      unit: kanban.unit,
      qty: kanban.qty,
      tanggal_scan: kanban.tanggal_scan,
    });

    setIsModalVisibleKanban(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const closeModalKanban = () => {
    setIsModalVisibleChangeKanban(false);
    setData([]);
  };

  const showModalChangeKanban = () => {
    setIsModalVisibleChangeKanban(true);
  };

  const handleGetKanbanNG = (val) => {
    Axios.post(
      `${url}/get-kanban-number-active`,
      {
        component_item: selectedRowsData[0].component_item,
        parent_item: selectedRowsData[0].parent_item,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    ).then((res) => {
        console.log(res.data)
        setKanbanActive(res.data.dataKanbanActive)
        setKanbanNumberEmpty(res.data.dataKanbanEmpty)
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectKanban = (name, value) => {
    if(typeOptions == 1 ) {
      console.log(value)
      if(name === 'kanban_ng') {
        setData({
          ...data,
          barcode_ng: value,
        });
      } else {
        setData({
          ...data,
          barcode_empty: value,
        });
  
      }
    } else {
      if(name === 'kanban_ng') {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_label`]: value.label,
        });
      } else {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_label`]: value.label,
        });
  
      }
    }


  };

  const getKanbanDetails = (name, value) => {
    console.log(data)
    console.log(value)
    
    Axios.get(`${url}/scan-produksi/get-details`, {
      params: {
        barcode_kanban: value === "kanban_ng" ? data.barcode_ng : data.barcode_empty,
        component_item: selectedRowsData.length === 1 ? selectedRowsData[0].component_item : '',
        typeKanban: value
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      if(value === "kanban_ng"){
        setGetDescriptionKanbanNG(res.data.data)
        scan_barcode_empty.current.focus();
      } else {
        setGetDescriptionKanbanEmpty(res.data.data)
      }
    })
    .catch((err) => {
      if (err.response) {
        setData({...data,barcode_kanban: ""})
        message.error(err.response.data.message);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
        }
      }
    });
  };

  console.log(getDescriptionKanbanNG)
  const getKanbanNG = (keyword) => {
    setLoadingSelect(true);
    const token = sessionStorage.getItem("token");
    send({
      url: `${url}/histori-scan-produksi/get-kanban-active`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      data: {
        // columns: ["storage", "area"],
        keyword,
      },
    })
      .then((res) => {
        console.log(res.data)
        if (res.success) {
          // setWarehouseLocations(res.data.data);
        } else {
          message.error(res.data.message);
        }
      })
      .finally(() => {
        setLoadingSelect(false);
      });
  };

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      sorter: true,
      render: (text, record, index) => index + 1 + (page - 1) * pageSize,
    },
    {
      title: "Parent Item",

      dataIndex: "parent_item",
      sorter: true,
    },
   
    {
      title: "Component Item",

      dataIndex: "component_item",
      sorter: true,
    },
    {
      title: "Qty Per",
      dataIndex: "qty_per",
      sorter: true,
    },
    // {
    //   title: "Qty",
    //   dataIndex: "qty_input",
    //   sorter: true,
    // },
    // {
    //   title: "Total",
    //   children: [
        {
          title: "Total Kanban Scan",
          dataIndex: "qty_scan_kanban",
          sorter: true,
        },
        // {
        //   title: "Total Qty",
        //   dataIndex: "total_qty",
        //   sorter: true,
        // },
    //   ],
    // },

    {
      title: "Std Pack",
      dataIndex: "std_pack",
      sorter: true,
    },
    {
      title: "Total Qty",
      dataIndex: "total_qty",
      sorter: true,
    },
    {
      title: "Qty Kanban",
      dataIndex: "total_qty_kanban",
      sorter: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: true,
    },
    // {
    //   title: "Lot Prod Sequence",
    //   dataIndex: "lot_prod",
    //   sorter: true,
    // },
    {
      title: "Tanggal Scan",
      dataIndex: "created_at",
      render: (tgl) => moment(tgl).format("DD MMMM YYYY"),
      sorter: true,
    },

    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: true,
    //   fixed: "right",
    //   width: 75,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               <Menu.Item key="1">
    //                 <Link to={`/history-scan-produksi/edit/${id}`}>
    //                   <EditOutlined /> Ubah
    //                 </Link>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(data, "data");

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Scan Produksi"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Modal
          title="Change Kanban NG"
          style={{ top: 20 }}
          visible={isModalVisibleChangeKanban}
          width={1000}
          footer={null}
          onCancel={closeModalKanban}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Form {...layout} name="control-hooks" labelAlign="left">
                  <Row>
                    <Fragment>
                      <Col xs={24}>
                        <Form.Item
                          validateStatus={
                            error && error.item_number ? "error" : false
                          }
                          help={
                            error && error.item_number
                              ? error.item_number[0]
                              : false
                          }
                          label="Componen Item"
                        >
                          <Input
                            disabled
                            value={selectedRowsData.length === 1 ? selectedRowsData[0].component_item : ''}
                            onChange={(e) => {
                              handleChange("item_number", e.target.value);
                            }}
                          />
                        </Form.Item>

                        <Form.Item label="Option Change Kanban NG :">
                          <Radio.Group
                            options={optionTypeScanNG}
                            onChange={onChangeTypeOptions}
                            value={typeOptions}
                          />
                        </Form.Item>
                        
                        {typeOptions == 1 ?
                          <>
                            <Form.Item label="Scan Kanban NG :">
                              <Input
                                type="primary"
                                onChange={(e) =>
                                  handleChangeSelectKanban("kanban_ng", e.target.value)
                                }
                                value={data.kanban_ng}
                                ref={scan_barcode_ng}
                                disabled={isDisabled.scan_ng}
                                onPressEnter={(e) => getKanbanDetails(e, "kanban_ng")}
                              />
                            </Form.Item>
                            
                            {getDescriptionKanbanNG.length != 0 ?
                                <>
                                  <Descriptions title="Detail Kanban NG">
                                    <Descriptions.Item label="Parent Item"> {getDescriptionKanbanNG.parent_item}</Descriptions.Item>
                                    <Descriptions.Item label="Id QAD"> {getDescriptionKanbanNG.id_qad}</Descriptions.Item>
                                    <Descriptions.Item label="Kanban Number"> {getDescriptionKanbanNG.kanban_number}</Descriptions.Item>
                                    <Descriptions.Item label="Part Name"> {getDescriptionKanbanNG.part_name}</Descriptions.Item>
                                    <Descriptions.Item label="Part Number"> {getDescriptionKanbanNG.part_number}</Descriptions.Item>
                                  </Descriptions>
                                </>
                              : ""
                            }

                            <Form.Item label="Scan Kanban Empty :">
                              <Input
                                type="primary"
                                onChange={(e) =>
                                  handleChangeSelectKanban("kanban_empty", e.target.value)
                                }
                                value={data.kanban_ng}
                                ref={scan_barcode_empty}
                                onPressEnter={(e) => getKanbanDetails(e, "kanban_empty")}
                              />
                            </Form.Item>

                            {getDescriptionKanbanEmpty.length != 0 ?
                                <>
                                  <Descriptions title="Detail Kanban NG">
                                    <Descriptions.Item label="Parent Item"> {getDescriptionKanbanEmpty.parent_item}</Descriptions.Item>
                                    <Descriptions.Item label="Id QAD"> {getDescriptionKanbanEmpty.id_qad}</Descriptions.Item>
                                    <Descriptions.Item label="Kanban Number"> {getDescriptionKanbanEmpty.kanban_number}</Descriptions.Item>
                                    <Descriptions.Item label="Part Name"> {getDescriptionKanbanEmpty.part_name}</Descriptions.Item>
                                    <Descriptions.Item label="Part Number"> {getDescriptionKanbanEmpty.part_number}</Descriptions.Item>
                                  </Descriptions>
                                </>
                              : ""
                            }

                          </>
                        :
                          <>
                            <Form.Item label="Select Kanban NG :">
                              <Select
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectKanban("kanban_ng", value)
                                }
                                onFocus={() => handleGetKanbanNG("")}
                                showSearch
                                onSearch={(value) => handleGetKanbanNG(value)}
                                filterOption={false}
                                value={{
                                  key: data.kanban_ng_id,
                                  label: data.kanban_ng_label,
                                }}
                              >
                                {kanbanActive &&
                                  kanbanActive.map((kanban) => {
                                    return (
                                      <Option value={kanban._id} key={kanban._id}>
                                        {`${kanban.kanban_number}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>

                            <Form.Item label="Select Kanban Empty :">
                              <Select
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectKanban("kanban_empty", value)
                                }
                                showSearch
                                filterOption={false}
                                value={{
                                    key: data.kanban_empty_id,
                                    label: data.kanban_empty_label,
                                }}
                              >
                                {kanbanNumberEmpty &&
                                  kanbanNumberEmpty.map((kanban) => {
                                    return (
                                      <Option value={kanban._id} key={kanban._id}>
                                        {`${kanban.kanban_number}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </>
                        }

                        {/* <Form.Item
                          validateStatus={error && error.qty ? "error" : false}
                          help={error && error.qty ? error.qty[0] : false}
                          label="QTY"
                        >
                          <Input
                            placeholder="qty"
                            value={data.qty}
                            onChange={(e) => {
                              handleChange("qty", e.target.value);
                            }}
                          />
                        </Form.Item> */}
                        
                      </Col>
                    </Fragment>
                  </Row>
                </Form>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={onSubmitChangeKanban}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                  }}
                >
                  Submit
                </Button>
                <Button htmlType="reset" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>

        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  {" "}
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />

                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "history-scan-produksi/change-kanban"
                    ) && (
                      <Button 
                        disabled={changeKanbanValid}
                        onClick={() => showModalChangeKanban()} 
                        type="primary"
                      >
                        <RotateRightOutlined />
                        Change Kanban
                      </Button>
                  )}
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 2000 }}
                    columns={columns}
                    dataSource={dataHistory.data}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dataHistory.total,
                      showSizeChanger: true,
                    }}
                    loading={loadingHistory}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        let collArr = [];
                        const collRow = selectedRows
                        const currDate = moment(new Date()).format("YYYY-MM-DD") 
                        
                        selectedRows.map((x) => {
                          const getCollDate = moment(x.created_at).format("YYYY-MM-DD") 
                          return getCollDate !== currDate || collRow.length > 1 || collRow.length == 0  ? 
                            message.error(`Silahkan cek kembali yang anda pilih, tanggal yang di pilih harus tanggal berjalan & tidak boleh lebih dari 1 untuk merubah kanban`)
                            : ''
                        })

                        selectedRows.map((x) => {
                          const getCollDate = moment(x.created_at).format("YYYY-MM-DD") 

                          return getCollDate !== currDate || collRow.length > 1 || collRow.length == 0 ? 
                            collArr.push(true)
                            : collArr.push(false)
                        })

                        setChangeKanbanValid(collArr.some(x => x === true) || collArr.length == 0)

                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                      },
                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PHistoryScanProduksi;
