/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Badge,
  Button,
  Input,
  message,
  Space,
  Modal,
  Form,
  Typography,
  Select,
  Table,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { BarcodeOutlined,ProjectOutlined,QrcodeOutlined,SyncOutlined } from "@ant-design/icons";

const { Title,Text } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function PScanProduksi(props) {
  const [modalOpenKanban, setmodalOpenKanban] = useState(false);
  const [dataModel, setDataModel] = useState("-");
  const [componentFailed, setcomponentFailed] = useState([]);
  const [scanProduksi, setScanProduksi] = useState([]);
  const [cloneScanProduksi, setCloneScanProduksi] = useState([]);
  const [scanKanban, setScanKanban] = useState([]);
  const [typeKanban, setTypeKanban] = useState("");
  const [dataTemporaryKanban, setDataTemporaryKanban] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [scanned, setScanned] = useState(true);
  const [error, setError] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);

  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    parent_item: "",
    qty: "",
    barcode_kanban: "",
  });

  const [isDisabled, setIsDisabled] = useState({
    parent_item: false,
    qty: true,
    barcode_kanban: true,
  });

  const parent_itemRef = useRef();
  const qtyRef = useRef();
  const barcode_kanbanRef = useRef();

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    parent_itemRef.current.focus();
    tempParent();
  }, [keyword, page, sort]);

  const handleQTY = (name, value) => {
    barcode_kanbanRef.current.focus();

    var qty_input = 0;

    // console.log("dataScan", data);
    var datanya = scanProduksi.map((x) => {
      var total_qty = x.qty_per;
      var total_qty_kanban = Math.ceil(total_qty / x.std_pack);
      if (x.current_stock == 0) {
        var curr_stock = 0;
      } else {
        var curr_stock = x.current_stock - total_qty;
      }
      setLoading(false)
      return {
        ...x,
        qty_input,
        current_stock: curr_stock,
        // total_qty,
        // total_qty_kanban,
      };
    });
    setScanProduksi(datanya);
    setCloneScanProduksi(datanya);
    setIsDisabled({
      parent_item: true,
      qty: true,
      barcode_kanban: false,
    });
  };
  const tempParent = () => {

    if (sessionStorage.getItem("parent_item") != null) {
      if (sessionStorage.getItem("parent_item").length > 0) {
        setData({
          ...data,
          parent_item: sessionStorage.getItem("parent_item"),
        });
        setIsDisabled({
          parent_item: true,
          qty: false,
          barcode_kanban: true,
        });
        setLoading(true)

        getData();

      }
    }

  }
  const getData = () => {
    setLoading(true)
    setDataModel('Loading...')
    Axios.get(`${url}/scan-produksi`, {
      params: {
        parent_item: sessionStorage.getItem("parent_item") == null || sessionStorage.getItem("parent_item") == '' ? data.parent_item : sessionStorage.getItem("parent_item"),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log("ini ress", res.data.data);
        const dataScan = res.data.data;
        if (sessionStorage.getItem("parent_item") == null) {

          sessionStorage.setItem("parent_item", data.parent_item);
        }
        setDataModel(res.data.model)
        setScanProduksi(dataScan);
        setDataTemporaryKanban(res.data.temp_scan);
        setCloneScanProduksi(dataScan);
        setcomponentFailed([])
        setmodalOpenKanban(false)
        setIsDisabled({
          parent_item: true,
          qty: false,
          barcode_kanban: true,
        });
        getKanbanType();
        getLotProduction(dataScan);
        handleQTY();
        barcode_kanbanRef.current.focus();
        parent_itemRef.current.blur();

      })
      .catch((err) => {
        if(err.response === undefined)
        {
          setDataModel('-')
          setLoading(false)
          setIsDisabled({
            parent_item: false,
            qty: true,
            barcode_kanban: true,
          });
          Modal.error({
            title: "Failed!",
            content:"Tidak dapat terhubung ke internet, mohon cek koneksi jaringan internet anda.",
          });
          
          // message.error("Can't connect to server, please try again later.");
        } else{
            setDataModel('-')
            setLoading(false)
            if(err.response.data.instruction == 'openmodal')
            {
              setcomponentFailed(err.response.data.component_items)
              setmodalOpenKanban(true)
            }
            setIsDisabled({
              parent_item: false,
              qty: true,
              barcode_kanban: true,
            });
            if (err.response) {
              message.error(err.response.data.message);
              if (err.response.status == 401) {
                sessionStorage.removeItem("token");
              }
            }
      }
      });
  };

  const getLotProduction = (dataScan) => {
    Axios.get(`${url}/lot-production`, {
      params: {
        parent_item: sessionStorage.getItem("parent_item") == null || sessionStorage.getItem("parent_item") == '' ? data.parent_item : sessionStorage.getItem("parent_item"),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        ShiftCode: sessionStorage.getItem("shift_code"),
      },
    })
      .then((res) => {
        // console.log("ini res", res);
        var result = dataScan.map((x) => {
          var lot_prod = res.data.data;
          // var total_qty_kanban = Math.ceil(total_qty / x.std_pack);
          return {
            ...x,
            lot_prod,
          };
        });
        setScanProduksi(result);
        setCloneScanProduksi(result);
        setLoading(false)
        console.log(dataScan, "lot");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const getKanbanType = () => {
    Axios.get(`${url}/kanban-type`, {
      params: {
        parent_item: sessionStorage.getItem("parent_item") == null || sessionStorage.getItem("parent_item") == '' ? data.parent_item : sessionStorage.getItem("parent_item"),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {

        setTypeKanban(res.data.data);

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const scanRollback = (parent_item, component_item, barcode, qty) => {
    Axios.post(`${url}/scan-produksi/rollback`, {
      parent_item: parent_item,
      component_item: component_item,
      barcode: barcode,
      qty: qty,

    },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {


      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const updateScannedKanban = (parent_item, component_item, barcode, qty) => {
    Axios.post(`${url}/scan-produksi/update-scan-kanban`, {
      parent_item: parent_item,
      component_item: component_item

    },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {


      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  console.log(scanProduksi);
  const getKanban = () => {
    Axios.get(`${url}/scan-produksi/component`, {
      params: {
        barcode_kanban: data.barcode_kanban,
        parent_item: data.parent_item,
        model: dataModel,
        total: data.qty
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.data === null) {
          message.error("Not Found");
          setData({
            parent_item: data.parent_item,
            qty: data.qty,
            barcode_kanban: "",
          });
          barcode_kanbanRef.current.focus();
        }
        setScanKanban(res.data.data);
        var qty_scan_kanban = res.data.data.qty_kanban;
        var quantity;
        var barcode_kanban = data.barcode_kanban;
        var update = cloneScanProduksi.map((x) => {

          if (x.component_item === res.data.data.id_qad) {
            // console.log('STDS',x.std_pack)
            quantity = !x.qty_scan_kanban ? 0 : x.qty_scan_kanban;
            qty_scan_kanban = Number(qty_scan_kanban) + Number(quantity);
            // if (x.current_stock == 0) {
            //   var curr_stock = 0;
            // } else {
            //   var curr_stock = x.current_stock - qty_scan_kanban;
            //
            // }
            // current_kanban = Number(currKanban) + 1;
            // if(!res.data.first_scan)
            // {
            // if(qty_scan_kanban <= (x.total_qty + res.data.data.qty_kanban))
            // {
            // if((x.current_kanban + 1) <= (x.total_qty_kanban) )
            // {
            // console.log('KANBAN', x.current_kanban, x.total_qty_kanban)
            updateScannedKanban(data.parent_item, res.data.data.id_qad, data.barcode_kanban, res.data.data.qty_kanban)

            return {
              ...x,
              qty_scan_kanban,
              barcode_kanban,
              // current_stock: curr_stock,
              current_kanban: x.current_kanban + 1,
            };
            //   } else{
            //     scanRollback(data.parent_item,res.data.data.id_qad, data.barcode_kanban,res.data.data.qty_kanban)
            //     message.error('Number of kanban component item '+res.data.data.id_qad+' has been fulfilled.');
            //     return {
            //       ...x,
            //     };
            //   }
            // } else{
            //   updateScannedKanban(data.parent_item,res.data.data.id_qad, data.barcode_kanban,res.data.data.qty_kanban)
            //   return {
            //     ...x,
            //     qty_scan_kanban,
            //     barcode_kanban,
            //     current_stock: curr_stock,
            //     current_kanban:x.current_kanban+1,
            //   };
            // }
          } else {
            return {
              ...x,
            };
          }
        });
        setScanProduksi(update);
        setCloneScanProduksi(update);
        if (res.data.data !== null) {
          setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
        }
        setData({
          parent_item: data.parent_item,
          qty: data.qty,
          barcode_kanban: "",
        });
        setScanned(false);
        barcode_kanbanRef.current.focus();
      })
      .catch((err) => {
        if (err.response) {
          setData({ ...data, barcode_kanban: "" })
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  console.log("temp", dataTemporaryKanban);

  console.log("ini res", scanProduksi);
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    resetTemp();
   
  };


  const resetTemp = () => {
    // var dataSend = true;
    // scanProduksi.map((x) => {
    //   if (x.qty_scan_kanban < x.total_qty || !x.qty_scan_kanban) {
    //     dataSend = false;
    //     return true;
    //   }
    //   return false;
    // });
    // console.log(scanProduksi);

    // if (dataSend == true) {
    setLoading(true);
    Axios.post(
      `${url}/reset-scan-produksi`,
      {
        temp_scan: dataTemporaryKanban,
        parent_item: data.parent_item,
        // barcode_kanban: data.barcode_kanban,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        sessionStorage.removeItem("parent_item")
        setData([]);
        setScanned(true);
        setIsDisabled({
          parent_item: false,
          qty: true,
          barcode_kanban: true,
        });
        setDataModel("-");
        setScanProduksi([]);
        setCloneScanProduksi([]);
        setDataTemporaryKanban([]);
        parent_itemRef.current.focus();
        setLoading(false);
        // if (data.parent_item) {
        //   message.success(res.data.message);
        // }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    // } else {
    //   message.error("Scan Kanban Belum Terpenuhi");
    // }
  };
  const onSaveData = () => {
    // var dataSend = true;
    // scanProduksi.map((x) => {
    //   if (x.qty_scan_kanban < x.total_qty || !x.qty_scan_kanban) {
    //     dataSend = false;
    //     return true;
    //   }
    //   return false;
    // });
    // console.log(scanProduksi);

    // if (dataSend == true) {
    setLoading(true);
    Axios.post(
      `${url}/histori-scan-produksi`,
      {
        temp_scan: dataTemporaryKanban,
        scan_produksi: scanProduksi,
        parent_item: data.parent_item,
        model: dataModel,
        kanban_type: typeKanban
        // barcode_kanban: data.barcode_kanban,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        sessionStorage.removeItem("parent_item")
        props.history.push("/history-scan-produksi");
        // if (data.parent_item) {
        //   message.success(res.data.message);
        // }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    // } else {
    //   message.error("Scan Kanban Belum Terpenuhi");
    // }
  };
  const handleCancel = () =>
  {
    setmodalOpenKanban(false);
  }
  const columnKanban = [
       
        {
          title: "#",
          render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
          title: "Component Item ",
          dataIndex: "component_item",
        },
        {
          title: "Project",
          dataIndex: "model",
        },
        
        {
          title: "Description",
          dataIndex: "description_1",
        },
  ];
  const columns = [
    {
      title: "Status",
      render: (data) => {
        if (data.current_stock < 0) {
          var notifSisaStok = (<div><Badge
            count={"Insufficient Stock"} /></div>);
        } else {
          var notifSisaStok = "";
        }
        if (data.qty_scan_kanban == 0) {
          if (data.qty_scan_kanban == 0) {
            return (<div><Badge
              count={"Un-scanned"} style={{ backgroundColor: '#292b2c', color: 'white' }} />{notifSisaStok}</div>)
          } else {
            return (<div><Badge
              count={"Not OK"} /></div>)
          }
        } else {

          return (<div><Badge
            count={"Scanned"} style={{ backgroundColor: '#52c41a' }} />{notifSisaStok}</div>)
        }

      },
      sorter: false,
    },
    // {
    //   title: "Qty Kanban",
    //   render: (data) => {
    //     if(data.total_qty_kanban == null)
    //     {
    //       return '-';
    //     } else{
    //       if(data.current_kanban == data.total_qty_kanban)
    //       {
    //         return (<Fragment><span style={{color:'green'}}>{data.current_kanban}/{data.total_qty_kanban}</span></Fragment>)

    //       } else{
    //         return (<Fragment><span style={{color:'red'}}>{data.current_kanban}/{data.total_qty_kanban}</span></Fragment>)
    //       }

    //     }
    //   },
    //   sorter: true,
    // },
    {
      title: "Component Item ",
      dataIndex: "component_item",
      sorter: true,
    },

    // {
    // title: "Total",
    // align: "center",
    // children: [
    {
      title: "Total Kanban Scan",
      dataIndex: "qty_scan_kanban",
      sorter: true,
    },

    // ],
    // },
    {
      title: "Current Stock",
      dataIndex: "current_stock",
      sorter: true,
    },
    {
      title: "Qty Per",
      dataIndex: "qty_per",
      sorter: true,
    },
    // {
    //   title: "Qty Input",
    //   dataIndex: "qty_input",
    //   sorter: true,
    // },

    {
      title: "Std Pack",
      dataIndex: "std_pack",
      sorter: true,
    },

    {
      title: "Unit",
      dataIndex: "unit",
      sorter: true,
    },
    {
      title: "Lot Prod Sequence",
      dataIndex: "lot_prod",
      sorter: true,
    },
    // {
    //   title: "Tanggal Scan",
    //   dataIndex: "tanggal_scan",
    //   sorter: true,
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  const styleForm = {
    marginTop: "-20px",
  };
  return (
    <Fragment>
     
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan Produksi"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
      <Modal  style={{ top: 20 }} width="100%" title="Kanban yang belum dibuat" visible={modalOpenKanban}  onCancel={handleCancel} footer={[
          
          <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
            Close
          </Button>,
         
        ]}>
          Parent Item : <b>{data.parent_item}</b>
          <hr/>
          <span>Tekan tombol Re-check jika kanban telah dibuatkan.</span><br/>
            <Button size="small" key="submit" icon={<SyncOutlined />} type="primary" loading={loading} onClick={() => getData()}>
            Re-check
          </Button>
            <Table
                scroll={{ x: 600 }}
                columns={columnKanban}
                okButtonProps={{ style: { display: 'none' } }} 
                dataSource={componentFailed}
                rowKey={(data) => data.id}
                onChange={handleTableChange}
                pagination={{
                  pageSize: 100
                }}
                loading={loading}
              />
      </Modal>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical">
                <Row>
                  <Fragment>
                    <Col style={styleForm} xs={24} md={12} lg={18}>
                      <Form.Item label="Parent Item :">
                        <Input
                          onPressEnter={(e) => getData(e, "parent_item")}
                          type="primary"
                          prefix={<QrcodeOutlined />}
                          onChange={(e) =>
                            handleChange("parent_item", e.target.value)
                          }
                          value={data.parent_item}
                          disabled={isDisabled.parent_item}
                          ref={parent_itemRef}
                        />
                      </Form.Item>
                    </Col>
                    
                     {/*<Col style={styleForm} xs={12} md={12} lg={6}>
                      {/* <Form.Item></Form.Item> 
                      {data.parent_item !== "" ? (
                        <Form.Item label=" " style={{ marginLeft: 10 }}>
                          <Button
                            type="danger"
                            onClick={handleReset}
                            loading={loading}
                            // htmlType="submit"
                            className="mr-button"
                          >
                            {" "}
                            Delete{" "}
                          </Button>
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </Col>*/}

                    <Col style={styleForm} xs={24} md={12} lg={18}>
                      <Form.Item label="Model / Project">
                        <h3><Text code><b>{dataModel}</b></Text></h3>
                      </Form.Item>
                    </Col>
                    {/* <Col style={styleForm} xs={24} md={24} lg={24}>
                      <Form.Item label="Qty :">
                        <Input
                          type="number"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          onChange={(e) => handleChange("qty", e.target.value)}
                          value={data.qty}
                          ref={qtyRef}
                          disabled={isDisabled.qty}
                          onPressEnter={(e) => handleQTY("qty", e.target.value)}
                        />
                      </Form.Item>
                    </Col> */}
                    <Col style={styleForm} xs={24} md={24} lg={24}>
                      <Form.Item label=" Scan Kanban :">
                        <Input
                          prefix={<QrcodeOutlined />}
                          type="primary"
                          onChange={(e) =>
                            handleChange("barcode_kanban", e.target.value)
                          }
                          value={data.barcode_kanban}
                          ref={barcode_kanbanRef}
                          disabled={isDisabled.barcode_kanban}
                          onPressEnter={(e) => getKanban(e, "barcode_kanban")}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Col style={styleForm} xs={24}>
                  <Table
                    scroll={{ x: 1500 }}
                    columns={columns}
                    dataSource={scanProduksi}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        onClick={onSaveData}
                        loading={loading}
                        // htmlType="submit"
                        className="mr-button"
                        // disabled={!data.data}
                        // disabled={scanned}
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PScanProduksi;
