import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Modal,
  Breadcrumb,
  message,
  DatePicker,
  TimePicker
} from "antd";
import { Link, Redirect } from "react-router-dom";

import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function ConfirmSPDNMaterial(props) {
  const [data, setData] = useState({
    project: "",
    description: "",
    part_number: "",
    qty_delivery: "",
    surat_jalan: "",
    keterangan: "",
    tgl_pelunasan:"",
    lot_prod: "",
    status_confirm: null,
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valueTime, setValueTime] = useState(null);

  const onChangeTime = (time, timestring) => {
    setValueTime(timestring);
  };
  
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(
      `${url}/dn-material-histori/show-details/${props.match.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data.data)
        setData({
          ...data,
          project: res.data.data.kanban.model,
          description: res.data.data.part_name,
          part_number: res.data.data.part_number,
          qty_delivery: 0,
          std_pack: res.data.data.std_pack,
          surat_jalan: res.data.data.surat_jalan,
          keterangan: res.data.data.keterangan,
          tgl_pelunasan: res.data.data.tgl_pelunasan,
          lot_prod: res.data.data.lot_prod,
          status_confirm: res.data.data.status_confirm,
          dn_material_id: res.data.data.material_histori_id,
          scan_produksi_id: res.data.data.scan_produksi_id,
          component_item: res.data.data.component_item,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    if (parseInt(data.qty_delivery) % parseInt(data.std_pack) === 0) {
    setLoading(true);

    let fd = new FormData();
    fd.set("project", data.project);
    fd.set("description", data.description);
    fd.set("part_number", data.part_number);
    fd.set("qty_delivery", data.qty_delivery);
    fd.set("surat_jalan", data.surat_jalan);
    fd.set("keterangan", data.keterangan);
    fd.set("lot_prod", data.lot_prod);
    fd.set("jam_pelunasan", valueTime);
    fd.set("status_confirm", data.status_confirm);
    fd.set("dn_material_id", data.dn_material_id);
    fd.set("scan_produksi_id", data.scan_produksi_id);
    fd.set("component_item", data.component_item);

    Axios.post(
      `${url}/dn-material-histori/confirm-ulang/${props.match.params.id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/dn-material");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    } else{
      Modal.error({
        title: "Failed!",
        content:"Qty Delivery harus kelipatan dari jumlah standar pack ("+data.std_pack+").",
      });
    }
  };

  const handleReset = () => {
    setData({
      qty_delivery: "",
      keterangan: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="DN Material"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/dn-material">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Confirm DN Material</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Project"
                  validateStatus={error && error.project ? "error" : false}
                  help={error && error.project ? error.project[0] : false}
                >
                  <Input
                    disabled
                    value={data.project}
                    onChange={(e) => handleChange("project", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Description"
                  validateStatus={error && error.description ? "error" : false}
                  help={
                    error && error.description ? error.description[0] : false
                  }
                >
                  <Input
                    disabled
                    value={data.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Part Number"
                  validateStatus={error && error.part_number ? "error" : false}
                  help={
                    error && error.part_number ? error.part_number[0] : false
                  }
                >
                  <Input
                    disabled
                    value={data.part_number}
                    onChange={(e) =>
                      handleChange("part_number", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Qty Delivery"
                  validateStatus={error && error.qty_delivery ? "error" : false}
                  help={
                    error && error.qty_delivery ? error.qty_delivery[0] : false
                  }
                >
                  <Input
                    value={data.qty_delivery}
                    onChange={(e) =>
                      handleChange("qty_delivery", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Surat Jalan"
                  validateStatus={error && error.surat_jalan ? "error" : false}
                  help={
                    error && error.surat_jalan ? error.surat_jalan[0] : false
                  }
                >
                  <Input
                    value={data.surat_jalan}
                    onChange={(e) =>
                      handleChange("surat_jalan", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  validateStatus={error && error.keterangan ? "error" : false}
                  help={error && error.keterangan ? error.keterangan[0] : false}
                  label="Keterangan"
                >
                  <TextArea
                    value={data.keterangan}
                    onChange={(e) => handleChange("keterangan", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  validateStatus={error && error.lot_prod ? "error" : false}
                  help={error && error.lot_prod ? error.lot_prod[0] : false}
                  label="Lot Prod"
                >
                  <Input
                    value={data.lot_prod}
                    onChange={(e) => handleChange("lot_prod", e.target.value)}
                  />
                </Form.Item>


                <Form.Item
                                          required
                                          validateStatus={
                                            error && error.jam_pelunasan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jam_pelunasan
                                              ? error.jam_pelunasan[0]
                                              : false
                                          }
                                          label="Jam Pelunasan"
                                        >
                                          <TimePicker
                                            onChange={onChangeTime
                                            }
                                           
                                            format="HH:mm:ss"
                                          />
                                        </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ConfirmSPDNMaterial;
