/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message, Dropdown,
  Menu,
  Form,
  Badge,
  Select
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined, SettingOutlined
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import send from "../../utils/send";
import imgDefault from "../../assets/img/img-empty.png";

const { Search } = Input;
const { Content } = Layout;

function ScanStockOpname(props) {
  const [dataTemporaryKanban, setDataTemporaryKanban] = useState([]);
  const [scanOutgoingSupplier, setScanOutgoingSupplier] = useState([]);
  const [scanOutgoingSupplierTempSeacrh, setScanOutgoingSupplierTempSearch] = useState([]);
  const [tempSearch, setTempSearch] = useState(false);
  const [dnKanban, setDnKanban] = useState([]);
  const [selectedKanban, setSelectedKanban] = useState({
    barcode: "",
    part_name: "",
    part_number: "",
    stock_actual: 0,
    keterangan: "",
  });

  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [warehouseLocations, setWarehouseLocations] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    storage_id: "",
    storage_name: "",
    area_id: "",
    area_name: "",
    barcode_kanban: "",
  });

  const barcode_kanbanRef = useRef();

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeKanban = (name, value) => {
    setSelectedKanban({
      ...selectedKanban,
      [name]: value,
    });
  };

  const handleSelectChange = (name, value) => {
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });

    barcode_kanbanRef.current.focus();
  };

  console.log(data)

  const layout = {
    labelCol: {
      xs: 24,
      lg: 24,
      md: 24,
    },
    wrapperCol: {
      xs: 24,
      lg: 24,
      md: 24,
    },
  };

  const scanNumberRef = useRef(null);

  useEffect(() => {
    getWarehouseLocation("");
  }, []);

  const onSaveData = (tempHeader, tempKanban) => {
    // console.log(tempHeader)
    // console.log(tempKanban)checkout
    // const findIncomplete = scanOutgoingSupplier.find(
    //   (x) => x.status === "Incomplete" && !x.keterangan
    // );
    // if (findIncomplete) {
    //   message.error("Please add keterangan for incomplete kanban");
    // } else {
    // }
    setLoading(true);

    const token = sessionStorage.getItem("token");
    send({
      url: `${url}/history-stock-opname`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      data: {
        warehouse_location_id: data.storage_id,
        storage: data.storage_name,
        details: tempHeader,
        tempScan: tempKanban,
      },
    })
      .then((res) => {
        if (res.success) {
          message.success(res.data.message);
          // props.history.push("/history-stock-opname");
        } else {
          message.error(res.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const getWarehouseLocation = (keyword) => {
    setLoadingSelect(true);
    const token = sessionStorage.getItem("token");
    send({
      url: `${url}/master-warehouse-location/list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      data: {
        columns: ["storage", "area"],
        keyword,
      },
    })
      .then((res) => {
        if (res.success) {
          setWarehouseLocations(res.data.data);
        } else {
          message.error(res.data.message);
        }
      })
      .finally(() => {
        setLoadingSelect(false);
      });
  };

  const closeModalKanban = () => {
    setIsModalVisibleKanban(false);
  };

  const handleReset = () => {
    setData({
      storage_id: "",
      storage_name: "",
      area_id: "",
      area_name: "",
      barcode_kanban: "",
    });
    setScanOutgoingSupplier([]);
    setDnKanban([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleSearch = (value) => {
    setTempSearch(true)
    getDataKanbanByFilter(data.storage_name, value)
  };

  const getDataKanbanByFilter = (storage, value) => {
    setLoading(true)

    if (value) {
      const tempFilter = scanOutgoingSupplierTempSeacrh.filter(
        (x) => x.id_qad.toLowerCase().includes(value.toLowerCase())
      );

      setScanOutgoingSupplierTempSearch(tempFilter)
    } else {
      setTempSearch(false)
    }

    // const token = sessionStorage.getItem("token");
    // send({
    //   url: `${url}/check-storage-kanban`,
    //   method: "get",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    //   data: {
    //     storage: storage,
    //     keyword: value,
    //     columns: [
    //       "id_qad",
    //     ],
    //   },
    // }).then((res) => {
    //   if (res.success) {
    //     setScanOutgoingSupplier(res.data.data);
    //   } else {
    //     message.error(res.data.message);
    //     setScanOutgoingSupplier([]);
    //   }
    // });

    setLoading(false)
  };



  function getDateCustom(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so we add 1 and pad with '0' if needed
    const day = String(date.getDate()).padStart(2, '0'); // Pad with '0' if needed

    return `${year}${month}${day}`;
  }

  const handleGetKanban = (value) => {
    const token = sessionStorage.getItem("token");
    setLoading(true)
    send({
      url: `${url}/check-storage-kanban`,
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        storage: value.label,
        keyword,
        columns: [
          "id_qad",
        ],
      },
      params: {
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
    }).then((res) => {

      if (res.success) {
        setDataTemporaryKanban(res.data.temporary);
        setScanOutgoingSupplier(res.data.data);
        setScanOutgoingSupplierTempSearch(res.data.data)
      } else {
      
        setScanOutgoingSupplier([]);
        setDataTemporaryKanban([]);
        setScanOutgoingSupplierTempSearch([]);
        Modal.error({
          title: "Failed!",
          content: res.data.message,
        });
      }
    }).finally((res) => {
      setLoading(false)
    });
  };

  const handleScanKanban = (value) => {
    const newScan = [...dataTemporaryKanban];
    const findFirst = newScan.find((x) => x.barcode === value);
    if (findFirst) {
      const findDuplicate = dnKanban.filter((x) => x.barcode === value);

      if (findDuplicate.length > 0) {
        message.error("Kanban already scanned!");
        setData({
          ...data,
          barcode_kanban: "",
        });
      } else {
        const token = sessionStorage.getItem("token");
        send({
          url: `${url}/scan-kanban-stock-opname`,
          method: "get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            barcode_kanban: value,
          },
        }).then((res) => {
          if (res.success) {
            // GET DATA STOCK INVENTORY BY ID_QAD
            const findMatchStockInventory = dataTemporaryKanban.filter(
              (x) => x.id_qad === res.data.data.id_qad
            );
            const getStockInventory = findMatchStockInventory.reduce(
              (total, num) => total + parseInt(num.qty_kanban),
              0
            )
            // END GET DATA STOCK INVENTORY

            console.log(getStockInventory)

            // GET DATA STOCK ACTUAL BY ID_QAD
            const findMatchStockActualByIdQad = dnKanban.filter(
              (x) => x.id_qad === res.data.data.id_qad
            );
            let stock_actual = findMatchStockActualByIdQad.reduce(
              (total, num) => total + parseInt(num.qty_kanban),
              0
            )
            // END GET DATA STOCK ACTUAL

            if (dnKanban.length >= 1) {
              const getActual = dnKanban.reduce(
                (total, num) => total + parseInt(num.qty_kanban),
                0
              )
              stock_actual = getActual + parseInt(res.data.data.qty_kanban);
            } else {
              stock_actual = parseInt(res.data.data.qty_kanban);
            }

            const findMatch = dataTemporaryKanban.filter(
              (x) => x.id_qad === res.data.data.id_qad
            );

            const totalQtyKanban = findMatch.reduce(
              (total, num) => total + parseInt(num.qty_kanban),
              0
            )
            const qty_selisih = findFirst.stock_inventory - stock_actual;

            findFirst.stock_inventory = findFirst.stock_inventory;
            findFirst.stock_actual = parseInt(stock_actual);
            findFirst.qty_selisih = qty_selisih;
            findFirst.status = qty_selisih === 0 ? "Complete" : "Incomplete";
            findFirst.status_scan_stock_opname = true;

            // Update Data real
            const updateStockOpname = scanOutgoingSupplier.map(x =>
              x.id_qad === findFirst.id_qad ?
                {
                  ...x,
                  status_scan: true,
                  stock_actual: stock_actual,
                  qty_selisih: qty_selisih,
                  scanned: stock_actual / findFirst.std_pack,
                  status: qty_selisih === 0 ? "Complete" : "Incomplete",
                }
                : x
            )

            setScanOutgoingSupplier(updateStockOpname);

            // Update Data temporary search
            const updateStockOpnameTemp = scanOutgoingSupplierTempSeacrh.map(x =>
              x.id_qad === findFirst.id_qad ?
                {
                  ...x,
                  status_scan: true,
                  stock_inventory: getStockInventory,
                  stock_actual: stock_actual,
                  qty_selisih: qty_selisih,
                  status: qty_selisih === 0 ? "Complete" : "Incomplete",
                }
                : x
            )
            setScanOutgoingSupplierTempSearch(updateStockOpnameTemp);

            setDnKanban([...dnKanban, res.data.data]);

            setData({
              ...data,
              barcode_kanban: "",
            });

            const tempHeader = updateStockOpname;
            const tempKanban = [...dnKanban, res.data.data];

            onSaveData(tempHeader, tempKanban);

          } else {
            setData({
              ...data,
              barcode_kanban: "",
            });
            message.error(res.data.message);
          }

          // Update or insert history scan kanban to table
        }).finally(() => {
          // setTimeout(() => {
          //   onSaveData();
          // }, 2000);
          barcode_kanbanRef.current.focus();

        });
      }
    } else {
      message.error("Kanban not match!");
      setData({
        ...data,
        barcode_kanban: "",
      });

      barcode_kanbanRef.current.focus();

    }
  };

  const showModalKanban = (id, row) => {
    setIsModalVisibleKanban(true);
    console.log('row ', id)
    setSelectedKanban(row);
  };


  const handleSubmitModal = () => {
    const newKanban = [...scanOutgoingSupplier];
    if (selectedKanban.keterangan == undefined || selectedKanban.keterangan.length == 0) {
      Modal.error({
        title: "Error!",
        content: "Keterangan wajib Diisi.",
      });
      return 0;
    }
    if (isNaN(selectedKanban.stock_actual)) {
      Modal.error({
        title: "Error!",
        content: "Stock Actual Harus berupa angka",
      });
      return 0;
    }
    const findKanban = newKanban.find(
      (x) => x.id_qad === selectedKanban.id_qad
    );
    console.log(findKanban);
    const qty_selisih = findKanban.qty_kanban - selectedKanban.stock_actual;

    console.log('soId', selectedKanban.soId);
    findKanban.stock_actual = selectedKanban.stock_actual;
    findKanban.qty_selisih = qty_selisih;
    findKanban.keterangan = selectedKanban.keterangan;
    findKanban.status = qty_selisih === 0 ? "Complete" : "Incomplete";
    setScanOutgoingSupplier(newKanban);
    setIsModalVisibleKanban(false);

    Axios.post(
      `${url}/history-stock-opname/${selectedKanban.soId}`,
      {
        keterangan: selectedKanban.keterangan,
        stock_adjusment: selectedKanban.stock_actual,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        Modal.success({
          title: "Success!",
          content: res.data.message,
        })
        // message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response === undefined) {
          Modal.error({
            title: "Failed!",
            content: "Tidak dapat terhubung ke internet, mohon cek koneksi jaringan internet anda",
          });
          // message.error("Can't connect to server, please try again later.");
        }
      }
      );

  };

  const handleResetModal = () => {
    setSelectedKanban({
      ...selectedKanban,
      keterangan: "",
      stock_actual: "",
    });
  };

  const columns = [
    {
      title: "Status",
      width: 100,
      dataIndex: "status",
      sorter: true,
      render: (status) => {
        if (status === "Complete") {
          return (
            <div>
              <Badge count={"Complete"} style={{ backgroundColor: 'green', color: 'white', fontWeight: 500 }} />
            </div>
          )
        } else {
          return (
            <div>
              <Badge count={"Incomplete"} style={{ backgroundColor: 'red', color: 'white', fontWeight: 500 }} />
            </div>
          )
        }
      },
    },
    {
      title: "Kanban",
      width: 120,
      render: (item, record) => {
        console.log('ITEMS', item)
        return record.scanned + "/" + item.total_num_kanban;
        // const findMatch = dnKanban.filter(
        //   (x) => x.id_qad === record.id_qad
        // );
        // return `${findMatch.reduce(
        //   (total, num) => total + parseInt(num.qty_kanban),
        //   0
        // )}`;
      },
    },
    {
      title: "Item Number",
      width: 120,
      dataIndex: "id_qad",
      sorter: true,
    },
    {
      title: "Unit",
      width: 100,
      // dataIndex: "unit",
      sorter: true,
      render: (data) => (
        data.item ? data.item.get_item.unit_of_measure !== null ? data.item.get_item.unit_of_measure : '-' : '-'
      ),
    },
    {
      title: "Tipe",
      width: 100,
      // dataIndex: "tipe",
      sorter: true,
      render: (data) => (
        data.item ? data.item.get_item.tipe !== null ? data.item.get_item.tipe : '-' : '-'
      ),
    },
    {
      title: "Part Name",
      width: 200,
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "Area",
      width: 100,
      dataIndex: "area",
      sorter: true,
    },
    {
      title: "Model",
      width: 100,
      dataIndex: "model",
      sorter: true,
    },
    {
      title: "Photo",
      width: 100,
      dataIndex: "img_url",
      sorter: true,
      render: (img_url) => (
        <img style={{ width: 80, height: 70 }} src={img_url == null ? imgDefault : img_url} alt="No-Image" />
      ),
    },
    {
      title: "Matl.Type",
      width: 100,
      dataIndex: "matl.type",
      sorter: true,
    },
    {
      title: "Supplier/Subcont",
      width: 100,
      dataIndex: "supplier_name",
      sorter: true,
    },

    {
      title: "Stock Inventory",
      width: 100,
      dataIndex: "stock_inventory",
      sorter: true,
    },

    // {
    //   title: "Stock Inventory",
    //   width: 200,
    //   dataIndex: "qty_kanban",
    //   sorter: true,
    //   align: 'right',
    //   render: (item, record) => {
    //     const findMatch = dataTemporaryKanban.filter(
    //       (x) => x.id_qad === record.id_qad
    //     );
    //     return `${findMatch.reduce(
    //       (total, num) => total + parseInt(num.qty_kanban),
    //       0
    //     )}`;
    //   },
    // },

    {
      title: "Stock Actual",
      width: 100,
      dataIndex: "stock_actual",
      sorter: true,
      align: 'right',
      render: (item, record) => {
        const findMatch = dnKanban.filter(
          (x) => x.id_qad === record.id_qad
        );
        return `${findMatch.reduce(
          (total, num) => total + parseInt(num.qty_kanban),
          0
        )}`;
      },
    },
    {
      title: "Qty Selisih",
      width: 100,
      dataIndex: "qty_selisih",
      align: 'right',
      sorter: true,
    },

    {
      title: "Keterangan",
      width: 100,
      dataIndex: "keterangan",
      sorter: true,
    },

    {
      title: "",
      dataIndex: "barcode",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 75,
      render: (id, row, record) => {
        console.log('ITEMS 2', row.soId)
        console.log('ITEMS 3', record)
        console.log('ITEMS 4', id)
        return (

          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="4">
                    <span onClick={() => showModalKanban(row.soId, row)}>
                      <SettingOutlined style={{ marginRight: 3 }} />
                      Edit
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  console.log('cek', selectedKanban)
  console.log('header', scanOutgoingSupplier)
  console.log('hasil-scan', dnKanban)
  console.log('tempscan', dataTemporaryKanban)
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Scan Stock Opname"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>

        <Modal
          title="Adjustment Stock Opname"
          style={{ top: 20 }}
          visible={isModalVisibleKanban}
          width={1000}
          footer={null}
          onCancel={closeModalKanban}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Form {...layout} name="control-hooks" labelAlign="left">
                  <Row>
                    <Fragment>
                      <Col xs={24}>
                        <Form.Item label="Item Number">
                          <Input disabled value={selectedKanban.id_qad} />
                        </Form.Item>
                        <Form.Item label="Part Name">
                          <Input disabled value={selectedKanban.part_name} />
                        </Form.Item>
                        <Form.Item label="Stock Actual">
                          <Input
                            value={selectedKanban.stock_actual}
                            onChange={(e) => {
                              handleChangeKanban(
                                "stock_actual",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          validateStatus={
                            error && error.keterangan ? "error" : false
                          }
                          help={
                            error && error.keterangan
                              ? error.keterangan[0]
                              : false
                          }
                          label="Keterangan"
                        >
                          <TextArea
                            required
                            placeholder="Tulis keterangan"
                            value={selectedKanban.keterangan}
                            onChange={(e) => {
                              handleChangeKanban(
                                "keterangan",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Item>

                      </Col>
                    </Fragment>
                  </Row>
                </Form>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={handleSubmitModal}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                  }}
                >
                  Submit
                </Button>
                <Button htmlType="reset" onClick={handleResetModal}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical">
                <Row>
                  <Fragment>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item label="Scan Storage :">
                        <Select
                          labelInValue
                          filterOption={false}
                          loading={loadingSelect}
                          showSearch
                          onSearch={(val) => getWarehouseLocation(val)}
                          options={warehouseLocations.map((x) => {
                            return {
                              label: x.storage,
                              value: x._id,
                            };
                          })}
                          onChange={(value) =>
                            handleSelectChange("storage", value)
                          }
                          onSelect={(val) => handleGetKanban(val)}
                          value={{
                            key: data.storage_id,
                            label: data.storage_name,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>


                      <Form.Item label=" Scan Kanban :">
                        <Input
                          type="primary"
                          onChange={(e) =>
                            handleChange("barcode_kanban", e.target.value)
                          }
                          value={data.barcode_kanban}
                          ref={barcode_kanbanRef}
                          onPressEnter={(e) =>
                            handleScanKanban(e.target.value, "barcode_kanban")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Col xs={24}>
                  {/* { scanOutgoingSupplier.length >= 1 ? ( */}
                  <Row type="flex" gutter={[10, 20]} justify="end">
                    <Col xs={10} md={12} lg={6} className="align-right">
                      <Search
                        placeholder="Search ..."
                        onSearch={(value) => handleSearch(value)}
                      />
                    </Col>
                  </Row>
                  {/* ) : ''} */}

                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={tempSearch ? scanOutgoingSupplierTempSeacrh : scanOutgoingSupplier}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
                {/* <Row justify="start">
                  {dnKanban.length === scanOutgoingSupplier.length &&
                    dnKanban.length !== 0 && (
                      <Col style={{ marginTop: 20 }}>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    )}
                </Row> */}
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ScanStockOpname;
