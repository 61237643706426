/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Form,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";

const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function DetailsScanOutgoingSupplier(props) {
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });

  const [data, setData] = useState({
    dn_no: "",
    dataSupplier: [],
  });

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    getData();
  }, []);
  console.log(props);

  const scanNumberRef = useRef(null);

  const getData = () => {
    Axios.get(`${url}/scan-outgoing-supplier/get-scan`, {
      params: {
        dn_no: props.match.params.id,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.data[0].dn_no, "pww");
        setData({
          ...data,
          dn_no: res.data.data[0].dn_no,
          no_po: res.data.data[0].no_po,
          tanggal: res.data.data[0].tanggal,
          supplier: res.data.data[0].supplier,
          dataSupplier: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  const onSaveData = () => {
    console.log(data.dataSupplier, "pppp");
    setLoading(true);

    let fd = new FormData();

    fd.set("details", JSON.stringify(data.dataSupplier));
    fd.set("dn_no", data.dn_no);
    fd.set("nomor", data.nomor);
    fd.set("supplier", data.supplier);
    fd.set("no_po", data.no_po);
    fd.set("no_kendaraan", data.no_kendaraan);
    fd.set("tanggal", data.tanggal);

    Axios.post(`${url}/scan-outgoing-supplier/store`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/history-outgoing-supplier");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
    },
    {
      title: "Item Number",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "Jumlah",
      dataIndex: "jumlah",
      sorter: true,
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      sorter: true,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan Outgoing Supplier"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item label="DN Manifest">
                        <Input
                          disabled
                          value={data.dn_no}
                          onChange={(e) =>
                            handleChange("dn_no", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Nomor">
                        <Input
                          value={data.nomor}
                          onChange={(e) =>
                            handleChange("nomor", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Supplier">
                        <Input
                          disabled
                          value={data.dn_no}
                          onChange={(e) =>
                            handleChange("dn_no", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item label="NO PO">
                        <Input
                          disabled
                          value={data.dn_no}
                          onChange={(e) =>
                            handleChange("dn_no", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="NO Kendaraan">
                        <Input
                          value={data.no_kendaraan}
                          onChange={(e) =>
                            handleChange("no_kendaraan", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Tanggal">
                        <Input
                          disabled
                          value={data.dn_no}
                          onChange={(e) =>
                            handleChange("dn_no", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Col xs={24}>
                  <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    size="small"
                    dataSource={data.dataSupplier}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        className="mr-button"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default DetailsScanOutgoingSupplier;
