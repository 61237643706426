/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateMasterShift(props) {
  const [data, setData] = useState({
    name: "",
    start: "",
    end: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("name", data.name);
    fd.set("start", data.start);
    fd.set("end", data.end);
    fd.set("code", data.code);

    Axios.post(`${url}/master-shift`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-shift");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      name: "",
      start: "",
      end: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Shift"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-shift">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Master Shift</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Name"
                  validateStatus={error && error.name ? "error" : false}
                  help={error && error.name ? error.name[0] : false}
                >
                  <Input
                    value={data.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Start"
                  validateStatus={error && error.start ? "error" : false}
                  help={error && error.start ? error.start[0] : false}
                >
                  <Input
                    value={data.start}
                    onChange={(e) => handleChange("start", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="End"
                  validateStatus={error && error.end ? "error" : false}
                  help={error && error.end ? error.end[0] : false}
                >
                  <Input
                    value={data.end}
                    onChange={(e) => handleChange("end", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Shift Code"
                  validateStatus={error && error.code ? "error" : false}
                  help={error && error.code ? error.code[0] : false}
                >
                  <Input
                  placeholder="cth: A"
                    value={data.code}
                    onChange={(e) => handleChange("code", e.target.value)}
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateMasterShift;
