import React from "react";
import { Row, Button, Col } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Error404() {
  return (
    <Row type="flex" justify="center" align="middle">
      <Col>
        <div>
          <img
            src={require("../../assets/img/404.svg")}
            style={{ width: 700 }}
          />
        </div>
        <Row type="flex" justify="center">
          <Col>
            <p>Page you visited seems like not found</p>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={0}>
          <Col>
            <Link to={sessionStorage.getItem("redirect")}>
              <Button type="primary">
                <HomeOutlined />
                Back to Home
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Error404;
