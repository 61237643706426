import React, { Fragment,useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Space,
  Spin,
  Upload,
  List,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";

const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function PPrintWipResult(props) {
  const [data, setData] = useState({
    item_number: "",
    group: "",
    model: "",
    part_number: "",
    qty_kanban_beredar: "",
    tipe: "",
    email: "",
    qty_kanban: "",
    proses: "",
    qty_actual: "",
    id_qad: "",
    showCard: false,
    storage: "",
    keterangan: "",
    lot_prod_sequence: "",
    reprintLotProd: [],
    lotprodseq: [],
  });

  const [openReprint, setOpenReprint] = useState(false);
  const [itemName, setItemName] = useState('Loading...');
  const [latestLoader, setLatestLoader] = useState(false);
  const [kanbanBeredar, setKanbanBeredar] = useState('-');
  const [totalPrinted, setTotalPrinted] = useState('-');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLatestItem()
  },[]);
  const getProdSeq = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/histori-scan-produksi-list`, {
      params: {
        keyword,
        kanban_type:'Kanban WIP',
        columns: ["parent_item",'lot_prod'],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        ShiftCode: sessionStorage.getItem("shift_code"),
      },
    })
      .then((res) => {
        setData({
          ...data,
          lotprodseq: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);

    console.log(data.qads);
  };
  const getLatestItem = () => {
    setLatestLoader(true)
    let fd = new FormData();
    fd.set("kanban_type", "Kanban WIP");
     Axios.post(`${url}/confirm/prod/latest`,fd ,{

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setLatestLoader(false)
        // Modal.success({
        //   title: "Success!",
        //   content:res.data.message,
        // });
        // handleChangeSelect('lotprodseq',res.data.data.parent_item);
        checkKanban(res.data.data.parent_item,res.data.data.scan_group);
        checkItem(res.data.data.parent_item);
        setData({
          ...data,
          showCard: true,
          parent_item: res.data.data.parent_item,
          part_number: res.data.data.component_item,
          group: res.data.data.kanbans.group,
          id_qad: res.data.data.parent_item,
          model: res.data.data.kanbans.model,
          qty_kanban_beredar: res.data.data.kanbans.kanban_beredar,
          qty_kanban: res.data.data.kanbans.qty_kanban,
          item_number: res.data.data.parent_item,
          tanggal: res.data.data.tanggal,
          tipe:
            res.data.data.kanbans == null
              ? ""
              : res.data.data.kanbans.tipe == null
              ? "-"
              : res.data.data.kanbans.tipe,
          proses:
            res.data.data.kanbans == null
              ? ""
              : res.data.data.kanbans.proses == null
              ? "-"
              : res.data.data.kanbans.proses,
          qty_actual: res.data.data.kanbans == null ? "" : res.data.data.kanbans.qty_kanban,
          storage: res.data.data.kanbans == null ? "" : res.data.data.kanbans.storage,
          lot_prod_sequence: res.data.data.lot_prod,
          scan_group: res.data.data.scan_group,
        });
      })
      .catch((err) => {
        setLatestLoader(false);
        if (err.response) {
          Modal.error({
              title: "Failed!",
              content:err.response.data.message,
            });
            setData({
              ...data,
             parent_item:"-",
              showCard: false,
            });
          // message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
  }
  const historyPrint = async (keyword, lot_prod,parent_item) => {
    setLoading(true);

    await Axios.get(`${url}/histori-print-wip-result`, {
      params: {
        keyword,
        lot_prod,
        parent_item,
        columns: ["parent_item",'lot_prod_seq','component_item'],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          reprintLotProd: res.data.data,
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
        setLoading(false);
      });
  }

  const sendReprint = async (keyword, lot_prod,parent_item) => {
    setLoading(true);
    let fd = new FormData();
    fd.set("id", data.reprintLotProd_id);
    await Axios.post(`${url}/history-print/reprint/wip`,fd ,{

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setLoading(false);
        Modal.success({
          title: "Success!",
          content:res.data.message,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);

    console.log(data.qads);
  };
  const handleRemove = () => {
    setData({
      ...data,
      photo: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      photo: files,
    });

    return false;
  };

  const getRole = async (keyword) => {
    await Axios.get(`${url}/role/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          roles: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const checkKanban = async (parent_item,scan_group) => {
    setTotalPrinted('-')
    await Axios.get(`${url}/check/kanban-print/wip`, {
      params: {
        scan_group,
        parent_item,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTotalPrinted(res.data.data);
        setKanbanBeredar(res.data.kanban_beredar);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const checkItem = async (parent_item) => {
    await Axios.get(`${url}/check/item`, {
      params: {
        parent_item,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItemName(res.data.data.description_1);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };
  const marginRow = {
    marginBottom: "10px",
  };
  const closeReprint = () => {
    setOpenReprint(false);
  }
  const modalHistory = () => {
    if (data.lot_prod_sequence == "" && data.qty_actual == "") {
      console.log(data.lot_prod_sequence);
      message.error("Mohon Lengkapi Form dibawah.");
      return 0;
    }
    setOpenReprint(true);
    console.log('aku punya ini',openReprint)
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    if(name == 'lotprodseq')
    {
      var find = data.lotprodseq.find((x) => x._id === value.key);
      checkKanban(find.parent_item,find.scan_group);
      checkItem(find.parent_item);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        showCard: true,
        part_number: find.component_item,
        group: find.kanbans.group,
        id_qad: find.parent_item,
        model: find.kanbans.model,
        qty_kanban_beredar: find.kanbans.kanban_beredar,
        qty_kanban: find.kanbans.qty_kanban,
        item_number: find.parent_item,
        tanggal: find.tanggal,
        tipe:
          find.kanbans == null
            ? ""
            : find.kanbans.tipe == null
            ? "-"
            : find.kanbans.tipe,
        proses:
          find.kanbans == null
            ? ""
            : find.kanbans.proses == null
            ? "-"
            : find.kanbans.proses,
        qty_actual: find.kanbans == null ? "" : find.kanbans.qty_kanban,
        storage: find.kanbans == null ? "" : find.kanbans.storage,
        lot_prod_sequence: find.lot_prod,
        scan_group: find.scan_group,
      });
    } else{
      var find = data.reprintLotProd.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }


  };

  const onSaveData = () => {
    if (data.lot_prod_sequence == "" && data.qty_actual == "") {
      console.log(data.lot_prod_sequence);
      message.error("Mohon Lengkapi Form dibawah.");
      return 0;
    }
    setLoading(true);

    let fd = new FormData();
    fd.set("id_qad", data.id_qad);
    fd.set("lot_prod_sequence", data.lot_prod_sequence);
    fd.set("scan_group", data.scan_group);
    fd.set("part_number", data.part_number);
    fd.set("parent_item", data.item_number);
    fd.set("tipe", data.tipe);
    fd.set("tanggal", data.tanggal);
    fd.set("proses", data.proses);
    fd.set("storage", data.storage);
    fd.set("group", data.group);
    fd.set("model", data.model);
    fd.set("std_pack", data.qty_kanban);
    fd.set("kanban_beredar", data.qty_kanban_beredar);
    fd.set("qty", data.qty_actual);
    fd.set("keterangan", data.keterangan);

    Axios.post(`${url}/wip/store`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        ShiftCode: sessionStorage.getItem("shift_code"),
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content:res.data.message,
        });
        // window.open(res.data.pdf, "_blank");
        // props.history.push("/print-wip-result");
        getLatestItem()
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          Modal.error({
            title: "Failed!",
            content:err.response.data.message,
          });
          // message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      username: "",
      password: "",
      full_name: "",
      department: "",
      phone_number: "",
      npk: "",
      email: "",
      password_confirmation: "",
      vendor_id: "",
      vendor_name: "",
      role_id: "",
      role_name: "",
      photo: null,
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
       <Modal
          visible={openReprint}
          title="Reprint List"
          onOk={closeReprint}
          onCancel={closeReprint}
          footer={[
            <Button key="back" onClick={closeReprint}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={sendReprint} loading={loading} >
              Reprint
            </Button>,

          ]}
        >
          Seq | Item Number (Lot Prod)
          <Select
                    labelInValue
                    onChange={(value) =>
                      handleChangeSelect("reprintLotProd", value)
                    }
                    onFocus={() => historyPrint('', data.lot_prod_sequence,data.item_number)}
                    showSearch
                    style={{minWidth:'100%'}}
                    onSearch={(value) => historyPrint(value, data.lot_prod_sequence,data.item_number)}
                    filterOption={false}
                    value={{
                      key: data.reprintLotProd_id,
                      label: data.reprintLotProd_label,
                    }}
                  >
                    {data.reprintLotProd &&
                      data.reprintLotProd.map((item) => {
                        return (
                          <Option value={item._id} key={item._id}>
                            {console.log("ini", item)}
                            {item.barcode.slice(-3)} | {item.parent_item} ({item.lot_prod}) 
                          </Option>
                        );
                      })}
                  </Select>
        </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Print WIP Result"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">Kembali</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Print WIP Result</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">

        <Form {...layout} layout="vertical" onFinish={onSaveData}>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
            <Row justify="start" style={{marginBottom:'12px'}}>
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Print
                      </Button>

                      <Button

                        onClick={modalHistory}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reprint
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={24} lg={24}>
                    {latestLoader && ( <Spin tip="Mohon Tunggu..." size="large">
                    </Spin>)}
                      <Form.Item
                        // label="Item Number"
                        validateStatus={
                          error && error.item_number ? "error" : false
                        }
                        help={
                          error && error.item_number
                            ? error.item_number[0]
                            : false
                        }
                      >
                        <Col md={24} sm={24} xs={24}>
                          {/* <Select
                            labelInValue
                            onChange={(value) =>
                              handleChangeSelect("lotprodseq", value)
                            }
                            onFocus={() => getProdSeq("", "wip")}
                            showSearch
                            onSearch={(value) => getProdSeq(value)}
                            filterOption={false}
                            value={{
                              key: data.lotprodseq_id,
                              label: data.lotprodseq_label,
                            }}
                          >
                            {data.lotprodseq &&
                              data.lotprodseq.map((item) => {
                                return (
                                  <Option value={item._id} key={item._id}>
                                    {item.parent_item}
                                  </Option>
                                );
                              })}
                          </Select> */}
                          <br/>
                          <b style={{fontSize:'25px'}}>{data.parent_item}</b>
                        </Col>
                      </Form.Item>
                      {/* :Part Name, Tipe, Model, Qty Kanban Beredar */}
                      {data.showCard && (
                        <Fragment>
                          {/* <Row style={marginRow}>
                            <Col xs={6} md={6} lg={6}>
                              <b>Item Number</b>
                            </Col>
                            <Col xs={18} md={18} lg={18}>
                              : {data.item_number}
                            </Col>
                          </Row> */}

                          <Row style={marginRow}>
                            <Col xs={24} md={6} lg={6}>
                              <b>Part name</b>
                            </Col>
                            <Col xs={24} md={18} lg={18}>
                              {itemName}
                            </Col>
                          </Row>

                          <Row style={marginRow}>
                            <Col xs={24} md={6} lg={6}>
                              <b>Tipe</b>
                            </Col>
                            <Col xs={24} md={18} lg={18}>
                              {data.tipe}
                            </Col>
                          </Row>

                          <Row style={marginRow}>
                            <Col xs={24} md={6} lg={6}>
                              <b>Model</b>
                            </Col>
                            <Col xs={24} md={18} lg={18}>
                              {data.model}
                            </Col>
                          </Row>

                          <Row style={marginRow}>
                            <Col xs={24} md={6} lg={6}>
                              <b>Printed</b>
                            </Col>
                            <Col xs={24} md={18} lg={18}>
                            {totalPrinted == '-' ? 'Loading...':kanbanBeredar <= 0 ? "Stok belum mencukupi":(<Fragment>{totalPrinted} sisa {kanbanBeredar}</Fragment>)}
                            </Col>
                          </Row>

                          {/* <Row style={marginRow}>
                            <Col xs={6} md={6} lg={6}>
                              <b>Qty Kanban / Standar Packing</b>
                            </Col>
                            <Col xs={18} md={18} lg={18}>
                              : {data.qty_kanban}
                            </Col>
                          </Row> */}
                        </Fragment>
                      )}

                      {/* <Form.Item

                        label="Item Number"
                        validateStatus={
                          error && error.username ? "error" : false
                        }
                        help={
                          error && error.username ? error.username[0] : false
                        }
                      >
                        <Input disabled
                          value={data.item_number}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item

                        label="Part Number"
                        validateStatus={
                          error && error.part_number ? "error" : false
                        }
                        help={
                          error && error.part_number ? error.part_number[0] : false
                        }
                      >
                        <Input disabled
                          value={data.part_number}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item

                        label="Tipe"
                        validateStatus={
                          error && error.tipe ? "error" : false
                        }
                        help={
                          error && error.tipe ? error.tipe[0] : false
                        }
                      >
                        <Input disabled
                          value={data.tipe}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item

                        label="Proses"
                        validateStatus={
                          error && error.proses ? "error" : false
                        }
                        help={
                          error && error.proses ? error.proses[0] : false
                        }
                      >
                        <Input disabled
                          value={data.proses}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item

                        label="Storage"
                        validateStatus={
                          error && error.storage ? "error" : false
                        }
                        help={
                          error && error.storage ? error.storage[0] : false
                        }
                      >
                        <Input disabled
                          value={data.storage}
                          onChange={(e) =>
                            handleChange("storage", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>

                    <Form.Item

                        label="Group"
                        validateStatus={
                          error && error.group ? "error" : false
                        }
                        help={
                          error && error.group ? error.group[0] : false
                        }
                      >
                        <Input disabled
                          value={data.group}
                          onChange={(e) =>
                            handleChange("group", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item

                        label="Model"
                        validateStatus={
                          error && error.model ? "error" : false
                        }
                        help={
                          error && error.model ? error.model[0] : false
                        }
                      >
                        <Input disabled
                          value={data.model}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item

                        label="Qty Kanban Beredar"
                        validateStatus={
                          error && error.qty_kanban_beredar ? "error" : false
                        }
                        help={
                          error && error.qty_kanban_beredar ? error.qty_kanban_beredar[0] : false
                        }
                      >
                        <Input disabled
                          value={data.qty_kanban_beredar}
                          onChange={(e) =>
                            handleChange("qty_kanban_beredar", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item

                        label="Qty Kanban / Standar Packing"
                        validateStatus={
                          error && error.qty_kanban ? "error" : false
                        }
                        help={
                          error && error.qty_kanban ? error.qty_kanban[0] : false
                        }
                      >
                        <Input disabled
                          value={data.qty_kanban}
                          onChange={(e) =>
                            handleChange("qty_kanban", e.target.value)
                          }
                        />
                      </Form.Item> */}

                      <Form.Item
                        label="Standard Packing"
                        validateStatus={
                          error && error.qty_actual ? "error" : false
                        }
                        help={
                          error && error.qty_actual
                            ? error.qty_actual[0]
                            : false
                        }
                      >
                        <Input
                        disabled
                          value={data.qty_actual}
                          onChange={(e) =>
                            handleChange("qty_actual", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item

                        label="Keterangan"
                        validateStatus={
                          error && error.keterangan ? "error" : false
                        }
                        help={
                          error && error.keterangan
                            ? error.keterangan[0]
                            : false
                        }
                      >
                        <Input
                          value={data.keterangan}
                          onChange={(e) =>
                            handleChange("keterangan", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>


            </Card>
          </Col>
        </Row>
        </Form>
      </Content>
    </Fragment>
  );
}

export default PPrintWipResult;
