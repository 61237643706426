import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Modal,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./MasterSupplier.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function MasterSupplier(props) {
  const [keyword, setKeyword] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const [error, setError] = useState(null);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);
  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-supplier`, {
      params: {
        keyword,
        columns: [
          "supplier_code",
          "supplier_name",
          "address_1",
          "address_2",
          "country",
          "city",
          "attention",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const HandlegetQAD = () => {
    setLoading(true);
    Axios.get(`${url}/soap/supplier`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });
        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const exportSupplier = () => {
    setLoadingExport(true);
    Axios.get(`${url}/master-supplier/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Supplier" + ".xlsx");
    }).finally((res) => {
      setLoadingExport(false);
    });;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/master-supplier/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Supplier" + ".xlsx");
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/master-supplier/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "Address 1",
      dataIndex: "address_1",
      sorter: true,
    },
    {
      title: "Address 2",
      dataIndex: "address_2",
      sorter: true,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Attention",
      dataIndex: "attention",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: true,
    },

    {
      title: "Telephone 1",
      dataIndex: "telephone_1",
      sorter: true,
    },
    {
      title: "Telephone 2",
      dataIndex: "telephone_2",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Jabatan",
      dataIndex: "jabatan",
      sorter: true,
    },
    {
      title: "Bagian",
      dataIndex: "bagian",
      sorter: true,
    },
    {
      title: "Eta SDI",
      dataIndex: "eta_sdi",
      sorter: true,
    },
    {
      title: "Unloading Finished",
      dataIndex: "unloading_finished",
      sorter: true,
    },
    {
      title: "Cycle Issue",
      dataIndex: "cycle_issue",
      sorter: true,
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: true,
    //   fixed: "right",
    //   width: 100,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               {buttonAccess &&
    //                 buttonAccess.find((x) => x.url === "master-supplier") && (
    //                   <Menu.Item key="1">
    //                     <Link to={`/master-supplier/${id}`}>
    //                       <EditOutlined /> Ubah
    //                     </Link>
    //                   </Menu.Item>
    //                 )}
    //               {buttonAccess &&
    //                 buttonAccess.find(
    //                   (x) => x.url === "master-supplier/delete"
    //                 ) && (
    //                   <Menu.Item key="2">
    //                     <span onClick={() => handleDelete(id)}>
    //                       <DeleteOutlined />
    //                       Hapus
    //                     </span>
    //                   </Menu.Item>
    //                 )}
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Import Master Supplier"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Supplier"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link>
                      {buttonAccess &&
                        buttonAccess.find(
                          (x) => x.url === "get-sap/supplier"
                        ) && (
                          <Button onClick={HandlegetQAD} type="primary">
                            <PlusOutlined />
                            Sync QAD
                          </Button>
                        )}
                    </Link>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-supplier/create"
                      ) && (
                        <Link to="/master-supplier/create">
                          <Button type="primary">
                            <PlusOutlined />
                            Tambah
                          </Button>
                        </Link>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-supplier/import"
                      ) && (
                        <Button type="primary" onClick={showModal}>
                          <ImportOutlined />
                          Import
                        </Button>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-supplier/export"
                      ) && (
                        <Button loading={loadingExport} onClick={exportSupplier} type="primary">
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "master-supplier/template"
                      ) && (
                        <Button type="primary" onClick={downloadTemplate}>
                          <DownloadOutlined />
                          Download Template
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2250 }}
                    columns={columns}
                    dataSource={suppliers.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: suppliers.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MasterSupplier;
