import {
  Card,
  Col,
  Row,
  Layout,
  Select,
  Form,
  Input,
  Space,
  Button,
  Breadcrumb,
  Table,
  Typography,
  InputNumber,
  message,
  Radio,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../../config/global";

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditMarix(props) {
  const id = props.match.params.id;
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [data, setData] = useState({
    shipment_id: "",
    shipment_name: "",
    unique: 2,
  });
  const [matrixList, setMatrixList] = useState([]);
  const [error, setError] = useState(null);

  const [dataMatrix, setDataMatrix] = useState([]);
  const handleAddRow = () => {
    const pushData = {
      id: Math.random(),
      field: "",
      start: 0,
      length: 0,
    };
    setDataMatrix([...dataMatrix, pushData]);
  };

  useEffect(() => {
    getMatrix();
  }, []);

  const handleChangeData = (value, name, id) => {
    const newDataMatrix = [...dataMatrix];
    const findDataMatrix = newDataMatrix.find((x) => x.id === id);
    if (findDataMatrix) {
      findDataMatrix[name] = value;
      setDataMatrix(newDataMatrix);
    }
  };

  const handleAddRowUnique = () => {
    const pushData = {
      id: Math.random(),
      field: "sequence",
      start: 0,
      length: 0,
    };
    setDataMatrix([...dataMatrix, pushData]);
  };

  const handleRemoveRowUnique = () => {
    const newMatrix = [...dataMatrix];
    const findMatrix = newMatrix.filter((x) => x.field !== "sequence");
    setDataMatrix(findMatrix);
  };

  const handleRemoveRow = (id) => {
    const newMatrix = [...dataMatrix];
    const findMatrix = newMatrix.filter((x) => x.id !== id);
    setDataMatrix(findMatrix);
  };

  const getMatrix = async () => {
    await Axios.get(`${url}/master-matrix/${id}`, {
      params: {
        columns: ["shipment_name"],
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data.data);
        setDataMatrix(res.data.data.matrix);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
  };

  const getShipment = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/shipment/list`, {
      params: {
        keyword,
        columns: ["shipment_name"],
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setShipments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getMatrixList = async () => {
    await Axios.get(`${url}/master-matrix/list`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setMatrixList(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
  };

  const handleChangeSelectShipment = (name, value) => {
    if (name === "unique") {
      if (value === 1) {
        handleAddRowUnique();
      } else {
        handleRemoveRowUnique();
      }
    }

    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      render: (data, record) => {
        return record.field === "sequence" ? (
          <Input value={data} disabled />
        ) : (
          <Select
            style={{ width: "100%" }}
            value={data}
            onChange={(value) => handleChangeData(value, "field", record.id)}
            onFocus={() => getMatrixList()}
            options={matrixList.map((item) => {
              return {
                value: item,
                label: item,
              };
            })}
          />
        );
      },
    },
    {
      title: "Start",
      dataIndex: "start",
      render: (data, record) => (
        <InputNumber
          value={data}
          onChange={(value) => handleChangeData(value, "start", record.id)}
        />
      ),
    },
    {
      title: "Length",
      dataIndex: "length",
      render: (data, record) => (
        <InputNumber
          value={data}
          onChange={(value) => handleChangeData(value, "length", record.id)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (data, row) => {
        return row.field !== "sequence" ? (
          <Button danger onClick={() => handleRemoveRow(data)}>
            Delete
          </Button>
        ) : null;
      },
    },
  ];

  const handleSubmit = async () => {
    setLoading(true);
    await Axios.post(
      `${url}/master-matrix/${id}`,
      {
        ...data,
        matrix: dataMatrix,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/matrix");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Add Master Matrix"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/matrix">Kembali</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Ubah Master Matrix</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks">
                <Form.Item
                  required
                  label="Shipped To"
                  validateStatus={error && error.shipment_id ? "error" : false}
                  help={
                    error && error.shipment_id ? error.shipment_id[0] : false
                  }
                >
                  <Select
                    loading={loading}
                    allowClear="true"
                    labelInValue
                    onChange={(value) =>
                      handleChangeSelectShipment("shipment", value)
                    }
                    onFocus={() => getShipment("")}
                    showSearch
                    onSearch={(value) => getShipment(value)}
                    filterOption={false}
                    value={{
                      key: data.shipment_id,
                      label: data.shipment_name,
                    }}
                  >
                    {shipments &&
                      shipments.map((shipment) => {
                        return (
                          <Option value={shipment._id} key={shipment._id}>
                            {shipment.group}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  required
                  label="Is Unique?"
                  //   validateStatus={error && error.name ? "error" : false}
                  //   help={error && error.name ? error.name[0] : false}
                >
                  <Radio.Group
                    onChange={(e) => handleChange("unique", e.target.value)}
                    value={data.unique}
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={2}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Space direction="vertical" style={{ marginBottom: "2rem" }}>
                  <Title level={4}>Matrix Logic</Title>
                  <Button onClick={handleAddRow}>Add new field</Button>
                </Space>
                <Table
                  size="small"
                  dataSource={dataMatrix}
                  rowKey="id"
                  columns={columns}
                />

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset">Reset</Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditMarix;
