import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Span,
  Skeleton,
  Row,
  Card,
  Col,
  Breadcrumb,
  Typography,
  Layout,
  Table,
  Tag,
  Space,
  message,
  Tooltip,
  Select,
  DatePicker,
  Input,
  Button,
} from "antd";
import { Redirect, Link, useLocation } from "react-router-dom";
import Axios from "axios";

import { url } from "../../config/global";
import {
  HomeFilled,
  DropboxOutlined,
  DropboxSquareFilled,
} from "@ant-design/icons";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { Chart as ChartJS, Title as ChartTitle } from "chart.js";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import '../../assets/css/responsive.css'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
ChartJS.register(ChartTitle, ChartDataLabels);

const { Title, Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;
const dateFormat = "YYYY/MM/DD";
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
function DashboardInventory(props) {
  let styles = {
    marginLeft: "10px",
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dt1 = queryParams.get('date1');
  const dt2 = queryParams.get('date2');
  const project = queryParams.get('model');
  const supplier = queryParams.get('supplier');
  const handle = useFullScreenHandle();
  const [width, setWidth] = useState(window.innerWidth);
  const [searchValue, setSearchValue] = useState("");
  const [onlyOne, setOnlyOne] = useState(0);
  const [selectSupplier, setSelectSupplier] = useState(null);
  const [selectProject, setSelectProject] = useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;

  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [loading, setLoading] = useState(false);

  const [date2, setDate2] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [customFontSize, setCustomFontSize] = useState("16px");
  const targetElement = useRef(null);
  const selectRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: undefined,
    height: 475,
  });
  const containerRef = useRef(null);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1600px)"); // Ganti dengan kondisi media query yang sesuai

    // Mengupdate state berdasarkan kondisi media query awal
    setIsLargeScreen(mediaQuery.matches);

    // Menambahkan event listener untuk memantau perubahan kondisi media query
    const handleMediaQueryChange = (event) => {
      setIsLargeScreen(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Membersihkan event listener pada saat komponen di-unmount
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (targetElement.current.requestFullscreen) {
        targetElement.current.requestFullscreen();
      } else if (targetElement.current.mozRequestFullScreen) {
        // Firefox
        targetElement.current.mozRequestFullScreen();
      } else if (targetElement.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        targetElement.current.webkitRequestFullscreen();
      } else if (targetElement.current.msRequestFullscreen) {
        // IE/Edge
        targetElement.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }

    sessionStorage.setItem("fullscreen_mode", !isFullscreen);
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (isLargeScreen) {
      ChartJS.defaults.font.size = isLargeScreen ? 28 : 16;
    }
  }, [isLargeScreen]);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    getSelectedModel()
    // getSelectedSupplier()
  },[])

  useEffect(() => {
    // getSelectedModel()
    getPersentaseModel();
    if (date.start == moment(new Date()).format("YYYY-MM-DD") && date.end == moment(new Date()).format("YYYY-MM-DD")) {
      intervalIdRef.current = setInterval(() => {

        props.history.push('/dashboard-inventory?date1=' + JSON.stringify(date) + '&date2=' + JSON.stringify(date2) + '&model=' + JSON.stringify(selectProject) + '&supplier=' + JSON.stringify(selectSupplier));
        window.location.reload(false);
        // getPersentaseModel();
        //   // Panggil getPersentaseModel dalam interval
        //   if (selectProject) {
        //     getInvSelectedModelDebounced(selectProject.key);
        //   }
      }, 100000);

      return () => {
        clearInterval(intervalIdRef.current);
      };
    }


  }, [date, selectProject]);

  useEffect(() => {
    getInvAllModel();
  }, [date]);
  // useEffect(() => {
  //   getInvSelectedModelDebounced(selectProject.key);
  // }, [date]);


  useEffect(() => {

    if (sessionStorage.getItem("username") == 'dashboard-incoming') {
      // setIsFullscreen(true)
      // selectRef.current.focus()
    }
    // Set focus to the Select element after entering fullscreen
    if (isFullscreen && selectRef.current) {
      selectRef.current.focus();
    }
  }, [isFullscreen]);
  useEffect(() => {
    // Update chart dimensions on initial render
    handleChartResize();

    // Update chart dimensions when the window is resized
    window.addEventListener('resize', handleChartResize);

    return () => {
      window.removeEventListener('resize', handleChartResize);
    };
  }, []);

  useEffect(() => {
    // Mengatur dimensi chart ulang saat mode fullscreen berubah
    handleChartResize();
  }, [isFullscreen]);


  useEffect(() => {
    getKanbanOrdVsRec();


  }, [date2]);


  let datepicker;
  let datepicker2;
  const [dataPersentase, setDataPersentase] = useState({
    persentaseLabel: [],
    valPersentaseAllModel: [],
  });

  const [dataAllInventoryModel, setDataAllInventoryModel] = useState({
    InvAllModelLabel: [],
    valMinimalSetModel: [],
    valMaksimalSetModel: [],
    ValActualModel: [],
    valKurangStd: [],
    valLebihStd: [],
  });

  const [dataSelectInventoryModel, setDataSelectInventoryModel] = useState({
    InvSelectModelLabel: [],
    valActualModelSelected: [],
    valMinModelSelected: [],
    valStdModelSelected: [],
    valKurangStd: [],
    valLebihStd: [],
  });

  const [dataKanbanVsRec, setDataKanbanVsRec] = useState({
    KanbanVsRecLabel: [],
    ValKanbanVsRecActual: [],
    ValKanbanVsRecActKurangStd: [],
    ValKanbanVsRecPlanKanbanOrdering: [],
    select: [],
  });

  const [dataKanbanVsPT, setKanbanVsPT] = useState({
    LabelKanbanVsPT: [],
    ValKanbanOrdering: [],
    ValKanbanReceiving: [],
    ValKanbanKurang: []
  });
  const [data, setData] = useState({
    kanbanPending: "0",
    kanbanOrdering: "0",
    kanbanReceiving: "0",
    receivingrate: "0%",
    matching: "0/0",
    range_chart_1: true,
    weekly_chart_1: false,
    monthly_chart_1: false,
    daily_chart_1: false,

    range_chart_2: true,
    weekly_chart_2: false,
    monthly_chart_2: false,
    daily_chart_2: false,
  });

  const getPersentaseModel = () => {
    Axios.get(`${url}/grafik/persentase-model`, {
      params: {
        start: date.start,
        end: date.end,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataPersentase({
          ...dataPersentase,
          valPersentaseAllModel: res.data.value,
          persentaseLabel: res.data.label,
        });
        // setSelectProject((prevSelectProject) => {
        //   if (prevSelectProject) {
        //     // Ensure that the selected project is still in the new list
        //     const isInNewList = res.data.label.includes(prevSelectProject.key);
        //     if (!isInNewList) {
        //       // If not, update to the first project in the new list
        //       const newLabel = res.data.label[0];
        //       getInvSelectedModel(newLabel);
        //       return {
        //         key: newLabel,
        //         value: newLabel,
        //         label: newLabel,
        //       };
        //     }
        //   }
        //   return prevSelectProject;
        // });
        getInvAllModel();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getInvAllModel = () => {
    setLoading(true);
    Axios.get(`${url}/grafik/inventory-all-model`, {
      params: {
        start: date.start,
        end: date.end,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // console.log('Response Data aLL:', res.data);
        if (res.data.label && res.data.label.length > 0) {
          setDataAllInventoryModel({
            ...dataAllInventoryModel,
            InvAllModelLabel: res.data.label,
            valMinimalSetModel: res.data.minstd,
            valMaksimalSetModel: res.data.maxstd,
            ValActualModel: res.data.actual,
            valKurangStd: res.data.bawahstd,
            valLebihStd: res.data.atasstd,
          });

          if (isInitialLoad && res.data.label.length > 0) {
            const initialLabel = res.data.cacheLabel;
            // setSelectProject({
            //   key: initialLabel,
            //   value: initialLabel,
            //   label: initialLabel,
            // });
            getInvSelectedModel(selectProject == null ? initialLabel:selectProject.label);
            setIsInitialLoad(false);
          }
        }
      
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  // Fungsi ini dijalankan setiap kali nilai input berubah
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  // Gunakan useEffect untuk menangani logika search setelah selesai mengetik
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      
      getInvSelectedModel(selectProject == null ? searchValue:selectProject.label);
    }, 1000);

    // Cleanup function untuk menghindari multiple API calls
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const getInvSelectedModel = (project) => {
    // if(onlyOne === 0)
    // {
      
    // }
    Axios.get(`${url}/grafik/inventory-model`, {
      params: {
        project: project,
        start: date.start,
        end: date.end,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // console.log('Response Data:', res.data);
      
        setDataSelectInventoryModel({
          ...dataSelectInventoryModel,
          InvSelectModelLabel: res.data.label,
          valActualModelSelected: res.data.actual,
          valMinModelSelected: res.data.min,
          valStdModelSelected: res.data.max,
          valKurangStd: res.data.bawahstd,
          valLebihStd: res.data.atasstd,
        });
        // getKanbanOrdVsRec();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getInvSelectedModelDebounced = debounce(getInvSelectedModel, 500);

  const getCounterKanban = () => {
    setData(
      {
        ...data,
        kanbanPending: 'Loading',
        kanbanOrdering: 'Loading',
        kanbanReceiving: 'Loading',
        receivingrate: 'Loading',
        matching: 'Loading',
      }
    )

    Axios.get(`${url}/grafik/counter-kanban`, {
      params: {
        start: date2.start,
        end: date2.end,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // console.log(res);
        setData({
          ...data,
          kanbanPending: res.data.kanban_pending,
          kanbanOrdering: res.data.kanban_ordering,
          kanbanReceiving: res.data.kanban_receiving,
          receivingrate: `${res.data.kanban_ordering !== 0
            ? `${Number.parseFloat(
              (res.data.kanban_not_matching / res.data.kanban_ordering) * 100
            ).toFixed(2)}%`
            : '0%'
            }`,
          matching: `${res.data.kanban_matching}/${res.data.kanban_not_matching}`,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    getCounterKanban();
  }, [date2]);

  useEffect(() => {
    getPersentaseModel();
    // setInterval(function () {getPersentaseModel()}, 10000);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [date]);

  const getKanbanOrdVsRec = () => {
    Axios.get(`${url}/grafik/kanban-order-vs-kanban-receive`, {
      params: {
        start: date2.start,
        end: date2.end,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // console.log('select data', res.data)
        setSelectSupplier({
          value: res.data.select[0].id,
          label: res.data.select[0].name,
          key: res.data.select[0].id,
        });
        getSelectedSupplier()
        setDataKanbanVsRec({
          ...dataKanbanVsRec,
          KanbanVsRecLabel: res.data.label,
          ValKanbanVsRecActual: res.data.actual,
          ValKanbanVsRecActKurangStd: res.data.act_kurang_std,
          ValKanbanVsRecPlanKanbanOrdering: res.data.plan,
          select: res.data.select,
        });
        getSelectedSupplier()
        getKanbanOrdVsPt(res.data.select[0].id);
        getCounterKanban();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getKanbanOrdVsPt = (supplier_code) => {
    Axios.get(`${url}/grafik/kanban-order-vs-kanban-receive-pt`, {
      params: {
        start: date2.start,
        end: date2.end,
        supplier_code: supplier_code,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {

        setKanbanVsPT({
          ...dataKanbanVsPT,
          LabelKanbanVsPT: res.data.label,
          ValKanbanOrdering: res.data.plan,
          ValKanbanReceiving: res.data.actual,
          ValKanbanKurang: res.data.act_kurang_std,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const filterAllModel = () => { };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const firstOfDate = moment(new Date()).startOf("month").format("D");
  const endOfDate = moment(new Date()).endOf("month").format("D");

  // let dates = [];
  // let datas = [];
  // let datas2 = [];
  // let datas3 = [];

  // for (let i = 1; i <= 11; i++) {
  //   dates.push(i)
  //   datas.push(Math.floor(Math.random() * 10000))
  //   datas2.push(Math.floor(Math.random() * 10000))
  //   datas3.push(Math.floor(Math.random() * 10000))
  // }
  // console.log(dates, datas)

  let labels = [
    "D30",
    "Y4L",
    "D14",
    "D55",
    "d36",
    "D05",
    "D08",
    "D78",
    "D09",
    "802X",
    "k64",
  ];

  let labelsModel = ["A", "V"];
  let labelsModelselected = ["A", "V"];

  const option_persentase_model = {
    aspect: 1,
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Model/Project",
        },
      },
      x: {
        title: {
          display: true,
          text: "Persentase",
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return value + "%";
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
  };

  const OptioninventoryAllModel = {
    aspect: 1,
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Set",
        },
      },
      x: {
        title: {
          display: true,
          text: "Persentase",
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return value;
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            let pointStyle = [];
            chart.data.datasets.forEach(datasets => {
              // console.log(datasets);
              if (datasets.type === 'bar') {
                pointStyle.push('rect')
              } else {
                pointStyle.push('line')
              }
            })
            // console.log('pointStyle', pointStyle)
            return chart.data.datasets.map(

              (datasets, index) => ({

                text: datasets.label,
                fillStyle: datasets.backgroundColor,
                strokeStyle: datasets.borderColor,
                pointStyle: pointStyle[index],
                hidden: false
              })
            )
          }
        }
      },
      title: {
        display: true,
      },
    },
  };

  const inventoryModel = {
    aspect: 1,
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Pcs",
        },
      },
      x: {
        title: {
          display: true,
          text: "Persentase",
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return value;
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            let pointStyle = [];
            chart.data.datasets.forEach(datasets => {
              // console.log(datasets);
              if (datasets.type === 'bar') {
                pointStyle.push('rect')
              } else {
                pointStyle.push('line')
              }
            })
            // console.log('pointStyle', pointStyle)
            return chart.data.datasets.map(

              (datasets, index) => ({

                text: datasets.label,
                fillStyle: datasets.backgroundColor,
                strokeStyle: datasets.borderColor,
                pointStyle: pointStyle[index],
                hidden: false
              })
            )
          }
        }
      },
      title: {
        display: true,
      },
    },
  };


  // const inventoryModel = {
  //   aspect: 1,
  //   indexAxis: "x",
  //   elements: {
  //     bar: {
  //       borderWidth: 2,
  //     },
  //   },
  //   responsive: true,
  //   scales: {
  //     y: {
  //       title: {
  //         display: true,
  //         text: "Model/Project",
  //       },
  //     },
  //     x: {
  //       title: {
  //         display: true,
  //         text: "Persentase",
  //       },
  //     },
  //   },
  //   plugins: {
  //     datalabels: {
  //       formatter: (value, ctx) => {
  //         return value;
  //       },
  //     },
  //     title: {
  //       display: true,
  //     },
  //     legend: {
  //       position: "bottom",
  //       usePointStyle: true,
  //       generateLabels: (chart) => {
  //         let pointStyle = [];
  //         chart.data.datasets.forEach(datasets => {
  //           console.log('awea',datasets);
  //           if(datasets.type === 'bar'){
  //             pointStyle.push('rect')
  //           } else{
  //             pointStyle.push('line')
  //           }
  //         })
  //         console.log('pointStyle 24',pointStyle)
  //         return chart.data.datasets.map(

  //           (datasets,index) => ({

  //             text: datasets.label,
  //             fillStyle: datasets.backgroundColor,
  //             strokeStyle: datasets.borderColor,
  //             pointStyle: pointStyle[index],
  //             hidden:false
  //           })
  //           )
  //       }
  //     },
  //   },
  // };

  const kanbanOrderVsKanbanReceivingOption = {
    aspect: 1,
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Persentase",
        },
      },
      x: {
        title: {
          display: false,
          text: "Persentase",
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return value + "%";
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            let pointStyle = [];
            chart.data.datasets.forEach(datasets => {
              // console.log(datasets);
              if (datasets.type === 'bar') {
                pointStyle.push('rect')
              } else {
                pointStyle.push('line')
              }
            })
            // console.log('pointStyle', pointStyle)
            return chart.data.datasets.map(

              (datasets, index) => ({

                text: datasets.label,
                fillStyle: datasets.backgroundColor,
                strokeStyle: datasets.borderColor,
                pointStyle: pointStyle[index],
                hidden: false
              })
            )
          }
        }
      },
      title: {
        display: true,
      },
    },
  };
  const kanbanOrderVsKanbanSupplierOption = {
    aspect: 1,
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Jumlah Kanban",
        },
      },
      x: {
        title: {
          display: false,
          text: "Persentase",
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return value;
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            let pointStyle = [];
            chart.data.datasets.forEach(datasets => {
              // console.log(datasets);
              if (datasets.type === 'bar') {
                pointStyle.push('rect')
              } else {
                pointStyle.push('line')
              }
            })
            // console.log('pointStyle', pointStyle)
            return chart.data.datasets.map(

              (datasets, index) => ({

                text: datasets.label,
                fillStyle: datasets.backgroundColor,
                strokeStyle: datasets.borderColor,
                pointStyle: pointStyle[index],
                hidden: false
              })
            )
          }
        }
      },
      title: {
        display: true,
      },
    },
  };

  let dataChartModule = {
    labels: dataPersentase.persentaseLabel,
    datasets: [
      {
        label: "Dataset 1",
        data: dataPersentase.valPersentaseAllModel,
        borderColor: "rgba(8, 146, 68, 1)",
        backgroundColor: "rgba(8, 146, 68, 0.5)",
      },
    ],
  };

  let dataChartModuleInvAllModel = {
    labels: dataAllInventoryModel.InvAllModelLabel,
    datasets: [
      // {
      //   label: 'Actual Lebih dari Std',
      //   data:  datas,
      //   borderColor: 'rgb(255, 221, 0)',
      //   backgroundColor: 'rgba(255, 221, 0, 0.5)',
      // },
      {
        label: "Actual Kurang dari Minimal Set",
        data: dataAllInventoryModel.valKurangStd,
        borderColor: "rgb(255, 47, 0)",
        backgroundColor: "rgba(255, 47, 0, 0.5)",
        type: "bar"
      },
      {
        label: "Minimal Set",
        data: dataAllInventoryModel.valMinimalSetModel,
        backgroundColor: "transparent",
        borderColor: "rgb(243, 197, 58)",
        borderWidth: 2,
        type: 'line',
      },
      {
        label: "Maksimal Set",
        data: dataAllInventoryModel.valMaksimalSetModel,
        backgroundColor: "transparent",
        borderColor: "rgb(68, 255, 0)",
        borderWidth: 2,
        type: "line",
        boxWidth: -1,
        boxHeight: 200,
      },
      {
        label: "Actual",
        data: dataAllInventoryModel.ValActualModel,
        borderColor: "rgba(8, 146, 68, 1)",
        backgroundColor: "rgba(8, 146, 68, 0.5)",
        type: "bar"
      },
      {
        label: "Actual Lebih dari Maximal Set",
        data: dataAllInventoryModel.valLebihStd,
        borderColor: 'rgb(255, 221, 0)',
        backgroundColor: 'rgba(255, 221, 0, 0.5)',
        type: "bar"
      },
    ],
  };

  let dataChartModelSelected = {
    labels: dataSelectInventoryModel.InvSelectModelLabel,
    datasets: [

      {
        label: "Minimal",
        data: dataSelectInventoryModel.valMinModelSelected,
        backgroundColor: "transparent",
        borderColor: "rgb(243, 197, 58)",
        borderWidth: 2,
        type: "line",
      },
      {
        label: "Maximal",
        data: dataSelectInventoryModel.valStdModelSelected,
        backgroundColor: "transparent",
        borderColor: "rgb(68, 255, 0)",
        borderWidth: 1,
        boxHeight: 1,
        type: "line",
      },
      {
        label: "Actual",
        data: dataSelectInventoryModel.valActualModelSelected,
        borderColor: "rgba(8, 146, 68, 1)",
        backgroundColor: "rgba(8, 146, 68, 0.5)",
        type: "bar"
      },
      {
        label: "Actual Lebih dari Maximal",
        data: dataSelectInventoryModel.valLebihStd,
        borderColor: 'rgb(255, 221, 0)',
        backgroundColor: 'rgba(255, 221, 0, 0.5)',
        type: "bar"
      },
      {
        label: "Actual Kurang dari Minimal",
        data: dataSelectInventoryModel.valKurangStd,
        borderColor: "rgb(255, 47, 0)",
        backgroundColor: "rgba(255, 47, 0, 0.5)",
        type: "bar"
      },
    ],
  };

  let dataChartKanbanVsRec = {
    labels: dataKanbanVsRec.KanbanVsRecLabel,
    datasets: [
      {
        label: "Plan Kanban Ordering",
        data: dataKanbanVsRec.ValKanbanVsRecPlanKanbanOrdering,
        backgroundColor: "transparent",
        borderColor: "rgb(68, 255, 0)",
        borderWidth: 1,
        type: "line",
      },
      {
        label: "Actual",
        data: dataKanbanVsRec.ValKanbanVsRecActual,
        borderColor: "rgba(8, 146, 68, 1)",
        backgroundColor: "rgba(8, 146, 68, 0.5)",
        type: "bar",
      },
      {
        label: "Actual Kanban Receiving kurang dari Minimal Set",
        data: dataKanbanVsRec.ValKanbanVsRecActKurangStd,
        borderColor: "rgb(255, 47, 0)",
        backgroundColor: "rgba(255, 47, 0, 0.5)",
        borderWidth: 1,
        type: "bar",
      },
    ],
  };

  let dataChartKanbanVsPT = {
    labels: dataKanbanVsPT.LabelKanbanVsPT,
    datasets: [
      {
        label: "Actual",
        data: dataKanbanVsPT.ValKanbanReceiving,
        borderColor: "rgba(8, 146, 68, 1)",
        backgroundColor: "rgba(8, 146, 68, 0.5)",
        display: false,
        type: "bar",
      },
      {
        label: "Actual Kanban Receiving kurang dari Kanban Ordering",
        data: dataKanbanVsPT.ValKanbanKurang,
        borderColor: "rgb(255, 47, 0)",
        backgroundColor: "rgba(255, 47, 0, 0.5)",
        type: "bar",
      },
      {
        label: "Plan Kanban Ordering",
        data: dataKanbanVsPT.ValKanbanOrdering,
        backgroundColor: "transparent",
        borderColor: "rgb(68, 255, 0)",
        borderWidth: 3,
        type: "line",
      },
    ],
  };

  const overflow = {
    overflowX: "auto",
    width: "80vw",
  };
  const margincard = {
    marginBottom: "5px",
  };
  const rangeChart1Change = (value) => {
    // console.log("data", dataKanbanVsRec);
    setData({
      ...data,
      range_chart_1: false,
      weekly_chart_1: false,
      monthly_chart_1: false,
      daily_chart_1: false,
      [`${value}`]: true,
    });

    // setData({
    //   ...data,

    // })
  };

  const rangeChart2Change = (value) => {
    setData({
      ...data,
      range_chart_2: false,
      weekly_chart_2: false,
      monthly_chart_2: false,
      daily_chart_2: false,
      [`${value}`]: true,
    });
  };

  const handleChartResize = () => {
    const chartContainer = document.getElementById('chart-container');
    if (chartContainer) {
      setChartDimensions({
        width: chartContainer.clientWidth,
        height: chartContainer.clientHeight,
      });
    }
  };
  const getSelectedModel = () => {

    let fd = new FormData();
    fd.set("dt", true);
    setOnlyOne(1);
    Axios.post(`${url}/select/model-get`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      var jsons = JSON.parse(res.data.data);
      setSelectProject(jsons);
      getInvSelectedModelDebounced(jsons.key)
      
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        // setLoading(false);
      });
  };
  const changeProject = (value) => {
    setSelectProject(value);
    let fd = new FormData();
    // console.log('select-model',value)
    fd.set("model", JSON.stringify(value));


    Axios.post(`${url}/select/model-set`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        // setLoading(false);
      });
  };
  const getSelectedSupplier = () => {

    let fd = new FormData();
    fd.set("dt", true);
    setOnlyOne(1);
    Axios.post(`${url}/select/supplier-get`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      var jsons = JSON.parse(res.data.data);
      setSelectSupplier(jsons);
      getKanbanOrdVsPt(jsons.key)
      
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        // setLoading(false);
      });
  };
  const changeSupplier = (value) => {
    setSelectSupplier(value);
    let fd = new FormData();
    console.log('select-supplier',value)
    fd.set("model", JSON.stringify(value));


    Axios.post(`${url}/select/supplier-set`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        // setLoading(false);
      });
  };


return (
  <Fragment>
    {sessionStorage.getItem("username") != 'dashboard-incoming' &&
      (
        <Row>
          <Col xs={24}>
            <Card
              type="inner"
              title="WAREHOUSE MANAGEMENT DASHBOARD"
              extra={moment(new Date()).format("DD MMMM YYYY")}
            ></Card>
          </Col>
        </Row>)}
    <div className="cek"
      ref={targetElement}
      style={{
        backgroundColor: "#F1F2F5",
        height: "98%",
        margin: "auto",
        overflowY: 'auto'
      }}
    >
      <div className="App__inner" ref={containerRef}>
        <Content className="content">

          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={16} sm={24}>
              <Button
                type="primary"
                onClick={toggleFullscreen}
                style={{ marginRight: 10 }}
              >
                {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
              </Button>
              <Space wrap>

                <RangePicker
                  getPopupContainer={() => containerRef.current}
                  defaultValue={[
                    moment(date.start, "YYYY-MM-DD"),
                    moment(date.end, "YYYY-MM-DD"),
                  ]}
                  allowClear={false}
                  format={"YYYY-MM-DD"}
                  inputReadOnly={true}
                  onChange={(value) =>
                    setDate({
                      ...date,
                      start: moment(value[0]).format("YYYY-MM-DD"),
                      end: moment(value[1]).format("YYYY-MM-DD"),
                    })
                  }
                  style={{
                    marginRight: 10,
                    zIndex: isFullscreen ? 10000 : 5000,
                  }}

                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                />
              </Space>
            </Col>
            <Col md={8} sm={24}>
              <Space wrap>
                {/* <Search
                  placeholder="Search..."
                  allowClear
                  onSearch={(value) => getInvSelectedModel(value)}
                  onChange={handleInputChange}
                /> */}

                <Select
                  labelInValue
                  getPopupContainer={() => containerRef.current}
                  dropdownClassName={isFullscreen ? 'fullscreen-select-dropdown' : ''}
                  style={{ width: 200, zIndex: 1500 }}
                  onChange={(value) => {
                    changeProject(value);
                    getInvSelectedModelDebounced(value.key);
                  }}
                  filterOption={false}
                  value={selectProject ? selectProject : undefined}
                >
                  {dataAllInventoryModel.InvAllModelLabel &&
                    dataAllInventoryModel.InvAllModelLabel.map((project) => (
                      <Option value={project}>
                        {project}
                      </Option>
                    ))}
                </Select>

              </Space>
            </Col>

          </Row>

          <div>
            <Row
              style={{
                width: "98%",
                flexDirection: "space-between",
              }}
            >

              <Col md={4} sm={24} style={{ flex: 1 }}>
                <Card
                  title="Persentase All Model"
                  headStyle={{
                    fontSize: isLargeScreen ? 24 : 16,
                  }}
                  style={{
                    minHeight: "98%",
                  }}
                >
                  <div id="chart-container">
                    <Bar
                      height={chartDimensions.height}
                      options={option_persentase_model}
                      title="AA"
                      data={dataChartModule}
                    />
                  </div>

                </Card>
              </Col>
              <Col md={10} sm={24} style={{ flex: 1 }}>
                <Card
                  title="Inventory All Model"
                  style={{
                    minHeight: "98%",
                  }}
                  headStyle={{
                    fontSize: isLargeScreen ? 24 : 16,
                  }}
                >
                  <Bar
                    height={180}
                    options={OptioninventoryAllModel}
                    title="AA"
                    data={dataChartModuleInvAllModel}
                  />
                </Card>
              </Col>
              <Col md={10} sm={24} style={{ flex: 1 }}>
                <Card
                  title={`Inventory Model ${selectProject?.label ? selectProject?.label : ""
                    }`}
                  style={{
                    minHeight: "98%",
                    fontSize: isLargeScreen ? "24px !important" : "16px",
                  }}
                  headStyle={{
                    fontSize: isLargeScreen ? 24 : 16,
                  }}
                >
                  <Bar
                    height={180}
                    options={inventoryModel}
                    title="AA"
                    data={dataChartModelSelected}
                  />
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: "10px" }}></div>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={4} sm={24}></Col>
            <Col md={10} sm={24}>
              <Space wrap>
                <RangePicker
                  getPopupContainer={() => containerRef.current}
                  defaultValue={[
                    moment(date2.start, "YYYY-MM-DD"),
                    moment(date2.end, "YYYY-MM-DD"),
                  ]}
                  allowClear={false}
                  format={"YYYY-MM-DD"}
                  inputReadOnly={true}
                  onChange={(value) =>
                    setDate2({
                      ...date2,
                      start: moment(value[0]).format("YYYY-MM-DD"),
                      end: moment(value[1]).format("YYYY-MM-DD"),
                    })
                  }
                  style={{
                    marginRight: 10,
                    zIndex: 1000,
                  }}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                />
              </Space>
            </Col>
            <Col md={10} sm={24}>
              <Space wrap>
                <Select
                  getPopupContainer={() => containerRef.current}
                  labelInValue
                  style={{ width: 200 }}
                  onChange={(value) => {
                    changeSupplier(value);
                    getKanbanOrdVsPt(value.key);
                  }}
                  filterOption={false}
                  value={selectSupplier ? selectSupplier : undefined}
                >
                  {dataKanbanVsRec.select &&
                    dataKanbanVsRec.select.map((supplier) => (
                      <Option value={supplier.id} key={supplier.id}>
                        {supplier.name}
                      </Option>
                    ))}
                </Select>
              </Space>
            </Col>
          </Row>
          <div>
            <Row style={{ width: "98%" }}>
              <Col xs={4} md={4} style={{ display: "flex", flex: 1 }}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col style={{ width: "98%" }}>
                    <Card
                      title="KANBAN ORDERING"
                      headStyle={{
                        backgroundColor: "#28a745",
                        color: "#FFF",
                        fontSize: isLargeScreen ? 24 : 16,
                      }}
                      style={{
                        textAlign: "center",
                        fontSize: isLargeScreen ? 32 : 16,
                      }}
                    >
                      {data.kanbanOrdering}
                    </Card>
                  </Col>
                  <Col style={{ width: "98%" }}>
                    <Card
                      headStyle={{
                        backgroundColor: "#ffc107",
                        color: "#000",
                        fontSize: isLargeScreen ? 24 : 16,
                      }}
                      title="KANBAN RECEIVING"
                      style={{
                        textAlign: "center",
                        fontSize: isLargeScreen ? 32 : 16,
                      }}
                    >
                      {data.kanbanReceiving}
                    </Card>
                  </Col>
                  <Col style={{ width: "98%" }}>
                    <Card
                      headStyle={{
                        backgroundColor: "#dc3545",
                        color: "#FFF",
                        fontSize: isLargeScreen ? 24 : 16,
                      }}
                      title="KANBAN PENDING"
                      style={{
                        textAlign: "center",
                        fontSize: isLargeScreen ? 32 : 16,
                      }}
                    >
                      {data.kanbanPending}
                    </Card>
                  </Col>
                  <Col style={{ width: "98%" }}>
                    <Card
                      headStyle={{
                        backgroundColor: "#17a2b8",
                        color: "#FFF",
                        fontSize: isLargeScreen ? 24 : 16,
                      }}
                      title="RECEIVING RATE"
                      style={{
                        textAlign: "center",
                        fontSize: isLargeScreen ? 32 : 16,
                      }}
                    >
                      {data.receivingrate}
                    </Card>
                  </Col>

                  <Col style={{ width: "98%" }}>
                    <Card
                      headStyle={{
                        backgroundColor: "#FF6600",
                        color: "#000",
                        fontSize: isLargeScreen ? 24 : 16,
                      }}
                      title="MATCHING"
                      style={{
                        textAlign: "center",
                        fontSize: isLargeScreen ? 32 : 16,
                      }}
                    >
                      {data.matching}
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={10} md={10}>
                <Card
                  style={{
                    height: "98%",
                    fontSize: isLargeScreen ? "24px !important" : "16px",
                  }}
                  headStyle={{
                    fontSize: isLargeScreen ? 24 : 16,
                  }}
                  title="Kanban Ordering Vs Kanban Receiving (All Supplier)"
                >
                  <Bar
                    width={280}
                    height={150}
                    options={kanbanOrderVsKanbanReceivingOption}
                    title="AA"
                    data={dataChartKanbanVsRec}
                  />
                </Card>
              </Col>
              <Col xs={10} md={10}>
                <Card
                  style={{
                    height: "98%",
                    fontSize: isLargeScreen ? "24px !important" : "16px",
                  }}
                  headStyle={{
                    fontSize: isLargeScreen ? 24 : 16,
                  }}
                  title={`Kanban Ordering Vs Kanban Receiving ${selectSupplier?.label ? selectSupplier?.label : ""
                    }`}
                >
                  <Bar
                    width={280}
                    height={150}
                    options={kanbanOrderVsKanbanSupplierOption}
                    title="AA"
                    data={dataChartKanbanVsPT}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
      </div>
    </div>
  </Fragment>
);
}

export default DashboardInventory;
