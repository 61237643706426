import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Badge,
  Button,
  Input,
  Table,
  message,
  Space,
  Form,
  Modal,
  DatePicker,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { BarcodeOutlined } from "@ant-design/icons";
import send from "../../utils/send";

const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function PDScanDNManifest(props) {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const token = `Bearer ${sessionStorage.getItem("token")}`;
  const [scanWarehouse, setScanWarehouse] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [tempTotalKanban, setTempTotalKanban] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [scanKanban, setScanKanban] = useState([]);
  const [dataTemporaryKanban, setDataTemporaryKanban] = useState([]);
  const [scanDNManifest, setScanDNManifest] = useState([]);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [dataTemporaryKanbanCustomer, setDataTemporaryKanbanCustomer] =
    useState([]);

  const [matrix, setMatrix] = useState(null);

  const [data, setData] = useState({
    dn_no: "",
    barcode_kanban: "",
    barcode_kanban_cust: "",
  });

  const [kanbanFg, setKanbanFg] = useState(null);
  const [kanbanCust, setKanbanCust] = useState(null);

  const [isDisabled, setIsDisabled] = useState({
    dn_no: false,
    barcode_kanban: true,
    barcode_kanban_cust: true,
  });

  const dn_noRef = useRef();
  const barcode_kanbanRef = useRef();
  const barcode_kanban_custRef = useRef();
  //tanggal Pelunasan
  const [valueDate, setValueDate] = useState(null);
  const onChangeDate = (date, dateString) => {
    setValueDate(dateString);
    // console.log(timestring);
  };
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.comopent_item === editingKey;
  const edit = (record) => {
    const { tanggal_scan } = record;
    form.setFieldsValue({
      tanggal_scan: tanggal_scan ? moment(tanggal_scan) : "",

      ...record,
    });
    setEditingKey(record.comopent_item);

    console.log(record);
  };

  const save = async (data) => {
    const row = await form.validateFields();
    console.log("ini data", row, data);
    setLoading(true);
    const tanggal_scan = valueDate,
      update = scanDNManifest.map((x) => {
        if (x.component_item === data.component_item) {
          // scan_kanban = res.data.data;
          return {
            ...x,
            tanggal_scan,
          };
        } else {
          return {
            ...x,
          };
        }
      });

    setScanDNManifest(update);
    console.log(scanDNManifest);

    setEditingKey("");
    setLoading(false);
  };

  const cancel = () => {
    setEditingKey("");
  };
  // const setTemp = () => {
  //   Axios.get(`${url}/check-temp-manifest`, {
  //     params: {
  //       dn_no: data.dn_no,
  //     },
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => {
  //       setDataTemporaryKanban([res.data.data])
      
  //     })
  // }
  const getData = () => {
    setLoading(true)
    Axios.get(`${url}/check-manifest`, {
      params: {
        dn_no: data.dn_no,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
       
          if(localStorage.getItem('dn_no') == null)
          {
              localStorage.setItem('dn_no', data.dn_no)     
          }
        
      
        const dataScan = res.data.data;
        setTempTotalKanban(res.data.qty_kanban_temp)
        setScanDNManifest(res.data.data);
        setIsDisabled({
          dn_no: true,
          barcode_kanban: false,
          barcode_kanban_cust: true,
        });
        setMatrix(res.data.matrix);
        barcode_kanbanRef.current.focus();
        setLoading(false)
        // getLotProduction(dataScan);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
            Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
     
  };
  const getDataAuto = (dnno) => {
    setLoading(true)
    Axios.get(`${url}/check-manifest`, {
      params: {
        dn_no: dnno,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setLoading(false)
        if(localStorage.getItem('dn_no') == null)
          {
              localStorage.setItem('dn_no', data.dn_no)     
          }
        
        const dataScan = res.data.data;
        setTempTotalKanban(res.data.qty_kanban_temp)
        setScanDNManifest(res.data.data);
        setIsDisabled({
          dn_no: true,
          barcode_kanban: false,
          barcode_kanban_cust: true,
        });
        setMatrix(res.data.matrix);
        barcode_kanbanRef.current.focus();

        // getLotProduction(dataScan);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
            Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
     
  };

  const getKanban = () => {
    const checkExists = dataTemporaryKanban.find(
      (x) => x.barcode === data.barcode_kanban
    );
    if (!checkExists) {
      Axios.get(`${url}/check-kanban`, {
        params: {
          barcode_kanban: data.barcode_kanban,
          dn_no: data.dn_no,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          console.log(res.data.data, "resnya");
          const kanbanData = res.data.data;
          if (kanbanData.id_qad) {
            const compareData = scanDNManifest.find((x) => {
              //agar menghapus spasi pada part number
              return (
                x.item_number.replace(/\s/g, "") ===
                kanbanData.id_qad.replace(/\s/g, "")
              );
            });
            console.log('TEMP SCAN',dataTemporaryKanban)

            if (compareData) {
              setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
              setKanbanFg(data.barcode_kanban);
              setIsDisabled({
                dn_no: true,
                barcode_kanban: true,
                barcode_kanban_cust: false,
              });

              //barcode_kanbanRef.current.focus();
              barcode_kanban_custRef.current.focus();
            } else {
              Modal.error({
                title: "Failed!",
                content: "Data not match!",
              });
             
              setData({
                ...data,
                barcode_kanban: "",
              });
              barcode_kanbanRef.current.focus();
            }
          } else {
            Modal.error({
              title: "Failed!",
              content: "This Kanban doesn\t have part number, please fix it!",
            });

            setData({
              ...data,
              barcode_kanban: "",
            });
            barcode_kanbanRef.current.focus();
          }
          // setData({
          //   ...data,
          //   barcode_kanban: "",
          // });
        })

        .catch((err) => {
          if (err.response) {
            Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
            if (err.response.status == 401) {
              sessionStorage.removeItem("token");
            }
          }
        });
    } else {
      Modal.error({
        title: "Failed!",
        content: "Kanban already scanned!",
      });
      setData({
        ...data,
        barcode_kanban: "",
      });
    }
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if(localStorage.getItem('dn_no') !== null)
    {
      if( localStorage.getItem('dn_no').length > 0)
      {
        setData({...data,
          dn_no: localStorage.getItem('dn_no')
        })
        getDataAuto(localStorage.getItem('dn_no'))
      }
      else{
        dn_noRef.current.focus();
  
      }
    }  else{
      dn_noRef.current.focus();

    }
    
    // localStorage.setItem('dataTemporaryKanban', JSON.stringify(dataTemporaryKanban))
    // setDataTemporaryKanban(JSON.parse(localStorage.getItem('dataTemporaryKanban')))
  }, [keyword, page, sort]);
  const handleResetTemp = () => {
    setLoading(true);
      Axios.post(
        `${url}/reset-manifest-temp`,
        {
          dn_no: data.dn_no,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
        setLoading(false)
        })
        .catch((err) => {
          if (err.response) {
              Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          }
          setLoading(false);
        });
  }
  const handleReset = () => {
    setData([]);
    setIsDisabled({
      dn_no: false,
      qty: true,
      barcode_kanban: true,
    });
    localStorage.removeItem('dn_no')
    
    setScanDNManifest([]);
    setDataTemporaryKanban([]);
    handleResetTemp();
    dn_noRef.current.focus();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Status",
      sorter: true,
      render: (qty, data, index) => {
        const findRecord = dataTemporaryKanbanCustomer.filter(
          (x) => x.unique_id === data.unique_id
        );

        console.log("RECORD", findRecord);
        console.log("qty", qty.order_pcs);
        const total = findRecord.reduce((total, num) => {
          return total + parseInt(num.qty_kanban);
        }, 0);

        console.log("total", total);
        return (total + data.temp_qty_kanban) === qty.order_pcs ? (
          <div>
            <Badge
              count={"OK"}
              style={{
                backgroundColor: "#52c41a",
                color: "white",
                fontWeight: 500,
              }}
            />
          </div>
        ) : (
          <div>
            <Badge
              count={"Not OK"}
              style={{
                backgroundColor: "red",
                color: "white",
                fontWeight: 500,
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Order Kanban",
      dataIndex: "order_kanban",
      sorter: true,
      render: (qty, record, index) => {
        const findRecord = dataTemporaryKanbanCustomer.filter(
          (x) => x.unique_id === record.unique_id
        );

        const totalQty = findRecord.length;

        return `${totalQty+record.temp_count}/${qty}`;
      },
    },
    {
      title: "Qty Actual",
      dataIndex: "order_pcs",
      sorter: true,
      render: (qty, record, index) => {
        const findRecord = dataTemporaryKanbanCustomer.filter(
          (x) => x.unique_id === record.unique_id
        );

        const total = findRecord.reduce((total, num) => {
          return total + parseInt(num.qty_kanban);
        }, 0);
        
        
        return `${total+record.temp_qty_kanban}/${qty}`;
      },
    },
    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "DN No",
      dataIndex: "delivery_note_no",
      sorter: true,
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
      sorter: true,
    },
    {
      title: "Shipped To",
      dataIndex: "shipment_name",
      sorter: true,
    },
    {
      title: "Delivery Note Date",
      dataIndex: "delivery_note_date",
      sorter: true,
    },

    {
      title: "Plan Receive Max Time",
      dataIndex: "plan_receive_max_time",
      sorter: true,
    },
    {
      title: "Delivery Note Cycle",
      dataIndex: "delivery_note_cycle",
      sorter: true,
    },
    {
      title: "Job No",
      dataIndex: "job_number",
      sorter: true,
    },
    {
      title: "Lane",
      dataIndex: "lane",
      sorter: true,
    },
    {
      title: "Qty Perkanban",
      dataIndex: "qty_kanban",
      sorter: true,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: true,
    },

    {
      title: "Part No",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    
    // {
    //   title: "Status Kanban Cust",
    //   dataIndex: "delivery_note_no",
    //   sorter: false,
    //   // render: (delivery_note_no, record, index) => {
    //   //   const findRecord = dataTemporaryKanban.find(
    //   //     (x) => x.part_number === record.part_number
    //   //   );

    //   //   const findRecordDn = scanDNManifest.find();

    //   //   const qtyCheck = findRecord.qty_kanban === data;

    //   //   return `${findRecord?.qty_kanban || 0}/${qtyCheck}`;
    //   // },
    // },
  ];

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    key,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          inputType === "date" ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
            >
              <DatePicker onChange={onChangeDate} />
            </Form.Item>
          ) : (
            ""
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "qty_delivery" ? "text" : "text",
        inputType:
          col.dataIndex === "tanggal_scan"
            ? "date"
            : col.dataIndex === "jam_pelunasan"
            ? "time"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onSubmit = () => {
    var dataSend = true;

    if (dataSend == true) {
      setLoading(true);
      Axios.post(
        `${url}/history-pd`,
        {
          scan_dn: scanDNManifest,
          dn_no: data.dn_no,
          po_number: data.po_number,
          delivery_note_date: data.delivery_note_date,
          plan_receive_max_time: data.plan_receive_max_time,
          delivery_note_cycle: data.delivery_note_cycle,
          part_number: data.part_number,
          job_number: data.job_number,
          lane: data.lane,
          qty_kanban: data.qty_kanban,
          order_pcs: data.order_pcs,
          remarks: data.remarks,
          item_number: data.item_number,
          status: data.status,
          barcode_fg: kanbanFg,
          barcode_customer: kanbanCust,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          setKanbanCust(null);
          setKanbanFg(null);
          localStorage.removeItem('dn_no')
          props.history.push("/history-dn-manifest");
        })
        .catch((err) => {
          if (err.response) {
              Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          }
          setLoading(false);
        });
    } else {
      Modal.error({
        title: "Failed!",
        content: "Scan Kanban Belum Terpenuhi",
      })
      
    }
  };

  const checkKanbanCustomer = () => {
    // const fgKanban = dataTemporaryKanban.find(x => )

    const findExists = dataTemporaryKanbanCustomer.find((x) => {
      return x.barcode_kanban_cust === data.barcode_kanban_cust;
    });
    console.log('DATA TEMP 2',findExists);
    if (matrix.unique !== 1) {
      processKanban();
    } else {
      if (!findExists) {
        processKanban();
      } else {
        Modal.error({
          title: "Failed!",
          content: "Kanban Already Scanned!",
        })
        setData({
          ...data,
          barcode_kanban_cust: "",
        });
      }
    }
  };

  // console.log(dataTemporaryKanbanCustomer, "dataTemporaryKanbanCustomer");

  const rollbackTemp = (id) => {
    Axios.post(
      `${url}/rollback-temp`,
      {
        id: id
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
      setLoading(false)
      })
      .catch((err) => {
        if (err.response) {
            Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  }
  const processKanban = () => {
    const matrixMap = matrix.matrix.map((item) => {
      return {
        field: item.field,
        value: data.barcode_kanban_cust.substr(item.start, item.length),
      };
    });
    send({
      url: `${url}/check-kanban-cust`,
      method: "get",
      headers: {
        Authorization: token,
      },
      data: {
        kanban_fg: kanbanFg,
        dn_no: data.dn_no,
        matrix: matrixMap,
        barcode_kanban_cust: data.barcode_kanban_cust,
      },
    }).then((res) => {
      const findRecord = dataTemporaryKanbanCustomer.filter(
        (x) => x.unique_id === res.data.data.unique_id
      );

      const total = findRecord.reduce((total, num) => {
             return total + parseInt(num.qty_kanban);
          }, 0);
    
      if (res.success) {
        console.log("LOGIC",total,res.data.data.order_pcs)
        if((total+tempTotalKanban) == res.data.data.order_pcs){
          rollbackTemp(res.data.temp_id)
          Modal.error({
            title: "Failed!",
            content: "Part number "+res.data.data.item_number+" sudah mencapai batas Order Kanban",
          })
          
          setData({
            ...data,
            barcode_kanban: "",
            barcode_kanban_cust: "",
          });
          return false;
        }
        const findPart = scanDNManifest.find(
          (x) => x.item_number == res.data.data.item_number
        );
        console.log("FIND PART", findPart);
        if (findPart) {
          setDataTemporaryKanbanCustomer([
            ...dataTemporaryKanbanCustomer,
            {
              ...res.data.data,
              barcode_kanban_cust: data.barcode_kanban_cust,
            },
          ]);
          setKanbanCust(data.barcode_kanban_cust);
          setData({
            ...data,
            barcode_kanban: "",
            barcode_kanban_cust: "",
          });
          setIsDisabled({
            ...isDisabled,
            barcode_kanban: false,
            barcode_kanban_cust: true,
          });
          barcode_kanbanRef.current.focus();
        } else {
          Modal.error({
            title: "Failed!",
            content: "Part number not match!",
          })
          
          setData({
            ...data,
            barcode_kanban_cust: "",
          });
        }
      } else {
        message.error(res.data.message);
        setData({
          ...data,
          barcode_kanban_cust: "",
        });
      }
    }).catch((err) => {
      setLoading(false)
      if (err.response) {
        Modal.error({
          title: "Failed!",
          content: err.response.data.message,
        })
        // message.error(err.response.data.message);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
        }
      }
    });
  };
  console.log(kanbanCust, kanbanFg);
  console.log(dataTemporaryKanban, dataTemporaryKanbanCustomer, "data nya");

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  const styleForm = {
    marginTop: "-20px",
  };
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan DN Manifest"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}  md={24} lg={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical">
                <Row>
                  <Fragment>
                    <Col xs={24} md={24} lg={24}>
                      <Row>
                          <Col  style={styleForm} xs={24} md={24} lg={24}>
                          <Space>
                        <Form.Item label="DN Manifest :">
                          <Input
                            onPressEnter={(e) => getData(e, "dn_no")}
                            type="primary"
                            prefix={<BarcodeOutlined />}
                            style={{ width: windowSize.current[0]-(windowSize.current[0]*0.3)  }}
                            onChange={(e) =>
                              handleChange("dn_no", e.target.value)
                            }
                            value={data.dn_no}
                            disabled={isDisabled.dn_no}
                            ref={dn_noRef}
                          />
                        </Form.Item>
                        {data.dn_no !== "" ? (
                          <Button
                            type="danger"
                            onClick={handleReset}
                            loading={loading}
                            className="mr-button"
                          >
                            Delete
                          </Button>
                        ) : (
                          ""
                        )}
                      </Space>

                          </Col>
                          <Col xs={24} md={23} lg={23}>
                              <Space>
                            <Form.Item label=" Scan Kanban FG :">
                              <Input
                                type="primary"
                                // style={{ width: 850 }}
                                onChange={(e) =>
                                  handleChange("barcode_kanban", e.target.value)
                                }
                                value={data.barcode_kanban}
                                ref={barcode_kanbanRef}
                                style={{ width: windowSize.current[0]-(windowSize.current[0]*0.3)  }}
                                disabled={isDisabled.barcode_kanban}
                                onPressEnter={(e) => getKanban(e, "barcode_kanban")}
                              />
                            </Form.Item>
                          </Space>
                            </Col>
                            <Col xs={24} md={23} lg={23}>
                            <Space>
                        <Form.Item label=" Scan Kanban Customer :">
                          <Input
                            type="primary"
                            // style={{ width: 850 }}
                            onChange={(e) =>
                              handleChange(
                                "barcode_kanban_cust",
                                e.target.value
                              )
                            }
                            value={data.barcode_kanban_cust}
                            ref={barcode_kanban_custRef}
                            style={{ width: windowSize.current[0]-(windowSize.current[0]*0.3)  }}
                            disabled={isDisabled.barcode_kanban_cust}
                            onPressEnter={checkKanbanCustomer}
                          />
                        </Form.Item>
                      </Space>
                              </Col>
                      </Row>
                  
                    

                  
                    </Col>
                  </Fragment>
                </Row>
                <Col xs={24}>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 2400 }}
                    columns={mergedColumns}
                    dataSource={scanDNManifest}
                    rowKey={"part_number"}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        // htmlType="submit"
                        className="mr-button"
                        onClick={onSubmit}
                        disabled={
                          scanDNManifest.length === 0 ||
                          (dataTemporaryKanbanCustomer.reduce(
                            (total, num) => total + parseInt(num.qty_kanban),
                            0
                          )+tempTotalKanban) !==
                            scanDNManifest.reduce(
                              (total, num) => total + parseInt(num.order_pcs),
                              0
                            )
                        }
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PDScanDNManifest;
