/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Input,
  Table,
  Modal,
  message,
  Checkbox,
  Dropdown,
  DatePicker,
  Menu,
  Form,
  Badge
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined, PrinterOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;

function PHistoryWipResult() {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [rowDetails, setRowDetails] = useState([]);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [data, setData] = useState({
    kanban_number: "",
    selected: [],
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort,date]);

  const scanNumberRef = useRef(null);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/histori-print-wip-result`, {
      params: {
        start_date: date.start,
        end_date: date.end,
        keyword,
       columns: ["parent_item",'lot_prod','component_item'],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_number", data.item_number);
    fd.set("unit", data.unit);
    fd.set("qty", data.qty);
    fd.set("tanggal_scan", data.tanggal_scan);

    Axios.post(`${url}/histori-scan-produksi/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        closeModalKanban();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModalKanban = (id) => {
    const kanban = data.data.find((data) => data._id === id);
    setKanbanId(id);

    console.log(id);

    setData({
      ...data,
      item_number: kanban.item_number,
      unit: kanban.unit,
      qty: kanban.qty,
      tanggal_scan: kanban.tanggal_scan,
    });

    setIsModalVisibleKanban(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const closeModalKanban = () => {
    setData({
      ...data,
      item_number: "",
      qty: "",
      unit: "",
      tanggal_scan: "",
    });
    setIsModalVisibleKanban(false);
  };

  const onReprint = (id) => {
    setLoading(true);
    let fd = new FormData();
    fd.set("id", id);
    Axios.post(`${url}/history-print/reprint/wip`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        Modal.success({
          title: "Success!",
          content:res.data.message,
        });
        // message.success(res.data.message);
        // window.open(res.data.pdf, "_blank");
        getData();
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };
  const changeCheck = (event, id) => {
    // console.log(id)
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, id];
    } else {
      updatedList.splice(checked.indexOf(id), 1);
    }
    setChecked(updatedList);

    console.log(updatedList);
    // setData({
    //   ...data,
    //   selected: data.selected.concat(id)
    // });
    // setIsModalVisibleKanban(false);
  };

  const columns = [
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "left",
    //   sorter: true,
    //   fixed: "left",
    //   width: 75,
    //   render: (data) => {
    //     return (
    //       <Fragment>
    //         <Form.Item>
    //           <Checkbox
    //             onChange={(e) => {
    //               changeCheck(e, data);
    //             }}
    //           ></Checkbox>
    //           ,
    //         </Form.Item>
    //       </Fragment>
    //     );
    //   },
    // },
    {
      title: "Lot Prod Sequence",
      dataIndex: "lot_prod_seq",

      sorter: true,
    },
    {
      title: "Item Number",

      dataIndex: "parent_item",
      sorter: true,
    },
    // {
    //   title: "Part Name",

    //   dataIndex: "part_name",
    //   sorter: true,
    // },
    {
      title: "Part Number",

      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: " Tipe",

      dataIndex: "tipe",
      sorter: true,
    },
    {
      title: "Proses",

      dataIndex: "proses",
      sorter: true,
    },
    {
      title: "Storage",

      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Group",

      dataIndex: "group",
      sorter: true,
    },
    // {
    //   title: " Qty Kanban Beredar",

    //   dataIndex: "kanban_beredar",
    //   sorter: true,
    // },
    {
      title: "Standar Packing",

      dataIndex: "std_pack",
      sorter: true,
    },
    {
      title: "Qty Actual",

      dataIndex: "qty",
      sorter: true,
    },
    {
      title: "Keterangan",

      dataIndex: "keterangan",
      sorter: true,
    },
    {
      title: " Status",
      sorter: true,
      render: (data) => {
        if(data.reprint_at != null) {
          return (
            <div>
              <Badge count={"RE-PRINTED"} style={{ backgroundColor: 'green', color:'white', fontWeight: 500 }} />
              <Badge count={`by ${data.reprint_by}`} style={{ backgroundColor: 'green', color:'white', fontWeight: 500 }} />
            </div>
          )
        } else {
          return (
            <div>
              <Badge count={""} style={{ backgroundColor: 'red', color:'white', fontWeight: 500 }} />
            </div>
          )
        }
      },
      // render: (data) => {
      //   if(data.reprint_at != null)
      //   {
      //     return (<div>
      //       <Text mark>RE-PRINTED</Text><br/>
      //       <Text type="secondary">by {data.reprint_by}</Text>
      //     </div>)
      //   }else{
      //     return (<div>-</div>)
      //   }
      // }
    },
    {
      title: "Re-Print at",
      render: (data) => (
        <div>
          {data.reprint_at == null ? "-" : data.reprint_at}
        </div>
      ),
      sorter: true,
    },
    {
      title: "Date Print",

      render: (data) => (
        <div>
          {data.created_at == null ? "" : data.created_at}
        </div>
      ),
      sorter: true,
    },

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 75,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link
                      onClick={(e) => {
                        onReprint(id);
                      }}
                      to="#"
                    >
                      <PrinterOutlined /> Re-Print
                    </Link>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(warehouseReceiving, "warehouseReceiving");

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Print Wip Result"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Modal
          title="Edit"
          style={{ top: 20 }}
          visible={isModalVisibleKanban}
          width={1000}
          footer={null}
          onCancel={closeModalKanban}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Form {...layout} name="control-hooks" labelAlign="left">
                  <Row>
                    <Fragment>
                      <Col xs={24}>
                        <Form.Item
                          validateStatus={
                            error && error.item_number ? "error" : false
                          }
                          help={
                            error && error.item_number
                              ? error.item_number[0]
                              : false
                          }
                          label="Part Number"
                        >
                          <Input
                            placeholder="Part Number"
                            value={data.item_number}
                            onChange={(e) => {
                              handleChange("item_number", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={error && error.qty ? "error" : false}
                          help={error && error.qty ? error.qty[0] : false}
                          label="QTY"
                        >
                          <Input
                            placeholder="qty"
                            value={data.qty}
                            onChange={(e) => {
                              handleChange("qty", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Form.Item
                            label="Unit"
                            validateStatus={
                              error && error.unit ? "error" : false
                            }
                            help={error && error.unit ? error.unit[0] : false}
                          >
                            <Input
                              disabled
                              value={data.unit}
                              onChange={(e) =>
                                handleChange("unit", e.target.value)
                              }
                            />
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </Fragment>
                  </Row>
                </Form>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={onSubmit}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                  }}
                >
                  Submit
                </Button>
                <Button htmlType="reset" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>

        <Row>
          <Col xs={24}>
            <Card className="body-data">
            <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 2500 }}
                    columns={columns}
                    dataSource={dataHistory.data}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dataHistory.total,
                      showSizeChanger: true,
                    }}
                    loading={loadingHistory || loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PHistoryWipResult;
