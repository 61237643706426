import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  DatePicker,
  Modal,
} from "antd";
import { Redirect, Link } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  ExportOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./SPListGRMaterial.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function SPListGRMaterial() {
  const [keyword, setKeyword] = useState("");
  const [grmaterials, setGrmaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [error, setError] = useState(null);
  const [date, setDate] = useState(moment(new Date()));

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-list-gr-material`, {
      params: {
        keyword,
        columns: [
          "component_item",
          "gr_no",
          "project",
          "storage",
          "dn_no",
          "no_po",
          "description2",
          "description1",
          "packing_slip",
          "line",
          "qty",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setGrmaterials(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const HandlegetQAD = () => {
    setLoading(true);
    Axios.get(`${url}/soap/get-list-gr`, {
      params: {
        date: moment(date).format("YYYY-M-D"),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });
        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const HandleSendQAD = () => {
    setLoading(true);
    Axios.post(`${url}/soap/send-list-gr`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res);
        // Modal.success({
        //   title: "Success!",
        //   content: res.data.message,
        // });

        Modal.info({
          width: 840,
          title: "QAD Feedback!",
          // onOk: () => {
          //   return props.history.push("/gr-material");
          // },
          content: (
            <div>
              <Table
                columns={[
                  {
                    title: "Component Item",
                    dataIndex: "component_item",
                    key: "component_item",
                  },
                  {
                    title: "Send GR Status",
                    dataIndex: "send_gr_status",
                    key: "send_gr_status",
                    render: (status) => {
                      return status ? (
                        <span style={{ color: "green" }}>SUCCESS</span>
                      ) : (
                        <span style={{ color: "red" }}>ERROR</span>
                      );
                    },
                  },
                  {
                    title: "Reason",
                    dataIndex: "send_gr_reason",
                    key: "send_gr_reason",
                  },
                ]}
                pagination={false}
                dataSource={res.data.result}
              />
            </div>
          ),
        });

        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const exportGRMaterial = () => {
    setLoadingExport(true);
    Axios.get(`${url}/master-list-gr-material/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    })
      .then((res) => {
        fileDownload(res.data, "Export List GR Material" + ".xlsx");
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "ID QAD",
      dataIndex: "component_item",
      sorter: true,
    },
    {
      title: "GR No",
      dataIndex: "gr_no",
      sorter: true,
    },
    {
      title: "GR Date",
      dataIndex: "gr_date",
      sorter: true,
    },
    {
      title: "Tanggal Receive",
      dataIndex: "created_at",
      sorter: true,
      render: (tgl) => (tgl !== null ? moment(tgl).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "DN No",
      dataIndex: "dn_no",
      sorter: true,
    },
    {
      title: "Project",
      dataIndex: "project",
      sorter: true,
    },

    {
      title: "Part Number",
      dataIndex: "description2",
      sorter: true,
    },
    {
      title: "Part Name ",
      dataIndex: "description1",
      sorter: true,
    },
    {
      title: "Std Pack",
      dataIndex: "std_pack",
      sorter: true,
    },
    {
      title: "Kanban Order",
      // dataIndex: "kanban_order",
      render : (row) => {
        return row.kanban_order_rcvd+'/'+row.total_order_kanban;
      },
      // render: (data, columns) => {
      //   return `${columns.details.reduce(
      //     (total, num) => parseInt(num.qty_kanban) + total,
      //     0
      //   )} / ${data}`;
      // },
      sorter: false,
    },
    {
      title: "Qty Delivery",
      dataIndex: "qty_delivery",
      sorter: false,
      render: (data, row) => {
        console.log(row, "row");
        return `${row.kanban_order_rcvd} / ${row.kanban_order}`;
        // return `${row.details.reduce(
        //   (total, num) => parseInt(num.qty_kanban) + total,
        //   0
        // )} / ${row.kanban_order}`;
      },
    },
    {
      title: "Total Minus",
      dataIndex: "qty_delivery",
      sorter: false,
      render: (data, row) => {
        return row.kanban_order-row.kanban_order_rcvd;
        // return Math.abs(
        //     row.details.reduce(
        //       (total, num) => parseInt(num.qty_kanban) + total,
        //       0
        //     )-row.kanban_order
        // );
      },
    },
  
    // {
    //   title: "Jam Pelunasan",
    //   dataIndex: "jam_pelunasan",
    //   sorter: true,
    // },
    {
      title: "Storage",
      dataIndex: "storage",
      sorter: true,
    },
    {
      title: "Status Sync GR",
      dataIndex: "status_sync_gr",
      sorter: true,
      align: "center",
      width: 150,
      render: (item) => {
        return item ? (
          <CheckCircleOutlined style={{ color: "#00944b", fontSize: 30 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 30 }} />
        );
      },
    },
    {
      title: "Status Send GR",
      dataIndex: "status_send_gr",
      sorter: true,
      width: 150,
      align: "center",
      render: (item) => {
        return item ? (
          <CheckCircleOutlined style={{ color: "#00944b", fontSize: 30 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 30 }} />
        );
      },
    },
    {
      title: "Status Receive",
      dataIndex: "status_receive",
      align: "center",
      width: 150,
      sorter: true,
      render: (item,row) => {
        return  parseInt(row.kanban_order_rcvd) == parseInt(row.total_order_kanban) ? (
          <CheckCircleOutlined style={{ color: "#00944b", fontSize: 30 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 30 }} />
        );
      },
    },
    {
      title: "Status Match",
      dataIndex: "status_match",
      align: "center",
      width: 150,
      sorter: true,
      render: (item) => {
        return item === "Matching" ? (
          <CheckCircleOutlined style={{ color: "#00944b", fontSize: 30 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 30 }} />
        );
      },
    },
    // {
    //   title: "Keterangan",
    //   dataIndex: "keterangan",
    //   width: 250,
    //   sorter: true,
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="List GR Material"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space align="start">
                    <div className="wrapper-sync">
                      <Link>
                        {buttonAccess &&
                          buttonAccess.find(
                            (x) => x.url === "get-sap/master-gr-material"
                          ) && (
                            <Button onClick={HandlegetQAD} type="primary">
                              <PlusOutlined />
                              Sync GR
                            </Button>
                          )}
                      </Link>
                      <br />
                      <label>Sync Date</label>
                      <DatePicker
                        value={date}
                        format="DD/MM/YYYY"
                        onChange={(val) => setDate(val)}
                      />
                    </div>

                    {/* <div className="wrapper-sync">
                      <Link>
                        {buttonAccess &&
                          buttonAccess.find(
                            (x) => x.url === "get-sap/master-gr-material"
                          ) && (
                            <Button onClick={HandleSendQAD} type="primary">
                              <PlusOutlined />
                              Send GR
                            </Button>
                          )}
                      </Link>
                    </div> */}

                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "gr-material/export"
                      ) && (
                        <Button
                          loading={loadingExport}
                          onClick={exportGRMaterial}
                          type="primary"
                        >
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right items-start">
                  <Search
                    placeholder="cth: G01-02-C atau DN0D240223L0284xxx"
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2500 }}
                    columns={columns}
                    dataSource={grmaterials.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: grmaterials.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPListGRMaterial;
