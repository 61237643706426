import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
  Form,
  Select,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  PrinterOutlined,
  BarcodeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Content } = Layout;

function RReportEKanban(props) {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [kanbanId, setKanbanId] = useState(null);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [historyDetailsTotal, setHistoryDetailsTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);
  const [kanbans, setKanbans] = useState([]);
  const [data, setData] = useState({
    dn_no: "",
    purchase_order: "",
    supplier_code: "",
    supplier_name: "",
    Alamat: "",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getHistoryReceivingDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    getKanban();
  }, []);

  useEffect(() => {
    if (selectedId) {
      getHistoryReceivingDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/history_receiving`, {
      params: {
        keyword,
        columns: [
          "dn_no",
          "purchase_order",
          "supplier_code",
          "supplier_name",
          "alamat",
          "project",
          "description",
          "part_number",
          "min_of_stock",
          "sum_of_kanban_order",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagesize(pagination.pageSize);
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPagesize(pagination.pageSize);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getKanban(data2[0]);
    }
  };

  const getKanban = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "kanban_type",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbans(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const exportReport = () => {
    Axios.get(`${url}/report-ekanban/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Report E-Kanban" + ".xlsx");
    });
  };

  const getHistoryReceivingDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/kanban/history/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["_id", "quantity_kanban"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setHistoryDetails(res.data.data);
        setHistoryDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const printPdf = (id) => {
    Axios.get(`${url}/kanban/pdf/download/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      // fileDownload(res.data, "Export Master Kanban" + ".xlsx");
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const columns = [
    {
      title: "Project",
      dataIndex: "project",
      align: "center",
      key: "project",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
      key: "description",
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      align: "center",
      key: "part_number",
    },
    {
      title: "Std Stock",
      dataIndex: "std_stock",
      align: "center",
      key: "std_stock",
    },
    {
      title: "Kanban Order",
      dataIndex: "kanban_order",
      align: "center",
      key: "kanban_order",
    },
    {
      title: "Total Order",
      dataIndex: "total_order",
      align: "center",
      key: "total_order",
    },
    {
      title: "Qty Delivery",
      dataIndex: "qty_delivery",
      align: "center",
      key: "qty_delivery",
    },
    {
      title: "Total Minus",
      dataIndex: "total_minus",
      align: "center",
      key: "total_minus",
    },
    {
      title: "Jam Pelunasan",
      dataIndex: "jam_pelunasan",
      align: "center",
      key: "jam_pelunasan",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      align: "center",
      key: "keterangan",
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Report E-Kanban"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "report-ekanban/export"
                      ) && (
                        <Button
                          onClick={exportReport}
                          type="primary"
                          style={{ marginRight: 20 }}
                        >
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                  </Space>
                  {/* <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  /> */}
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default RReportEKanban;
