import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Form,
  Select,
  DatePicker,
  Badge,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url, storage_path } from "../config/global";
import imgDefault from "../assets/img/img-empty.png";
import fileDownload from "js-file-download";
import {
  MoreOutlined,
  PrinterOutlined,
  BarcodeOutlined,
  EditOutlined,
  ImportOutlined,
  PlusOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

function SPKanbanGenerate(props) {
  // const [keyword, setKeyword] = useState("");
  const [data, setData] = useState({
    dataKanban: [],
    kanban_type: "",
    item_code: "",
    item_name: "",
    quantity_kanban: "",
    qty_generate: "",
    select_id: "",
    select_label: "",
    select_name: "",
    job_number: "",
    selects: [],
  });

  const [keywordDetails, setKeywordDetails] = useState("");
  const [generateDisabled, setgenerateDisabled] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [isModalVisibleKanbanBulk, setIsModalVisibleKanbanBulk] =
    useState(false);
  const [error, setError] = useState(null);
  const [kanbanId, setKanbanId] = useState(null);
  const [kanbanDetails, setKanbanDetails] = useState([]);
  const [kanbanDetailsTotal, setKanbanDetailsTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [rowDetails, setRowDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [kanbans, setKanbans] = useState([]);
  const [detailKanbanType, setDetailKanbanType] = useState("");
  const [selectedRowKeysDetails, setSelectedRowKeysDetails] = useState([]);
  const [selectedRowsDetailsData, setSelectedRowsDetailsData] = useState([]);
  const [selectedQadIds, setSelectedQadIds] = useState([]);

  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  useEffect(() => {
    getData();
  }, [sort, data.kanban_type, date.from, date.to, page, pageSize, keyword]);

  useEffect(() => {
    if (selectedId) {
      getKanbanDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-kanban-generate`, {
      params: {
        keyword,
        select: data.kanban_type,
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        columns: [
          "kanban_type",
          "id_qad",
          "description_1",
          "description_2",
          "model",
          "storage",
          "area",
          "supplier_code",
          "quantity_kanban",
          "items",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          dataKanban: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    // console.log(setSelectedKanban)
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    console.log(index);
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      console.log(data2);
      setSelectedId(data2[0]);
      getKanbanDetails(data2[0]);
    }
  };

  const handleChangeDate = (value) => {
    console.log(value);
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const getKanbanDetails = (kanban_id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/master-kanban-generate/details/${kanban_id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["_id", "quantity_kanban"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbanDetails(res.data.data);
        setKanbanDetailsTotal(res.data.total);
        setDetailKanbanType(res.data.type);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleReset = () => {
    setData({
      ...data,
      qty_generate: "",
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalKanban = (id, item) => {
    setLoading(true);
    Axios.get(`${url}/calculate-kanban-beredar`, {
      params: {
        id_kanban: id,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type == "warning") {
          const kanban = data.dataKanban.find((data) => data._id === id);
          message.info(res.data.message);
          setgenerateDisabled(false);
          setKanbanId(id);
          setIsModalVisibleKanban(true);
          setData({
            ...data,
            job_number: kanban.job_number,
            item_code: kanban.item_code,
            qty_generate: res.data.kanban_beredar,
            id_qad: item.id_qad,
          });
        } else {
          setgenerateDisabled(true);
          const kanban = data.dataKanban.find((data) => data._id === id);
          setKanbanId(id);
          setData({
            ...data,
            job_number: kanban.job_number,
            item_code: kanban.item_code,
            qty_generate: res.data.kanban_beredar,
            id_qad: item.id_qad,
          });

          setIsModalVisibleKanban(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const closeModalKanban = () => {
    setData({
      ...data,
      select: "",
      job_number: "",
      item_code: "",
    });
    setIsModalVisibleKanban(false);
  };

  const closeModalKanbanBulk = () => {
    setData({
      ...data,
      select: "",
      job_number: "",
      item_code: "",
    });
    setSelectedRowKeysDetails([]);
    setSelectedQadIds([]);
    setIsModalVisibleKanbanBulk(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
    // handleRemoveDetails();
  };

  const onSubmit = () => {
    setLoading(true);

    // console.log(kanbanId);

    let fd = new FormData();

    fd.set("qty_generate", data.qty_generate);
    fd.set("description_2", data.description_2);
    fd.set("items", data.items);
    fd.set("id_qad", data.id_qad);

    Axios.post(`${url}/master-kanban-generate/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        // setLoading(false);
        closeModalKanban();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const onSubmitBulk = () => {
    setLoading(true);

    // console.log(kanbanId);

    let fd = new FormData();

    fd.set("qty_generate", data.qty_generate);
    fd.set("description_2", data.description_2);
    fd.set("items", data.items);

    fd.set("kanban_id", selectedRowKeysDetails);
    fd.set("id_qad", selectedQadIds);

    Axios.post(`${url}/master-kanban-generate/bulk`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        res.data.map((data, index) => {
          if(data.type === "error"){
            message.error(data.message);
          } else {
          message.success(data.message);
        }
        })
        if (res.data.type === "error") {
          message.error(res.data.message);
        }

        // setLoading(false);
        closeModalKanbanBulk();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleChangeSelectPart = (name, value) => {
    const find = data.items.find((x) => x._id === value.key);
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`description_1`]: find.description_1,
      [`description_2`]: find.description_2,
    });
  };

  const getPart = async (keyword) => {
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const processPrint = (id, data) => {
    Axios.get(`${url}/master-kanban-generate/print/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    })
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, `${data.data}`);
        // var blob = new Blob([res.data], { type: "application/pdf" });
        // var blobURL = URL.createObjectURL(blob);
        // window.open(blobURL);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err.response.status)
        if (err.response) {
          if(err.response.status == 405)
          {
            message.error('Tidak dapat melakukan Re-Print, Item number sudah melakukan cycle.')
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const printPdf = (id) => {
    setLoading(true);
    Axios.post(
      `${url}/get-print-name`,
      {
        id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        // this is for update or add print record into kanban details.
        // So it will change the print status after user click print button
        const newData = kanbanDetails.map((knb) => {
          if (knb._id === id) {
            const newPrintRecord = [
              {
                print_date: null,
                print_by: null,
              },
            ];
            if (knb.print_records) {
              return {
                ...knb,
                print_records: [...knb.print_records, ...newPrintRecord],
              };
            } else {
              return { ...knb, print_records: [...newPrintRecord] };
            }
          }
          return knb;
        });
        setKanbanDetails(newData);
        console.log("Next process print");
        processPrint(id, res.data);
      })
      .catch((err) => {
        console.log("ada error ", err);
        setLoading(false);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };


  const processRePrint = (id, data) => {
    Axios.get(`${url}/master-kanban-generate/reprint/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    })
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, `${data.data}`);
        // var blob = new Blob([res.data], { type: "application/pdf" });
        // var blobURL = URL.createObjectURL(blob);
        // window.open(blobURL);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err.response.status)
        if (err.response) {
          if(err.response.status == 405)
          {
            message.error('Tidak dapat melakukan Re-Print, Item number sudah melakukan cycle.')
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const reprintPdf = (id) => {
    setLoading(true);
    Axios.post(
      `${url}/get-print-name`,
      {
        id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        // this is for update or add print record into kanban details.
        // So it will change the print status after user click print button
        const newData = kanbanDetails.map((knb) => {
          if (knb._id === id) {
            const newPrintRecord = [
              {
                print_date: null,
                print_by: null,
              },
            ];
            if (knb.print_records) {
              return {
                ...knb,
                print_records: [...knb.print_records, ...newPrintRecord],
              };
            } else {
              return { ...knb, print_records: [...newPrintRecord] };
            }
          }
          return knb;
        });
        setKanbanDetails(newData);
        console.log("Next process print");
        processRePrint(id, res.data);
      })
      .catch((err) => {
        console.log("ada error ", err);
        setLoading(false);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const columns = [
    {
      title: "ID QAD",
      dataIndex: "id_qad",
      sorter: true,
    },
    {
      title: "Kanban Type",
      dataIndex: "kanban_type",
        sorter: true,
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
        sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
        sorter: true,
    },
    {
      title: "Model",
      dataIndex: "model",
        sorter: true,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_code",
        sorter: true,
    },

    {
      title: "Area",
      dataIndex: "area",
        sorter: true,
    },
    {
      title: "Storage",
      dataIndex: "storage",
        sorter: true,
    },
    {
      title: "Qty Kanban",
      dataIndex: "qty_kanban",
        sorter: true,
    },
    {
      title: "Date Delivery",
      dataIndex: "date_delivery",
        sorter: true,
      // render: (date_delivery) =>
      //   moment(date_delivery).format("DD MMM YYYY HH:mm"),
    },
    {
      title: "Picture",
      width: 100,
      dataIndex: "file_img",
      sorter: true,
      render: (file_img) => (
        <img
          style={{ width: 80, height: 70 }}
          src={file_img == "-" ? imgDefault : storage_path + file_img}
        />
      ),
    },
    // {
    //   title: "LOT Prod-Seqence ",
    //   dataIndex: "lot_prod_seq",
    //   sorter: false,
    //   // render: (lot_prod_seq) =>
    //   //   moment(lot_prod_seq).format("DD MMM YYYY HH:mm"),
    // },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 75,
      render: (id, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="4">
                    <span onClick={() => showModalKanban(id, data)}>
                      <BarcodeOutlined style={{ marginRight: 3 }} />
                      Generate
                    </span>
                  </Menu.Item>
                  {/* <Menu.Item key="1">
                    <Link to={`/kanban-generate/edit/${id}`}>
                      <EditOutlined /> Ubah
                    </Link>
                  </Menu.Item> */}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const getKanbans = () => {
    const columns = [
      {
        title: "Quantity Generate",
        align: "right",
        dataIndex: "qty_generate",
        key: "qty_generate",
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        align: "center",
        key: "created_at",
      },
      {
        title: "Created by",
        dataIndex: "generate_by",
        align: "center",
        key: "generate_by",
      },
      {
        title: "Status Print",
        dataIndex: "print_records",
        sorter: true,
        render: (pr) => {
          if (pr?.length > 1) {
            return (
              <div>
                <Badge
                  count={"RE PRINT "}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              </div>
            );
          } else if (pr?.length == 1) {
            return (
              <div>
                <Badge
                  count={"PRINT"}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              </div>
            );
          } else {
            return (
              <div>
                <Badge
                  count={"NO PRINT"}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              </div>
            );
          }
        },
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 100,
        render: (id, data) => {
          const isReprint = data.print_records?.length > 1;
          return detailKanbanType == "Kanban Supplier" ||
            detailKanbanType == "Kanban WIP" ? (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    
                    <Menu.Item key="1">
                      {isReprint ? 
                       <span onClick={() => reprintPdf(id)}>
                       <PrinterOutlined /> Re Print
                     </span>
                    :  <span onClick={() => printPdf(id)}>
                    <PrinterOutlined /> Print
                  </span>}
                     
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            {/* <RangePicker
              onChange={handleChangeDate}
              value={[date.from, date.to]}
              format="DD MMM YYYY"
              picker="date"
              // style={{ marginBottom: "2%" }}
            /> */}

            <Table
              //scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={kanbanDetails}
              // size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: kanbanDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const generateBulk = () => {
    console.log(selectedRowKeysDetails);
    console.log(selectedQadIds);
    setIsModalVisibleKanbanBulk(true);
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Generate Kanban"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]}>
                <Col xs={24} md={12} lg={12}>
                  <Space>
                    <Button type="primary" onClick={() => generateBulk()}>
                      <BarcodeOutlined style={{ marginRight: 3 }} />
                      Generate Bulk Kanban
                    </Button>

                    <Modal
                      title="Generate Bulk Kanban"
                      style={{ top: 20 }}
                      visible={isModalVisibleKanbanBulk}
                      width={1000}
                      footer={null}
                      onCancel={closeModalKanbanBulk}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Form
                              {...layout}
                              name="control-hooks"
                              labelAlign="left"
                            >
                              <Row>
                                <Fragment>
                                  <Col xs={24}>
                                    <Form.Item label="Apakah Anda yakin generate kanban yang dipilih ?"></Form.Item>
                                  </Col>
                                </Fragment>
                              </Row>
                            </Form>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSubmitBulk}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              disabled={generateDisabled}
                              htmlType="reset"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>

                    <Modal
                      title="Generate Kanban"
                      style={{ top: 20 }}
                      visible={isModalVisibleKanban}
                      width={1000}
                      footer={null}
                      onCancel={closeModalKanban}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Form
                              {...layout}
                              name="control-hooks"
                              labelAlign="left"
                            >
                              <Row>
                                <Fragment>
                                  <Col xs={24}>
                                    {/* <Form.Item
                                      validateStatus={
                                        error && error.select ? "error" : false
                                      }
                                      help={
                                        error && error.select
                                          ? error.select[0]
                                          : false
                                      }
                                      label="Select"
                                      name="select"
                                    >
                                      <Select
                                        placeholder="Part Name"
                                        allowClear
                                        labelInValue
                                        onChange={(value) =>
                                          handleChangeSelectPart("item", value)
                                        }
                                        onFocus={() => getPart("")}
                                        showSearch
                                        onSearch={(value) => getPart(value)}
                                        filterOption={false}
                                        value={{
                                          key: data.item_id,
                                          label: data.description_1,
                                        }}
                                      >
                                        {data.items &&
                                          data.items.map((item) => {
                                            return (
                                              <Option
                                                value={item._id}
                                                key={item._id}
                                              >
                                                {item.description_1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item> */}

                                    <Form.Item
                                      validateStatus={
                                        error && error.qty_generate
                                          ? "error"
                                          : false
                                      }
                                      help={
                                        error && error.qty_generate
                                          ? error.qty_generate[0]
                                          : false
                                      }
                                      label="Qty Kanban"
                                    >
                                      <Input
                                        disabled={generateDisabled}
                                        placeholder="Qty Kanban"
                                        value={data.qty_generate}
                                        onChange={(e) => {
                                          handleChange(
                                            "qty_generate",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                    {/* <Form.Item
                                      noStyle
                                      shouldUpdate={(
                                        prevValues,
                                        currentValues
                                      ) =>
                                        prevValues.select !==
                                        currentValues.select
                                      }
                                    >
                                      <Form.Item
                                        label="Part Name"
                                        validateStatus={
                                          error && error.description_1
                                            ? "error"
                                            : false
                                        }
                                        help={
                                          error && error.description_1
                                            ? error.description_1[0]
                                            : false
                                        }
                                      >
                                        <Input
                                          disabled
                                          value={data.description_1}
                                          onChange={(e) =>
                                            handleChange(
                                              "description_1",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Form.Item> */}
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(
                                        prevValues,
                                        currentValues
                                      ) =>
                                        prevValues.select !==
                                        currentValues.select
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue("select") ===
                                        "Item Code" ? (
                                          <Form.Item
                                            label="Item Code"
                                            validateStatus={
                                              error && error.item_code
                                                ? "error"
                                                : false
                                            }
                                            help={
                                              error && error.item_code
                                                ? error.item_code[0]
                                                : false
                                            }
                                          >
                                            <Input
                                              disabled
                                              value={data.item_code}
                                              onChange={(e) =>
                                                handleChange(
                                                  "item_code",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        ) : null
                                      }
                                    </Form.Item>
                                  </Col>
                                </Fragment>
                              </Row>
                            </Form>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSubmit}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              disabled={generateDisabled}
                              htmlType="reset"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>
                  </Space>
                </Col>
                <Col xs={24} md={12} className="main-body">
                  <Row
                    type="flex"
                    gutter={[10, 20]}
                    justify="space-around"
                    // style={{ marginBottom: 10 }}
                  >
                    <Col xs={24} md={12} lg={18}></Col>
                    <Col xs={24} md={12} lg={6} className="align-right">
                      <Search
                        placeholder="Search ..."
                        onSearch={(value) => handleSearch(value)}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getKanbans,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    // size="small"
                    dataSource={data.dataKanban}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysDetails,
                      type: "checkbox",
                      getCheckboxProps: (record) => ({
                        disabled: record.kanban_type !== "Kanban Supplier",
                      }),
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsDetailsData(selectedRows);
                        setSelectedRowKeysDetails(selectedRowKeys);
                        const qadIds = selectedRows.map((row) => row.id_qad);
                        setSelectedQadIds(qadIds);
                      },
                    }}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPKanbanGenerate;
