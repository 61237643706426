import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Badge,
  Button,
  Input,
  Table,
  message,
  Space,
  Form,
  DatePicker,
  Modal,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { BarcodeOutlined } from "@ant-design/icons";
import _ from "underscore";

const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function WScanReceiving(props) {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [scanKanban, setScanKanban] = useState([]);
  const [dataTemporaryKanban, setDataTemporaryKanban] = useState([]);
  const [scanReceiving, setScanReceiving] = useState([]);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    dn_number: "",
    barcode_kanban: "",
    tanggal_scan: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [isDisabled, setIsDisabled] = useState({
    dn_number: false,
    barcode_kanban: true,
    tanggal_scan: true,
  });

  const dn_numberRef = useRef();
  const barcode_kanbanRef = useRef();
  //tanggal Pelunasan
  const [valueDate, setValueDate] = useState(null);
  const onChangeDate = (date, dateString) => {
    setValueDate(dateString);
    // console.log(timestring);
  };

  console.log(dataTemporaryKanban, "dataTemp");

  const [form] = Form.useForm();
  // const [editingKey, setEditingKey] = useState("");
  // const isEditing = (record) => record.comopent_item === editingKey;
  // const edit = (record) => {
  //   const { tanggal_scan } = record;
  //   form.setFieldsValue({
  //     tanggal_scan: tanggal_scan ? moment(tanggal_scan) : "",

  //     ...record,
  //   });
  //   setEditingKey(record.comopent_item);

  //   console.log(record);
  // };

  // const save = async (data) => {
  //   const row = await form.validateFields();
  //   console.log("ini data", row, data);
  //   setLoading(true);

  //   console.log(scanReceiving);

  //   setEditingKey("");
  //   setLoading(false);
  // };

  // const cancel = () => {
  //   setEditingKey("");
  // };

  const getData = () => {
    setLoading(true)
    setIsDisabled({
      dn_number: true,

      barcode_kanban: true,
      tanggal_scan: true,
    });
    Axios.get(`${url}/scan-receiving-material/get-scan`, {
      params: {
        barcode: data.dn_number,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.data);
        const dataScan = res.data.data;

        if (res.data.data_list_gr !== null) {
          setDataTemporaryKanban(res.data.data_list_gr);
        }
        setScanReceiving(res.data.data);
        setIsDisabled({
          dn_number: true,

          barcode_kanban: false,
          tanggal_scan: false,
        });
        barcode_kanbanRef.current.focus();
        setLoading(false)
        // getLotProduction(dataScan);
      })
      .catch((err) => {
        // console.log(err)
        setLoading(false)
        setIsDisabled({
          dn_number: false,

          barcode_kanban: true,
          tanggal_scan: true,
        });
        if(err.response === undefined)
        {
          Modal.error({
            title: "Failed!",
            content:"Cannot connect to server, please check your network.",
          }); 
          return false;
        }
        if (err.response) {
          Modal.error({
            title: "Failed!",
            content:err.response.data.message,
          }); 
          // message.error(err.response.data.message);

          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getKanban = () => {
    // console.log(data.barcode_kanban);
    const findFirst = dataTemporaryKanban.find(
      (x) => x.barcode === data.barcode_kanban
    );
    if (findFirst) {
      message.error("Kanban already scanned!");
      setData({
        ...data,
        barcode_kanban: "",
      });
      barcode_kanbanRef.current.focus();
    } else {
      Axios.get(`${url}/scan-receiving-material/scan-kanban`, {
        params: {
          barcode_kanban: data.barcode_kanban,
          dn_no: data.dn_number,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.data === null) {
            message.error("Not Found");
            setData({
              ...data,
              barcode_kanban: "",
            });
            barcode_kanbanRef.current.focus();
          } else {
            const findItem = scanReceiving.find(
              (x) => x.key_record === res.data.data.key_record
            );

            if (dataTemporaryKanban.length >= 1) {
              const tempScanKanban = dataTemporaryKanban.filter(
                (x) => x.key_record === res.data.data.key_record
              );
              const countDnByKanban = scanReceiving.find(
                (x) => x.key_record === res.data.data.key_record
              );

              if (tempScanKanban.length >= findItem.total_order) {
                message.error(
                  `Qty Kanban pada Component Item = ${countDnByKanban.component_item}, sudah terpenuhi, harap scan kanban yang belum terpenuhi.`
                );
              } else {
                if (findItem) {
                  setDataTemporaryKanban([
                    ...dataTemporaryKanban,
                    res.data.data,
                  ]);
                } else {
                  message.error("Component not match!");
                }
              }
            } else {
              if (findItem) {
                setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
              } else {
                message.error("Component not match!");
              }
            }
          }

          setData({
            dn_number: data.dn_number,
            barcode_kanban: "",
          });
          barcode_kanbanRef.current.focus();
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status == 401) {
              sessionStorage.removeItem("token");
            }
          }
        });
    }
  };

  console.log(scanReceiving);
  console.log(data);

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Apakah Anda yakin ingin me-refresh halaman?';
      event.returnValue = message; // Standar untuk kebanyakan browser
      return message; // Untuk Firefox
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    dn_numberRef.current.focus();
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [keyword, page, sort]);

  const handleReset = () => {
    setData([]);
    setScanReceiving([]);
    setDataTemporaryKanban([]);
    setIsDisabled({
      dn_number: false,

      barcode_kanban: true,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleEditTanggal = () => {
    setIsDisabled({
      ...isDisabled,
      tanggal_scan: false,
    });
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "total_qty_kanban",
      key: "total_qty_kanban",
      sorter: true,
      render: (item, record) => {
        const findMatch = dataTemporaryKanban.filter(
          (x) => x.key_record === record.key_record
        );
        console.log("TEMP ",dataTemporaryKanban);
        console.log("MATCH "+record.component_item,findMatch);
        return dataTemporaryKanban.length > 0 ? (
          findMatch.reduce(
            (total, num) => total + parseInt(num.qty_kanban),
            0
          ) === parseInt(item) ? (
            <div>
              <Badge count={"OK"} style={{ backgroundColor: "#52c41a" }} />
            </div>
          ) : (
            <div>
              <Badge count={"Not OK"} />
            </div>
          )
        ) : (
          <div>
            <Badge
              count={"COLLECT"}
              style={{ backgroundColor: "#292b2c", color: "white" }}
            />
          </div>
        );
      },
    },
    {
      title: "Component Item ",
      dataIndex: "component_item",
      key: "component_item",
      sorter: true,
    },
    {
      title: "Model / Project",
      dataIndex: "project_parsed",
      key: "project_parsed",
      sorter: true,
    },
    {
      title: "Total",
      align: "center",
      children: [
        {
          title: "Kanban Scan",
          dataIndex: "total_qty_kanban",
          key: "total_qty_kanban",
          sorter: true,
          render: (item, record) => {
            const findMatch = dataTemporaryKanban.filter(
              (x) => x.key_record === record.key_record
            );
            return `${findMatch.reduce(
              (total, num) => total + parseInt(num.qty_kanban),
              0
            )}/${item}`;
          },
        },
        {
          title: "Qty Kanban",
          dataIndex: "total_qty_kanban",
          key: "total_qty_kanban",
          sorter: true,
          render: (item, record) => {
            const findMatch = dataTemporaryKanban.filter(
              (x) => x.key_record === record.key_record
            );
            return `${findMatch.reduce(
              (total, num) => total + parseInt(num.qty_kanban),
              0
            ) / record.std_pack
              }/${item / record.std_pack}`;
          },
        },
      ],
    },
    {
      title: "Std Pack",
      dataIndex: "std_pack",
      key: "std_pack",
      sorter: true,
    },
    {
      title: "Unit",
      dataIndex: "item",
      key: "item",
      sorter: true,
      render: (item) => item.unit_of_measure,
      sorter: true,
    },
    {
      title: "Description 1",
      dataIndex: "item",
      key: "item",
      render: (item) => item.description_1,
      sorter: true,
    },
    {
      title: "Description 2",
      dataIndex: "item",
      key: "item",
      render: (item) => item.description_2,
      sorter: true,
    },
    // {
    //   title: "Tanggal Scan",
    //   dataIndex: "tanggal_scan",
    //   key: "tanggal_scan",
    //   sorter: true,
    //   editable: true,
    // },
    // {
    //   title: "Status Receive",
    //   dataIndex: "status_receive",
    //   key: "status_receive",
    //   sorter: true,
    // },
    // {
    //   title: "",
    //   sorter: true,
    //   width: 200,
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Button
    //           type="primary"
    //           style={{
    //             marginRight: 8,
    //           }}
    //           onClick={() => save(record)}
    //         >
    //           Save
    //         </Button>
    //         <Button onClick={cancel} type="danger">
    //           Cancel
    //         </Button>
    //       </span>
    //     ) : record ? (
    //       <Button
    //         style={{ width: 125 }}
    //         type="primary"
    //         onClick={() => edit(record)}
    //       >
    //         Edit Tanggal
    //       </Button>
    //     ) : (
    //       ""
    //     );
    //   },
    // },
  ];

  // const EditableCell = ({
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   key,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // }) => {
  //   return (
  //     <td {...restProps}>
  //       {editing ? (
  //         inputType === "date" ? (
  //           <Form.Item
  //             name={dataIndex}
  //             style={{
  //               margin: 0,
  //             }}
  //             // rules={[
  //             //   {
  //             //     required: true,
  //             //     message: `Please Input Date!`,
  //             //   },
  //             // ]}
  //           >
  //             {/* <Input type="date" /> */}
  //             <DatePicker onChange={onChangeDate} />
  //           </Form.Item>
  //         ) : (
  //           ""
  //         )
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "qty_delivery" ? "text" : "text",
  //       inputType:
  //         col.dataIndex === "tanggal_scan"
  //           ? "date"
  //           : col.dataIndex === "jam_pelunasan"
  //           ? "time"
  //           : "text",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  const onSubmit = () => {
    console.log('difference GR & Scanned',dataTemporaryKanban,dataTemporaryKanban.reduce(
      (total, num) => total + parseInt(num.qty_kanban),
      0
    ) , scanReceiving.reduce(
      (total, num) => total + parseInt(num.total_qty_kanban),
      0
    ))
  
    if (
      dataTemporaryKanban.reduce(
        (total, num) => total + parseInt(num.qty_kanban),
        0
      ) !==
      scanReceiving.reduce(
        (total, num) => total + parseInt(num.total_qty_kanban),
        0
      )
    ) {
      Modal.confirm({
        title: "Confirmation",
        content:
          "Kanban is not fully complete yet, are you sure want to proceed?",
        onOk: () => confirmSubmit({ isComplete: false }),
      });
    } else {
      confirmSubmit({ isComplete: true });
    }
  };

  const confirmSubmit = ({ isComplete }) => {
    setLoading(true);
    Axios.post(
      `${url}/scan-receiving-material/store`,
      {
        details: scanReceiving,
        kanbans: _.groupBy(dataTemporaryKanban, "id_qad"),
        dn_no: data.dn_number,
        tanggal_scan:
          valueDate === null
            ? moment(new Date()).format("YYYY-MM-DD")
            : valueDate,
        isComplete,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        if (!isComplete) {
          message.success("Receiving saved succesfully!");
          return props.history.push("/gr-material");
        } else {
          const hasErrorStatus = (obj) => {
            return obj.some(item => item.is_error === false || item.is_error === false)
          }
          Modal[hasErrorStatus ? "error" : "success"]({
            width: 840,
            title: hasErrorStatus ? (
              <span style={{ color: "#000" }}>QAD Feedback</span>
            ) : (
              <span style={{ color: "#000" }}>QAD Feedback</span>
            ),
            onOk: () => {
              return props.history.push("/gr-material");
            },
            content: (
              <div>
                <Table
                 scroll={{ x: 2000 }}
                  columns={[
                    {
                      title: "Component Item",
                      dataIndex: "component_item",
                      key: "component_item",
                    },
                    {
                      title: "Get GR Status",
                      dataIndex: "get_gr_status",
                      key: "get_gr_status",
                      render: (status) => {
                        return status ? (
                          <span style={{ color: "green" }}>SUCCESS</span>
                        ) : (
                          <span style={{ color: "red" }}>ERROR</span>
                        );
                      },
                    },
                    {
                      title: "Reason",
                      dataIndex: "get_gr_reason",
                      key: "get_gr_reason",
                    },
                    {
                      title: "Send GR Status",
                      dataIndex: "send_gr_status",
                      key: "send_gr_status",
                      render: (status) => {
                        return status ? (
                          <span style={{ color: "green" }}>SUCCESS</span>
                        ) : (
                          <span style={{ color: "red" }}>ERROR</span>
                        );
                      },
                    },
                    {
                      title: "Reason",
                      dataIndex: "send_gr_reason",
                      key: "send_gr_reason",
                    },
                  ]}
                  pagination={false}
                  dataSource={res.data.response}
                />
              </div>
            ),
          });
        }
        // message.success(res.data.message);
        // props.history.push("/gr-material");
        // if (data.dn_number) {
        //   message.success(res.data.message);
        // }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan Receiving"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical">
                <Row>
                  <Fragment>
                    <Row align="bottom" gutter={10} style={{ width: "100%" }}>
                      <Col xs={20} md={20} lg={20}>
                        <Form.Item label="DN Number :">
                          <Input
                            onPressEnter={(e) => getData(e, "dn_number")}
                            type="primary"
                            prefix={<BarcodeOutlined />}
                            onChange={(e) =>
                              handleChange("dn_number", e.target.value)
                            }
                            value={data.dn_number}
                            ref={dn_numberRef}
                            disabled={isDisabled.dn_number}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        {data.dn_number !== "" ? (
                          <Form.Item>
                            <Button
                              type="danger"
                              onClick={handleReset}
                              loading={loading}
                              className="mr-button"
                            >
                              Delete
                            </Button>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item label=" Scan Kanban :">
                        <Input
                          type="primary"
                          onChange={(e) =>
                            handleChange("barcode_kanban", e.target.value)
                          }
                          value={data.barcode_kanban}
                          ref={barcode_kanbanRef}
                          disabled={isDisabled.barcode_kanban}
                          onPressEnter={(e) => getKanban(e, "barcode_kanban")}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24} lg={24}>
                      <Form.Item label=" Tanggal Scan :">
                        <DatePicker
                          onChange={onChangeDate}
                          type="primary"
                          defaultValue={moment()}
                          disabled={isDisabled.tanggal_scan}
                        />
                        <Button
                          style={{ marginLeft: 10 }}
                          type="primary"
                          loading={loading}
                          // htmlType="submit"
                          className="mr-button"
                          onClick={handleEditTanggal}
                        >
                          EDIT
                        </Button>
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Col xs={24}>
                  <Table
                    // components={{
                    //   body: {
                    //     cell: EditableCell,
                    //   },
                    // }}
                    scroll={{ x: 1400 }}
                    columns={columns}
                    dataSource={scanReceiving}
                    rowKey={(data) => data.component_item}
                    onChange={handleTableChange}
                    pagination={false}
                    loading={loading}
                  />
                </Col>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        // htmlType="submit"
                        className="mr-button"
                        onClick={onSubmit}
                        disabled={
                          dataTemporaryKanban.length === 0
                          //   ||
                          //   dataTemporaryKanban.reduce(
                          //     (total, num) => total + parseInt(num.qty_kanban),
                          //     0
                          //   ) !==
                          //     scanReceiving.reduce(
                          //       (total, num) =>
                          //         total + parseInt(num.total_qty_kanban),
                          //       0
                          //     )
                        }
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default WScanReceiving;
