import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import {
  Card,
  Layout,
  Row,
  Select,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  DatePicker,
  Modal,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./MasterWeeklyForecast.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function MasterWeeklyForecast() {
  const [itemOption, setitemOption] = useState([
    { label: "TEST 1", value: "1" },
  ]);
  const [keyword, setKeyword] = useState("");
  const [yearQad, setYearQad] = useState("");
  const [yearSearch, setYearSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalQAD, setisModalQADVisible] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  const [error, setError] = useState(null);
  const yearFormat = "YYYY";
  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort, yearSearch);
  }, [keyword, page, sort, yearSearch]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);
  const onChangeYearQad = (value) => {
    const yr = new Date(value).getFullYear();
    setYearQad(yr);
  };
  const onChangeYearSearch = (value) => {
    const yr = new Date(value).getFullYear();
    setYearSearch(yr);
  };
  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/weekly-forecast`, {
      params: {
        keyword,
        columns: ["year", "item_number"],
        year: yearSearch,
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGetQAD = () => {
    setLoading(true);
    Axios.get(`${url}/soap/forecast`, {
      params: {
        year: yearQad,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });
        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleGetQADModal = () => {
    setisModalQADVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
    setisModalQADVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/master-product-structure/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(
        res.data,
        "Template Master Master Weekly Forecast" + ".xlsx"
      );
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/master-product-structure/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportProductStructure = () => {
    Axios.get(`${url}/master-product-structure/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Weekly Forecast" + ".xlsx");
    });
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      sorter: true,
    },
    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "Week 1",
      dataIndex: "w1",
      sorter: true,
    },
    {
      title: "Week 2",
      dataIndex: "w2",
      sorter: true,
    },
    {
      title: "Week 3",
      dataIndex: "w3",
      sorter: true,
    },
    {
      title: "Week 4",
      dataIndex: "w4",
      sorter: true,
    },
    {
      title: "Week 5",
      dataIndex: "w5",
      sorter: true,
    },
    {
      title: "Week 6",
      dataIndex: "w6",
      sorter: true,
    },
    {
      title: "Week 7",
      dataIndex: "w7",
      sorter: true,
    },
    {
      title: "Week 8",
      dataIndex: "w8",
      sorter: true,
    },
    {
      title: "Week 9",
      dataIndex: "w9",
      sorter: true,
    },
    {
      title: "Week 10",
      dataIndex: "w10",
      sorter: true,
    },
    {
      title: "Week 11",
      dataIndex: "w11",
      sorter: true,
    },
    {
      title: "Week 12",
      dataIndex: "w12",
      sorter: true,
    },
    {
      title: "Week 13",
      dataIndex: "w13",
      sorter: true,
    },
    {
      title: "Week 14",
      dataIndex: "w14",
      sorter: true,
    },
    {
      title: "Week 15",
      dataIndex: "w15",
      sorter: true,
    },
    {
      title: "Week 16",
      dataIndex: "w16",
      sorter: true,
    },
    {
      title: "Week 17",
      dataIndex: "w17",
      sorter: true,
    },
    {
      title: "Week 18",
      dataIndex: "w18",
      sorter: true,
    },
    {
      title: "Week 19",
      dataIndex: "w19",
      sorter: true,
    },
    {
      title: "Week 20",
      dataIndex: "w20",
      sorter: true,
    },
    {
      title: "Week 21",
      dataIndex: "w21",
      sorter: true,
    },
    {
      title: "Week 22",
      dataIndex: "w22",
      sorter: true,
    },
    {
      title: "Week 23",
      dataIndex: "w23",
      sorter: true,
    },
    {
      title: "Week 24",
      dataIndex: "w24",
      sorter: true,
    },
    {
      title: "Week 25",
      dataIndex: "w25",
      sorter: true,
    },
    {
      title: "Week 26",
      dataIndex: "w26",
      sorter: true,
    },
    {
      title: "Week 27",
      dataIndex: "w27",
      sorter: true,
    },
    {
      title: "Week 28",
      dataIndex: "w28",
      sorter: true,
    },
    {
      title: "Week 29",
      dataIndex: "w29",
      sorter: true,
    },
    {
      title: "Week 30",
      dataIndex: "w30",
      sorter: true,
    },
    {
      title: "Week 31",
      dataIndex: "w31",
      sorter: true,
    },
    {
      title: "Week 32",
      dataIndex: "w32",
      sorter: true,
    },
    {
      title: "Week 33",
      dataIndex: "w33",
      sorter: true,
    },
    {
      title: "Week 34",
      dataIndex: "w34",
      sorter: true,
    },
    {
      title: "Week 35",
      dataIndex: "w35",
      sorter: true,
    },
    {
      title: "Week 36",
      dataIndex: "w36",
      sorter: true,
    },
    {
      title: "Week 37",
      dataIndex: "w37",
      sorter: true,
    },
    {
      title: "Week 38",
      dataIndex: "w38",
      sorter: true,
    },
    {
      title: "Week 39",
      dataIndex: "w39",
      sorter: true,
    },
    {
      title: "Week 40",
      dataIndex: "w40",
      sorter: true,
    },
    {
      title: "Week 41",
      dataIndex: "w41",
      sorter: true,
    },
    {
      title: "Week 42",
      dataIndex: "w42",
      sorter: true,
    },
    {
      title: "Week 43",
      dataIndex: "w43",
      sorter: true,
    },
    {
      title: "Week 44",
      dataIndex: "w44",
      sorter: true,
    },
    {
      title: "Week 45",
      dataIndex: "w45",
      sorter: true,
    },
    {
      title: "Week 46",
      dataIndex: "w46",
      sorter: true,
    },
    {
      title: "Week 47",
      dataIndex: "w47",
      sorter: true,
    },
    {
      title: "Week 48",
      dataIndex: "w48",
      sorter: true,
    },
    {
      title: "Week 49",
      dataIndex: "w49",
      sorter: true,
    },
    {
      title: "Week 50",
      dataIndex: "w50",
      sorter: true,
    },
    {
      title: "Week 51",
      dataIndex: "w51",
      sorter: true,
    },
    {
      title: "Week 52",
      dataIndex: "w52",
      sorter: true,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Sync Forecast QAD"
        visible={isModalQAD}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <DatePicker
                  disabledDate={disabledDate}
                  placeholder="Select year"
                  onChange={(value) => onChangeYearQad(value)}
                  style={{ width: "100%" }}
                  picker="year"
                  format={yearFormat}
                />
              </Fragment>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button
                loading={loading}
                onClick={handleGetQAD}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Sync QAD
              </Button>
              {/* <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button> */}
            </div>
          </div>
        </Card>
      </Modal>

      <Modal
        title="Import Master Weekly Forecast"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Weekly Forecast"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={12}>
                  <Space>
                    <Link>
                      <Button onClick={handleGetQADModal} type="primary">
                        <PlusOutlined />
                        Sync QAD
                      </Button>
                    </Link>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Row style={{ width: "100%" }}>
                    <Col xs={24} md={12} lg={12}>
                      <DatePicker
                        onChange={(value) => onChangeYearSearch(value)}
                        style={{ width: "100%" }}
                        disabledDate={disabledDate}
                        picker="year"
                      />
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Search
                        placeholder="ex: CVD7800002"
                        onSearch={(value) => handleSearch(value)}
                      />
                      {/* <Button type="primary">Submit</Button> */}
                    </Col>
                  </Row>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1400 }}
                    columns={columns}
                    dataSource={products.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: products.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MasterWeeklyForecast;
