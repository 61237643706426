import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function PDUploadDNMaterial(props) {
  const [keyword, setKeyword] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  // Delete All
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);
  const handleDeleteAll = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDeleteAll();
      },
    });
  };
  const [deleteAll, setDeleteAll] = useState([]);

  const confirmDeleteAll = () => {
    setLoading(true);
    Axios.post(
      `${url}/delivery-note-manifest/delete-all`,
      {
        remove: selectedRowKeysData.map((x) => {
          return {
            _id: x,
          };
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/delivery-note-manifest`, {
      params: {
        keyword,
        columns: [
          "delivery_note_no",
          "po_number",
          "delivery_note_date",
          "plan_receive_max_time",
          "delivery_note_cycle",
          "part_number",
          "item_number",
          "part_name",
          "lane",
          "job_number",
          "qty_kanban",
          "order_kanban",
          "order_pcs",
          "remarks",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/delivery-note-manifest/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/dn-manifest-template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Upload DN Manifest" + ".xlsx");
    });
  };

  const exportUserExternal = () => {
    Axios.get(`${url}/upload-dn-material/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Upload DN Manifest" + ".xlsx");
    });
  };

  const editDN = (id) => {
    console.log(id);
    const edit = users.data.find((data) => data._id === id);
    // setSelectedId(id);

    console.log(edit, data, "cek thr bos");
    props.history.push(`/upload-dn-material/edit/${id}`);
   
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/delivery-note-manifest/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   align: "center",
    //   key: "status",
    //   render: (status) => {
    //     return (
    //       <img
    //         alt="icon"
    //         width="20"
    //         src={
    //           // status === 1
    //           //   ? require("../../assets/img/checked.png")
    //           require("../../assets/img/danger.png")
    //         }
    //       />
    //     );
    //   },
    // },
    {
      title: "DN No",
      dataIndex: "delivery_note_no",
      sorter: true,
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
      sorter: true,
    },
    {
      title: "Shipped To",
      dataIndex: "shipment_name",
      sorter: true,
    },
    {
      title: "Delivery Note Date",
      dataIndex: "delivery_note_date",
      sorter: true,
    },
    {
      title: "Plan Receive Max Time",
      dataIndex: "plan_receive_max_time",
      sorter: true,
    },
    {
      title: "Delivery Note Cycle",
      dataIndex: "delivery_note_cycle",
      sorter: true,
    },
    {
      title: "Part No",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "Job Number",
      dataIndex: "job_number",
      sorter: true,
    },
    {
      title: "Lane",
      dataIndex: "lane",
      sorter: true,
    },
    {
      title: "Qty Perkanban",
      dataIndex: "qty_kanban",
      sorter: true,
    },
    {
      title: "Order (Kanban)",
      dataIndex: "order_kanban",
      sorter: true,
    },
    {
      title: "Order (Pcs)",
      dataIndex: "order_pcs",
      sorter: true,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: true,
    },
    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },

    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    //  disabled={kanbanDetails.length > 0}
                    onClick={() => editDN(id)}
                  >
                    <EditOutlined /> Edit
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Hapus
                    </span>
                  </Menu.Item>
                  {/* )} */}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Import Upload DN Manifest"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Upload DN Manifest"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "upload-dn-material/import"
                      ) && (
                        <Button type="primary" onClick={showModal}>
                          <ImportOutlined />
                          Import
                        </Button>
                      )}

                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "upload-dn-material/template"
                      ) && (
                        <Button type="primary" onClick={downloadTemplate}>
                          <DownloadOutlined />
                          Download Template
                        </Button>
                      )}
                       <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={handleDeleteAll}
                  >
                    <DeleteOutlined />
                    Hapus Data
                  </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search By DN No..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2000 }}
                    columns={columns}
                    dataSource={users.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);
                      },
                    }}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: users.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PDUploadDNMaterial;
