import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Menu,
  Dropdown,
  Form,
  TimePicker,
  DatePicker,
  Badge,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  EditOutlined,
  MoreOutlined,
  ImportOutlined,
  DownloadOutlined,
  PrinterOutlined,
  QrcodeOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import "./SPDNMaterial.less";
import fileDownload from "js-file-download";
import TextArea from "antd/lib/input/TextArea";
const { Search } = Input;
const { Content } = Layout;

const { RangePicker } = DatePicker;

function SPDNMaterial(props) {
  const [openDetail, setOpenDetail] = useState(false);
  const [openEditDetail, setopenEditDetail] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [historyDetail, setHistoryDetail] = useState([]);
  const [confirmDetail, setconfirmDetail] = useState([]);
  const [keywordDetails, setKeywordDetails] = useState("");
  const [dns, setDNmaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [formConfirmEdit, setFormConfirmEdit] = useState({});
  const [data, setData] = useState({
    qty_delivery: "",
    keterangan: "",
    lot_prod: "",
    surat_jalan: "",
  });

  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const layout = {
    labelCol: {
      lg: 8,
      md: 10,
    },
    wrapperCol: {
      lg: 8,
      md: 14,
    },
  };
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [dnmDetailsTotal, setDNMDetailsTotal] = useState(0);

  const [dnDetails, setDnDetails] = useState([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [generate, setGenerate] = useState({
    purchase_order: "",
  });
  const columnsHistoryDetails = [
    {
      title: "Item Number",
      dataIndex: "component_item",
      key: "component_item",
      sorter: true,
      width: 100,
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      sorter: true,
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "part_name",
      key: "part_name",
      sorter: true,
      width: 200,
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      key: "part_number",
      sorter: true,
      width: 200,
    },
    {
      title: "Standar Pack",
      dataIndex: "std_pack",
      key: "std_pack",
      sorter: true,
      width: 100,
    },
    {
      title: "Kanban Order",
      dataIndex: "total_order",
      key: "total_order",
      sorter: true,
      width: 100,
    },
    {
      title: "Total Order",
      dataIndex: "total_qty_kanban",
      key: "total_qty_kanban",
      sorter: true,
      width: 100,
    },
    {
      title: "Qty Delivery",
      dataIndex: "qty_delivery",
      key: "qty_delivery",
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: "Total Minus",
      dataIndex: "total_minus",
      key: "total_minus",
      sorter: true,
      width: 100,
    },
    {
      title: "Tanggal Pelunasan",
      dataIndex: "tgl_pelunasan",
      key: "tgl_pelunasan",
      render: (tgl_pelunasan) =>
        tgl_pelunasan !== null
          ? moment(tgl_pelunasan).format("YYYY-MM-DD")
          : "",
      editable: true,
      sorter: true,
      width: 100,
    },
    {
      title: "Jam Pelunasan",
      dataIndex: "jam_pelunasan",
      key: "jam_pelunasan",
      render: (jam_pelunasan) =>
        jam_pelunasan !== null
          ? moment("2023-08-08 " + jam_pelunasan).format("HH:mm:ss")
          : "",
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: "Alasan Pending",
      dataIndex: "keterangan",
      key: "keterangan",
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: "Lot Prod",
      dataIndex: "lot_prod",
      key: "lot_prod",
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: "Surat Jalan",
      dataIndex: "surat_jalan",
      key: "surat_jalan",
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: "Status Order",
      dataIndex: "status_order",
      key: "status_order",
      render: (row) => (row.total_minus === 0 ? "Complete" : "Not Complete"),
      sorter: true,
      width: 100,
    },
    {
      title: "Status Confirm",
      dataIndex: "status_confirm",
      key: "status_confirm",
      render: (status_confirm) =>
        status_confirm == null
          ? status_confirm
          : "Confirm ke " + status_confirm,
      sorter: true,
      width: 100,
    },
    {
      title: "Confirm By",
      dataIndex: "confirm_by",
      key: "confirm_by",
      sorter: true,
      width: 100,
    }, 
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: true,
      fixed: "right",
      width: 100,
      render: (id, data) => {
        // console.log('LOGS',data)
        return (
          <Fragment>
            
                
                 
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "dn-material-histori/edit"
                    ) && (
                      <Button
                      onClick={() => openDetailConfirm(data._id)}
                      type="primary"
                      ghost
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <EditOutlined/>
                      Edit
                    </Button>
                    )}
               
              
       
          </Fragment>
        );
      },
    },
  ];
  //jam_pelunasan
  const [valueTime, setValueTime] = useState(null);
  const onChangeTime = (time, timestring) => {
    setValueTime(timestring);
  };

  //tanggal Pelunasan
  const [valueDate, setValueDate] = useState(null);
  const onChangeDate = (date, dateString) => {
    setValueDate(dateString);
    // console.log(timestring);
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;
  
  const edit = (record) => {
    console.log(record);
    form.setFieldsValue({
      tgl_pelunasan: record.tgl_pelunasan ? moment(record.tgl_pelunasan) : "",
      jam_pelunasan: "",
      // total_minus: "",
      qty_delivery: "",
      keterangan: "",
      lot_prod: "",
      surat_jalan: "",
      ...record,
    });
    setEditingKey(record._id);

    console.log(record);
  };

  const format = "HH:mm";

  const cancel = () => {
    setEditingKey("");
  };

  useEffect(() => {
    getData(keyword, page, orderDate, sort);
    if (selectedId) {
      getDataDetailDN();
    }
  }, [keyword, orderDate, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const save = async (data) => {
    const row = await form.validateFields();
    console.log('cek',data);
    let total_minus;
    total_minus = parseInt(row.qty_delivery) - parseInt(data.total_qty_kanban) > 0
                ? 0
                : parseInt(row.qty_delivery) - parseInt(data.total_qty_kanban)
           console.log(total_minus);
    

           if (row.qty_delivery > Math.abs(total_minus)) {
            if (
              (row.lot_prod == "" || row.lot_prod === null)
            ) {
              Modal.error({
                title: "Failed!",
                content: "Lot Prod Harus di Isi",
              });
              return false;
            }
          }
    
    console.log(row);
    if (row.qty_delivery < data.total_qty_kanban) {
      if (
        (valueDate == "" || valueDate === null) && (valueTime == "" || valueTime === null)
      ) {
        Modal.error({
          title: "Failed!",
          content: "Tanggal, Jam Pelunasan, dan Alasan Pending Wajib Diisi.",
        });
        return false;
      }
    }
    if (row.qty_delivery < 0) {
      Modal.error({
        title: "Failed!",
        content: "Qty Delivery tidak boleh  kurang dari 0.",
      });
      return false;
    }
   
    if (row.qty_delivery > data.total_qty_kanban) {
      Modal.error({
        title: "Failed!",
        content: "Qty Delivery tidak boleh lebih dari total order.s",
      });
    } else {
      if (parseInt(row.qty_delivery) % parseInt(data.std_pack) === 0) {
        // console.log("ini data", row, data);
        setLoading(true);

        Axios.post(
          `${url}/dn-material-histori/confirm`,
          {
            id: data._id,
            qty_delivery: parseInt(row.qty_delivery),
            model: data.model,
            lot_prod: row.lot_prod,
            keterangan: row.keterangan,
            surat_jalan: row.surat_jalan,
            dn_material_id: row.material_histori_id,
            scan_produksi_id: row.scan_produksi_id,
            component_item: row.component_item,
            tgl_pelunasan: valueDate,
            jam_pelunasan: valueTime,
            total_minus:
              parseInt(row.qty_delivery) - parseInt(data.total_qty_kanban) > 0
                ? 0
                : parseInt(row.qty_delivery) - parseInt(data.total_qty_kanban),
            status_order:
              parseInt(row.qty_delivery) >= parseInt(data.total_qty_kanban)
                ? true
                : false,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
          .then((res) => {
            
            message.success(res.data.message);
            getDataDetailDN(selectedId);
            // setDNmaterials([]);
            // setRowDetails([]);
            // getData();//
            // props.history.push("/dn-material");
          })
          .catch((err) => {
            if (err.response) {
              Modal.error({
                title: "Failed!",
                content: err.response.data.message,
              });
              // message.error(err.response.data.message);
              if (err.response.status === 422) {
                setError(err.response.data.errors);
              }
              if (err.response.status === 401) {
                localStorage.removeItem("token");
              }
            } else {
              message.error("Backend not yet Started");
            }
            setLoading(false);
          });
        setEditingKey("");
        setLoading(false);
      } else {
        Modal.error({
          title: "Failed!",
          content:
            "Qty Delivery harus kelipatan dari jumlah standar pack (" +
            data.std_pack +
            ").",
        });
        // message.error();
      }
    }
  };

  const handleDatepicker = (value) => {
    if (value != null) {
      var date = moment(value).format("YYYY-MM-DD");
      // console.log(value);
      // setOrderDate(date);
      getDataOrderDt(date);
    }
  };
  const handleSearch = (value) => {
    setKeyword(value);
  };
  const handleChange = (name, value) => {
    setError(null);
    console.log(value)
    setconfirmDetail({
      ...confirmDetail,
      [name]: value,
    });
  };
  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/dn-material-histori`, {
      params: {
        keyword,
        order_date: orderDate,
        columns: [
          "dn_no",
          "tanggal_terbit",
          "status",
          "total",
          "purchase_order",
          "group",
          "line",
          "supplier_name",
          "supplier_code",
          "qty_delivery",
          "keterangan",
          "lot_prod",
          "surat_jalan",
          "tgl_order",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDNmaterials(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getDataOrderDt = (date) => {
    setLoading(true);
    Axios.get(`${url}/dn-material-histori`, {
      params: {
        keyword: keyword,
        order_date: date,
        columns: [
          "dn_no",
          "purchase_order",
          "purchase_order",
          "tanggal_terbit",
          "status",
          "total",
          "purchase_order",
          "group",
          "line",
          "supplier_name",
          "supplier_code",
          "qty_delivery",
          "keterangan",
          "lot_prod",
          "surat_jalan",
          "tgl_order",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDNmaterials(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    // console.log(index)
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getDataDetailDN(data2[0]);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    key,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          inputType === "date" ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: `Please Input Date!`,
              //   },
              // ]}
            >
              {/* <Input type="date" /> */}
              <DatePicker onChange={onChangeDate} />
            </Form.Item>
          ) : inputType === "time" ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: `Please Input Date!`,
              //   },
              // ]}
            >
              {/* <Input type="date" /> */}
              <TimePicker onChange={onChangeTime} />
            </Form.Item>
          ) : (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: `Harus Di Isi`,
              //   },
              // ]}
            >
              <Input type="input" />
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const getDataDetailDN = (dn_id = selectedId) => {
    setLoadingDetail(true);
    setDnDetails([])
    Axios.get(`${url}/dn-material-histori/details/${dn_id}`, {
      params: {
        keyword: keywordDetails,
        // columns: ["_id", "quantity_kanban"],
        perpage: 100,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDnDetails(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const getDNMaterialDetails = (item, index) => {
    const columnsDetails = [
      {
        title: "",
        render: (data) => {
          return (
            <span>
              <Button
                onClick={() => openModalDetail(data._id)}
                type="primary"
                style={{
                  marginRight: 8,
                }}
              >
                Detail
              </Button>
            </span>
          );
        },
        sorter: true,
        width: 100,
      },
      {
        title: "Item Number",
        dataIndex: "component_item",
        key: "component_item",
        sorter: true,
        width: 100,
      },
      {
        title: "Project",
        dataIndex: "project",
        key: "project",
        sorter: true,
        width: 100,
      },
      {
        title: "Description",
        dataIndex: "part_name",
        key: "part_name",
        sorter: true,
        width: 200,
      },
      {
        title: "Part Number",
        dataIndex: "part_number",
        key: "part_number",
        sorter: true,
        width: 200,
      },
      {
        title: "Standar Pack",
        dataIndex: "std_pack",
        key: "std_pack",
        sorter: true,
        width: 100,
      },
      {
        title: "Kanban Order",
        dataIndex: "total_order",
        key: "total_order",
        sorter: true,
        width: 100,
      },
      {
        title: "Total Order",
        dataIndex: "total_qty_kanban",
        key: "total_qty_kanban",
        sorter: true,
        width: 100,
      },
      {
        title: "Qty Delivery",
        dataIndex: "qty_delivery",
        key: "qty_delivery",
        sorter: true,
        editable: true,
        width: 100,
      },
      {
        title: "Total Minus",
        dataIndex: "total_minus",
        key: "total_minus",
        sorter: true,
        width: 100,
      },
      {
        title: "Tanggal Delivery",
        dataIndex: "tgl_delivery",
        sorter: true,
        width: 100,
      },
      {
        title: "Tanggal Pelunasan",
        dataIndex: "tgl_pelunasan",
        key: "tgl_pelunasan",
        render: (tgl_pelunasan) =>
          tgl_pelunasan !== null
            ? moment(tgl_pelunasan).format("YYYY-MM-DD")
            : "",
        editable: true,
        sorter: true,
        width: 100,
      },

      {
        title: "Jam Pelunasan",
        dataIndex: "jam_pelunasan",
        key: "jam_pelunasan",
        render: (jam_pelunasan) =>
          jam_pelunasan !== null
            ? moment("2023-08-08 " + jam_pelunasan).format("HH:mm:ss")
            : "",
        sorter: true,
        editable: true,
        width: 100,
      },
      {
        title: "Alasan Pending",
        sorter: true,
        dataIndex: "keterangan",
        key: "keterangan",
       
        editable: true,
        width: 100,
      },
      {
        title: "Lot Prod",
        dataIndex: "lot_prod",
        key: "lot_prod",
        sorter: true,
        editable: true,
        width: 100,
      },

      {
        title: "Surat Jalan",
        dataIndex: "surat_jalan",
        key: "surat_jalan",
        sorter: true,
        editable: true,
        width: 100,
      },
      {
        title: "Status Order",
        dataIndex: "status_order",
        key: "status_order",
        render: (status) =>
          status == null ? "" : status == true ? "Complete" : "Not Complete",
        sorter: true,
        width: 100,
      },
      {
        title: "Status Confirm",
        dataIndex: "status_confirm",
        key: "status_confirm",
        render: (status_confirm) =>
          status_confirm == null
            ? status_confirm
            : "Confirm ke " + status_confirm,
        sorter: true,
        width: 100,
      },
      {
        title: "Status Print",
        dataIndex: "print_records",
        sorter: true,
        width: 100,
        render: (pr) => {
          if (pr?.length > 1) {
            return (
              <div>
                <Badge
                  count={"RE PRINT "}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              </div>
            );
          } else if (pr?.length == 1) {
            return (
              <div>
                <Badge
                  count={"PRINT"}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              </div>
            );
          } else {
            return (
              <div>
                <Badge
                  count={"NO PRINT"}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              </div>
            );
          }
        },
      },
      {
        title: "",
        sorter: true,
        width: 100,
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Button
                type="primary"
                style={{
                  marginRight: 8,
                }}
                onClick={() => save(record)}
              >
                Save
              </Button>
              <Button onClick={cancel} type="danger">
                Cancel
              </Button>
            </span>
          ) : record.status_confirm == null ? (
            <Button
              style={{ width: 125 }}
              type="primary"
              onClick={() => edit(record)}
            >
              Confirm
            </Button>
          ) : (
            <Fragment></Fragment>
          );
        },
      },

      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: true,
        fixed: "right",
        width: 100,
        render: (id, data) => {
          // console.log('LOGS',data)
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    {/* {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/edit"
                      ) && (
                        <Menu.Item key="1">
                          <Link to={`/dn-material/edit/${id}`}>
                            <EditOutlined /> Ubah
                          </Link>
                        </Menu.Item>
                      )} */}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material-histori/qrcode"
                      ) && (
                        <Menu.Item key="1">
                          <span onClick={() => printQr(id)}>
                            <QrcodeOutlined /> Print QR
                          </span>
                        </Menu.Item>
                      )}
                      

                    {(buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/confirm"
                      ) &&
                      data.status_order == null) ||
                      (data.status_order == false && (
                        <Menu.Item key="1">
                          <Link to={`/dn-material/confirm/${id}`}>
                            <CheckCircleOutlined /> Confirm Ulang
                          </Link>
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    const mergedColumns = columnsDetails.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "qty_delivery" ? "text" : "text",
          inputType:
            col.dataIndex === "tgl_pelunasan"
              ? "date"
              : col.dataIndex === "jam_pelunasan"
              ? "time"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col></Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                scroll={{ x: 1500 }}
                columns={mergedColumns}
                rowKey={(data) => data._id}
                loading={loadingDetail}
                // dataSource={dns.data[index].details}
                dataSource={dnDetails}
                size="small"
                pagination={{
                  pageSize: 100,
                 
                }}
                onChange={handleChangeDetailsTable}
              />
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const closeDetail = () => {
    setOpenDetail(false);
  };
  const closeDetailEdit = () => {
    setopenEditDetail(false);
  };
  const onConfirmForm = () => {
    console.log('payload Detail',confirmDetail);
      setLoading(true);
      if (parseInt(confirmDetail.qty_delivery) % parseInt(confirmDetail.std_pack) === 0) {

        let fd = new FormData();
        fd.set("id", confirmDetail._id);
        fd.set("qty_delivery", confirmDetail.qty_delivery);
        fd.set("lot_prod", confirmDetail.lot_prod);
        fd.set("keterangan", confirmDetail.keterangan);
        fd.set("project", confirmDetail.project);
        fd.set("std_pack", confirmDetail.std_pack);
        fd.set("surat_jalan",confirmDetail.surat_jalan);

          Axios.post(`${url}/confirm-delivery-material-manifest/update-detail`, fd,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setopenEditDetail(false);
              console.log(res.data.data)
              refreshDetail(res.data.data.material_histori_detail_id)
              getDataDetailDN()
            })
            .catch((err) => {
              if (err.response) {
                message.error(err.response.data.message);
              } else {
                message.error("Backend not yet Started");
              }
            })
            .finally(() => {
              // setLoadingDetail(false);
              setLoading(false);
            });
          } else {
            setLoading(false);
            Modal.error({
              title: "Failed!",
              content:
                "Qty Delivery harus kelipatan dari jumlah standar pack (" +
                confirmDetail.std_pack +
                ").",
            });
        // message.error();
      }
  }
  const openDetailConfirm = (id) => {
    setLoading(true);
    Axios.get(`${url}/confirm-delivery-material-manifest/edit-detail`, {
      params: {
        id: id,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setconfirmDetail(res.data.data);
        setopenEditDetail(true);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        // setLoadingDetail(false);
        setLoading(false);
      });
   
  }
  const refreshDetail = (id) => {
    setLoading(true);
    Axios.get(`${url}/dn-material-histori-details`, {
      params: {
        keyword: keywordDetails,
        id: id,
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setHistoryDetail(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        // setLoadingDetail(false);
        setLoading(false);
      });
  }
  const openModalDetail = (id) => {
    setLoading(true);
    Axios.get(`${url}/dn-material-histori-details`, {
      params: {
        keyword: keywordDetails,
        id: id,
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setHistoryDetail(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        // setLoadingDetail(false);
        setLoading(false);
      });
    setOpenDetail(true);
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/dn-material/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template DN Material" + ".xlsx");
    });
  };

  const exportDnMaterial = (id) => {
    if (selectedRowsData.length === 0) {
      message.error("Ceklis Terlebih Dahulu");
    } else {
      Axios.post(
        `${url}/dn-material-histori/print-all`,
        {
          print_all: selectedRowsData,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      ).then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      });
    }
  };

  const exportAll = (id) => {
    setLoadingPrint(true);
    if (selectedRowsData.length === 0) {
      message.error("Mohon ceklis DN terlebih dahulu");
      setLoadingPrint(false);
    } else {
     Axios.post(
        `${url}/dn-material-histori/print-all-with-qr`,
        {
          print_all: selectedRowsData,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          // var blob = new Blob([res.data], { type: "application/pdf" });
          // var blobURL = URL.createObjectURL(blob);
          setLoadingPrint(false);
          window.open(res.data.filename);
        })
        .catch((err) => {
          if (err.response) {
            // console.log(err.response.data);
            message.error(err.response.data.message);
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          } else {
            message.error("Backend not yet Started");
          }
          setLoadingPrint(false);
        });
    }
  };

  const printPdf = (id) => {
    Axios.get(`${url}/dn-material-histori/print/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const printQr = (id) => {
    setLoading(true);
    Axios.get(`${url}/dn-material-histori/print/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setLoading(false);
        // var blob = new Blob([res.data], { type: "application/pdf" });
        // var blobURL = URL.createObjectURL(blob);
        window.open(res.data.filename);
      })
      .catch((err) => {
        if (err.response) {
          // console.log(err.response.data);
          message.error(
            "Kanban untuk delivery note ini belum dibuat / tidak ditemukan."
          );
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "DN NO",
      dataIndex: "dn_no",
      sorter: true,
    },
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
      sorter: true,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: "Alamat",
      dataIndex: "address_1",
      sorter: true,
    },
    {
      title: "Group",
      dataIndex: "group",
      sorter: true,
    },
    {
      title: "Area",
      dataIndex: "area",
      sorter: true,
    },
    // {
    //   title: "Tanggal Delivery",
    //   dataIndex: "tanggal_deliveri",
    //   sorter: true,
    //   render: (tanggal_delivery) =>
    //     moment(tanggal_delivery).format("DD MMMM YYYY"),
    // },
    {
      title: "ETA SDI",
      dataIndex: "eta_sdi",
      sorter: true,
    },
    {
      title: "Unloading Finished",
      dataIndex: "unloading_finished",
      sorter: true,
    },
    {
      title: "Cycle Issue",
      dataIndex: "cycle_issue",
      sorter: true,
    },
    {
      title: "Tanggal Order",
      dataIndex: "tgl_generate",
      render: (tgl_generate) => moment(tgl_generate).format("YYYY-MM-DD"),
      sorter: true,
    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
    },
    {
      title: "Status Print",
      dataIndex: "print_records",
      sorter: true,
      render: (pr) => {
        if (pr?.length > 1) {
          return (
            <div>
              <Badge
                count={"RE PRINT "}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else if (pr?.length == 1) {
          return (
            <div>
              <Badge
                count={"PRINT"}
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Badge
                count={"NO PRINT"}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Generate From",
      sorter: true,
      render: (data) => {
        if (data.is_production_request == true) {
          return (
            <div>
              <Badge
                count={"PROD REQUEST"}
                style={{
                  backgroundColor: "#ffc107",
                  color: "black",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else if (data.is_auto_generate == true) {
          return (
            <div>
              <Badge
                count={"AUTO CUTOFF"}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Badge
                count={"MANUAL"}
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </div>
          );
        }
      },
    },
  ];
  const marginRow = {
    marginBottom: "10px",
  };
  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
       <Modal
        width={1000}
        visible={openEditDetail}
        title="Edit Confirm"
        onOk={closeDetailEdit}
        onCancel={closeDetailEdit}
        footer={[
          <Button key="back" onClick={closeDetailEdit}>
            Cancel
          </Button>,
        ]}
      >
       <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout}  name="control-hooks"  onFinish={onConfirmForm}>
              <Form.Item
                  label="Item Number"
                  validateStatus={error && error.qty_delivery ? "error" : false}
                  help={
                    error && error.qty_delivery ? error.qty_delivery[0] : false
                  }
                >
                  <b>{confirmDetail.component_item}</b>
                </Form.Item>
              
              <Form.Item
                  label="Confirm ke"
                  validateStatus={error && error.qty_delivery ? "error" : false}
                  help={
                    error && error.qty_delivery ? error.qty_delivery[0] : false
                  }
                >
                  <b>{confirmDetail.status_confirm}</b>
                </Form.Item>
              
                
                <Form.Item
                  required
                  label="Qty Delivery"
                  validateStatus={error && error.qty_delivery ? "error" : false}
                  help={
                    error && error.qty_delivery ? error.qty_delivery[0] : false
                  }
                >
                  <Input
                    value={confirmDetail.qty_delivery}
                    onChange={(e) =>
                      handleChange("qty_delivery", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Surat Jalan"
                  validateStatus={error && error.surat_jalan ? "error" : false}
                  help={
                    error && error.surat_jalan ? error.surat_jalan[0] : false
                  }
                >
                  <Input
                    value={confirmDetail.surat_jalan}
                    onChange={(e) =>
                      handleChange("surat_jalan", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                required
                  rules={[
                    {
                      required: true,
                      message: `Please Input Alasan Pending!`,
                    },
                  ]}
                  validateStatus={error && error.keterangan ? "error" : false}
                  help={error && error.keterangan ? error.keterangan[0] : false}
                  label="Alasan Pending"
                >
                  <TextArea
                    value={confirmDetail.keterangan}
                    onChange={(e) => handleChange("keterangan", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  validateStatus={error && error.lot_prod ? "error" : false}
                  help={error && error.lot_prod ? error.lot_prod[0] : false}
                  label="Lot Prod"
                >
                  <Input
                    value={confirmDetail.lot_prod}
                    onChange={(e) => handleChange("lot_prod", e.target.value)}
                  />
                </Form.Item>
                <Form.Item {...tailLayout} >
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={1000}
        visible={openDetail}
        title="Histori Confirm DN"
        onOk={closeDetail}
        onCancel={closeDetail}
        footer={[
          <Button key="back" onClick={closeDetail}>
            Cancel
          </Button>,
        ]}
      >
        <Table
          scroll={{ x: 2000 }}
          columns={columnsHistoryDetails}
          dataSource={historyDetail}
          rowKey={(data) => data._id}
          // onChange={handleTableChange}
          // pagination={{
          //   pageSize: pageSize,
          //   current: page,
          //   total: dns.total,
          //   showSizeChanger: true,
          // }}
          loading={loading}
        />
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="DN Material History"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/import"
                      ) && (
                        <Button type="primary" onClick={showModal}>
                          <ImportOutlined />
                          Import
                        </Button>
                      )}
                    {/* {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/export"
                      ) && (
                        <Button onClick={exportDnMaterial} type="primary">
                          <PrinterOutlined />
                          Print DN
                        </Button>
                      )} */}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/export"
                      ) && (
                        <Button
                          loading={loadingPrint}
                          onClick={exportAll}
                          type="primary"
                        >
                          <PrinterOutlined />
                          Print All
                        </Button>
                      )}
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/reprint"
                      ) && (
                        <Link to="/history-printed-dn">
                        <Button
                        type="primary">
                      
                        <PrinterOutlined />
                        History Print DN
                      </Button>
                      </Link>
                        
                      )}

                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "dn-material/template"
                      ) && (
                        <Button type="primary" onClick={downloadTemplate}>
                          <DownloadOutlined />
                          Download Template
                        </Button>
                      )}
                  </Space>
                </Col>

                <Col xs={24} md={12} lg={6} className="align-right">
                  <DatePicker
                    onChange={(value) => handleDatepicker(value)}
                    presetsWidth={40}
                  />
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2000 }}
                    columns={columns}
                    dataSource={dns.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);
                        // console.log("A",selectedRows);
                        // console.log("B",selectedRowKeys);
                        const purchase_order = selectedRows
                          ? selectedRows[0]
                          : null;

                        setGenerate({
                          ...generate,
                          purchase_order,
                        });
                      },
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;

                        return {
                          disabled:
                            (checkDisable &&
                              checkDisable.purchase_order !==
                                record.purchase_order) ||
                            record.status === 1,
                        };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                    // expandable={{
                    //   expandedRowRender: (item, index) =>
                    //     getDNMaterialDetails(item, index),
                    //   onExpandedRowsChange: (data) => handleChangeDetails(data),
                    //   expandedRowKeys: rowDetails,
                    // }}
                    expandable={{
                      expandedRowRender: getDNMaterialDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dns.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPDNMaterial;
