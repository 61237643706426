import React, { Fragment, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  List,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";

const { Title } = Typography;
const { Content } = Layout;

function ViewPermission(props) {
  const [data, setData] = useState({
    name: "",
    description: "",
    url: "",
    icon: "",
    parent_id: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(`${url}/permission/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          name: res.data.data.name,
          description: res.data.data.description,
          url: res.data.data.url,
          icon: res.data.data.icon,
          parent_id: res.data.data.parent_id,
          parent_name: res.data.data.parent_name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const dataview = [
    {
      title: "Name",
      description: data && data.name,
    },
    {
      title: "Description",
      description: data && data.description,
    },
    {
      title: "URL",
      description: data && data.url,
    },
  ];

  const dataview2 = [
    {
      title: "Icon",
      description: data && data.icon,
    },
    {
      title: "Parent Name",
      description: data && data.parent_name,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>View Permission</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/permission">Permission</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Permission</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                <Col sm={24} md={12}>
                  <List
                    itemLayout="horizontal"
                    dataSource={dataview}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<strong>{item.title}</strong>}
                          description={
                            item.description ? item.description : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col sm={24} md={12}>
                  <List
                    itemLayout="horizontal"
                    dataSource={dataview2}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<strong>{item.title}</strong>}
                          description={
                            item.description ? item.description : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ViewPermission;
