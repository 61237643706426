import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  Space,
  Select,
  DatePicker,
  TimePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function CreateSPDNMaterials(props) {
  const [data, setData] = useState({
    dn_no: "",
    purchase_order: "",
    supplier_code: "",
    supplier_name: "",
    address_1: "",
    group: "",
    line: "",
    tgl_deliveri: "",
    eta_sdi: "",
    unloading_finished: "",
    cycle_issue: "",
    tgl_order: "",
    description_1: "",
    description_2: "",
    items: [],
    customer: [],
    shipped: [],
    billed: [],
    alias: "",
    groups: [],
    lines: [],
  });

  useEffect(() => {
    getItem();
  }, []);

  const [dnMaterial, setDNMaterial] = useState({
    project: "",
    purchase_order: "",
    supplier_code: "",
    supplier_name: "",
    address_1: "",
    group: "",
    line: "",
    tgl_deliveri: "",
    eta_sdi: "",
    unloading_finished: "",
    cycle_issue: "",
    tgl_order: "",
    description_1: "",
    description_2: "",
    tgl_pelunasan: "",
    jam_pelunasan: "",

    items: [],
  });

  useEffect(() => {
    console.log(data);
  }, [data]);

  const [selectedId, setSelectedId] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [dnMaterialDetails, setDNMaterialDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [valCheckbox, setValCheckbox] = useState(false);

  const getItem = async (keyword) => {
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
        columns: ["description_1", "description_2"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDNMaterial({
          ...dnMaterial,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getGroup = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-grouping/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          groups: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };
  const getLine = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-line/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          lines: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };

  const getSupplier = async (keyword) => {
    await Axios.get(`${url}/master-supplier/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDNMaterial = (name, value) => {
    setDNMaterial({
      ...dnMaterial,
      [name]: value,
    });
  };

  const handleChangeSelectGroup = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: {
        id: value.value,
        name: value.label,
      },
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = dnMaterial.items.find((x) => x._id === value.key);
      setDNMaterial({
        ...dnMaterial,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.description_1,
        [`${name}_name`]: find.description_2,
        [`description_1`]: find.description_1,
        [`description_2`]: find.description_2,
        [`${name}_code`]: find.description_1,
        [`${name}_name`]: find.description_2,
      });
    } else if (name === "supplier") {
      const find = data.suppliers.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.supplier_code,
        [`${name}_name`]: find.supplier_name,
        [`address_1`]: find.address_1,
      });

      console.log(find.alias);
    } else if (name === "shipped") {
      const find = data.shipped.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });
    } else if (name === "billed") {
      const find = data.billed.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });
    } else {
      const find = dnMaterial.items.find((x) => x._id === value.key);
      setDNMaterial({
        ...dnMaterial,
        [`${name}_id`]: value.value,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });
    }
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifyDetails = [...dnMaterialDetails];
      const findDetails = modifyDetails.find((x) => x._id === selectedId);

      findDetails.project = dnMaterial.project;
      findDetails.description_1 = dnMaterial.description_1;
      findDetails.description_2 = dnMaterial.description_2;
      findDetails.std_pack = dnMaterial.std_pack;
      findDetails.kanban_order = dnMaterial.kanban_order;
      findDetails.total_order = dnMaterial.total_order;
      findDetails.qty_delivery = dnMaterial.qty_delivery;
      findDetails.total_minus = dnMaterial.total_minus;
      findDetails.tgl_pelunasan = dnMaterial.tgl_pelunasan;
      findDetails.jam_pelunasan = dnMaterial.jam_pelunasan;
      findDetails.keterangan = dnMaterial.keterangan;

      setDNMaterialDetails(modifyDetails);
    } else {
      setDNMaterialDetails([
        ...dnMaterialDetails,
        {
          _id: Math.random(),
          project: dnMaterial.project,
          description_1: dnMaterial.description_1,
          description_2: dnMaterial.description_2,
          std_pack: dnMaterial.std_pack,
          kanban_order: dnMaterial.kanban_order,
          total_order: dnMaterial.total_order,
          qty_delivery: dnMaterial.qty_delivery,
          total_minus: dnMaterial.total_minus,
          tgl_pelunasan: dnMaterial.tgl_pelunasan,
          jam_pelunasan: dnMaterial.jam_pelunasan,
          keterangan: dnMaterial.keterangan,
        },
      ]);
      console.log(dnMaterial.jam_pelunasan);
    }

    setSelectedId("");

    setDNMaterial({
      ...dnMaterial,
      project: "",
      description_1: "",
      description_2: "",
      std_pack: "",
      kanban_order: "",
      total_order: "",
      qty_delivery: "",
      total_minus: "",
      tgl_pelunasan: "",
      jam_pelunasan: "",
      keterangan: "",
    });

    setValCheckbox(false);
    setIsModalVisible(false);
  };

  const handleRemoveDetails = (id) => {
    const details = dnMaterialDetails.filter(
      (dnMaterialDetails) => dnMaterialDetails._id !== id
    );
    setDNMaterialDetails(details);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      dn_no: "",
      purchase_order: "",
      supplier_code: "",
      supplier_name: "",
      address_1: "",
      group: "",
      line: "",
      tgl_deliveri: "",
      eta_sdi: "",
      unloading_finished: "",
      cycle_issue: "",
      tgl_order: "",
    });

    setDNMaterialDetails([]);
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();

    fd.set("details", JSON.stringify(dnMaterialDetails));
    fd.set("dn_no", data.dn_no);
    fd.set("purchase_order", data.purchase_order);
    fd.set("supplier_code", data.supplier_code);
    fd.set("supplier_name", data.supplier_name);
    fd.set("address_1", data.address_1);
    fd.set("group", data.group.name);
    fd.set("line", data.line.name);
    fd.set("tgl_deliveri", data.tgl_deliveri);
    fd.set("eta_sdi", data.eta_sdi);
    fd.set("unloading_finished", data.unloading_finished);
    fd.set("cycle_issue", data.cycle_issue);
    fd.set("tgl_order", data.tgl_order);

    Axios.post(`${url}/master-material-order/manual`, fd, {
      headers: {
        "Content-Type": "multiitem/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success") {
          message.success(res.data.message);
        }
        props.history.push("/dn-material");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetModal = () => {
    setDNMaterial({
      ...dnMaterial,
      project: "",
      description_1: "",
      description_2: "",
      std_pack: "",
      kanban_order: "",
      total_order: "",
      qty_delivery: "",
      total_minus: "",
      tanggal_pelunasan: "",
      jam_pelunasan: "",
      keterangan: "",
    });
  };

  const closeModal = () => {
    setDNMaterial({
      ...dnMaterial,
      project: "",
      description_1: "",
      description_2: "",
      std_pack: "",
      kanban_order: "",
      total_order: "",
      qty_delivery: "",
      total_minus: "",
      tanggal_pelunasan: "",
      jam_pelunasan: "",
      keterangan: "",
    });

    setSelectedId("");
    setIsModalVisible(false);
    setValCheckbox(false);
  };

  const columns = [
    {
      align: "left",
      title: "Project",
      dataIndex: "project",
    },
    {
      align: "left",
      title: "Description",
      dataIndex: "description_1",
    },
    {
      align: "left",
      title: "Part Number",
      dataIndex: "description_2",
    },
    {
      align: "left",
      title: "Std Pack ",
      dataIndex: "std_pack",
    },
    {
      align: "left",
      title: "Kanban Order",
      dataIndex: "kanban_order",
    },
    {
      align: "left",
      title: "Total Order",
      dataIndex: "total_order",
    },
    {
      align: "left",
      title: "Qty Delivery",
      dataIndex: "qty_delivery",
    },
    {
      align: "left",
      title: "Total Minus",
      dataIndex: "total_minus",
    },
    {
      align: "left",
      title: "Tanggal Pelunasan",
      dataIndex: "tgl_pelunasan",
      render: (tgl_pelunasan) => moment(tgl_pelunasan).format("DD-MM-YYYY"),
    },
    {
      align: "left",
      title: "Jam Pelunasan",
      dataIndex: "jam_pelunasan",
      render: (jam_pelunasan) => moment(jam_pelunasan).format("HH:mm"),
    },
    {
      align: "left",
      title: "Keterangan",
      dataIndex: "keterangan",
    },

    {
      title: "Action",
      align: "center",
      dataIndex: "_id",
      width: 200,
      render: (id) => (
        <Fragment>
          <Button
            onClick={() => handleRemoveDetails(id)}
            size="small"
            type="danger"
          >
            <DeleteOutlined />
            Delete
          </Button>
        </Fragment>
      ),
    },
  ];

  console.log(dnMaterialDetails, "dnMaterialDetails");

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="DN Material History"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/dn-material">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create DN Material</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="DN Material"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.dn_no ? "error" : false
                              }
                              help={
                                error && error.dn_no ? error.dn_no[0] : false
                              }
                              label="DN No"
                            >
                              <Input
                                value={data.dn_no}
                                onChange={(e) => {
                                  handleChange("dn_no", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              label="Purchase Order"
                              validateStatus={
                                error && error.purchase_order ? "error" : false
                              }
                              help={
                                error && error.purchase_order
                                  ? error.purchase_order[0]
                                  : false
                              }
                            >
                              <Input
                                value={data.purchase_order}
                                onChange={(e) =>
                                  handleChange("purchase_order", e.target.value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.supplier_code ? "error" : false
                              }
                              help={
                                error && error.supplier_code
                                  ? error.supplier_code[0]
                                  : false
                              }
                              label="Supplier Code"
                            >
                              <Select
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("supplier", value)
                                }
                                onFocus={() => getSupplier("")}
                                showSearch
                                onSearch={(value) => getSupplier(value)}
                                filterOption={false}
                                value={{
                                  key: data.supplier_id,
                                  label: data.supplier_label,
                                }}
                              >
                                {data.suppliers &&
                                  data.suppliers.map((supplier) => {
                                    return (
                                      <Option
                                        value={supplier._id}
                                        key={supplier._id}
                                      >
                                        {`${supplier.supplier_code}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              required
                              label="Supplier Name"
                              validateStatus={
                                error && error.supplier_name ? "error" : false
                              }
                              help={
                                error && error.supplier_name
                                  ? error.supplier_name[0]
                                  : false
                              }
                            >
                              <Input
                                disabled
                                value={data.supplier_name}
                                onChange={(e) =>
                                  handleChange("supplier_name", e.target.value)
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              required
                              validateStatus={
                                error && error.address_1 ? "error" : false
                              }
                              help={
                                error && error.address_1
                                  ? error.address_1[0]
                                  : false
                              }
                              label="Alamat"
                            >
                              <Input
                                disabled
                                value={data.address_1}
                                onChange={(e) =>
                                  handleChange("address_1", e.target.value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.group ? "error" : false
                              }
                              help={
                                error && error.group ? error.group[0] : false
                              }
                              label="Group"
                            >
                              <Select
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectGroup("group", value)
                                }
                                onFocus={() => getGroup("")}
                                showSearch
                                onSearch={(value) => getGroup(value)}
                                filterOption={false}
                                value={{
                                  key: data.group.id,
                                  label: data.group.name,
                                }}
                              >
                                {data.groups &&
                                  data.groups.map((group) => {
                                    return (
                                      <Option value={group._id} key={group._id}>
                                        {group.group}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.line ? "error" : false
                              }
                              help={error && error.line ? error.line[0] : false}
                              label="Line"
                            >
                              <Select
                                allowClear
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectGroup("line", value)
                                }
                                onFocus={() => getLine("")}
                                showSearch
                                onSearch={(value) => getLine(value)}
                                filterOption={false}
                                value={{
                                  key: data.line.id,
                                  label: data.line.name,
                                }}
                              >
                                {data.lines &&
                                  data.lines.map((line) => {
                                    return (
                                      <Option value={line._id} key={line._id}>
                                        {line.line}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.tgl_deliveri ? "error" : false
                              }
                              help={
                                error && error.tgl_deliveri
                                  ? error.tgl_deliveri[0]
                                  : false
                              }
                              label="Tanggal Delivery"
                            >
                              <DatePicker
                                onChange={(value) =>
                                  handleChange("tgl_deliveri", value)
                                }
                                value={data.tgl_deliveri}
                                format="YYYY-MM-DD"
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.eta_sdi ? "error" : false
                              }
                              help={
                                error && error.eta_sdi
                                  ? error.eta_sdi[0]
                                  : false
                              }
                              label="Eta Sdi"
                            >
                              <TimePicker
                                onChange={(value) =>
                                  handleChange("eta_sdi", value)
                                }
                                value={data.eta_sdi}
                                format="HH:mm"
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.unloading_finished
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.unloading_finished
                                  ? error.unloading_finished[0]
                                  : false
                              }
                              label="Unloading Finished"
                            >
                              <TimePicker
                                onChange={(value) =>
                                  handleChange("unloading_finished", value)
                                }
                                value={data.unloading_finished}
                                format="HH:mm"
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.cycle_issue ? "error" : false
                              }
                              help={
                                error && error.cycle_issue
                                  ? error.cycle_issue[0]
                                  : false
                              }
                              label="Cycle Issue"
                            >
                              <Input
                                value={data.cycle_issue}
                                onChange={(e) =>
                                  handleChange("cycle_issue", e.target.value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.tgl_order ? "error" : false
                              }
                              help={
                                error && error.tgl_order
                                  ? error.tgl_order[0]
                                  : false
                              }
                              label="Tanggal Order"
                            >
                              <DatePicker
                                onChange={(value) =>
                                  handleChange("tgl_order", value)
                                }
                                value={data.tgl_order}
                                format="YYYY-MM-DD"
                              />
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Details"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button>

                        <Modal
                          title="Add Details DN Material"
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layout}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.project
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.project
                                              ? error.project[0]
                                              : false
                                          }
                                          label="Project"
                                        >
                                          <Input
                                            value={dnMaterial.project}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "project",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.description_1
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.description_1
                                              ? error.description_1[0]
                                              : false
                                          }
                                          label="Description"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelect("item", value)
                                            }
                                            onFocus={() => getItem("", "item")}
                                            showSearch
                                            onSearch={(value) => getItem(value)}
                                            filterOption={false}
                                            value={{
                                              key: dnMaterial.item_id,
                                              label: dnMaterial.item_label,
                                            }}
                                          >
                                            {dnMaterial.items &&
                                              dnMaterial.items.map((item) => {
                                                return (
                                                  <Option
                                                    value={item._id}
                                                    key={item._id}
                                                  >
                                                    {`${item.description_1}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.description_2
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.description_2
                                              ? error.description_2[0]
                                              : false
                                          }
                                          label="Part Name"
                                        >
                                          <Input
                                            disabled
                                            value={dnMaterial.description_2}
                                            onChange={(e) => {
                                              handleChangeSelect(
                                                "description_2",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.std_pack
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.std_pack
                                              ? error.std_pack[0]
                                              : false
                                          }
                                          label="Std Pack"
                                        >
                                          <Input
                                            value={dnMaterial.std_pack}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "std_pack",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.kanban_order
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.kanban_order
                                              ? error.kanban_order[0]
                                              : false
                                          }
                                          label="Kanban Order"
                                        >
                                          <Input
                                            value={dnMaterial.kanban_order}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "kanban_order",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.total_order
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.total_order
                                              ? error.total_order[0]
                                              : false
                                          }
                                          label="Total Order"
                                        >
                                          <Input
                                            value={dnMaterial.total_order}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "total_order",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.qty_delivery
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.qty_delivery
                                              ? error.qty_delivery[0]
                                              : false
                                          }
                                          label="Qty Delivery"
                                        >
                                          <Input
                                            value={dnMaterial.qty_delivery}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "qty_delivery",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.total_minus
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.total_minus
                                              ? error.total_minus[0]
                                              : false
                                          }
                                          label="Total_minus"
                                        >
                                          <Input
                                            value={dnMaterial.total_minus}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "total_minus",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.tgl_pelunasan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.tgl_pelunasan
                                              ? error.tgl_pelunasan[0]
                                              : false
                                          }
                                          label="Tanggal Pelunasan"
                                        >
                                          <DatePicker
                                            value={dnMaterial.tgl_pelunasan}
                                            format="YYYY-MM-DD"
                                            onChange={(value) => {
                                              handleChangeDNMaterial(
                                                "tgl_pelunasan",
                                                value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.jam_pelunasan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jam_pelunasan
                                              ? error.jam_pelunasan[0]
                                              : false
                                          }
                                          label="Jam Pelunasan"
                                        >
                                          <TimePicker
                                            onChange={(value) =>
                                              handleChangeDNMaterial(
                                                "jam_pelunasan",
                                                value
                                              )
                                            }
                                            value={dnMaterial.jam_pelunasan}
                                            format="HH:mm"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.keterangan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.keterangan
                                              ? error.keterangan[0]
                                              : false
                                          }
                                          label="Keterangan"
                                        >
                                          <TextArea
                                            value={dnMaterial.keterangan}
                                            onChange={(e) => {
                                              handleChangeDNMaterial(
                                                "keterangan",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                        size="small"
                        columns={columns}
                        scroll={{ x: 500 }}
                        dataSource={dnMaterialDetails}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                      />
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateSPDNMaterials;
