/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Menu,
  Form,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import send from "../../utils/send";
import Text from "antd/lib/typography/Text";

const { Search } = Input;
const { Content } = Layout;

function HistoryStockOpname() {
  const [keyword, setKeyword] = useState("");

  const [dataHistory, setDataHistory] = useState({
    data: [],
    total: 0,
  });

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });

  useEffect(() => {
    getData();
  }, [sort, page, pageSize, keyword]);

  const getData = () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    send({
      url: `${url}/history-stock-opname`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      data: {
        page,
        perpage: pageSize,
        keyword,
        order: sort.order,
        sort: sort.sort,
        columns: [
          "part_number",
          "tipe",
          "part_name",
          "storage",
          "supplier_name",
          "area",
        ],
      },
    })
      .then((res) => {
        if (res.success) {
          setDataHistory(res.data);
        } else {
          message.error(res.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Item Number",
      width: 200,
      dataIndex: "id_qad",
      sorter: false,
    },
    {
      title: "Unit",
      width: 200,
      dataIndex: "unit",
      sorter: false,
    },
    {
      title: "Tipe",
      width: 200,
      dataIndex: "tipe",
      sorter: false,
    },
    {
      title: "Part Name",
      width: 200,
      dataIndex: "part_name",
      sorter: false,
    },
    {
      title: "Storage",
      width: 200,
      dataIndex: "storage",
      sorter: false,
    },
    {
      title: "Photo",
      width: 100,
      dataIndex: "photo_url",
      sorter: false,
      render: (photo_url) => (
        <img style={{ width: 80, height: 70 }} src={photo_url} />
      ),
    },
    {
      title: "MATL.TYPE",
      width: 200,
      dataIndex: "matl_type",
      sorter: false,
    },
    {
      title: "Supplier/Subcont",
      width: 200,
      dataIndex: "supplier_subcont",
      sorter: false,
    },
    {
      title: "Stock Inventory",
      width: 200,
      dataIndex: "stock_inventory",
      sorter: false,
    },
    {
      title: "Stock Actual",
      width: 200,
      dataIndex: "stock_actual",
      sorter: false,
    },
    {
      title: "Qty Selisih",
      width: 200,
      dataIndex: "qty_selisih",
      sorter: false,
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "status",
      sorter: false,
      render: (status) => {
        return status === "Complete" ? (
          <Text style={{ color: "#089244" }}>Complete</Text>
        ) : (
          <Text type="danger">Incomplete</Text>
        );
      },
    },
    {
      title: "Keterangan",
      width: 200,
      dataIndex: "keterangan",
      sorter: false,
    },
    {
      title: "Date",
      width: 200,
      dataIndex: "created_at",
      render: (created_at) => {
        return moment(created_at).format("DD/MM/YYYY");
      },
      sorter: false,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="History Stock Opname"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={dataHistory.data}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: dataHistory.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default HistoryStockOpname;
