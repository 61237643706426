import React, { Fragment, useState } from "react";
import {
  Menu,
  Badge,
  Avatar,
  Space,
  Dropdown,
  Tabs,
  List,
  Button,
  Input,
  Modal,
  Card,
  Form,
  Row,
  Col,
  message,
} from "antd";
import {
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import "./Navbar.less";
import { Link } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import { url } from "../config/global";

const { TabPane } = Tabs;

const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
];

function Navbar(props) {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [formError, setFormError] = useState({});
  const handleNotification = () => {
    setVisible(!visible);
  };

  const handleSeeMore = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    console.log("terclick");
    let fd = new FormData();
    fd.set("id_user", sessionStorage.getItem("id_user"));
    Axios.post(`${url}/logout`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log('Logout')
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("id_user");
        sessionStorage.removeItem("parent_item");
        sessionStorage.removeItem("shift_code");
        sessionStorage.removeItem("dn_no");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
    return props.history.push("/login");
  };

  const handleToggleSearch = (value = false) => {
    console.log(value);
    setSearch(value);
  };

  const onSaveData = () => {
    let fd = new FormData();

    fd.set("new_password", form.new_password);
    fd.set("old_password", form.old_password);

    Axios.post(`${url}/change-password`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        setShowModal(!showModal);
        setForm({
          old_password: "",
          new_password: "",
          confirm_new_password: "",
        });
        // props.history.push("/master-user-internal");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          setShowModal(!showModal);
          setForm({
            old_password: "",
            new_password: "",
            confirm_new_password: "",
          });
        }
      });
  };

  return (
    <div className="navbar">
      <Modal
        width={600}
        title="Change Password"
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(!showModal)}
      >
        <Card className="body-data">
          <Form layout="vertical" onFinish={onSaveData}>
            <Row gutter={[30, 30]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  required
                  name="old_password"
                  label="Kata Sandi Lama"
                  rules={[
                    {
                      required: true,
                      message: "Kata Sandi Lama harus diisi",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    value={form.old_password}
                    onChange={(e) =>
                      setForm({ ...form, old_password: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  required
                  label="Kata Sandi Baru"
                  name="new_password"
                  help={formError.new_password}
                  rules={[
                    {
                      required: true,
                      message: "Kata Sandi Baru harus diisi",
                    },
                    {
                      min: 8,
                      message: "Kata Sandi minimal 8 karakter",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    value={form.new_password}
                    onChange={(e) => {
                      setForm({ ...form, new_password: e.target.value });
                      console.log(formError);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  required
                  label="Ulangi Kata Sandi Baru"
                  name="confirm_new_password"
                  dependencies={["new_password"]}
                  rules={[
                    {
                      required: true,
                      message: "Ulangi Kata Sandi Baru harus diisi",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Kata Sandi Baru dan Ulangi Kata Sandi Baru harus sama"
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="password"
                    value={form.confirm_new_password}
                    onChange={(e) =>
                      setForm({ ...form, confirm_new_password: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="start">
              <Col>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !!formError.old_password ||
                      !!formError.new_password ||
                      !!formError.confirm_new_password
                    }
                  >
                    Ubah Kata Sandi
                  </Button>
                  <Button type="default" htmlType="button">
                    Batal
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
      <div>
        {props.collapsed ? (
          <MenuUnfoldOutlined onClick={props.onToggleCollapsed} />
        ) : (
          <MenuFoldOutlined onClick={props.onToggleCollapsed} />
        )}
      </div>
      {/* <div>
                {moment(new Date()).format('DD MMMM YYYY')}                
            </div> */}
      <div>
        <Menu mode="horizontal" className="menu-header">
          <Menu.Item key="profile">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="3">
                    <span onClick={() => setShowModal(!showModal)}>
                      <UnlockOutlined />
                      Change Password
                    </span>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={handleLogout}>
                      <LogoutOutlined />
                      Logout
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  {sessionStorage.getItem("avatar") ? (
                    <Avatar
                      size="small"
                      src={sessionStorage.getItem("avatar")}
                    />
                  ) : (
                    <Avatar size="small" icon={<UserOutlined />} />
                  )}
                  {!props.screens.xs && (
                    <span> {sessionStorage.getItem("fullname")} </span>
                  )}
                </Space>
              </a>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
}

export default Navbar;
