import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  Upload,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./MasterShipment.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function MasterShipment() {
  const [keyword, setKeyword] = useState("");
  const [shipment, setShipment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [error, setError] = useState(null);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-shipment`, {
      params: {
        keyword,
        columns: ["group", "description"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setShipment(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/master-shift/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const HandlegetQAD = () => {
    setLoading(true);
    Axios.get(`${url}/soap/shipment`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // message.success(res.data.message);
        Modal.success({
          title: "Success!",
          content: res.data.message,
        });

        getData();
      })
      .catch((err) => {
        if (err.response) {
          // message.error(err.response.data.message);
          Modal.error({
            title: "Error!",
            content: err.response.data.message,
          });
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const exportCustomer = () => {
    setLoadingExport(true)
    Axios.get(`${url}/master-shipment/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Shipment" + ".xlsx");
    }).finally((res) => {
      setLoadingExport(false)
    });
  };

  const columns = [
    {
      title: "Group",
      dataIndex: "group",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Shipment"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
              <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "get-qad/shipment"
                      ) && (
                        <Link>
                          <Button onClick={HandlegetQAD} type="primary">
                            <PlusOutlined />
                            Sync QAD
                          </Button>
                        </Link>
                      )}

                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "shipment/export"
                      ) && (
                        <Button loading={loadingExport} onClick={exportCustomer} type="primary">
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={shipment.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: shipment.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MasterShipment;
