/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Space,
  Image,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { base_url, url, storage_path } from "../../config/global";
import moment from "moment";

const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditKanbanWIP(props) {
  const [data, setData] = useState({
    kanban_type: "",
    item_number: "",
    description_2: "",
    model: "",
    tipe: "",
    proses: "",
    qty_kanban: "",
    kanban_beredar: "",
    line_id: "",
    id_qad: "",
    line_name: "",
    idqad: [],
    qads: [],
    lines: [],
    line_id: "",
    line_name: "",
    storages: [],
    groups: [],
    customers: [],
    customer_id: "",
    customer_name: "",
  });

  useEffect(() => {
    getData();
    getKanban();
  }, []);

  const [codes, setCodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    if (name === "kanban_type") {
      setError(null);

      setData({
        ...data,
        [name]: value,
      });

      console.log(name, value, "kanban");
    } else {
      setError(null);
      setData({
        ...data,
        [name]: value,
      });
      console.log(name, value);
    }
  };

  const getData = () => {
    Axios.get(`${url}/master-kanban/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
      
        setData({
          ...data,
          //WIP
          kanban_type: res.data.data.kanban_type,
          item_number: res.data.data.id_qad,
          description_2: res.data.data.part_number,
          model: res.data.data.model,
          tipe: res.data.data.tipe,
          proses: res.data.data.proses,
          qty_kanban: res.data.data.qty_kanban,
          kanban_beredar: res.data.data.kanban_beredar,
          line_id: res.data.data.line_id,
          line_name: res.data.data.line_name,
          group_id: res.data.data.group_id,
          group_name: res.data.data.group_name,
          qads_label: res.data.data.id_qad,
          group_name: res.data.data.group,
          line_name: res.data.data.line,
          qads_label: res.data.data.id_qad,
          storage: res.data.data.storage,
          storages_label: res.data.data.storage,
          customer_name: res.data.data.customer_name,
          customer_id: res.data.data.customer_name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = async (name, value) => {
    if (name === "qads") {
      if (value) {
        const find = data.qads.find((x) => x._id === value.key);
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_label`]: value.label,
          ["item_number"]: find.item_number,
          [`description_2`]: find.description_2,
          [`job_number`]: find.job_number,
          [`${name}_code`]: find.supplier_code,
          [`${name}_name`]: find.supplier_name,
          [`supplier_code`]: find.supplier_code,
          [`type`]: find.type,
          [`marking`]: find.marking,
          [`tipe`]: find.tipe,
          ["file_img"]: find.file_img,
          ["customer_id"]: find.customer_name,
          ["customer_name"]: find.customer_name,
        });
      } else {
        setData({
          ...data,
          [`${name}_id`]: "",
          [`${name}_label`]: "",
          ["item_number"]: "",
          [`description_1`]: "",
          [`description_2`]: "",
          [`job_number`]: "",
          [`${name}_code`]: "",
          [`${name}_name`]: "",
          [`type`]: "",
          [`marking`]: "",
          [`qty_kanban`]: "",
          ["file_img"]: "",
        });
      }
    } else if (name === "parts") {
      const find = data.parts.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`description_2`]: find.description_2,
      });
    } else if (name === "storages") {
      await Axios.get(`${url}/master-kanban/check-in-storage`, {
        params: {
          id_qad: data.qads_label,
          storage: value.label,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          const find = data.storages.find((x) => x._id === value.key);
          setData({
            ...data,
            [`${name}_id`]: value.value,
            [`${name}_label`]: value.label,
            // [`${name}`]: value.label,
            [`storage`]: value.label,
            [`area`]: find.area,
            [`model`]: find.model,
          });
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          }

          return false;
        });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    }
  };

  const handleChangeSelectGroupLine = async (name, value) => {
    setError(null);

    // validation on here
    await Axios.get(`${url}/master-kanban/check-in-group`, {
      params: {
        id_qad: data.qads_label,
        group: value.label,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
          [`${name}_code`]: value.item_code,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }

        return false;
      });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("kanban_type", data.kanban_type);

    fd.set("description_2", data.description_2);
    fd.set("item_number", data.item_number);
    fd.set("model", data.model);
    fd.set("tipe", data.tipe);
    fd.set("proses", data.proses);
    fd.set("storage", data.storage);
    fd.set("prod_sequence", data.prod_sequence);
    fd.set("barcode", data.barcode);
    fd.set("qty_kanban", data.qty_kanban);
    fd.set("file_img", data.file_img);
    fd.set("customer", data.customer_name);

    if (data.qty_kanban) {
      fd.set("qty_kanban", data.qty_kanban);
    }
    fd.set("kanban_beredar", data.kanban_beredar);
    if (data.kanban_beredar) {
      fd.set("kanban_beredar", data.kanban_beredar);
    }
    if (data.group_name) {
      fd.set("group", data.group_name);
    }

    Axios.post(`${url}/master-kanban/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-kanban");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      kanban_type: "",
      job_type: "",
      parts: [],
      model: "",
      tipe: "",
      proses: "",
      storage: "",
      qty_kanban: "",
      prod_sequence: "",
      barcode: "",
      idqad: [],
      supplier: "",
      area: "",
      no_kanban: "",
      date_delivery: "",
      photo: "",
      marking: "",
      kanban_beredar: "",
      line_id: "",

      description_2: "",
      line_name: "",
      item_number: "",
      storages: [],
      file_img: "",
    });
  };

  const getQAD = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-item/list`, {
      params: {
        keyword,
        columns: ["item_number"],
        type: data.kanban_type,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          qads: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getKanban = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "kanban_type",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbans(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const getGroup = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-grouping/list`, {
      params: {
        keyword,
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          groups: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getCustomer = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-customer/list`, {
      params: {
        keyword,
        // order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getWHLocation = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/master-warehouse-location/list`, {
      params: {
        keyword,
        // order: sort.order,
        columns: ["storage"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          storages: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Kanban"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-kanban">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Kanban WIP</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Kanban Type"
                        validateStatus={
                          error && error.kanban_type ? "error" : false
                        }
                        help={
                          error && error.kanban_type
                            ? error.kanban_type[0]
                            : false
                        }
                      >
                        <Input
                          disabled
                          value={data.kanban_type}
                          onChange={(e) =>
                            handleChange("kanban_type", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="ID QAD"
                        validateStatus={
                          error && error.item_number ? "error" : false
                        }
                        help={
                          error && error.item_number
                            ? error.item_number[0]
                            : false
                        }
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Select
                            labelInValue
                            onChange={(value) =>
                              handleChangeSelect("qads", value)
                            }
                            onFocus={() => getQAD("", "wip")}
                            showSearch
                            onSearch={(value) => getQAD(value)}
                            filterOption={false}
                            value={{
                              key: data.qads_id,
                              label: data.qads_label,
                            }}
                          >
                            {data.qads &&
                              data.qads.map((item) => {
                                return (
                                  <Option value={item._id} key={item._id}>
                                    {console.log("ini", item)}
                                    {item.item_number}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Form.Item>
                      <Form.Item label="Part Number">
                        <Input
                          disabled
                          value={data.description_2}
                          onChange={(e) =>
                            handleChange("description_2", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Tipe">
                        <Input
                          disabled
                          value={data.tipe}
                          onChange={(e) => handleChange("tipe", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Proses"
                        validateStatus={error && error.proses ? "error" : false}
                        help={error && error.proses ? error.proses[0] : false}
                      >
                        <Input
                          value={data.proses}
                          onChange={(e) =>
                            handleChange("proses", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Customer"
                        validateStatus={
                          error && error.customer ? "error" : false
                        }
                        help={
                          error && error.customer ? error.customer[0] : false
                        }
                      >
                        <Select
                          allowClear="true"
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("customer", value)
                          }
                          onFocus={() => getCustomer("")}
                          showSearch
                          onSearch={(value) => getCustomer(value)}
                          filterOption={false}
                          value={{
                            key: data.customer_id,
                            label: data.customer_name,
                          }}
                        >
                          {data.customers &&
                            data.customers.map((customer) => {
                              return (
                                <Option value={customer._id} key={customer._id}>
                                  {customer.customer_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        required
                        label="Storage"
                        validateStatus={
                          error && error.storage ? "error" : false
                        }
                        help={error && error.storage ? error.storage[0] : false}
                      >
                        <Select
                          allowClear="true"
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("storages", value)
                          }
                          onFocus={() => getWHLocation("")}
                          showSearch
                          onSearch={(value) => getWHLocation(value)}
                          filterOption={false}
                          value={{
                            key: data.storages_id,
                            label: data.storages_label,
                          }}
                        >
                          {data.storages &&
                            data.storages.map((storage) => {
                              return (
                                <Option value={storage._id} key={storage._id}>
                                  {storage.storage}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>{" "}
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item label="Model">
                        <Input
                          disabled
                          value={data.model}
                          onChange={(e) =>
                            handleChange("model", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Group"
                        validateStatus={error && error.group ? "error" : false}
                        help={error && error.group ? error.group[0] : false}
                      >
                        <Select
                          allowClear
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelectGroupLine("group", value)
                          }
                          onFocus={() => getGroup("")}
                          showSearch
                          onSearch={(value) => getGroup(value)}
                          filterOption={false}
                          value={{
                            key: data.group_id,
                            label: data.group_name,
                          }}
                        >
                          {data.groups &&
                            data.groups.map((group) => {
                              return (
                                <Option value={group._id} key={group._id}>
                                  {group.group}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        required
                        label="Qty Kanban / Standar Packing"
                        validateStatus={
                          error && error.qty_kanban ? "error" : false
                        }
                        help={
                          error && error.qty_kanban
                            ? error.qty_kanban[0]
                            : false
                        }
                      >
                        <Input
                          value={data.qty_kanban}
                          onChange={(e) =>
                            handleChange("qty_kanban", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Kanban Beredar"
                        validateStatus={
                          error && error.kanban_beredar ? "error" : false
                        }
                        help={
                          error && error.kanban_beredar
                            ? error.kanban_beredar[0]
                            : false
                        }
                      >
                        <Input
                          disabled={true}
                          value={data.kanban_beredar}
                          onChange={(e) =>
                            handleChange("kanban_beredar", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="mr-button"
                      >
                        Submit
                      </Button>
                      <Button htmlType="reset" onClick={handleReset}>
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditKanbanWIP;
