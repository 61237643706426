import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Input,
  Table,
  Form,
  message,
  Button,
  Space,
  Modal,
  Menu,
  Dropdown,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { BarcodeOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function SPDetailsMaterialOrder(props) {
  const [keyword, setKeyword] = useState("");
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);

  const pathname = props.location.pathname.replace("/", "").split("/");

  const [sort, setSort] = useState({
    sort: "purchase_order",
    order: "ascend",
  });

  const [materialID, setMaterialID] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [buttonAccess, setButtonAccess] = useState(null);

  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [generate, setGenerate] = useState({
    purchase_order: "",
  });

  const [statMaterialOrder, setStatMaterialOrder] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }

      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/master-material-order/details`, {
      params: {
        keyword,
        purchase_order: pathname[2],
        search: "",
        columns: ["purchase_order", "component_item"],
        perpage: pageSize,
        page,
        sort: "purchase_order",
        order: "ascend",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(selectedRowsData);
  const onSaveData = () => {
    console.log(selectedRowsData.length);
    if (selectedRowsData.length <= 0) {
      message.error("Anda Belum Memilih");
    } else {
      setLoading(true);
      Axios.post(
        `${url}/master-material-order/generate`,
        {
          details: selectedRowsData,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          getData();
          props.history.push("/dn-material");
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          } else {
            message.error("Backend not yet Started");
          }
          setLoading(false);
        });
      setSelectedRowsData([]);
      setSelectedRowKeysData([]);
    }
  };

  const onSubmit = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("purchase_order", data.purchase_order);
    fd.set("item_number", data.item_number);
    fd.set("qty_ordered", data.qty_ordered);

    Axios.post(`${url}/material-order/${materialID}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        closeModalMaterial();
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const closeModalMaterial = () => {
    setData({
      ...data,
      purchase_order: "",
      item_number: "",
      qty_ordered: "",
    });
    setIsModal(false);
  };

  const handleReset = () => {
    setData({
      ...data,
      purchase_order: "",
      item_number: "",
      qty_ordered: "",
    });
  };
  //#endregion

  //#region Table Config
  const columns = [
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
    },
    {
      title: "Component Item",
      dataIndex: "component_item",
      sorter: false,
    },
    {
      title: "Line",
      dataIndex: "line",
      sorter: false,
    },
    {
      title: "Description 1",
      dataIndex: "description1",
      sorter: false,
    },
    {
      title: "Description 2",
      dataIndex: "description2",
      sorter: false,
    },

    {
      title: "Quantity Order (Qty PO)",
      // dataIndex: "quantity_ordered",
      render: (data) => {
        var nf = new Intl.NumberFormat()
    
        return nf.format(data.quantity_ordered)
      },
      sorter: false,
    },
    {
      title: "Quantity Received PO",
      // dataIndex: "quantity_received",
      render: (data) => {
        var nf = new Intl.NumberFormat()
    
        return nf.format(data.quantity_received)
      },
      sorter: false,
    },
    {
      title: "Quantity Open",
      // dataIndex: "quantity_open",
      render:(data) => {
        var nf = new Intl.NumberFormat()
        // return (<Fragment>
        //   {nf.format(Number(data.quantity_ordered) - Number(data.quantity_received))}
        // </Fragment>)
        return data.quantity_open;
      },
      sorter: false,
    },
    {
      title: "Std Pack",
      // dataIndex: "std_pack",
      render: (data) => {
        var nf = new Intl.NumberFormat()
    
        return nf.format(data.item.order_multiple)
      },
      sorter: false,
    },
    {
      title: "Quantity Daily Order",
      // dataIndex: "qty_daily_order",
      render: (data) => {
        var nf = new Intl.NumberFormat()
    
        return nf.format(data.qty_daily_order)
      },
      sorter: false,
    },
    
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   fixed: "right",
    //   width: 75,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               <Menu.Item key="1">
    //                 <Link to={`/material-order/edit/${id}`}>
    //                   <PlusOutlined /> Manual Order
    //                 </Link>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Material Order Details "
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={`/${pathname[0]}`}>Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{pathname[1]}</Breadcrumb.Item>
                <Breadcrumb.Item>{pathname[2]}</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Manual Order"
        style={{ top: 20 }}
        visible={isModal}
        width={1000}
        footer={null}
        onCancel={closeModalMaterial}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Form {...layout} name="control-hooks" labelAlign="left">
                <Row>
                  <Fragment>
                    <Col xs={24}>
                      <Form.Item
                        validateStatus={
                          error && error.purchase_order ? "error" : false
                        }
                        help={
                          error && error.purchase_order
                            ? error.purchase_order[0]
                            : false
                        }
                        label="Purchase Order"
                      >
                        <Input
                          disabled
                          value={data.purchase_order}
                          onChange={(e) =>
                            handleChange("purchase_order", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={
                          error && error.item_number ? "error" : false
                        }
                        help={
                          error && error.item_number
                            ? error.item_number[0]
                            : false
                        }
                        label="Item Number"
                      >
                        <Input
                          disabled
                          value={data.item_number}
                          onChange={(e) => {
                            handleChange("item_number", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={
                          error && error.qty_ordered ? "error" : false
                        }
                        help={
                          error && error.qty_ordered
                            ? error.qty_ordered[0]
                            : false
                        }
                        label="Qty Ordered"
                      >
                        <Input
                          value={data.qty_ordered}
                          onChange={(e) => {
                            handleChange("qty_ordered", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSubmit}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Submit
              </Button>
              <Button htmlType="reset" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link>
                      <Button 
                        disabled={statMaterialOrder}
                        onClick={onSaveData} 
                        type="primary"
                      >
                        <BarcodeOutlined />
                        Generate
                      </Button>
                    </Link>
                  </Space>
                </Col>
                {/* <Col xs={24} md={12} lg={18}></Col> */}

                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col xs={24}>
                  <Form form={form} component={false}>
                    <Table
                      bordered
                      dataSource={data}
                      rowKey={(data) => data._id}
                      columns={columns}
                      rowClassName="editable-row"
                      loading={loading}
                      scroll={{ x: 1000 }}
                      onChange={handleTableChange}
                      pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: data.total,
                        showSizeChanger: true,
                      }}
                      rowSelection={{
                        selectedRowKeys: selectedRowKeysData,
                        type: "checkbox",
                        onChange: (selectedRowKeys, selectedRows, record) => {
                          let collArr = [];
                          console.log(selectedRows)
                          selectedRows.map((x) => {
                            console.log('GENERATE',x)
                            var nf = new Intl.NumberFormat()
                            return x.quantity_ordered == 0 || 
                            x.qty_daily_order == 0 || nf.format(x.item.order_multiple) == 0 || x.quantity_ordered == null || 
                            x.qty_daily_order == null || nf.format(x.item.order_multiple) == null || x.quantity_ordered <= x.quantity_received || x.quantity_ordered == x.quantity_received ? 
                              message.error(`Silahkan cek kembali yang anda pilih, untuk Qty PO, Qty Daily Order & Std Pack tidak boleh kosong / null.`)
                              : ''
                          })

                          selectedRows.map((x) => {
                            var nf = new Intl.NumberFormat()
                            return x.quantity_ordered == 0 || 
                            x.qty_daily_order == 0 || nf.format(x.item.order_multiple) == 0 || x.quantity_ordered == null || 
                            x.qty_daily_order == null || nf.format(x.item.order_multiple) == null || x.quantity_ordered <= x.quantity_received || x.quantity_ordered == x.quantity_received ? 
                              collArr.push(true)
                              : collArr.push(false)
                          })

                          setStatMaterialOrder(collArr.some(x => x === true));

                          setSelectedRowsData(selectedRows);
                          setSelectedRowKeysData(selectedRowKeys);

                          const purchase_order = selectedRows
                            ? selectedRows[0]
                            : null;

                          setGenerate({
                            ...generate,
                            purchase_order,
                          });
                        },
                        getCheckboxProps: (record) => {
                          const checkDisable = selectedRowsData
                            ? selectedRowsData[0]
                            : null;

                          return {
                            disabled:
                              (checkDisable &&
                                checkDisable.purchase_order !==
                                  record.purchase_order) ||
                              record.status === 1,
                          };
                        },

                        columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                      }}
                    />
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPDetailsMaterialOrder;
